/* eslint-disable react-hooks/exhaustive-deps */
import {BaseButton, BaseLoading, BasePopup, Icon} from "components"
import styles from "./PreviewDocument.module.css"
import {PDFViewer} from "components/FileViewer"
import {useEffect, useState} from "react"
import {documentService} from "services"
import {handleError} from "helpers"

type Props = {
    userDocumentId?: number
    isVisible: boolean
    onClose: () => void
    goToDetails: (document: any) => void
}

export function PreviewDocument(props: Props) {
    const {userDocumentId, isVisible, onClose, goToDetails} = props
    const [document, setDocument] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (userDocumentId) {
            getDocumentDetail(+userDocumentId)
        }
    }, [userDocumentId])

    const getDocumentDetail = async (id: number) => {
        try {
            setIsLoading(true)
            const data = await documentService.getDocumentDetail(id)
            setDocument({...data, id})
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }

    if (!document) return null

    return (
        <BasePopup isShow={isVisible} onClose={onClose} isShowLeftSide={false} width="70vw" closable={false}>
            <div>
                <div className={styles.previewHeader}>
                    <div className={styles.previewTitle}>
                        <Icon icon="FILE_TEXT" className={styles.icon} />
                        <span className={styles.title}>Preview Document</span>
                    </div>
                    <div className={styles.actions}>
                        <BaseButton title="Document Details" onClick={() => goToDetails(document)} />
                        <div className={styles.actionClose} onClick={onClose}>
                            <Icon icon="CLOSE" color="#000" className={styles.closeIcon} />
                        </div>
                    </div>
                </div>
                <PDFViewer fileSrc={document.documentUrl} options={{zoom: "fitToWidth", view: "fitToWidth"}} />
                <BaseLoading isShow={isLoading} />
            </div>
        </BasePopup>
    )
}
