import React from "react"
import {Tabs} from "antd"
import {useActiveTab} from "hooks"
import {LedgerTab} from "./parts"
import {ChargesTab} from "./parts/ChargesTab"
import {PaymentsTab} from "./parts/PaymentsTab"

const {TabPane} = Tabs

export enum StudentLedgerSubtab {
    Ledger = "ledger",
    Charges = "charges",
    Payments = "payments"
}

export function StudentLedgerTab(props) {
    const [activeTabKey, onChangeTab] = useActiveTab(StudentLedgerSubtab.Ledger, "studentledgertab")

    return (
        <div className="mt-16">
            <Tabs className="fullwidth klassSubTab" activeKey={activeTabKey} onChange={onChangeTab}>
                <TabPane tab="Ledger" key={StudentLedgerSubtab.Ledger}>
                    {activeTabKey === StudentLedgerSubtab.Ledger && <LedgerTab {...props} />}
                </TabPane>
                <TabPane tab="Charges" key={StudentLedgerSubtab.Charges}>
                    {activeTabKey === StudentLedgerSubtab.Charges && <ChargesTab {...props} />}
                </TabPane>
                <TabPane tab="Payments" key={StudentLedgerSubtab.Payments}>
                    {activeTabKey === StudentLedgerSubtab.Payments && <PaymentsTab {...props} />}
                </TabPane>
            </Tabs>
        </div>
    )
}
