/* eslint-disable react-hooks/exhaustive-deps */
import {
    checkPermission,
    formatDateOnly,
    formatDateRange,
    formatDecimal,
    formatDecimalWithoutRound,
    getFieldLabel,
    getFullName,
    handleError,
    roundTwoDigits
} from "helpers"
import jsPDF from "jspdf"
import React, {useEffect, useMemo, useRef, useState} from "react"
import {
    fontBold,
    fontRegular
} from "sections/FinancialAid/FinancialAidStudent/parts/CollegeFinancingPlan/CFPStepDetail/font"
import {degreeAuditService, reportsService, studentService} from "services"
import styles from "./ProgressReportPdf.module.css"
import {Branding} from "types/branding"
import {useModel, useVisible} from "hooks"
import StudentInfoCredit from "./StudentInfoCredit"
import {ExportButton, ExportFileType, Screen} from "components/ui"
import {Auth} from "types/auth"
import {get} from "lodash"
import {BaseDepartmentId} from "types/departments"
import moment from "moment"
import TermCourseClock from "./TermCourseClock"
import PracticalTable from "./PracticalTable"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import StudentInfoClock from "./StudentInfoClock"
import TermCourseCredit from "./TermCourseCredit"
import {Course} from "types/courses"
import {exportMultiTableToCsv} from "helpers/export-table"

import {AcademicPlans} from "types/academicPlans"
import {Settings} from "types/settings"
import {GeneralSettingField} from "types/settings/general-setting"
import FooterReport from "./FooterReport"
import {CollegeSlug} from "types/clients"
import {SignaturePopup} from "uiKit"
import {Permissions} from "@edular/permissions"
import OfficialTranscriptSignature from "./OfficialTranscriptSignature"
import FooterPdf from "./Footer"
import {useQuery} from "@tanstack/react-query"
import {BaseLoading} from "components"
import {ClockHoursTranscriptSections} from "./ClockHoursTranscripts"

type ProgressReportPdfProps = {
    match: {
        params: {
            profileId: number
            departmentId: BaseDepartmentId
            type: Course.CourseType
            transcriptType: GeneralSettingField
        }
    }
}

const GenerateTranscript = (props: ProgressReportPdfProps) => {
    const {
        match: {
            params: {profileId, departmentId: departmentIdValue, type, transcriptType}
        }
    } = props
    const signaturePopup = useVisible()
    const exportTypeFormat = useRef<ExportFileType>()
    const [signatureBase64, setSignatureBase64] = useState<string>()
    const model = useModel()
    const pdfRef = useRef<HTMLDivElement>()
    const pdfRefFooter = useRef<HTMLDivElement>()
    const footerTranscriptRef = useRef<HTMLDivElement>()
    const [downloading, setDownloading] = useState(false)
    const [listOfSubmodules, setListOfSubmodules] = useState<string[]>([])
    const [studentInfo, setStudentInfo] = useState<Auth.DepartmentStudent | undefined>()
    const [totalHoursSubModules, setTotalHoursSubModules] = useState(0)
    const [logoBase64, setLogoBase64] = useState("")
    const slug = model?.clientSetting?.slug

    const studentProfileId = Number(profileId)
    const departmentId = Number(departmentIdValue)

    const {data: progressReportData, isFetching} = useQuery(
        ["progress-report-data", studentProfileId, departmentId],
        () =>
            reportsService.getProgressReport({
                studentProfileId,
                departmentId
            }),
        {
            onSuccess: ({departmentStudentDetail}) => {
                if (model.clientSetting.isNewStudentStatusesVisible) {
                    departmentStudentDetail.studentStatus = departmentStudentDetail.status
                    setStudentInfo({...departmentStudentDetail})
                } else {
                    getStatusProfile(departmentStudentDetail)
                }
            },
            onError: (error) => {
                handleError(error)
            },
            initialData: {
                transferCredits: [],
                previouslyCompletedCredits: [],
                listOfCurrentProgressTable: [],
                practicalCourses: [],
                overallStudentStats: {}
            }
        }
    )
    const {
        clientDetail,
        academicProgressData,
        listOfCurrentProgressTable,
        transferCredits,
        previouslyCompletedCredits,
        practicalCourses,
        overallStudentStats,
        clockHourTranscriptSections
    } = progressReportData

    const logo = clientDetail?.college_logo || model.clientSetting?.branding?.[Branding.BrandingType.OfficialLogoSquare]

    function convertImgToBase64(url, callback) {
        var canvas: any = document.createElement("CANVAS")
        var ctx = canvas.getContext("2d")
        var img = new Image()
        img.crossOrigin = "Anonymous"
        img.onload = function () {
            canvas.height = img.height
            canvas.width = img.width
            ctx.drawImage(img, 0, 0)
            var dataURL = canvas.toDataURL("image/png")
            callback.call(this, dataURL)
            canvas = null
        }
        img.src = url
    }

    useEffect(() => {
        if (logo) {
            convertImgToBase64(logo + `?t=${Date.now()}`, function (base64Img) {
                setLogoBase64(base64Img)
            })
        }
    }, [logo])

    const getStatusProfile = async (student) => {
        try {
            const {data: statuses} = await studentService.getStudentStatuses(+profileId)

            statuses.forEach((status) => {
                if (status.departmentId === BaseDepartmentId.StudentAccount) {
                    student.studentAccountStatus = status.status
                } else if (status.departmentId === BaseDepartmentId.Academics) {
                    student.studentStatus = status.status
                }
            })
            setStudentInfo({...student})
        } catch (err) {
            handleError(err)
        }
    }

    const getData = async (termId: number) => {
        try {
            const params: any = {
                studentProfileId: +profileId,
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                }
            }
            if (termId) {
                params.termIds = [termId]
            }
            const {data, total} = await degreeAuditService.getDetailCurrentProgress(params)
            const checkEarnedHours = (data) => {
                if (type === Course.CourseType.Credit) {
                    if (data.status === AcademicPlans.AcademicPlanCourseReviewStatus.Dropped) {
                        return "Withdrawn"
                    } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Suspended) {
                        return "Suspended"
                    } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Completed) {
                        return data.earnedCredits
                    } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Failed) {
                        return "Failed"
                    } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Inprogress) {
                        return "In-progress"
                    } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Incomplete) {
                        return "Incomplete"
                    } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.LoaIncomplete) {
                        return "Loa-Incomplete"
                    } else if (data.courseStatus === AcademicPlans.AcademicPlanCourseStatus.Loa) {
                        return "Loa"
                    } else if (data.status === AcademicPlans.AcademicPlanCourseReviewStatus.Registered) {
                        return data.earnedCredits
                    } else {
                        return ""
                    }
                } else {
                    if (data.status === AcademicPlans.AcademicPlanCourseReviewStatus.Dropped) {
                        return "Withdrawn"
                    } else if (data.status === AcademicPlans.AcademicPlanCourseStatus.Suspended) {
                        return "Suspended"
                    } else if (data.status === AcademicPlans.AcademicPlanCourseReviewStatus.Registered) {
                        return data.earnedHours
                    } else {
                        return ""
                    }
                }
            }
            const listData = data.map((item) => ({
                ...item,
                completedDate: formatDateOnly(item.lastDayOfCourse, model.getUserDateFormat()),
                alpha: typeof item?.alpha === "string" ? item?.alpha : "",
                earnedHours: checkEarnedHours(item)
            }))
            return listData
        } catch (err) {
            handleError(err)
        }
    }

    useEffect(() => {
        if (signatureBase64?.length && exportTypeFormat.current) {
            handleDownload(exportTypeFormat.current)
        }
    }, [signatureBase64])

    const handleDownload = React.useCallback(
        async (exportType: ExportFileType) => {
            setDownloading(true)
            try {
                if (exportType === "pdf") {
                    const element = pdfRef.current
                    const elementFooter = pdfRefFooter.current

                    const pdfWidth = 210
                    const elementWidth = element.clientWidth
                    const widthScaleFactor = pdfWidth / elementWidth

                    const pdf = new jsPDF("p", "mm", "a4")

                    pdf.addFileToVFS("Poppins-regular.ttf", fontRegular)
                    pdf.addFont("Poppins-regular.ttf", "Poppins", "normal", "normal")
                    pdf.addFileToVFS("Poppins-bold.ttf", fontBold)
                    pdf.addFont("Poppins-bold.ttf", "Poppins", "normal", "bold")

                    pdf.setFont("Poppins")

                    await pdf.html(element, {
                        image: {
                            type: "jpeg",
                            quality: 100
                        },
                        html2canvas: {
                            scale: widthScaleFactor,
                            allowTaint: true,
                            letterRendering: true,
                            svgRendering: true
                        },
                        autoPaging: "text",
                        margin: [10, 0, 25, 0],
                        x: 0,
                        y: 0,
                        width: pdfWidth,
                        windowWidth: elementWidth
                    })
                    if (elementFooter) {
                        const pageCount = pdf.getNumberOfPages()
                        const pageSize = pdf.internal.pageSize
                        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
                        await pdf.html(elementFooter, {
                            image: {
                                type: "jpeg",
                                quality: 100
                            },
                            html2canvas: {
                                scale: 1,
                                allowTaint: true,
                                letterRendering: true,
                                svgRendering: true
                            },
                            autoPaging: "text",
                            margin: [0, 0, 10, 0],
                            x: 0,
                            y: pageHeight * pageCount
                        })
                    }
                    const pageCount = pdf.getNumberOfPages()
                    for (let i = 1; i <= pageCount; i++) {
                        pdf.setPage(i)
                        const pageSize = pdf.internal.pageSize
                        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()

                        const footerTranscript = footerTranscriptRef.current
                        await pdf.html(footerTranscript, {
                            x: 0,
                            y: pageHeight * i - 15,
                            image: {
                                type: "jpeg",
                                quality: 100
                            },
                            html2canvas: {
                                scale: widthScaleFactor,
                                allowTaint: true,
                                letterRendering: true,
                                svgRendering: true
                            },
                            width: pdfWidth,
                            windowWidth: elementWidth
                        })
                    }
                    pdf.save(`Transcript - ${getFullName(studentInfo)}.pdf`)
                } else if (exportType === "csv") {
                    let listTable = []

                    if (transferCredits?.length) {
                        listTable = listTable.concat([
                            {
                                columns: ["TRANSFERRED CREDITS", "\n"],
                                rows: []
                            },
                            {
                                columns: [
                                    "TRANSFER ID",
                                    "COURSE NAME",
                                    "TERM NAME",
                                    "UNIVERSITY",
                                    "EQUIVALENT ID",
                                    "COURSE NAME",
                                    "UNITS EARNED",
                                    "QUALITY POINTS",
                                    "GRADE",
                                    "GRADE POINTS"
                                ],
                                rows: transferCredits.map((item) => {
                                    return [
                                        "idTransfer",
                                        "courseNameTransfer",
                                        "termNameTransfer",
                                        "universityName",
                                        "codeForTranscript",
                                        "nameForTranscript",
                                        "appliedCredits",
                                        "qualityPoints",
                                        "alphabetical",
                                        "grades"
                                    ].map((col) => {
                                        const value = item[col] || ""
                                        return value.toString()
                                    })
                                })
                            },
                            {
                                columns: ["\n", "\n"],
                                rows: []
                            }
                        ])
                    }

                    if (previouslyCompletedCredits?.length) {
                        listTable = listTable.concat([
                            {
                                columns: ["PREVIOUSLY COMPLETED CREDITS", "\n"],
                                rows: []
                            },
                            {
                                columns: [
                                    "ID",
                                    "COURSE NAME",
                                    "TERM NAME",
                                    "EARNED CREDITS",
                                    "QUALITY POINTS",
                                    "GRADE",
                                    "GRADE POINTS"
                                ],
                                rows: previouslyCompletedCredits.map((item) => {
                                    return [
                                        "codeForTranscript",
                                        "nameForTranscript",
                                        "termName",
                                        "creditsApplied",
                                        "qualityPoints",
                                        "alphabetical",
                                        "grades"
                                    ].map((col) => {
                                        const value = item[col] || ""
                                        return value.toString()
                                    })
                                })
                            },
                            {
                                columns: ["\n", "\n"],
                                rows: []
                            }
                        ])
                    }

                    const listTermIds = listOfCurrentProgressTable.map((item) => item.termId)
                    const tableDetails: any = await Promise.all(listTermIds.map((termId) => getData(termId)))
                    for (let i = 0; i < tableDetails.length; i++) {
                        const termDetail = listOfCurrentProgressTable[i]
                        const tableDetail = tableDetails[i]
                        const gpa =
                            typeof termDetail?.totalGpa === "number"
                                ? formatDecimal(termDetail?.totalGpa)?.toString()
                                : termDetail?.totalGpa ?? ""
                        const cgpa =
                            typeof termDetail?.cgpa === "number"
                                ? formatDecimal(termDetail.cgpa)?.toString()
                                : termDetail?.cgpa ?? ""
                        listTable.push({
                            columns: [
                                termDetail?.name,
                                formatDateRange(termDetail.startDate, termDetail.endDate, model.getUserDateFormat()),
                                getFieldLabel(model, Settings.AcademicLabel.Gpa, "GPA"),
                                gpa,
                                getFieldLabel(model, Settings.AcademicLabel.Cgpa, "CGPA"),
                                cgpa,
                                "\n"
                            ],
                            rows: []
                        })
                        const submodules =
                            transcriptType === GeneralSettingField.ProgressReportSubmodulesBreakDown
                                ? listOfSubmodules
                                : []
                        listTable.push({
                            columns:
                                type === Course.CourseType.Credit
                                    ? [
                                          "COURSE ID",
                                          "NAME",
                                          "COMPLETED DATE",
                                          "UNITS ATTEMPTED",
                                          "UNITS EARNED",
                                          "QUALITY POINTS",
                                          "ALPHABETICAL",
                                          "GRADE POINTS"
                                      ]
                                    : [
                                          "COURSE ID",
                                          "NAME",
                                          "TERM",
                                          "COMPLETED DATE",
                                          ...submodules.map((title) => title),
                                          "QUALITY POINTS",
                                          "COMPLETED HOURS",
                                          "ALPHABETICAL",
                                          "GRADE"
                                      ],
                            rows: tableDetail.map((item) => {
                                return (
                                    type === Course.CourseType.Credit
                                        ? [
                                              "codeForTranscript",
                                              "nameForTranscript",
                                              "completedDate",
                                              "credit",
                                              "earnedHours",
                                              "qualityPoints",
                                              "alpha",
                                              "totalGpa"
                                          ]
                                        : [
                                              "codeForTranscript",
                                              "nameForTranscript",
                                              "nameTerm",
                                              "completedDate",
                                              ...submodules.map((title) => {
                                                  const submodule = item.submodules.find((item) => item.title === title)
                                                  return submodule?.quantity ?? 0
                                              }),
                                              "qualityPoints",
                                              "earnedHours",
                                              "alpha",
                                              "totalGpa"
                                          ]
                                ).map((col, index) => {
                                    if (col === "nameForTranscript") {
                                        return `${item[col]}${
                                            item["role"] === Course.AcademicPlanCourseRole.ClassAssistance
                                                ? " (CA)"
                                                : ""
                                        }`
                                    }
                                    if (
                                        transcriptType === GeneralSettingField.ProgressReportSubmodulesBreakDown &&
                                        index >= 4 &&
                                        index <= 4 + listOfSubmodules.length - 1
                                    ) {
                                        return col.toString()
                                    }
                                    return get(item, [col], "")?.toString() ?? ""
                                })
                            })
                        })
                        listTable = listTable.concat([
                            {
                                columns: ["\n"],
                                rows: []
                            },
                            {
                                columns:
                                    type === Course.CourseType.Credit
                                        ? ["", "TERM AVG", "", "", "", "", "", gpa]
                                        : ["", "TERM AVG", "", "", "", ...submodules.map(() => ""), "", "", "", gpa],
                                rows: []
                            },
                            {
                                columns: ["\n", "\n"],
                                rows: []
                            }
                        ])
                    }
                    const studentName = getFullName(studentInfo) || "-"
                    const customProfileId = get(studentInfo, ["customProfileId"], "")
                    const startDate = get(studentInfo, ["startDate"])
                    const appliedToDegree = academicProgressData?.appliedToDegree ?? 0
                    const degreeRequirement = academicProgressData?.degreeRequirement ?? 0
                    const attempted = academicProgressData?.attempted ?? 0
                    const missedHours = academicProgressData?.missedHours ?? 0
                    const totalTransferApplied = academicProgressData?.totalTransferApplied ?? 0
                    const totalPreviousCompletedApplied = academicProgressData?.totalPreviousCompletedApplied ?? 0
                    const makeUpHours = academicProgressData?.makeUpHours ?? 0
                    const degreeCredential = studentInfo?.degreeCredential ?? ""
                    const studentStatusName = get(studentInfo, ["studentStatus", "name"], "")
                    const programName = studentInfo?.programName ?? ""
                    const campusName = get(studentInfo, ["campuses", 0], "")
                    const campusAddress = get(studentInfo, ["campusAddresses", 0], "")
                    const dob = formatDateOnly(studentInfo?.birthDate, model.getUserDateFormat())
                    let headerInfo = [
                        {
                            label: "ID",
                            value: customProfileId
                        },
                        {
                            label: "STUDENT",
                            value: studentName
                        },
                        {
                            label: "DOB",
                            value: dob
                        },
                        {
                            label: "MAJOR",
                            value: programName
                        },
                        {
                            label: "DEGREE CREDENTIALS",
                            value: degreeCredential
                        },
                        {
                            label: "CAMPUS",
                            value: campusName
                        },
                        {
                            label: "CAMPUS ADDRESS",
                            value: campusAddress
                        },
                        {
                            label: "STATUS",
                            value: studentStatusName
                        },
                        {
                            label: "START DATE",
                            value: formatDateOnly(startDate, model.getUserDateFormat())
                        },
                        {
                            label: "CONFERRED DATE",
                            value: formatDateOnly(studentInfo?.degreeConferredDate, model.getUserDateFormat(), "NA")
                        },
                        {
                            label: "WITHDRAWAL DATE",
                            value:
                                studentInfo?.isCancelled || studentInfo?.isDropped
                                    ? studentInfo?.withdrawalDate
                                        ? formatDateOnly(studentInfo.withdrawalDate, model.getUserDateFormat())
                                        : formatDateOnly(studentInfo.cancelDate, model.getUserDateFormat())
                                    : ""
                        },
                        {
                            label: getFieldLabel(model, Settings.AcademicLabel.Cgpa, "CGPA"),
                            value: cgpa.toString()
                        }
                    ]
                    if (type === Course.CourseType.Credit) {
                        headerInfo = headerInfo.concat([
                            {
                                label: "DEGREE REQUIREMENT",
                                value: roundTwoDigits(degreeRequirement).toString()
                            },
                            {
                                label: "APPLIED TO DEGREE",
                                value: roundTwoDigits(appliedToDegree).toString()
                            },
                            {
                                label: "CREDITS ATTEMPTED",
                                value: roundTwoDigits(attempted).toString()
                            },
                            {
                                label: "CREDITS EARNED",
                                value: roundTwoDigits(academicProgressData?.completed).toString()
                            },
                            {
                                label: "TRANSFER CREDITS",
                                value: roundTwoDigits(totalTransferApplied).toString()
                            },
                            {
                                label: "PREVIOUSLY COMPLETED",
                                value: roundTwoDigits(totalPreviousCompletedApplied).toString()
                            }
                        ])
                    } else {
                        headerInfo = headerInfo.concat([
                            {
                                label: "DEGREE REQUIREMENT",
                                value: roundTwoDigits(degreeRequirement).toString()
                            },
                            {
                                label: "APPLIED TO DEGREE",
                                value: roundTwoDigits(appliedToDegree).toString()
                            },
                            {
                                label: "CREDITS ATTEMPTED",
                                value: roundTwoDigits(attempted).toString()
                            },
                            {
                                label: "CREDITS EARNED",
                                value: roundTwoDigits(academicProgressData?.completed).toString()
                            },
                            {
                                label: "TRANSFER CREDITS",
                                value: roundTwoDigits(totalTransferApplied).toString()
                            },
                            {
                                label: "PREVIOUSLY COMPLETED",
                                value: roundTwoDigits(totalPreviousCompletedApplied).toString()
                            },
                            {
                                label: "TOTAL SCHEDULED HOURS",
                                value: roundTwoDigits(attempted).toString()
                            },
                            {
                                label: "TOTAL MISSED HOURS",
                                value: roundTwoDigits(missedHours).toString()
                            },
                            {
                                label: "TOTAL MAKEUP HOURS",
                                value: roundTwoDigits(makeUpHours).toString()
                            }
                        ])
                    }
                    const payload = {
                        filename: `Transcript - ${getFullName(studentInfo)}.csv`,
                        headerColumns: ["", ""],
                        headerRows: ["", ...headerInfo.flatMap((obj) => [...Object.values(obj), "\n"]), "\n", "\n"],
                        listTable
                    }
                    exportMultiTableToCsv(payload)
                }
            } catch (error) {
                handleError(error)
            } finally {
                setDownloading(false)
            }
        },
        [studentInfo, listOfSubmodules, signatureBase64]
    )

    const onSignSignature = async (signature) => {
        setSignatureBase64("")
        convertImgToBase64(signature.imageUrl + `?t=${Date.now()}`, function (base64Img) {
            setSignatureBase64(base64Img)
        })
        signaturePopup.close()
    }

    const renderTitleSignature = useMemo(() => {
        return (
            <div className={styles.titleSignContainer}>
                <span className={styles.titleSign}>PUT YOUR SIGN</span>
            </div>
        )
    }, [])

    const renderSignature = useMemo(() => {
        return <OfficialTranscriptSignature signatureBase64={signatureBase64} />
    }, [signatureBase64])

    const renderFooter = useMemo(() => {
        return (
            <div
                ref={footerTranscriptRef}
                style={
                    downloading
                        ? {
                              paddingLeft: 33,
                              paddingRight: 33
                          }
                        : {}
                }>
                <FooterPdf />
            </div>
        )
    }, [downloading])

    const hasPermissionToCreateOfficialTranscript = useMemo(() => {
        return checkPermission({staff: [Permissions.Staff.Academics.Registrar.Students.OfficialTranscript.Add]}, model)
    }, [model])

    const hasPermissionToSignOfficialTranscript = useMemo(() => {
        return checkPermission({staff: [Permissions.Staff.Academics.Registrar.Students.OfficialTranscript.Edit]}, model)
    }, [model])

    const totalTransferCreditGpa = transferCredits.reduce((total, item) => {
        if (typeof item.grades == "number" && item?.grades >= 0) {
            total += item.grades * (item.appliedCredits ?? 0)
        }
        return total
    }, 0)

    const totalTransferCredits = transferCredits.reduce((total, item) => {
        if (typeof item.appliedCredits === "number" && item.appliedCredits) {
            total += item.appliedCredits
        }
        return total
    }, 0)

    const totalTransferCreditGpaValue = formatDecimal(
        totalTransferCreditGpa >= 0 ? totalTransferCreditGpa / transferCredits.length : 0
    )
    const totalTransferCreditsValue = formatDecimalWithoutRound(totalTransferCredits ?? 0)

    const totalCompletedGpa = previouslyCompletedCredits.reduce((total, item) => {
        if (typeof item.grades == "number" && item?.grades >= 0) {
            total += item.grades * (item.creditsApplied ?? 0)
        }
        return total
    }, 0)

    const totalCompletedCredits = previouslyCompletedCredits.reduce((total, item) => {
        if (typeof item.creditsApplied === "number" && item.creditsApplied) {
            total += item.creditsApplied
        }
        return total
    }, 0)

    const totalCompletedGpaValue = formatDecimal(
        totalCompletedGpa >= 0 ? totalCompletedGpa / previouslyCompletedCredits.length : 0
    )
    const totalCompletedCreditsValue = formatDecimalWithoutRound(totalCompletedCredits ?? 0)
    const cgpa = formatDecimal(overallStudentStats?.cgpa ?? 0)

    return (
        <Screen
            className={styles.screen}
            header={{
                isShowNotification: false,
                className: styles.header,
                backUrl: `/academics/registrar/student/${profileId}?tab=degree-audit`,
                variant: "column"
            }}>
            <div className={styles.container}>
                <div
                    ref={pdfRef}
                    className={styles.pdfPage}
                    style={
                        !downloading
                            ? {
                                  paddingTop: 40
                              }
                            : {paddingTop: 0}
                    }>
                    <div className={styles.rowTitle}>
                        <div>
                            <img className={styles.logo} alt="logo" src={logoBase64} />
                        </div>
                        <div className={styles.titleView}>
                            <span className={styles.title}>Transcript</span>
                            <span className={styles.title}>(Official Transcript)</span>
                        </div>
                    </div>

                    <div className={styles.studentNameForm}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <span className={styles.studentCode}>{get(studentInfo, "customProfileId", "")}</span>
                            <span className={styles.studentName}>{getFullName(studentInfo) || "-"}</span>
                        </div>
                        <span className={styles.issuedDate}>Issued {moment().format(model.getUserDateFormat())}</span>
                    </div>

                    {type === Course.CourseType.Credit ? (
                        <StudentInfoCredit cgpa={cgpa} progressData={academicProgressData} studentInfo={studentInfo} />
                    ) : (
                        <StudentInfoClock
                            studentStats={overallStudentStats}
                            cgpa={cgpa}
                            progressData={academicProgressData}
                            studentInfo={studentInfo}
                        />
                    )}

                    {transferCredits?.length ? (
                        <div className={styles.tableContainer}>
                            <span className={styles.tableTitle}>Transferred credits</span>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}>Transfer</span>
                                                <span>Id</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Course Name</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Term Name</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>University</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}>Equivalent</span>
                                                <span>Id</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Course Name</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 115}}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Units earned</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 60}}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Quality Points</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 60}}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Grade</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 114}}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Grade Points</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transferCredits.map((item, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td className={styles.tableColumn}>{item.idTransfer}</td>
                                                <td className={styles.tableColumn}>{item.courseNameTransfer}</td>
                                                <td className={styles.tableColumn}>{item.termNameTransfer}</td>
                                                <td className={styles.tableColumn}>{item.universityName}</td>
                                                <td className={styles.tableColumn}>{item.codeForTranscript}</td>
                                                <td className={styles.tableColumn}>{item.nameForTranscript}</td>
                                                <td className={styles.tableColumn}>{item.appliedCredits}</td>
                                                <td className={styles.tableColumn}>{item.qualityPoints}</td>
                                                <td className={styles.tableColumn}>{item.alphabetical}</td>
                                                <td className={styles.tableColumn}>{item.grades}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                <tr>
                                    <td className={styles.termAvg}>TOTAL</td>
                                    <td className={styles.termAvg}></td>
                                    <td className={styles.termAvg} />
                                    <td className={styles.termAvg} />
                                    <td className={styles.termAvg} />
                                    <td className={styles.termAvg} />
                                    <td className={styles.termAvg}>{totalTransferCreditsValue}</td>
                                    <td className={styles.termAvg} />
                                    <td className={styles.termAvg} />
                                    <td className={styles.termAvg}>{totalTransferCreditGpaValue}</td>
                                </tr>
                            </table>
                        </div>
                    ) : null}

                    {previouslyCompletedCredits?.length ? (
                        <div className={styles.tableContainer}>
                            <span className={styles.tableTitle}>Previously completed credits</span>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span>Id</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span>Course Name</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Term Name</span>
                                            </div>
                                        </th>

                                        <th className={styles.tableHeaderText} style={{width: 115}}>
                                            <div className={styles.headerTransfer}>
                                                <span>Units earned</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 60}}>
                                            <div className={styles.headerTransfer}>
                                                <span>Quality Points</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 60}}>
                                            <div className={styles.headerTransfer}>
                                                <span>Grade</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 114}}>
                                            <div className={styles.headerTransfer}>
                                                <span>Grade Points</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {previouslyCompletedCredits.map((item, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td className={styles.tableColumn}>{item.codeForTranscript}</td>
                                                <td className={styles.tableColumn}>{item.nameForTranscript}</td>
                                                <td className={styles.tableColumn}>{item.termName}</td>
                                                <td className={styles.tableColumn}>{item.creditsApplied}</td>
                                                <td className={styles.tableColumn}>{item.qualityPoints}</td>
                                                <td className={styles.tableColumn}>{item.alphabetical}</td>
                                                <td className={styles.tableColumn}>{item.grades}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td className={styles.termAvg}>TOTAL</td>
                                        <td className={styles.termAvg}></td>
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg}>{totalCompletedCreditsValue}</td>
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg}>{totalCompletedGpaValue}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ) : null}

                    <ClockHoursTranscriptSections data={clockHourTranscriptSections} />

                    {type === Course.CourseType.Credit ? (
                        <TermCourseCredit listTableDegreeAudit={listOfCurrentProgressTable} profileId={profileId} />
                    ) : (
                        <TermCourseClock
                            transcriptType={transcriptType}
                            listTableDegreeAudit={listOfCurrentProgressTable}
                            profileId={profileId}
                            setTotalHoursSubModules={setTotalHoursSubModules}
                            listOfSubmodules={listOfSubmodules}
                            setListOfSubmodules={setListOfSubmodules}
                        />
                    )}
                    {practicalCourses?.length ? <PracticalTable data={practicalCourses} /> : null}
                    {transcriptType === GeneralSettingField.ProgressReportSystemDefault ? (
                        <>
                            {renderSignature}
                            {!downloading ? renderFooter : null}
                        </>
                    ) : null}
                    {transcriptType === GeneralSettingField.ProgressReportSubmodulesBreakDown ? (
                        <>
                            <div className={styles.totalHours}>
                                <span>Total Class Hours</span>
                                <span style={{marginLeft: 40}}>{totalHoursSubModules}</span>
                            </div>
                            <hr className={styles.signatureLine} />
                            <div className={styles.placeholderSignature}>
                                <p className={styles.titleSignature}>(Administrator)</p>
                                <p className={styles.titleSignature}>(Print name)</p>
                                <p className={styles.titleSignature}>(Date)</p>
                            </div>
                        </>
                    ) : null}
                </div>
                <br />

                {slug === CollegeSlug.HHS ? (
                    <div ref={pdfRefFooter} className={styles.pdfPage}>
                        <FooterReport logo={logoBase64} />
                    </div>
                ) : null}
            </div>
            {hasPermissionToCreateOfficialTranscript ? (
                <ExportButton
                    label="Download"
                    className={styles.buttonDownload}
                    isLoading={downloading}
                    onSelect={(exportType: ExportFileType) => {
                        if (transcriptType === GeneralSettingField.ProgressReportSystemDefault) {
                            if (hasPermissionToSignOfficialTranscript) {
                                exportTypeFormat.current = exportType
                                signaturePopup.open()
                            } else {
                                handleDownload(exportType)
                            }
                        } else {
                            handleDownload(exportType)
                        }
                    }}
                    classNameButton={styles.button}
                    availableFileTypes={
                        transcriptType === GeneralSettingField.ProgressReportSubmodulesBreakDown
                            ? ["pdf"]
                            : ["pdf", "csv"]
                    }
                />
            ) : null}
            {downloading ? renderFooter : null}
            <SignaturePopup
                isShow={signaturePopup.isVisible}
                onClose={signaturePopup.close}
                targetUser={{
                    type: Auth.UserProfileType.Staff,
                    id: model.user.id,
                    profileId: model.user.profileId
                }}
                onSetNewSignature={onSignSignature}
                actions={{
                    onCancel: signaturePopup.close,
                    onSign: onSignSignature
                }}
                autoClose
                customTitle={renderTitleSignature}
            />
            <BaseLoading isShow={isFetching} />
        </Screen>
    )
}

export default GenerateTranscript
