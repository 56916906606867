import {Auth} from "types/auth"
import {YesNo} from "./common"

export enum OnlineVerificationFrequency {
    Manual = -1,
    Random = 0,
    Interval15Mins = 15,
    Interval30Mins = 30,
    Interval45Mins = 45,
    Interval60Mins = 60
}

export enum LessonStatus {
    Inactive = "inactive",
    Active = "active",
    Paused = "paused",
    Ended = "ended"
}

export enum LessonAttendanceStatus {
    Missed = "missed",
    NeedAction = "need_action",
    Attended = "attended",
    Verified = "verified"
}

export type RegistrarLesson = {
    id: number
    actionNeededStudentsCount: number
    allStudentsCount: number
    makeupInfo?: {
        studentProfileId: number
    }
    attendanceStatus: LessonAttendanceStatus
    attendedStudentsCount: number
    calendarEventId: number
    code: string
    courseId: number
    date: string
    dayOfTerm: number
    endDate: string
    instructors: string
    lessonId: number
    missedStudentsCount: number
    name: string
    notVerifiedStudentsCount: number
    scheduleId: number
    status: LessonStatus
    studentNotCheckInCount: number
    termId: number
    type: string
    verifiedStudentsCount: number
}

export type LessonDetails = {
    id: number
    calendarEventId: number
    name: string
    code: string
    courseId: number
    status: LessonStatus
    startsAt: string
    endsAt: string
    actualStartedAt: string
    actualEndedAt: string
    startGracePeriod: number
    endGracePeriod: number
    beaconUuid: string
    termId: number
    beaconMajor: number
    beaconMinor: number
    meetingUrl: string | null
    streamKey: string | null
    isOnline: YesNo
    onlineVerificationFrequency: OnlineVerificationFrequency | null

    lessonAttendanceStatus: LessonAttendanceStatus | null

    students?: Array<{
        profileId: number
        userId: number
        fullName: string
        profileImageUrl?: string | null
    }>
    instructors?: Array<{
        profileId: number
        userId: number
        fullName: string
    }>
    hasChecks?: boolean
    checks?: Array<{
        createdAt: string
        facialRecognitionCheckId: number
        imageUrl: string
        recognizedStudents: Array<{
            userId: number
            boundingBox: {
                top: number
                left: number
                width: number
                height: number
            }
        }>
    }>
}

export type LessonAttendance = {
    lesson: LessonDetails
    attendance: {
        total: number
        attended: number
        percentage: number
        actionNeededIds: number[]
    }
}

export type LessonsScheduleItem = {
    calendarEventId: number
    duration: number
    date: string
    startAt: string
    endAt: string
    name: string
    code: string
    courseId: number
    onlineUrl: string | null
    streamKey: string | null
}

export type AttendanceUserDetails = {
    userId: number
    profileId: number
    firstName: string
    lastName: string
    photo: Auth.UserPhotoDetails | null
}

export type CourseStudentAttendanceStats = {
    student: AttendanceUserDetails
    lda: string | null
    gpa: number | null
}

export enum AttendanceStatus {
    InClass = "in_class",
    Approved = "approved",
    Irregular = "irregular",
    Rejected = "rejected"
}

export type AttendanceDetails = {
    id: number
    lessonId: number
    checkin: string
    checkout: string | null
    status: AttendanceStatus | null
    isVerified: YesNo
    createdAt: string
    updatedAt: string
}

export type StudentAttendanceByLessons = {
    student: AttendanceUserDetails
    items: Array<{
        lessonId: number
        attendance: AttendanceDetails | null
    }>
}

export type StudentsAttendanceByCourse = {
    lessons: LessonDetails[]
    attendance: StudentAttendanceByLessons[]
}

export type HistoryStudentsAttendanceByCourse = {
    attendanceId: number
    checkInTime: string
    checkOutTime: string
    events: any[]
}[]

export enum AttendanceType {
    Online = "online",
    OnSite = "on-site",
    Manual = "manual"
}

export enum AttendanceEventStatus {
    Success = "success",
    Failure = "failure"
}

export enum AttendanceEventType {
    Checkin = "check-in",
    Checkout = "check-out",
    OnlineCheck = "online-verification",
    BreakStart = "break-start",
    BreakEnd = "break-end",

    // Local types for generating events in timeline
    LessonStart = "lesson-start",
    LessonEnd = "lesson-end"
}

export type AttendanceEvent = {
    id?: number
    eventType: AttendanceEventType
    location: string
    photoUrl?: string
    presignedPhotoUrl?: string
    createdAt: string
    status: AttendanceEventStatus
}

export type StudentLessonActivity = {
    id: number | null
    status: AttendanceStatus | null
    date: string | null
    lessonId: number
    student: AttendanceUserDetails
    isVerified: YesNo
    message: string | null
    duration: number | null
    events: AttendanceEvent[]
}

export const MAP_EVENT_TYPE_2_LABEL = {
    [AttendanceEventType.Checkin]: "Chekin",
    [AttendanceEventType.Checkout]: "Chekout",
    [AttendanceEventType.BreakStart]: "Break Start",
    [AttendanceEventType.BreakEnd]: "Break End",
    [AttendanceEventType.OnlineCheck]: "Online Check",
    [AttendanceEventType.LessonStart]: "Lesson Start",
    [AttendanceEventType.LessonEnd]: "Lesson End"
}

export const ATTENDANCE_SUCCESS_STATUSES = [AttendanceStatus.Approved, AttendanceStatus.InClass]

export type UpdateAttendancePayload = {
    attendanceId: number
    status: AttendanceStatus
    message?: string
    isVerified?: boolean
}

export type CreateAttendancePayload = {
    lessonId: number
    profileId: number
    status: AttendanceStatus
    message?: string
    isVerified?: boolean
}

export type UpdateOrCreateAttendancePayload = UpdateAttendancePayload | CreateAttendancePayload

export type LastDayOfAttendancePayload = {
    profileIds: number[]
    courseIds?: number[]
    termIds?: number[]
}

export type LastDayOfAttendanceResponse = {
    [key: string]: string | any | null | undefined
}

export type AttendanceChange = {
    calendarEventId: number
    code: string
    createdAt: string
    duration: number
    enrollmentId: string
    firstName: string
    id: number
    instructors: string
    isAbsent: boolean
    isExcusedAbsence: boolean
    lastName: string
    name: string
    profileId: number
    scheduleId: number
    startTime: string
    updatedAt: string
    userId: number
    notes: string
}

export type LessonItem = {
    scheduleId: number
    courseId: number
    termId: number
    id: number
    isChecked: boolean
    calendarEventId: number
}

export enum DailyAttendanceViewType {
    ViewByProgram = "view-by-program",
    ViewByPhase = "view-by-phase"
}

export type DailyAttendanceByPhaseItem = {
    name: string
    phaseId: number
    activeStudentsCount: number
    studentsInSchool: number
    studentsOnBreak: number
    studentsCheckedOut: number
    studentsMissed: number
}

export type DailyAttendanceByProgramVersionItem = {
    majorVersionName: string
    majorName: string
    versionCode: string
    id: number
    activeStudentsCount: number
    studentsInSchool: number
    studentsOnBreak: number
    studentsCheckedOut: number
    studentsMissed: number
}
