export function getContentPositionStyle(bodyRef, field, scaleInfo, totalPage) {
    const bodyWidth = bodyRef?.current.offsetWidth
    const bodyHeight = bodyRef?.current.offsetHeight
    const isShowLeft = field.placeInformation.left + field.placeInformation.width + 74 + bodyWidth < scaleInfo.pageWidth
    const isShowTop =
        field.placeInformation.top + field.placeInformation.height + 74 + bodyHeight < scaleInfo.pageHeight * totalPage
    const style: any = isShowLeft ? {left: field.placeInformation.width + 10} : {left: -235}
    const canShowBottom = field.placeInformation.top > 200
    if (!isShowTop || !isShowLeft) {
        if (canShowBottom) {
            style.top = "auto"
            style.bottom = 10
        } else {
            style.bottom = "auto"
            style.top = field.placeInformation.height + 10
        }
        if (isShowLeft) {
            style.left = -74
        } else {
            style.left = -bodyWidth
        }
    }
    return style
}
