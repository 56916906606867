import {FileSelect} from "sections/Documents/parts/DocumentUpload/parts/FileSelect/FileSelect"
import styles from "./MakeupFileSelect.module.css"
import {IconButton} from "components/ui"
import {Icon} from "components"
import {Attachment} from "hooks"
import commonStyles from "./MakeupAttendance.module.css"

type MakeupFileSelectProps = {
    attachments: Attachment[]
    onRemoveFile: (id: string) => void
    editable?: boolean
    onSelectFiles: (attachedFiles: File[]) => void
}

const MakeupFileSelect = (props: MakeupFileSelectProps) => {
    const {attachments, onRemoveFile, onSelectFiles, editable} = props
    return (
        <>
            <div className={styles.titleContainer}>
                <span className={styles.label}>Attachment</span>
            </div>
            <div className={commonStyles.attachmentContainer}>
                {editable ? <FileSelect className={styles.fileSelect} onSelectFiles={onSelectFiles} /> : null}
                {attachments.map((attachment) => {
                    return (
                        <div className={styles.files}>
                            <span className={styles.fileName}>
                                {attachment.file.name}
                                {attachment.presignedUrl?.length ? (
                                    <a target="_blank" rel="noopener noreferrer" href={attachment.presignedUrl}>
                                        {" "}
                                        PREVIEW
                                    </a>
                                ) : (
                                    ""
                                )}
                            </span>
                            <IconButton onClick={() => onRemoveFile(attachment.id)}>
                                <Icon icon="DELETE" />
                            </IconButton>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default MakeupFileSelect
