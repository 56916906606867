import cx from "classnames"
import {isMobile} from "react-device-detect"
import styles from "./PreviousAnswer.module.css"
import {BaseInput, BaseTextArea} from "components"
import {DESKTOP_TEXT_INPUT_HEIGHT, MOBILE_TEXT_INPUT_HEIGHT} from "../config"

type Props = {
    field: any
    backgroundColor: string
}

export function PreviousAnswer(props: Props) {
    const {field, backgroundColor} = props
    const {width, height} = field.placeInformation
    const inputHeight = Math.min(height, isMobile ? MOBILE_TEXT_INPUT_HEIGHT : DESKTOP_TEXT_INPUT_HEIGHT)
    const maxRows = Math.floor(height / inputHeight)

    const renderContent = () => {
        if (maxRows >= 2) {
            return (
                <BaseTextArea
                    value={field.value}
                    className={cx(styles.input, styles.textarea)}
                    classNameWrap={styles.textAreaWrap}
                    readOnly
                    style={{backgroundColor: `var(--${backgroundColor}-100)`}}
                />
            )
        }

        return (
            <BaseInput
                value={field.value}
                className={styles.input}
                readOnly
                style={{
                    backgroundColor: `var(--${backgroundColor}-100)`,
                    maxHeight: Math.floor(height) - 3
                }}
            />
        )
    }

    return (
        <div
            className={cx(styles.actionItem, styles.answerText)}
            style={{
                width,
                height,
                backgroundColor: `var(--${backgroundColor}-100)`,
                borderColor: `var(--${backgroundColor}-400-base)`
            }}>
            <div
                className={cx(styles.inputWrap, {
                    [styles.smallInput]: height <= 30,
                    [styles.superSmallInput]: height <= 14
                })}>
                {renderContent()}
            </div>
        </div>
    )
}
