/* eslint-disable react-hooks/exhaustive-deps */
import {useTranslation} from "react-i18next"
import styles from "./StudentFilterV2.module.css"
import {useAdvisors, useAllCountries, useAllLeadSources, useAllLeadTypes, useModel, useVisible} from "hooks"
import {AppliedFilter, FieldPayload, FieldType, FilterKey, Status, StatusDepartments, ViewType} from "types/filter"
import {FilterField, KlassappFilters} from "uiKit"
import TermSelect from "components/TermSelect"
import CampusSelect from "components/CampusSelect"
import ProgramSelect from "components/ProgramSelect"
import React, {useCallback, useMemo} from "react"
import {NewStudentStatusSelectFilter} from "components/NewStudentStatusSelect"
import UserSelect from "components/UserSelect"
import StudentStatusSelect from "components/StudentStatusSelect"
import {KlassDropdown} from "components/Select"
import {STATES_OPTIONS} from "types/students"
import DepartmentActivitySelect from "components/DepartmentActivitySelect"
import {BaseDepartmentId} from "types/departments"
import CreateUserPopup from "../CreateUserPopup"
import {ExportButton, ExportFileType} from "components/ui"
import {Tooltip} from "antd"
import cx from "classnames"
import {BaseButton, BaseInput, Icon, IconType} from "components"
import {
    RetentionCategory,
    RetentionCategoryLabels,
    retentionLevelOptions,
    sapCheckpointOptions
} from "types/student-services/sap"
import {Auth} from "types/auth"
import {UserOptionLabel} from "components/UserSelect/UserSelect"
import PhaseSelect from "components/PhaseSelect"
import ShiftSelect from "components/ShiftSelect"
import UserSelectV3 from "components/UserSelectV3"

export type StudentFormV2 = {
    startingTerm?: FieldPayload
    campus?: FieldPayload
}

type Props = {
    filterKey?: FilterKey
    viewTypes?: ViewType[]
    onApplyFilter: (payload: AppliedFilter) => void
    initialFilter?: AppliedFilter
    onClearFilter: () => void
    departmentId?: number
    onCreatedUser?: () => void
    // legacy props
    hideAdvisorFilter?: boolean
    hideStatuses?: boolean
    hideSecondSection?: boolean
    hideApplicationCompletedDate?: boolean
    hideChecklist?: boolean
    hideDateAddedRange?: boolean
    hideLastActivityRange?: boolean
    hideIncludeArchive?: boolean
    hideLeadRecordAction?: boolean
    hideLeadSource?: boolean
    hideShowDueOnly?: boolean
    hideActivityTypesDropdown?: boolean
    hidePhaseAndShift?: boolean
    canShowAllStudents?: boolean
    canHaveAccessOthers?: boolean
    showExport?: boolean
    handleExport?: (type: ExportFileType) => void
    isExporting?: boolean
    viewType?: ViewType
    onChangeViewType?: Function
    status?: Status
    renderCustomActions?: () => JSX.Element
    renderFinancialInfo?: () => JSX.Element
    showStudentServiceFilters?: boolean
    showRiskLevelFilters?: boolean
}

const VIEW_TYPE_LABELS: Record<ViewType, {label: string; iconName: IconType}> = {
    kanban: {
        label: "Kanban",
        iconName: "VIEW_LIST_COLUMNS"
    },
    priority: {
        label: "Priority",
        iconName: "ALERT_CIRCLE_FILL"
    },
    standard: {
        label: "Standard",
        iconName: "VIEW_LIST"
    }
}

export function StudentFilterV2(props: Props) {
    const {
        onApplyFilter,
        initialFilter,
        filterKey,
        viewTypes,
        hideAdvisorFilter,
        hideStatuses,
        hideSecondSection,
        hideApplicationCompletedDate,
        hideDateAddedRange,
        hideActivityTypesDropdown,
        hideChecklist,
        hideLastActivityRange,
        hideLeadSource,
        hideLeadRecordAction,
        hideShowDueOnly,
        hideIncludeArchive,
        hidePhaseAndShift,
        showStudentServiceFilters,
        canShowAllStudents,
        canHaveAccessOthers,
        departmentId,
        onCreatedUser,
        showExport,
        handleExport,
        isExporting,
        viewType,
        onChangeViewType,
        status,
        renderCustomActions,
        renderFinancialInfo,
        onClearFilter,
        showRiskLevelFilters = false
    } = props
    const {t} = useTranslation(["common"])
    const createUserPopup = useVisible(false)
    const model = useModel()
    const {leadTypes} = useAllLeadTypes()
    const {leadSources} = useAllLeadSources()
    const {admissionAdvisors, finAidAdvisors, academicAdvisors} = useAdvisors().data
    const {countries} = useAllCountries()

    const onClickAddUser = () => {
        createUserPopup.open()
    }

    const renderAdditionalFilter = () => {
        if (renderCustomActions) return <div className={styles.actionWrap}>{renderCustomActions()}</div>
        return (
            <div className={styles.actionWrap}>
                {showExport && (
                    <ExportButton
                        isLoading={isExporting}
                        onSelect={handleExport}
                        availableFileTypes={["csv", "excel"]}
                    />
                )}
                {(["kanban", "standard", "priority"] as ViewType[])
                    .filter((viewType) => viewTypes?.includes(viewType))
                    .map((v) => {
                        const i = VIEW_TYPE_LABELS[v]
                        return (
                            <Tooltip key={v} title={i.label}>
                                <span
                                    className={cx(styles.viewTypeIconContainer, {
                                        [styles.viewTypeIconContainerSelected]:
                                            viewType === v ||
                                            (viewType === "kanban" &&
                                                StatusDepartments.finAidStatus === StatusDepartments[status])
                                    })}
                                    onClick={() => onChangeViewType(v)}>
                                    <Icon className={styles.viewTypeIcon} icon={i.iconName} />
                                </span>
                            </Tooltip>
                        )
                    })}
                {!hideLeadRecordAction && (
                    <BaseButton
                        title={t("action.createLeadRecord")}
                        onClick={onClickAddUser}
                        className={styles.addButton}
                    />
                )}
                {renderFinancialInfo?.()}
            </div>
        )
    }

    const allUserCampusIds = useMemo(() => {
        const userCampuses = (model.user?.profiles || [])
            .map((profile) => (profile.campuses || []).map(({id}) => id))
            .filter(Boolean)
        return userCampuses.flat()
    }, [model.user])

    const availableFilters = useMemo(() => {
        let filters: FilterField[] = [
            {
                field: "profiles",
                label: t("statsFilter.student"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <UserSelect
                            type={Auth.UserProfileType.Student}
                            isMulti
                            placeholder={t("statsFilter.student")}
                            supportCustomProfileIdSearch
                            {...props}
                        />
                    )
                }
            },
            {
                field: "users",
                label: "User",
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <UserSelectV3
                            type={Auth.UserProfileType.Student}
                            isMulti
                            placeholder="Users"
                            withProfiles={false}
                            belongsToMyCampuses={false}
                            {...props}
                        />
                    )
                }
            },
            {
                field: "startingTerm",
                label: t("statsFilter.startingTerm"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return <TermSelect isMulti placeholder={t("statsFilter.startingTerm")} {...props} />
                }
            },
            {
                field: "campus",
                label: t("statsFilter.campus"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <CampusSelect
                            isMulti
                            placeholder={t("statsFilter.campus")}
                            displayOnlyCampusIds={allUserCampusIds}
                            {...props}
                        />
                    )
                }
            },
            {
                field: "program",
                label: t("statsFilter.program"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return <ProgramSelect isMulti placeholder={t("statsFilter.program")} {...props} />
                }
            }
        ]

        if (hideAdvisorFilter) {
            if (!hideStatuses && model.clientSetting.isNewStudentStatusesVisible) {
                filters.push({
                    field: "studentStatusIds",
                    label: "Student Status",
                    type: FieldType.List,
                    renderForm: (props: any) => {
                        return <NewStudentStatusSelectFilter isMulti placeholder="Student Status" {...props} />
                    }
                })
            }
        } else {
            if (canShowAllStudents || canHaveAccessOthers) {
                const advisorFilters: FilterField[] = [
                    {
                        field: "admAdvisors",
                        label: t("statsFilter.admissionAdvisor"),
                        type: FieldType.List,
                        renderForm: (props: any) => {
                            return (
                                <KlassDropdown
                                    isMulti
                                    options={admissionAdvisors}
                                    valueKey="profileId"
                                    labelKey="fullName"
                                    getOptionLabel={UserOptionLabel}
                                    placeholder={t("statsFilter.admissionAdvisor")}
                                    {...props}
                                />
                            )
                        }
                    },
                    {
                        field: "acadAdvisors",
                        label: t("statsFilter.academicAdvisor"),
                        type: FieldType.List,
                        renderForm: (props: any) => {
                            return (
                                <KlassDropdown
                                    isMulti
                                    options={academicAdvisors}
                                    valueKey="profileId"
                                    labelKey="fullName"
                                    getOptionLabel={UserOptionLabel}
                                    placeholder={t("statsFilter.academicAdvisor")}
                                    {...props}
                                />
                            )
                        }
                    },
                    {
                        field: "faAdvisors",
                        label: t("statsFilter.financialAidAdvisor"),
                        type: FieldType.List,
                        renderForm: (props: any) => {
                            return (
                                <KlassDropdown
                                    isMulti
                                    options={finAidAdvisors}
                                    valueKey="profileId"
                                    labelKey="fullName"
                                    getOptionLabel={UserOptionLabel}
                                    placeholder={t("statsFilter.financialAidAdvisor")}
                                    {...props}
                                />
                            )
                        }
                    }
                ]
                filters.push(...advisorFilters)
            }
        }

        if (!hideSecondSection) {
            if (!model.clientSetting.isNewStudentStatusesVisible && !model.clientSetting.isDepartmentalStatusesHidden) {
                filters.push({
                    field: "status",
                    label: t("statsFilter.studentStatus"),
                    type: FieldType.List,
                    renderForm: (props: any) => {
                        return (
                            <StudentStatusSelect
                                isMulti
                                valueKey="statusId"
                                labelKey="name"
                                placeholder={t("statsFilter.studentStatus")}
                                isClearable={false}
                                shortly
                                {...props}
                            />
                        )
                    }
                })
            }
            filters.push({
                field: "state",
                label: t("statsFilter.state"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <KlassDropdown
                            options={STATES_OPTIONS}
                            isMulti
                            placeholder={t("statsFilter.state")}
                            {...props}
                        />
                    )
                }
            })
            if (model.clientSetting.isNewStudentStatusesVisible) {
                filters.push({
                    field: "studentStatusIds",
                    label: "Student Status",
                    type: FieldType.List,
                    renderForm: (props: any) => {
                        return <NewStudentStatusSelectFilter isMulti placeholder="Student Status" {...props} />
                    }
                })
            }
            filters.push({
                field: "profileStatus",
                label: t("statsFilter.profileStatus"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <KlassDropdown
                            options={[
                                {id: "active", name: t("message.active")},
                                {id: "inactive", name: t("message.inactive")}
                            ]}
                            isMulti
                            placeholder={t("statsFilter.profileStatus")}
                            {...props}
                        />
                    )
                }
            })
            if (!hideChecklist) {
                filters.push({
                    field: "checklist",
                    label: "CHECKLIST",
                    type: FieldType.Single,
                    renderForm: (props: any) => {
                        return (
                            <KlassDropdown
                                options={[
                                    {id: "completed", name: t("statsFilter.completed")},
                                    {id: "notCompleted", name: t("statsFilter.notCompleted")}
                                ]}
                                isClearable
                                {...props}
                            />
                        )
                    }
                })
            }
        }

        if (!hideApplicationCompletedDate) {
            filters.push({
                field: "applicationCompletedDate",
                label: "Application Completed Date",
                type: FieldType.Date
            })
        }

        filters.push({
            field: "startDateRange",
            label: "Student Start Date",
            type: FieldType.Date
        })
        if (!hideDateAddedRange) {
            filters.push({
                field: "createdAtRange",
                label: "Date added",
                type: FieldType.Date
            })
        }
        filters.push({
            field: "inquiryDateRange",
            label: "Inquiry Date",
            type: FieldType.Date
        })

        if (!hideLastActivityRange) {
            filters.push({
                field: "lastActivityDateRange",
                label: "Last Activity Date",
                type: FieldType.Date
            })
        }

        if (!hideActivityTypesDropdown) {
            filters.push({
                field: "activityIds",
                label: "Last Activity Types",
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <DepartmentActivitySelect
                            departmentId={departmentId}
                            isMulti
                            isClearable
                            placeholder="Last Activity Types"
                            {...props}
                        />
                    )
                }
            })
            filters.push({
                field: "activityDescription",
                label: "Last Activity Description",
                type: FieldType.Text,
                renderForm: (props: any) => {
                    return <BaseInput placeholder="Last Activity Description" {...props} />
                }
            })
        }

        if (!hideLeadSource) {
            filters.push({
                field: "admissionsLeadSourceIds",
                label: t("studentInfo.leadSource"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <KlassDropdown
                            options={leadSources}
                            isMulti
                            placeholder={t("studentInfo.leadSource")}
                            {...props}
                        />
                    )
                }
            })
            filters.push({
                field: "leadTypeIds",
                label: t("studentInfo.leadType"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <KlassDropdown options={leadTypes} isMulti placeholder={t("studentInfo.leadType")} {...props} />
                    )
                }
            })
        }

        filters.push({
            field: "countries",
            label: "Country",
            type: FieldType.List,
            renderForm: (props: any) => {
                return <KlassDropdown options={countries} isMulti placeholder="Country" {...props} />
            }
        })

        if (!hideIncludeArchive) {
            filters.push({
                field: "includeArchive",
                label: t("statsFilter.includeArchive"),
                type: FieldType.Flag
            })
        }

        if (!hideShowDueOnly) {
            filters.push({
                field: "showDueOnly",
                label: t("statsFilter.showDueOnly"),
                type: FieldType.Flag
            })
        }

        filters.push({
            field: "isAccessDisabled",
            label: t("statsFilter.isAccessDisabled"),
            type: FieldType.Single,
            renderForm: (props: any) => {
                return (
                    <KlassDropdown
                        options={[
                            {id: 0, name: "Enabled"},
                            {id: 1, name: "Disabled"}
                        ]}
                        placeholder={t("statsFilter.isAccessDisabled")}
                        {...props}
                    />
                )
            }
        })

        if (!hidePhaseAndShift) {
            filters.push({
                field: "phases",
                label: t("studentInfo.phase"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return <PhaseSelect isMulti placeholder={t("studentInfo.phase")} {...props} />
                }
            })
            filters.push({
                field: "shifts",
                label: t("studentInfo.shift"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return <ShiftSelect isMulti placeholder={t("studentInfo.leadType")} {...props} />
                }
            })
        }

        if (departmentId === BaseDepartmentId.FinancialAid) {
            filters.push({
                field: "tranxDates",
                label: "Transaction Date",
                type: FieldType.Date
            })
        }

        if (showStudentServiceFilters) {
            filters.push({
                field: "fullyEnrolled",
                label: t("statsFilter.fullyEnrolled"),
                type: FieldType.Flag
            })
            filters.push({
                field: "onlySEV",
                label: t("statsFilter.onlySEV"),
                type: FieldType.Flag
            })
            filters.push({
                field: "sapCheckpoints",
                label: t("statsFilter.sapCheckpoints"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <KlassDropdown
                            options={sapCheckpointOptions}
                            isMulti
                            placeholder={t("statsFilter.sapCheckpoints")}
                            {...props}
                        />
                    )
                }
            })
            filters.push({
                field: "retentionLevels",
                label: t("statsFilter.retentionLevels"),
                type: FieldType.List,
                renderForm: (props: any) => {
                    return (
                        <KlassDropdown
                            options={retentionLevelOptions}
                            isMulti
                            placeholder={t("statsFilter.retentionLevels")}
                            {...props}
                        />
                    )
                }
            })
        }

        if (showRiskLevelFilters) {
            Object.values(RetentionCategory).forEach((x) => {
                filters.push({
                    field: `riskLevel${x}`,
                    label: RetentionCategoryLabels[x],
                    type: FieldType.Single,
                    renderForm: (props: any) => {
                        return (
                            <KlassDropdown
                                options={retentionLevelOptions}
                                placeholder={RetentionCategoryLabels[x]}
                                {...props}
                            />
                        )
                    }
                })
            })
        }

        return filters
    }, [
        model,
        hideAdvisorFilter,
        hideStatuses,
        hideSecondSection,
        hideChecklist,
        hideDateAddedRange,
        hideActivityTypesDropdown,
        hideApplicationCompletedDate,
        hideLastActivityRange,
        hideLeadSource,
        hideLeadRecordAction,
        hideShowDueOnly,
        hideIncludeArchive,
        hidePhaseAndShift,
        canShowAllStudents,
        canHaveAccessOthers,
        departmentId,
        showStudentServiceFilters,
        leadTypes,
        leadSources,
        countries,
        admissionAdvisors,
        finAidAdvisors,
        academicAdvisors,
        showRiskLevelFilters,
        allUserCampusIds
    ])

    const handleCreateUserSuccess = useCallback(() => {
        createUserPopup.close()
        onCreatedUser?.()
    }, [])

    return (
        <>
            <KlassappFilters
                filterKey={filterKey}
                renderAdditionalFilter={renderAdditionalFilter}
                availableFilters={availableFilters}
                initialFilter={initialFilter}
                onApply={onApplyFilter}
                onClear={onClearFilter}
            />
            {!!createUserPopup.isVisible && (
                <CreateUserPopup
                    isShow={createUserPopup.isVisible}
                    onClose={createUserPopup.close}
                    onDone={handleCreateUserSuccess}
                />
            )}
        </>
    )
}
