import {Timestamps} from "../common"
import {DeepPartial} from "types/common"
import {Auth} from "../auth"
import {FundSource} from "./fund-source"
import {CoaType} from "../coa"
import {DegreeLevel} from "types/degreeLevel"
import {TermDetails} from "types/terms"
import {DependencyStatus, TermBillingMethod, TermEnrollStatus} from "types/students"
import {PaymentPeriod} from "types/student-account/payment-period"

export enum EnrollmentStatus {
    FullTime = "full_time",
    ThreeQuarterTime = "three_quarter_time",
    HalfTime = "half_time",
    LessThanHalfTime = "less_than_half_time"
}

export enum HousingPlan {
    OnCampus = "on_campus",
    OnCampusWithDependents = "on_campus_w_dependents",
    OffCampus = "off_campus",
    WithParent = "with_parent",
    MilitaryBAH = "military_bah"
}

export enum TermTuitionRateBy {
    PerCredit = "credit",
    PerCourse = "course"
}

export type FinAidPackage = Timestamps & {
    finAidPackageId: number
    studentProfileId: number
    code: string

    // section 1
    coaTemplateId?: number
    firstName: string
    lastName: string
    ssn: string
    awardYear: number // 2021 for 2021-2022
    type: CoaType
    campusId?: number | null
    programId?: number | null
    programVersionId?: number | null
    creditsEarned?: number | null
    expectedGradDate?: string | null
    degreeLevelId?: number | null
    enrollmentStatus?: EnrollmentStatus | null
    housingPlan?: HousingPlan | null
    dependencyStatus?: DependencyStatus | null
    gradeLevel: number
    numberOfTerms: number
    isir?: string | null
    efc?: number | null
    programLength?: number | null
    creditsTowarDegree?: number | null
    termTuitions?: FinAidPackageTermTuition[] // populate
    tuitionRateBy?: TermTuitionRateBy
    numberOfPayments?: number | null
    payingMethodId?: number | null

    // section 2
    currentBBAYFrom?: string // date
    currentBBAYTo?: string // date
    loanPeriodFrom?: string // date
    loanPeriodTo?: string // date
    loanYear?: number // 2021 for 2021-2022
    directCosts?: FinAidPackageDirectCost[] // populate
    midYearTransfer?: boolean
    midYearTransferAmounts?: FinAidPackageMidYearTransferAmount[] // populate
    dayDelay?: boolean
    qtrLoanNeed2Disbursement?: boolean
    studentApprovedUnsubsidizedOnly?: boolean
    studentIndependentDueToPlusCreditDenial?: boolean
    termStartDates?: string[] // date[]
    disbursementDates?: string[] // date[]
    secondDisbursementDates?: string[] // date[]

    // section 3
    pellGrantYears?: FinAidPackagePellGrantYear[] // populate
    grants?: FinAidPackageGrant[] // populate

    // section 4
    loans?: FinAidPackageLoan[] // populate
    subLoanId?: number
    subLoanCode?: string
    subLoanTitle?: string
    unsubLoanId?: number
    unsubLoanCode?: string
    unsubLoanTitle?: string
    plusLoanId?: number
    plusLoanCode?: string
    plusLoanTitle?: string
    subLoanInfo?: FinAidPackageSubLoanInfo // populate
    unsubLoanInfo?: FinAidPackageUnsubLoanInfo // populate
    plusLoanInfo?: FinAidPackagePlusLoanInfo // populate

    // section 5
    summary?: FinAidPackageSummary // populate
    directLoanMPN?: boolean
    entranceCounseling?: boolean
    pellLEU?: boolean
    summaryHidden?: boolean | 0 | 1 | null

    // section 6
    initSubLoanId?: number | null
    initSubLoanCode?: string | null
    initSubLoanTitle?: string | null
    initUnsubLoanId?: number | null
    initUnsubLoanCode?: string | null
    initUnsubLoanTitle?: string | null
    initPlusLoanId?: number | null
    initPlusLoanCode?: string | null
    initPlusLoanTitle?: string | null
    aidSubsequences: FinAidPackageAidSubsequence[] // populate

    // section 7
    ftfy?: FinAidPackageFTFY

    // note
    note?: string | null
}

export type FinAidPackageTermTuition = {
    finAidPackageId: number
    term: number

    termId?: number | null
    termCode?: string | null
    termName?: string | null
    termStartDate?: string | null
    isLocked?: boolean | 0 | 1 | null
    creditsOrHours: number
    numCourses: number
    rateId?: number | null
    rateName: string
    rate: number
    cop?: boolean | 0 | 1 | null
    newCreditsTowardDegree?: number | null
}

export type FinAidPackageDirectCost = {
    finAidPackageId: number
    order: number

    rateId?: number | null
    name: string
    isInstitutionalCharge: boolean
    termRates: number[]
}

export type FinAidPackageMidYearTransferAmount = {
    finAidPackageId: number
    order: number

    credits: number
    acadYr: number
    maxAnn: "Sub" | "Unsub" | "Total Grade"
    amount: number
    proration: number
}

export type FinAidPackagePellGrantYear = {
    finAidPackageId: number
    term: number

    year: number
    amount: number
}

export type FinAidPackageGrant = {
    finAidPackageId: number
    order: number

    grantId?: number
    scholarshipId?: number
    name: string
    termRates: number[]
}

export type FinAidPackageLoan = {
    finAidPackageId: number
    order: number

    loanId?: number | null
    name: string
    interestRebases: number[]
    originationFees: number[]
    grossRates: number[]
    netRates: number[]
    secondGrossRates: number[]
    secondNetRates: number[]
}

export type FinAidPackageSubLoanInfo = {
    finAidPackageId: number

    prvBrw?: number | null
    coa?: number | null
    efc?: number | null
    pell?: number | null
    grantAndOutsideAid?: number | null
    fedNeeded?: number | null
    maxAnnual?: number | null
    directCost?: number | null
    proration?: number | null
    appliedFor?: number | null
    certDate?: string | null
}

export type FinAidPackageUnsubLoanInfo = {
    finAidPackageId: number

    prvBrw?: number | null
    coa?: number | null
    subLoan?: number | null
    pell?: number | null
    grantAndOutsideAid?: number | null
    fedNeeded?: number | null
    maxAnnual?: number | null
    directCost?: number | null
    proration?: number | null
    appliedFor?: number | null
    certDate?: string | null
}

export type FinAidPackagePlusLoanInfo = {
    finAidPackageId: number

    coa?: number | null
    pell?: number | null
    grantAndOutsideAid?: number | null
    subLoan?: number | null
    unsubLoan?: number | null
    fedNeeded?: number | null
    directCost?: number | null
    appliedFor?: number | null
    certDate?: string | null
}

export type FinAidPackageSummary = {
    finAidPackageId: number

    cashPaymentTermRates: number[]
    federalWorkStudyTermRates: number[]
}

export type FinAidPackageAidSubsequence = {
    finAidPackageId: number
    order: number

    name: string
    awardYear: number
    termRates: number[]
    disbursementDates: string[]
}

export type FinAidPackageFTFY = {
    tuition?: number | null
    tuitionActual?: number | null
    resMatFees?: number | null
    resMatFeesActual?: number | null
    techFees?: number | null
    techFeesActual?: number | null
    livingExpenses?: number | null
    livingExpensesActual?: number | null
    transportation?: number | null
    transportationActual?: number | null
    miscPersMatFee?: number | null
    miscPersMatFeeActual?: number | null
}

/////////

export enum FinAidWorksheetStatus {
    Saved = "saved",
    Accepted = "accepted",
    Packaged = "packaged",
    Estimated = "estimated",
    Certified = "certified"
}

export type FinAidWorksheet = Timestamps & {
    finAidWorksheetId: number
    finAidPackageId: number
    studentProfileId: number
    code: string
    awardYear: number
    status: FinAidWorksheetStatus
    revision: number
    createdByProfileId: number
    updatedByProfileId: number
} & Partial<{
        finAidPackage: FinAidPackage
        studentProfile: Auth.LinkedProfileDetails
        createdBy: Auth.LinkedProfileDetails
        updatedBy: Auth.LinkedProfileDetails
    }>

/////////

export enum FinAidOfferStatus {
    Packaged = "packaged",
    Certified = "certified"
}

export type FinAidOffer = Timestamps & {
    finAidOfferId: number
    finAidWorksheetId: number
    finAidWorksheet?: FinAidWorksheet // populate
    finAidPackageId: number
    finAidPackage?: FinAidPackage // populate
    studentProfileId: number
    studentProfile?: Auth.LinkedProfileDetails // populate

    code: string
    status: FinAidOfferStatus
    packageDate: string
    awardYear: number
    isDefault: boolean

    cpsNo?: number | null
    opeidBranchCode?: string | null
    currentDegreeSite?: string | null
    schoolPackageCategoryCode?: string | null
    namedPackageRule?: string | null
    sarFlag?: boolean | 0 | 1

    createdByProfileId: number
    createdBy: Auth.LinkedProfileDetails
    updatedByProfileId: number
    updatedBy: Auth.LinkedProfileDetails
}

////////////////

export enum FinAidPackageType {
    Worksheet = "worksheet",
    Offer = "offer",
    Package = "package"
}

export const DEFAULT_FTFY: FinAidPackageFTFY = {
    tuition: null,
    resMatFees: 450,
    techFees: 450,
    livingExpenses: 3006,
    transportation: 2538,
    miscPersMatFee: 1449
}

export const getNewFinAidPackage = (data?: Partial<FinAidPackage>): DeepPartial<FinAidPackage> => ({
    enrollmentStatus: EnrollmentStatus.FullTime,
    dependencyStatus: DependencyStatus.Independent,
    gradeLevel: 1,
    numberOfTerms: 0,
    programLength: 12,
    termTuitions: [],
    tuitionRateBy: TermTuitionRateBy.PerCredit,
    directCosts: [],
    termStartDates: [],
    disbursementDates: [],
    secondDisbursementDates: [],
    midYearTransfer: false,
    midYearTransferAmounts: [
        {credits: 0, acadYr: 36, maxAnn: "Sub", amount: 0, proration: 0},
        {credits: 0, acadYr: 36, maxAnn: "Unsub", amount: 0, proration: 0},
        {credits: 0, acadYr: 36, maxAnn: "Total Grade", amount: 0, proration: 0}
    ],
    pellGrantYears: [],
    grants: [],
    loans: [],
    subLoanInfo: {},
    unsubLoanInfo: {},
    plusLoanInfo: {},
    summary: {
        cashPaymentTermRates: [],
        federalWorkStudyTermRates: []
    },
    aidSubsequences: [],
    ftfy: DEFAULT_FTFY,
    ...data
})

////////////////////

export type FinAidAwardYear = {
    profileId: number
    awardYear: number
    verificationStatusId?: number | null
    verificationUpdatedAt?: string | null
    verificationUpdatedByProfileId?: number | null
    verificationUpdatedByProfile?: Auth.UserShortDetails // populate
}

export type FinAidStudentTerm = {
    profileId: number
    termId: number
    term?: TermDetails // populate
    verificationStatusId?: number | null
    verificationUpdatedAt?: string | null
    verificationUpdatedByProfileId?: number | null
    verificationUpdatedByProfile?: Auth.UserShortDetails // populate

    faStatusId?: number | null
    faStatusUpdatedAt?: string | null
    faStatusUpdatedByProfileId?: number | null
    faStatusUpdatedByProfile?: Auth.UserShortDetails // populate

    termEnrollStatus?: TermEnrollStatus | null
    termBillingMethod?: TermBillingMethod | null
    termCredits?: number | null
    vaBenefit?: number | null
    estimatedAid?: number | null
}

////////////////////

export enum CollegeFinancingPlanStatus {
    Draft = "draft",
    Active = "active"
}

export enum MartialStatus {
    Single = "single",
    Married = "married",
    Divorced = "divorced",
    Widowed = "widowed",
    Separated = "separated"
}

export enum MilitaryStatus {
    ActiveDuty = "active_duty",
    DrillingGuard = "drilling_guard",
    ChildDependent = "child_dependent",
    Spouse = "spouse",
    Veteran = "veteran",
    NotVeteran = "not_veteran"
}

export enum MilitaryEducationBenefitProgram {
    MontgomeryChapter30 = "montgomery_chapter_30",
    MontgomeryChapter1606 = "montgomery_chapter_1606",
    VeteranChapter31 = "veteran_readiness_chapter_31",
    Post911Chapter33 = "post_9_11_chapter_33",
    None = "none"
}

export enum MilitaryPost911Duration {
    Month36 = "36_months",
    Month30 = "30_months",
    Month24 = "24_months",
    Month18 = "18_months",
    Month12 = "12_months",
    Month6 = "6_months",
    Day90 = "90_days",
    Day30Discharged = "30_days_and_discharged",
    Day90NotDischarged = "lt_90_days_and_not_discharged",
    Discharged = "discharged_prior"
}

export enum MilitaryBranch {
    AirForce = "air_force",
    AirForceReserve = "air_force_reserve",
    AirNationalGuard = "air_national_guard",
    Army = "army",
    ArmyNationalGuard = "army_national_guard",
    ArmyReserve = "army_reserve",
    CoastGuard = "coast_guard",
    CoastGuardReserve = "coast_guard_reserve",
    MarineCorps = "marine_corps",
    MarineCorpsReserve = "marine_corps_reserve",
    Navy = "navy",
    NavyReserve = "navy_reserve",
    SpaceForce = "space_force"
}

export enum ServiceMemberStatus {
    ActiveDuty = "active_duty",
    DrillingGuard = "drilling_guard",
    Veteran = "veteran",
    None = "none"
}

export enum IntendedDegree {
    Associates = "associates",
    Bachelors = "bachelors",
    Masters = "masters",
    Doctoral = "doctoral"
}

export enum StudentRelationship {
    Student = "student",
    Mother = "mother",
    Father = "father",
    Spouse = "spouse",
    Educator = "educator",
    TaxProfessional = "tax_professional",
    Guardian = "guardian",
    Other = "other"
}

export enum AcademicLevel {
    Under8thGrade = "under_8th_grade",
    HighSchool = "high_school",
    College = "college",
    NotStudying = "not_studying"
}

export enum CitizenshipStatus {
    USCitizen = "us-citizen",
    ResidentAlien = "resident-alien",
    NonResidentAlien = "non-resident-alien",
    Asylum = "asylum"
}

export enum CFPType {
    TuitionAndFees = "tuition_and_fees",
    HousingAndMeals = "housing_and_meals",
    BooksAndSupplies = "book_and_supplies",
    Transportation = "transportation",
    OtherEducationCosts = "other_edu_costs"
}

export type CollegeFinancingPlan = Timestamps & {
    cfpId: number
    studentProfileId: number
    code: string
    awardYear: number
    status: CollegeFinancingPlanStatus
    createdByProfileId: number
    updatedByProfileId: number

    dob: string // date
    maritalStatus: MartialStatus | string
    hasDependents: boolean | 0 | 1
    isFoster: boolean | 0 | 1
    isMilitaryMember: boolean | 0 | 1
    militaryStatus?: MilitaryStatus | null
    militaryEducationBenefitProgram?: MilitaryEducationBenefitProgram | null
    militaryPost911Duration?: MilitaryPost911Duration | null
    isActiveDutyPersonTransferBenefit?: boolean | 0 | 1 | null
    isEligibleTuitionAssistance?: boolean | 0 | 1 | null
    militaryBranch?: MilitaryBranch | null
    isEligiblePost911EducationBenefits?: boolean | 0 | 1 | null
    isChildOfVeteran?: boolean | 0 | 1 | null
    serviceMemberStatus?: ServiceMemberStatus | null
    stateId: number
    stateName: string
    citizenshipStatus: CitizenshipStatus
    majorId: number
    majorName: string
    academicLevel: AcademicLevel
    studentRelationship: StudentRelationship
    // intendedDegree: IntendedDegree
    degreeLevelId?: number | null
    housingPlan: HousingPlan
    outsideScholarships: boolean | 0 | 1
    outsideScholarshipsAmount?: number | null
    employerPaidTuitionBenefits: boolean | 0 | 1
    employerPaidTuitionBenefitsAmount?: number | null
    hasEFC: boolean | 0 | 1
    efc: number
    isIndependent?: boolean | 0 | 1 | null
    numberInHousehold?: number | null
    childrenInCollege?: number | null
    oldestParentAge?: number | null
    parentFilledTaxReturns?: boolean | 0 | 1 | null
    parentAdjustedGrossIncome?: number | null
    parentIncomeTaxPaid?: number | null
    parent1Income?: number | null
    parent2Income?: number | null
    parentUntaxedIncome?: number | null
    parentCash?: number | null
    parentOwnBusiness?: boolean | 0 | 1 | null
    parentBusinessNetWorth?: number | null
    parentGrantScholarship?: boolean | 0 | 1 | null
    parentAidReceived?: number | null
    studentFilledTaxReturns?: boolean | 0 | 1 | null
    studentAdjustedGrossIncome?: number | null
    studentIncomeTaxPaid?: number | null
    studentIncome?: number | null
    studentSpouseIncome?: number | null
    studentUntaxedIncome?: number | null
    studentCash?: number | null
    studentSpouseEnrolled?: boolean | 0 | 1 | null
    studentOwnBusiness?: boolean | 0 | 1 | null
    studentBusinessNetWorth?: number | null
    studentGrantScholarship?: boolean | 0 | 1 | null
    studentAidReceived?: number | null
    // report
    reportDate?: string | null // date
    tuitionAndFees?: number | null
    housingMealsOnCampus?: number | null
    housingMealsOffCampus?: number | null
    showOnCampus?: boolean | 0 | 1 | null
    booksAndSupplies?: number | null
    transportation?: number | null
    otherEduCosts?: number | null
    meritSchoolScholarships?: number | null
    meritStateScholarships?: number | null
    schoolPaidTuitionBenefits?: number | null
    tuitionRemissionWaiver?: number | null
    pellGrant?: number | null
    institutionalGrants?: number | null
    stateGrants?: number | null
    scholarshipsForDisadvantaged?: number | null
    otherFormsOfGrantAid?: number | null
    educationBenefits?: number | null
    subAmount?: number | null
    subInterestRate?: number | null
    subOriginationFee?: number | null
    unsubAmount?: number | null
    unsubInterestRate?: number | null
    unsubOriginationFee?: number | null
    gradPlusAmount?: number | null
    gradPlusInterestRate?: number | null
    gradPlusOriginationFee?: number | null
    hrsaLoanAmount?: number | null
    hrsaLoanInterestRate?: number | null
    hrsaLoanOriginationFee?: number | null
    workStudy?: number | null
    hoursPerWeek?: number | null
    assistantships?: number | null
} & Partial<{
        degreeLevel: DegreeLevel.DegreeLevel
        studentProfile: Auth.LinkedProfileDetails
        createdBy: Auth.LinkedProfileDetails
        updatedBy: Auth.LinkedProfileDetails
    }>

export const getNewCFP = (data?: DeepPartial<CollegeFinancingPlan>): DeepPartial<CollegeFinancingPlan> => ({
    ...data,
    maritalStatus: MartialStatus.Single,
    hasDependents: false,
    isFoster: false,
    isMilitaryMember: false,
    hasEFC: true,
    isIndependent: false,
    citizenshipStatus: CitizenshipStatus.USCitizen,
    studentRelationship: StudentRelationship.Student,
    housingPlan: HousingPlan.OnCampus,
    parentFilledTaxReturns: true,
    parentOwnBusiness: false,
    parentGrantScholarship: false,
    studentFilledTaxReturns: true,
    studentSpouseEnrolled: false,
    studentOwnBusiness: false,
    studentGrantScholarship: false,
    reportDate: new Date().toISOString()
})

export const CFP_UPDATE_OMIT_FIELDS = [
    "createdAt",
    "updatedAt",
    "deletedAt",
    "awardYear",
    "studentProfileId",
    "code",
    "status",
    "createdByProfileId",
    "updatedByProfileId",
    "studentProfile",
    "createdBy",
    "updatedBy"
]

export enum CommonFundSourceId {
    Pell = 1,
    Sub = 3,
    Unsub = 4,
    Plus = 5
}

/////////////

export type CollegeFinancingPlanSetting = {
    id: string
    field: CFPSettingField
    value: string
    degreeLevelIds: number[]
}

export enum CFPSettingField {
    SchoolContact = "school_contact",
    Customized1 = "customized_1",
    Customized2 = "customized_2",
    AdditionalInfo = "additional_info"
}

/////////////

export type FinAidOfferSetting = {
    id: string
    field: FinAidOfferSettingField
    value: string | any
}

export enum FinAidOfferSettingField {
    Template = "template"
}

/////////////

export type AwardSummary = Timestamps & {
    awardSummaryId: number
    finAidOfferId: number
    finAidOffer?: FinAidOffer // populate
    finAidPackage?: FinAidPackage // populate
    studentProfileId: number
    studentProfile?: Auth.LinkedProfileDetails
    awardYear: number
    fundSourceId: number
    fundSource?: FundSource // populate
    revision: number
    status: AwardSummaryStatus
    rejectionCode?: string | null

    grossAmount: number
    netAmount: number
    interestRebase: number
    originationFee: number
    grossDisbursedAmount?: number | null
    netDisbursedAmount?: number | null
    periodId?: number | null
    period?: PaymentPeriod
    acceptanceDate?: string | null

    currentCPSTransactionNo?: number | null
    adjustedCPSTransactionNo?: number | null
    isirCPSTrans?: number | null
    comments?: string | null
    codStatusCode?: string | null
    codInformedBorrowerId?: string | null
    codInformedBorrowerComplete?: string | null
    codInformedBorrowerCompletedDate?: string | null
    codAwardId?: string | null
    additionalPellEligible?: boolean | 0 | 1 | null

    createdByProfileId: number
    createdBy: Auth.LinkedProfileDetails
    updatedByProfileId: number
    updatedBy: Auth.LinkedProfileDetails
}

export enum AwardSummaryStatus {
    Pending = "pending",
    Approved = "approved",
    Rejected = "rejected",
    Disbursed = "disbursed"
}

export const AWARD_SUMMARY_REJECTION_CODES = [{id: "R", name: "R"}]

/////////////

export enum FAStatusBy {
    AwardYear = "award-year",
    Term = "term"
}

export enum FAAwardWorksheetTemplate {
    Standard = "standard",
    Clock = "clock"
}
