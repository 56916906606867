import React, {useCallback, useMemo, useState} from "react"
import {Tabs} from "antd"
import AttendanceTab from "./parts/Attendance/AttendanceTab"
import {Course} from "types/courses"
import GradeTab from "./parts/Grade/GradeTab"
import {TabHeader} from "components/Tab"
import ClassActivitiesTab from "sections/academics/instructional/InstructionalCourse/parts/ClassActivitiesTab/ClassActivitiesTab"
import {get} from "lodash"
import {useGradingTemplates, useModel} from "hooks"
import {AttendanceOverviewExternshipTab} from "sections/academics/instructional/InstructionalCourse/parts"

const {TabPane} = Tabs
type CourseTabProps = {
    getAttendanceStats?: () => void
    courseIds: number[]
    termIds: number[]
    studentGradingTemplateId: number
    scheduleIds: number[]
    studentProfileIds: number[]
    courseInfo: Course.InstructionalCourse
}
const CourseTab = (props: CourseTabProps) => {
    const {
        studentProfileIds = [],
        termIds = [],
        courseIds = [],
        scheduleIds,
        courseInfo,
        getAttendanceStats,
        studentGradingTemplateId
    } = props
    const [activeTabKey, setActiveTabKey] = useState<string>("1")
    const model = useModel()
    const {data: gradingTemplates} = useGradingTemplates({
        gradingTemplateIds: courseInfo?.gradingTemplateIds ?? []
    })
    const scheduleId = get(props, "match.params.scheduleId")
        ? Number(get(props, "match.params.scheduleId"))
        : get(scheduleIds, [0])

    const onChangeTab = useCallback((activeKey) => {
        setActiveTabKey(activeKey)
    }, [])
    const termId = get(termIds, [0])
    const courseId = get(courseIds, [0])
    const studentProfileId = get(studentProfileIds, [0])
    const isExternshipCourse = !!courseInfo?.isExternshipCourse

    return (
        <div style={{paddingBottom: 300}}>
            <Tabs className="fullwidth" activeKey={activeTabKey} onChange={onChangeTab}>
                <TabPane tab={<TabHeader title="Attendance" icon="PROFILE" />} key="1">
                    {isExternshipCourse ? (
                        <AttendanceOverviewExternshipTab
                            courseId={courseId}
                            termId={termId}
                            studentProfileId={studentProfileId}
                        />
                    ) : (
                        <AttendanceTab
                            model={model}
                            courseInfo={courseInfo}
                            studentProfileIds={studentProfileIds}
                            courseIds={courseIds}
                            getAttendanceStats={getAttendanceStats}
                            scheduleId={scheduleId}
                            termIds={termIds}
                        />
                    )}
                </TabPane>
                <TabPane tab={<TabHeader title="Grades" icon="COURSE" />} key="2">
                    {courseInfo ? (
                        <GradeTab
                            model={model}
                            gradingTemplates={gradingTemplates}
                            courseInfo={courseInfo}
                            studentGradingTemplateId={studentGradingTemplateId}
                            scheduleId={scheduleId}
                            studentProfileIds={studentProfileIds}
                            termIds={termIds}
                            courseIds={courseIds}
                        />
                    ) : null}
                </TabPane>
                <TabPane tab={<TabHeader title="Class Activities" />} key="3">
                    <ClassActivitiesTab
                        studentProfileIds={studentProfileIds}
                        termId={termId}
                        courseInfo={courseInfo}
                        courseId={courseId}
                    />
                </TabPane>
            </Tabs>
        </div>
    )
}

export {CourseTab}
