/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useState, useEffect} from "react"
import {Checkbox} from "antd"
import {v4 as uuid} from "uuid"
import {get, head} from "lodash"
import {Icon} from "components/Icon"
import {BaseInput} from "components/inputs"
import {KlassDropdown} from "components/Select"
import {FormLabel} from "components/Form"
import {DigitalDocumentFieldSetUp} from "types/tasks"
import {getContentPositionStyle} from "./utils"
import {SIGNATURE_METHOD_OPTIONS} from "../../DigitalDocSetUp"
import styles from "./FieldActionItem.module.css"
import {Actions} from "./Actions"

export function SignatureForm(props) {
    const {
        field,
        definedUsers,
        scaleInfo,
        totalPage,
        previousFieldInfo,
        isEditable,
        onClickDelete,
        onChangeField,
        onAddNewFieldValue,
        onRemoveFieldValue,
        onDuplicateField
    } = props
    const [style, setStyle] = useState({})
    const bodyRef = useRef(null)

    useEffect(() => {
        if (!bodyRef || !bodyRef.current) {
            return
        }
        const style = getContentPositionStyle(bodyRef, field, scaleInfo, totalPage)
        setStyle(style)
    }, [bodyRef])

    const onChangeData = (key, value) => {
        if (!isEditable) {
            return
        }
        field[key] = value
        const newFields = onChangeField(field)
        if (key === "isAddDateTime") {
            if (value) {
                const left = field.placeInformation.left - previousFieldInfo.width - 10
                const newFieldValue = {
                    id: uuid(),
                    type: DigitalDocumentFieldSetUp.DATE_FIELD,
                    parentId: field.id,
                    parentSignatureTypeId: get(field, "signatureTypes[0].id", null),
                    placeInformation: {
                        width: previousFieldInfo.width,
                        height: previousFieldInfo.height,
                        top: field.placeInformation.top,
                        left: left > 0 ? left : 0
                    }
                }
                onAddNewFieldValue(field.id, newFieldValue, newFields)
            } else {
                onRemoveFieldValue(field.id, DigitalDocumentFieldSetUp.DATE_FIELD, newFields)
            }
        }
    }

    return (
        <div ref={bodyRef} className={styles.bodyContent} style={style}>
            <div className={styles.bodyHeader}>
                <p className={styles.bodyHeaderTitle}>Signature</p>
                <Actions
                    field={field}
                    isEditable={isEditable}
                    onClickDelete={onClickDelete}
                    onDuplicateField={onDuplicateField}
                />
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Label" />
                <BaseInput
                    placeholder="Label"
                    value={field.label}
                    onChange={(newValue) => onChangeData("label", newValue)}
                />
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Signers" />
                <KlassDropdown
                    options={definedUsers}
                    labelKey="label"
                    value={head(field.signatureTypes)}
                    onChange={(newValue) => onChangeData("signatureTypes", [newValue])}
                />
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Signature method" />
                <KlassDropdown
                    options={SIGNATURE_METHOD_OPTIONS}
                    value={field.signatureMethod}
                    onChange={(newValue) => onChangeData("signatureMethod", newValue)}
                />
            </div>
            <div className={styles.formItem}>
                <Checkbox
                    checked={field.required}
                    onChange={({target: {checked}}) => onChangeData("required", checked)}>
                    <span>Required</span>
                </Checkbox>
            </div>
            <div className={styles.formItem}>
                <Checkbox
                    checked={field.isAddDateTime}
                    onChange={({target: {checked}}) => onChangeData("isAddDateTime", checked)}>
                    <span>Add Date &amp; Time</span>
                </Checkbox>
            </div>
        </div>
    )
}
