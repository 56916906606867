import {useMutation} from "@tanstack/react-query"
import {documentHubService, documentService} from "services"
import {handleError} from "helpers"
import {UserDocumentStatus} from "types/user-documents"

export const useDocumentActions = () => {
    const deleteMutation = useMutation({
        mutationFn: (userDocumentIds: number[]) => {
            return documentService.deleteDocument(userDocumentIds)
        },
        onError: (err) => {
            handleError(err, true)
        }
    })

    const updateStatusMutation = useMutation({
        mutationFn: (payload: {userDocumentId: number; status: UserDocumentStatus}[]) => {
            return documentService.updateDocumentStatus(payload)
        },
        onError: (err) => {
            handleError(err, true)
        }
    })

    const printMutation = useMutation({
        mutationFn: (userDocumentIds: number[]) => {
            return documentService.mergeDocuments({userDocumentIds})
        },
        onError: (err) => {
            handleError(err, true)
        }
    })

    const bulkCreateMutation = useMutation({
        mutationFn: (payload: FormData) => {
            return documentHubService.createDocumentsBulk(payload)
        },
        onError: (err) => {
            handleError(err, true)
        }
    })

    const downloadMutation = useMutation({
        mutationFn: (userDocumentIds: number[]) => {
            return documentHubService.downloadDocuments({userDocumentIds})
        },
        onError: (err) => {
            handleError(err, true)
        }
    })

    return {
        deleteDocuments: deleteMutation.mutate,
        updateStatus: updateStatusMutation.mutate,
        print: printMutation.mutate,
        bulkCreate: bulkCreateMutation.mutate,
        isSubmitting:
            deleteMutation.isLoading ||
            updateStatusMutation.isLoading ||
            printMutation.isLoading ||
            downloadMutation.isLoading,
        isBulkCreating: bulkCreateMutation.isLoading,
        download: downloadMutation.mutate
    }
}
