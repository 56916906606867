import {useQuery} from "@tanstack/react-query"
import {useMemo} from "react"
import {generalSettingsService} from "services"
import {YesNo} from "types/common"
import {GeneralSettingField} from "types/settings/general-setting"

type UseMakeupAttendanceSettingsProps = {
    enabled?: boolean
}

const useMakeupAttendanceSettings = (props?: UseMakeupAttendanceSettingsProps) => {
    const {enabled = true} = props || {}
    const {data, refetch, isFetching} = useQuery(
        ["makeup-attendance-setting"],
        () =>
            generalSettingsService.getSettings([
                GeneralSettingField.MakeupDocumentAttachment,
                GeneralSettingField.MakeupRemote,
                GeneralSettingField.MakeupDocumentAttachmentMandatory
            ]),
        {
            initialData: [],
            enabled
        }
    )

    const isMakeupDocumentAttachmentEnabled = useMemo(
        () =>
            data.find((setting) => setting.field === GeneralSettingField.MakeupDocumentAttachment)?.value === YesNo.Yes,
        [data]
    )

    const isRemoteMakeupDocumentEnabled = useMemo(
        () => data.find((setting) => setting.field === GeneralSettingField.MakeupRemote)?.value === YesNo.Yes,
        [data]
    )

    const isMakeupDocumentAttachmentMandatory = useMemo(
        () =>
            data.find((setting) => setting.field === GeneralSettingField.MakeupDocumentAttachmentMandatory)?.value ===
            YesNo.Yes,
        [data]
    )

    return {
        data,
        isMakeupDocumentAttachmentEnabled,
        isRemoteMakeupDocumentEnabled,
        isMakeupDocumentAttachmentMandatory,
        refetch,
        isFetching
    }
}

export {useMakeupAttendanceSettings}
