import {useMutation} from "@tanstack/react-query"
import axios from "axios"
import {getFileExtension, handleError} from "helpers"
import {clone, orderBy} from "lodash"
import {useState} from "react"
import {fileService} from "services"
import {v4 as uuid} from "uuid"

export type Attachment = {
    id: string
    file: File
    student?: any
    documentName?: string
    description?: string
    attachmentUrl?: string
    presignedUrl?: string
}

export type UploadedAttachment = {
    name: string
    url: string
}
const INITIAL_DOCUMENT_INFO: Attachment = {
    id: null,
    file: null,
    documentName: "",
    description: ""
}

export const useAttachmentsUploader = () => {
    const [attachments, setAttachments] = useState<Attachment[]>([])

    const uploadMutation = useMutation(
        async (): Promise<Array<UploadedAttachment>> => {
            const filesToUpload = attachments
                .filter((document) => !document.attachmentUrl)
                .map((document) => {
                    const fileExtension = document.file.name.split(".").pop()
                    return {
                        ...document,
                        fileExtension
                    }
                })

            const existingAttachmentUrls = attachments
                .filter((document) => document.attachmentUrl)
                .map((document) => {
                    return {
                        url: document.attachmentUrl,
                        name: document.documentName
                    }
                })

            const fileExtensions = filesToUpload.map((file) => file.fileExtension)

            let filesUploadData = await fileService.createMultipleSignedUrlForUploadingFile({
                filesExtensions: fileExtensions
            })
            filesUploadData = filesUploadData.map((item) => ({...item, extension: getFileExtension(item.url)}))

            const sortedFilesUploadData = orderBy(filesUploadData, ["extension"])
            const sortedFiles = orderBy(filesToUpload, ["extension"])

            const filesToUploadToAws = sortedFiles.map((item, index) => ({
                ...item,
                url: sortedFilesUploadData[index].url,
                urlForUploading: sortedFilesUploadData[index].urlForUploading
            }))

            await Promise.all(
                filesToUploadToAws.map((item) => uploadUsingPresignedUrl(item.urlForUploading, item.file))
            )

            const result = filesToUploadToAws
                .map((document) => ({
                    url: document.url,
                    name: document.file.name
                }))
                .concat(existingAttachmentUrls)

            return result
        },
        {
            onError: (error) => handleError(error)
        }
    )

    const uploadUsingPresignedUrl = async (urlForUploading: string, file: File) => {
        const headers = {"Content-Type": file.type}
        const result = await axios.put(urlForUploading, file, {headers})
        return result
    }

    const upload = async (payload: {
        onSuccess: (result: Array<UploadedAttachment>) => void
        onError: (err: any) => void
    }) => uploadMutation.mutate(undefined, {onSuccess: payload.onSuccess, onError: payload.onError})

    const onSelectFiles = (attachedFiles: File[]) => {
        setAttachments((prev: Attachment[]) => {
            const newItems: Attachment[] = attachedFiles.map((x) => ({
                ...clone(INITIAL_DOCUMENT_INFO),
                file: x,
                id: uuid()
            }))

            return [...prev, ...newItems]
        })
    }

    const removeAttachment = (id: string) => {
        setAttachments((prev) => prev.filter((x) => x.id !== id))
    }

    return {
        attachments,
        setAttachments,
        removeAttachment,
        onSelectFiles,
        upload,
        isUploading: uploadMutation.isLoading
    }
}
