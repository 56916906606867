import React, {useMemo} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownAsyncProps} from "../Select"
import {LedgerAccount, LedgerAccountType} from "types/student-account/ledger-accounts"
import styles from "components/BankAccountSelect/BankAccountSelect.module.css"
import {useAllLedgerAccounts} from "hooks"
import {BankAccount, BankAccountType} from "types/student-account/bank-account"
import {useAllBankAccounts} from "hooks/useAllBankAccounts"

type Props = Partial<KlassDropdownAsyncProps> & {
    accountType?: BankAccountType | BankAccountType[]
}

const BankAccountSelect: React.FC<Props> = ({accountType, value, ...props}) => {
    const {t} = useTranslation(["studentAccount", "common"])
    const {bankAccounts} = useAllBankAccounts()

    const options = useMemo(
        () =>
            bankAccounts.filter((account) => {
                if (accountType != null) {
                    if (!(Array.isArray(accountType) ? accountType : [accountType]).includes(account.accountType))
                        return false
                }
                return true
            }),
        [bankAccounts, accountType]
    )

    const optionGroups = React.useMemo(
        () =>
            Object.values(BankAccountType)
                .map((type) => ({
                    label: t(`settings.bankAccounts.typeLabel.${type}`),
                    options: options.filter((account) => account.accountType === type)
                }))
                .filter((group) => group.options.length > 0),
        [t, options]
    )

    return (
        <KlassDropdown
            {...props}
            placeholder={props.placeholder || t("common:selectField.placeholder")}
            options={optionGroups}
            valueKey="accountId"
            labelKey="name"
            value={typeof value === "number" ? options.find((option) => option.accountId === value) : value}
            getOptionLabel={(option: BankAccount) => (
                <span>
                    {option.name} <i>(&bull;&bull;&bull;{option.accountNumber.slice(-4)})</i>
                </span>
            )}
        />
    )
}

export default BankAccountSelect
