/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import moment from "moment"
import {useHistory} from "react-router-dom"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import styles from "./FinAidOffer.module.css"
import {finAidService} from "services"
import {useModel} from "hooks"
import {Col, Row} from "antd"
import {routing} from "helpers/routing"
import {getFullName} from "helpers"
import {KlassappTableProps} from "types/common"
import {Auth} from "types/auth"

interface FinAidPackagesProps extends KlassappTableProps {
    studentId: number
    awardYear: number
}

function FinAidPackages(props: FinAidPackagesProps) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        isLoading,
        menuActions,
        isHideMenuActions,
        isShowTableHeaderAction,
        getCurrentPage,
        getCurrentPageSize,
        studentId,
        awardYear
    } = props
    const history = useHistory()
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const {t} = useTranslation(["financialAid", "common"])

    const getPageTitle = () => {
        return t("finAidOffer.packageTableTitle")
    }

    const getFields = () => {
        return [
            t("finAidOffer.column.code"),
            t("finAidOffer.column.isir"),
            t("finAidOffer.column.studentProfile"),
            t("finAidOffer.column.awardYear"),
            t("finAidOffer.column.primaryEFC")
        ]
    }

    const getColumns = () => {
        return [
            {
                title: t("finAidOffer.column.code"),
                field: "code",
                style: {minWidth: "100px"}
            },
            {
                title: t("finAidOffer.column.isir"),
                field: "isir",
                style: {minWidth: "100px"}
            },
            {
                title: t("finAidOffer.column.studentProfile"),
                field: "studentProfile",
                style: {minWidth: "100px"},
                render: (value) => getFullName(value)
            },
            {
                title: t("finAidOffer.column.awardYear"),
                field: "awardYear",
                style: {minWidth: "60px"},
                render: (value) => `${value} - ${value + 1}`
            },
            {
                title: t("finAidOffer.column.isirSSN"),
                field: "ssn",
                style: {minWidth: "50px"}
            },
            {
                title: t("finAidOffer.column.primaryEFC"),
                field: "efc",
                style: {minWidth: "80px"}
            },
            {
                title: t("finAidOffer.column.lastUpdated"),
                field: "updatedAt",
                style: {minWidth: "150px"},
                render: (value) => moment(value).format(userDateTimeFormat)
            }
        ]
    }

    const getData = async () => {
        dispatch({isLoading: true})
        const page = getCurrentPage()
        const pageSize = getCurrentPageSize()
        const {total, data} = await finAidService.getFinAidPackages({
            filter: {studentProfileId: studentId, awardYear},
            range: {
                page,
                pageSize
            }
        })
        dispatch({total, data: data.map((item) => ({...item, id: item.finAidPackageId})), isLoading: false})
    }

    const getTableHeaderActions = React.useCallback(
        (isShowDuplicateBtn = true, checkedData = []) => {
            if (checkedData.length > 1) {
                return []
            }
            return []
        },
        [t]
    )

    const getMenuActions = React.useCallback(() => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit
            }
        ]
    }, [])

    const onClickRowItem = ({studentProfileId, finAidPackageId}) => {
        history.push(routing.finAid.student.packageDetail(studentProfileId, finAidPackageId))
    }

    const onClickEdit = ({studentProfileId, finAidPackageId}) => {
        history.push(routing.finAid.student.packageDetail(studentProfileId, finAidPackageId))
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [page, pageSize])

    const [, setSearch] = React.useState("")
    const [filter, setFilter] = React.useState<Record<string, any>>({})

    const onApplyFilter = () => {}

    const onClearFilter = () => {
        setFilter({})
    }

    const onSearchInput = (search) => {
        setSearch(search)
    }

    return (
        <div>
            {/*
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClearFilter}
                onSearchInput={onSearchInput}>
                <Row gutter={[24, 24]}>
                    <Col span={12}></Col>
                    <Col span={12}></Col>
                </Row>
            </BaseNewFilter>
*/}
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={isHideMenuActions ? [] : menuActions}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(FinAidPackages)
