import {get} from "lodash"
import BaseService from "./Base"
import {DeepPartial} from "types/common"
import {
    AwardSummary,
    CollegeFinancingPlan,
    CollegeFinancingPlanSetting,
    FinAidAwardYear,
    FinAidOffer,
    FinAidOfferSetting,
    FinAidPackage,
    FinAidStudentTerm,
    FinAidWorksheet
} from "types/fin-aid/fin-aid"

class FinAidService extends BaseService {
    async getFinAidWorksheets(params = {}) {
        const response = await this.post(`/v1/fin-aid-plan/worksheets/list`, params)
        return get(response, "data", {})
    }

    async createFinAidWorksheet(data: DeepPartial<FinAidWorksheet> & {savePackage?: boolean}) {
        const response = await this.post(`/v1/fin-aid-plan/worksheets/create`, data)
        return get(response, "data.data", {})
    }

    async updateFinAidWorksheet(data: DeepPartial<FinAidWorksheet> & {savePackage?: boolean}) {
        const response = await this.post(`/v1/fin-aid-plan/worksheets/edit`, data)
        return get(response, "data.data", {})
    }

    async createFinAidWorksheetRevision(id: number) {
        const response = await this.post(`/v1/fin-aid-plan/worksheets/create-revision`, {id})
        return get(response, "data.data", {})
    }

    async deleteFinAidWorksheet(id: number) {
        const response = await this.post(`/v1/fin-aid-plan/worksheets/delete`, {id})
        return get(response, "data", {})
    }

    async getFinAidPackages(params = {}): Promise<{total: number; data: FinAidPackage[]}> {
        const response = await this.post(`/v1/fin-aid-plan/packages/list`, params)
        return get(response, "data", {})
    }

    async getFinAidOffers(params = {}) {
        const response = await this.post(`/v1/fin-aid-plan/offers/list`, params)
        return get(response, "data", {})
    }

    async getFinAidLatestOffer(params = {}) {
        const response = await this.post(`/v1/fin-aid-plan/offers/latest`, params)
        return get(response, "data.data", {})
    }

    async getPackagingStatistics(awardYear?: number) {
        const response = await this.post(`/v1/fin-aid-plan/offers/statistics`, {awardYear})
        return get(response, "data.data", [])
    }

    async updateFinAidOffer(data: DeepPartial<FinAidOffer>) {
        const response = await this.post(`/v1/fin-aid-plan/offers/edit`, data)
        return get(response, "data.data", {})
    }

    async deleteFinAidOffer(id: number) {
        const response = await this.post(`/v1/fin-aid-plan/offers/delete`, {id})
        return get(response, "data", {})
    }

    async getStudentAwardYears(profileId: number): Promise<FinAidAwardYear[]> {
        const response = await this.post(`/v1/fin-aid-plan/award-years/list`, {
            profileId
        })
        return get(response, "data.data", {})
    }

    async updateStudentAwardYears(profileId: number, awardYears: Partial<FinAidAwardYear>[]): Promise<void> {
        const response = await this.post(`/v1/fin-aid-plan/award-years/edit`, {
            profileId,
            awardYears
        })
        return get(response, "data.data", {})
    }

    async getAwardYearsInfo(studentProfileId: number): Promise<{isir?: string; efc?: number}> {
        const response = await this.post(`/v1/fin-aid-plan/award-years/info`, {
            studentProfileId
        })
        return get(response, "data.data", {})
    }

    async getStudentTerms(profileId: number): Promise<FinAidStudentTerm[]> {
        const response = await this.post(`/v1/fin-aid-plan/student-terms/list`, {
            profileId
        })
        return get(response, "data.data", {})
    }

    async updateStudentTerms(profileId: number, studentTerms: Partial<FinAidStudentTerm>[]): Promise<void> {
        const response = await this.post(`/v1/fin-aid-plan/student-terms/edit`, {
            profileId,
            studentTerms
        })
        return get(response, "data.data", {})
    }

    async updateStudentTerm(data: Partial<FinAidStudentTerm>): Promise<void> {
        const response = await this.post(`/v1/fin-aid-plan/student-terms/edit-one`, data)
        return get(response, "data.data", {})
    }

    async getCFPs(params = {}) {
        const response = await this.post(`/v1/fin-aid-plan/cfps/list`, params)
        return get(response, "data", {})
    }

    async getCFP(id: number, params = {}) {
        const response = await this.get(`/v1/fin-aid-plan/cfps/${id}`, params)
        return get(response, "data.data", {})
    }

    async getLatestCFP(profileId: number) {
        const response = await this.post(`/v1/fin-aid-plan/cfps/latest`, {profileId})
        return get(response, "data.data", {})
    }

    async createCFP(data: DeepPartial<CollegeFinancingPlan>): Promise<CollegeFinancingPlan> {
        const response = await this.post(`/v1/fin-aid-plan/cfps/create`, data)
        return get(response, "data.data", {})
    }

    async updateCFP(data: DeepPartial<CollegeFinancingPlan>) {
        const response = await this.post(`/v1/fin-aid-plan/cfps/edit`, data)
        return get(response, "data.data", {})
    }

    async deleteCFP(id: number) {
        const response = await this.post(`/v1/fin-aid-plan/cfps/delete`, {id})
        return get(response, "data", {})
    }

    async getCFPSettings(params?: object): Promise<CollegeFinancingPlanSetting[]> {
        const response = await this.post(`/v1/fin-aid-plan/cfp-settings/list`, {}, params)
        return get(response, "data.data", [])
    }

    async updateCFPSettings(data: Partial<CollegeFinancingPlanSetting>[]) {
        await this.post(`/v1/fin-aid-plan/cfp-settings/edit`, data)
    }

    async getFAOSettings(): Promise<FinAidOfferSetting[]> {
        const response = await this.post(`/v1/fin-aid-plan/fao-settings/list`, {})
        return get(response, "data.data", [])
    }

    async updateFAOSettings(data: Partial<FinAidOfferSetting>[]) {
        await this.post(`/v1/fin-aid-plan/fao-settings/edit`, data)
    }

    async getAdmissionInfo(params = {}) {
        const response = await this.post(`/v3/fin-aid/staff/admission/list`, params)
        return get(response, "data", {})
    }

    async getAwardSummaries(params = {}) {
        const response = await this.post(`/v1/fin-aid-plan/award-summaries/list`, params)
        return get(response, "data", {})
    }

    async getAwardSummary(awardSummaryId: number) {
        const response = await this.post(`/v1/fin-aid-plan/award-summaries/get`, {awardSummaryId, linkedEntities: true})
        return get(response, "data.data", {})
    }

    async createAwardSummary(data: DeepPartial<AwardSummary>): Promise<AwardSummary> {
        const response = await this.post(`/v1/fin-aid-plan/award-summaries/create`, data)
        return get(response, "data.data", {})
    }

    async bulkCreateAwardSummaries(data: DeepPartial<AwardSummary>[]): Promise<void> {
        await this.post(`/v1/fin-aid-plan/award-summaries/bulk-create`, data)
    }

    async updateAwardSummary(data: DeepPartial<AwardSummary>) {
        const response = await this.post(`/v1/fin-aid-plan/award-summaries/edit`, data)
        return get(response, "data.data", {})
    }

    async deleteAwardSummaries(ids: number[]) {
        await this.post(`/v1/fin-aid-plan/award-summaries/delete`, {ids})
    }

    getAllScholarships = async (params = {}) => {
        const response = await this.post("/v1/fin-aid/scholarships/list", params)
        return get(response, "data", [])
    }

    getMaxScholarshipsId = async (params = {}) => {
        const response = await this.post("/v1/fin-aid/scholarships/get-max-id", params)
        return get(response, "data", [])
    }

    createScholarship = async (body = {}) => {
        const response = await this.post("/v1/fin-aid/scholarships/create", body)
        return get(response, "data.data", {})
    }

    updateScholarship = async (id, data) => {
        data.scholarshipId = id
        const response = await this.post("/v1/fin-aid/scholarships/edit", data)
        return get(response, "data", {})
    }

    deleteScholarship = async (scholarshipIds) => {
        const response = await this.post("/v1/fin-aid/scholarships/delete", {scholarshipIds})
        return get(response, "data", {})
    }

    getAllGuidelinesScholarship = async (params = {}) => {
        const response = await this.post("/v1/fin-aid/scholarships/guidelines/list", params)
        return get(response, "data", [])
    }

    saveGuidelinesScholarship = async (body = {}) => {
        const response = await this.post("/v1/fin-aid/scholarships/guidelines/save", body)
        return get(response, "data", {})
    }

    getAllDonorsScholarship = async (params = {}) => {
        const response = await this.post("/v1/fin-aid/scholarships/donors/list", params)
        return get(response, "data", [])
    }

    saveDonorsScholarship = async (body = {}) => {
        const response = await this.post("/v1/fin-aid/scholarships/donors/save", body)
        return get(response, "data", {})
    }

    getScholarshipOverallSummary = async (body = {}) => {
        const response = await this.post("/v1/fin-aid/scholarships/overall-summary", body)
        return get(response, "data.data", {})
    }

    getScholarshipDetailSummary = async (scholarshipId) => {
        const response = await this.post("/v1/fin-aid/scholarships/summary", {filter: {scholarshipId}})
        return get(response, "data.data", {})
    }

    getScholarshipDonorTotalAmount = async (scholarshipId) => {
        const response = await this.post("/v1/fin-aid/scholarships/donors/total-amount", {filter: {scholarshipId}})
        return get(response, "data.data", {})
    }

    getPellGrant = async (body = {}) => {
        const response = await this.post("/v1/fin-aid/pell-grant/list", body, {
            baseURL: process.env.REACT_APP_PELLGRANT_API
        })
        return get(response, "data.data", [])
    }

    updateStaffSignature = async (body = {}) => {
        const response = await this.post("/v1/fin-aid/student/package/signature/staff/update", body)
        return get(response, "data.data", {})
    }

    updateStudentSignature = async (body = {}) => {
        const response = await this.post("/v1/fin-aid/student/package/signature/student/update", body)
        return get(response, "data.data", {})
    }

    getSAPAwardYear = async (studentProfileId: number): Promise<number> => {
        const response = await this.post("/v1/fin-aid-reports/sap-award-year", {studentProfileId})
        return get(response, "data.data", null)
    }

    getSAPItems = async (payload: {
        profileId: number
        programId?: number
        programVersionId?: number
        periodIds: number[]
    }): Promise<{
        sapItems: any[]
        cgpa?: number
        minGPAs?: number[]
        minAttendance?: number
        minCompletionRate?: number
        programLength?: number
        maxTimeframePercentage?: number
        maxTimeframe?: number
    }> => {
        const response = await this.post("/v1/fin-aid-reports/sap-items", payload)
        return get(response, "data.data", [])
    }

    getAllFinancialAidStatuses = async (params = {}) => {
        const response = await this.post("/v1/fin-aid/settings/fa-statuses/list", params)
        return get(response, "data", [])
    }

    createFinancialAidStatus = async (params = {}) => {
        const response = await this.post("/v1/fin-aid/settings/fa-statuses/create", params)
        return get(response, "data.data", {})
    }

    duplicateFinancialAidStatus = async (statuses) => {
        const response = await this.post(`/v1/fin-aid/settings/fa-statuses/create`, statuses)
        return get(response, "data", {})
    }

    updateFinancialAidStatus = async (id, data, params = {}) => {
        data.statusId = id
        const response = await this.post(`/v1/fin-aid/settings/fa-statuses/edit`, data, params)
        return get(response, "data", {})
    }

    deleteFinancialAidStatus = async (statusIds) => {
        const response = await this.post(`/v1/fin-aid/settings/fa-statuses/delete`, {statusIds})
        return get(response, [], {})
    }

    getAllVerificationStatuses = async (params = {}) => {
        const response = await this.post("/v1/fin-aid/settings/verification-statuses/list", params)
        return get(response, "data", [])
    }

    createVerificationStatus = async (params = {}) => {
        const response = await this.post("/v1/fin-aid/settings/verification-statuses/create", params)
        return get(response, "data.data", {})
    }

    duplicateVerificationStatus = async (statuses) => {
        const response = await this.post(`/v1/fin-aid/settings/verification-statuses/create`, statuses)
        return get(response, "data", {})
    }

    updateVerificationStatus = async (id, data, params = {}) => {
        data.statusId = id
        const response = await this.post(`/v1/fin-aid/settings/verification-statuses/edit`, data, params)
        return get(response, "data", {})
    }

    deleteVerificationStatus = async (statusIds) => {
        const response = await this.post(`/v1/fin-aid/settings/verification-statuses/delete`, {statusIds})
        return get(response, [], {})
    }
}

export default FinAidService
