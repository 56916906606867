import {Icon} from "components/Icon"
import styles from "./FieldActionItem.module.css"

type Props = {
    field: any
    isEditable: boolean
    onClickDelete: (field) => void
    onDuplicateField: (field) => void
}

export function Actions(props: Props) {
    const {field, isEditable, onClickDelete, onDuplicateField} = props

    if (!isEditable) {
        return null
    }
    return (
        <div className={styles.headerActions}>
            <div className={styles.actionBtn} onClick={() => onDuplicateField(field)}>
                <Icon icon="COPY" color="#1e90ff" className={styles.actionIcon} />
            </div>
            <div className={styles.actionBtn} onClick={() => onClickDelete(field)}>
                <Icon icon="DELETE" color="#DF1642" className={styles.actionIcon} />
            </div>
        </div>
    )
}
