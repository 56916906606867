/* eslint-disable react-hooks/exhaustive-deps */
import {checkDocumentDepartmentPermission, downloadFile, handleError, openLinkInNewTab} from "helpers"
import {KlassappTableHOC} from "HOC"
import {useModel, useVisible} from "hooks"
import moment from "moment"
import {useCallback, useEffect, useMemo, useState} from "react"
import {documentHubService} from "services"
import {KlassappTableProps} from "types/common"
import {BaseDepartmentId} from "types/departments"
import {FilterKey, FilterList} from "types/filter"
import styles from "./DocumentListView.module.css"
import {PermissionType} from "types/permission"
import {Icon} from "components"
import {useHistory} from "react-router-dom"
import {ConfirmPopup, KlassappTable, KlassappTableHeader} from "uiKit"
import {useDocumentActions} from "hooks/"
import {stringifyUrl} from "query-string"
import {DocumentAction, UserDocument, UserDocumentStatus} from "types/user-documents"
import update from "immutability-helper"
import PrintPreparePopup from "sections/Documents/parts/PrintPreparePopup"
import {ExportFileType} from "components/ui"
import {exportCsv, exportExcel} from "helpers/export-table"
import {get} from "lodash"
import {Tooltip} from "antd"
import {PreviewDocument} from "../PreviewDocument"

type Props = KlassappTableProps & {
    filterKey?: FilterKey
    filterParams?: {
        filters: FilterList
        search?: string
        userId?: number
        profileId?: number
        onlyVisibleForUser: boolean
    }
    departmentId?: BaseDepartmentId
    backUrl?: string
    exportFileType?: ExportFileType
    onExporting?: () => void
    onExported?: () => void
}

function DocumentList(props: Props) {
    const model = useModel()
    const history = useHistory()
    const {
        data,
        page,
        total,
        pageSize,
        columns,
        fields,
        allFields,
        isLoading,
        orderField,
        isLoadedTableFuncs,
        dispatch,
        dispatchFunc,
        getCurrentData,
        isShowTableHeaderAction,
        tableHeaderActions,
        isHideMenuActions,
        menuActions
    } = props
    const {filterKey, filterParams, backUrl, departmentId, exportFileType, onExporting, onExported} = props
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const {deleteDocuments, updateStatus, download, isSubmitting} = useDocumentActions()
    const showConfirmationPopup = useVisible(false)
    const [actionDocuments, setActionDocuments] = useState<{
        action?: DocumentAction
        userDocumentIds?: number[]
    }>({})
    const [documentsForPrint, setDocumentsForPrint] = useState<UserDocument[]>([])
    const [selectedDocument, setSelectedDocument] = useState<any>()
    const previewPopup = useVisible(false)

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getFilterMemoryKey", func: getFilterKey},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickEdit", func: onClickEdit}
        ])
    }, [])

    useEffect(() => {
        dispatchFunc([
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "onClickDelete", func: onClickDelete},
            {key: "getMenuActions", func: getMenuActions}
        ])
    }, [filterParams])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, orderField?.field, orderField?.order, filterParams])

    useEffect(() => {
        if (exportFileType) {
            handleExport(exportFileType)
        }
    }, [exportFileType])

    const getFilterKey = useCallback(() => {
        return filterKey
    }, [filterKey])

    const getData = useCallback(async () => {
        try {
            dispatch({isLoading: true})

            const storageData = model.getStorageFilter(filterKey)
            let orderFieldData = orderField
            if (storageData?.colOrd) {
                const columns: any[] = getColumns()
                const columnOrder = columns.find((column: any) => column.columnIndex === storageData.colOrd.idx)
                orderFieldData = {field: columnOrder?.orderField || columnOrder?.field, order: storageData.colOrd.order}
            }

            const payload = {
                range: {
                    pageSize: pageSize,
                    page: page
                },
                ...filterParams,
                sort: orderFieldData?.field
                    ? {
                          orderBy: orderFieldData.field,
                          orderDir: orderFieldData.order
                      }
                    : undefined
            }
            const {data, total} = await documentHubService.listDocuments(payload)
            const formattedDocuments = data.map((item: any) => {
                item.id = item.userDocumentId
                item.statusHtml = renderStatusHtml(item)
                item.advisorsHtml = renderAdvisorsHtml(item)
                item.nameHtml = renderNameHtml(item)
                item.previewHtml = renderPreviewHtml(item)
                return item
            })
            dispatch({data: formattedDocuments, total})
        } catch (err) {
            handleError(err)
        } finally {
            dispatch({isLoading: false})
        }
    }, [filterKey, filterParams, page, pageSize, orderField])

    const getFields = () => {
        const fields = ["Preview", "ID", "Name", "Description", "Department"]
        if (!filterParams.profileId) {
            fields.push(...["Student", "User ID", "Enrollment ID"])
        }

        fields.push(...["Date", "Approved"])
        return fields
    }

    const getColumns = () => {
        const cols: any = [
            {
                title: "Preview",
                field: "previewHtml",
                isHideTitle: true,
                style: {width: 50, padding: "3px 4px", textAlign: "right", lineHeight: "1.2"},
                headerStyle: {width: 50, padding: "3px 4px", lineHeight: "1.2"},
                columnIndex: 1
            },
            {
                title: "ID",
                field: "code",
                style: {
                    minWidth: "70px",
                    maxWidth: "80px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true,
                columnIndex: 2
            },
            {
                title: "Name",
                field: "nameHtml",
                style: {
                    minWidth: "90px",
                    maxWidth: "100px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true,
                orderField: "name",
                renderText: (nameHtml: any, row: any) => {
                    const {name} = row
                    return name
                },
                columnIndex: 3
            },
            {
                title: "Description",
                field: "description",
                style: {
                    minWidth: "120px",
                    maxWidth: "130px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true,
                columnIndex: 4
            },
            {
                title: "Department",
                field: "departmentName",
                style: {
                    minWidth: "100px",
                    maxWidth: "100px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true,
                columnIndex: 5
            },
            {
                title: "Assigned Advisor",
                field: "advisorsHtml",
                style: {
                    minWidth: "100px",
                    maxWidth: "100px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                renderText: (advisorsHtml: any, row: any) => {
                    const {advisors} = row
                    return advisors?.map((x) => x.fullName).join(", ")
                },
                columnIndex: 6
            }
        ]

        if (!filterParams.profileId) {
            cols.push(
                ...[
                    {
                        title: "Student",
                        field: "ownerFullName",
                        style: {minWidth: "120px", maxWidth: "120px"},
                        sortable: true,
                        columnIndex: 7
                    },
                    {
                        title: "User ID",
                        field: "ownerUserId",
                        style: {
                            minWidth: "100px",
                            maxWidth: "100px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden"
                        },
                        sortable: true,
                        columnIndex: 8
                    },
                    {
                        title: "Enrollment ID",
                        field: "ownerEnrollmentId",
                        style: {
                            minWidth: "100px",
                            maxWidth: "100px",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden"
                        },
                        sortable: true,
                        columnIndex: 9
                    }
                ]
            )
        }

        cols.push(
            ...[
                {
                    title: "Date",
                    field: "createdAt",
                    fieldType: "date",
                    format: model.getUserDateFormat(),
                    style: {minWidth: "100px", maxWidth: "110px"},
                    sortable: true,
                    columnIndex: 10
                },
                {
                    title: "Approved",
                    field: "statusHtml",
                    style: {
                        minWidth: "100px",
                        maxWidth: "110px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden"
                    },
                    renderText: (statusHtml: any, row: any) => {
                        const {status} = row
                        return status
                    },
                    columnIndex: 11
                }
            ]
        )

        return cols
    }

    const canShowDeleteIcon = (data) => {
        return data.status !== UserDocumentStatus.Approved
    }

    const getMenuActions = () => {
        return [
            {
                title: "Edit",
                icon: "EDIT",
                action: onClickEdit
            },
            {
                title: "Delete",
                icon: "DELETE",
                action: onClickDelete,
                canShow: canShowDeleteIcon
            }
        ]
    }

    const getTableHeaderActions = () => {
        const actions: any[] = []
        const data = getCurrentData()

        const canDelete = data
            .filter((item) => item.isChecked)
            .every((item) => item.status !== UserDocumentStatus.Approved)
        const canDeleteByPermission = checkDocumentDepartmentPermission(PermissionType.Delete, model, departmentId)
        if (canDeleteByPermission && canDelete) {
            actions.push({
                title: "Delete",
                icon: "DELETE",
                action: () => onConfirmAction(DocumentAction.Delete)
            })
        }

        const canApprove =
            model.isStaffOrAdmin() &&
            data.filter((item) => item.isChecked).every((item) => item.ownerUserId !== model.user.id)
        if (canApprove) {
            actions.push(
                ...[
                    {
                        title: "Approve",
                        icon: "CHECKMARK_CIRCLE",
                        iconClassName: styles.approveIcon,
                        action: () => onConfirmAction(DocumentAction.Approve)
                    },
                    {
                        title: "Reject",
                        icon: "CROSS_CIRCLE_FILL",
                        iconClassName: styles.rejectIcon,
                        action: () => onConfirmAction(DocumentAction.Reject)
                    }
                ]
            )
        }
        const canPrint =
            model.isStaffOrAdmin() ||
            data.filter((item) => item.isChecked).every((item) => item.ownerUserId === model.user.id)
        if (canPrint) {
            actions.push(
                ...[
                    {
                        title: "Print",
                        icon: "DOCUMENT",
                        iconClassName: styles.printIcon,
                        action: onClickPrint
                    },
                    {
                        title: "Download",
                        icon: "DOWNLOAD",
                        iconClassName: styles.printIcon,
                        action: onClickDownload
                    }
                ]
            )
        }
        return actions
    }

    const onConfirmAction = (action: DocumentAction) => {
        const data = getCurrentData()
        const checkedItems = data.filter((item) => item.isChecked)

        setActionDocuments({
            action,
            userDocumentIds: checkedItems.map((item) => item.id)
        })
        showConfirmationPopup.open()
    }

    const onHandleAction = async () => {
        if (!actionDocuments.action) return
        showConfirmationPopup.close()
        const {action, userDocumentIds} = actionDocuments
        switch (action) {
            case DocumentAction.Delete:
                return deleteDocuments(userDocumentIds, {
                    onSuccess: async () => {
                        getData()
                        dispatch({isShowTableHeaderAction: false, isHideMenuActions: false})
                    }
                })
            case DocumentAction.Approve:
            case DocumentAction.Reject:
                const payload = userDocumentIds.map((id) => ({
                    userDocumentId: id,
                    status:
                        action === DocumentAction.Approve ? UserDocumentStatus.Approved : UserDocumentStatus.Rejected
                }))
                return updateStatus(payload, {
                    onSuccess: async () => {
                        getData()
                        dispatch({isShowTableHeaderAction: false})
                    }
                })
        }
    }

    const onClickRowItem = (data) => {
        const documentDetailsUrl = stringifyUrl(
            {
                url: `/documents/detail`,
                query: {
                    id: data.id,
                    backUrl,
                    userProfileId: data.ownerUserProfileId
                }
            },
            {skipNull: true}
        )
        history.push(documentDetailsUrl)
    }

    const onClickEdit = (editedItem) => {
        onClickRowItem(editedItem)
    }

    const onClickDelete = async (deletedItem) => {
        setActionDocuments({
            action: DocumentAction.Delete,
            userDocumentIds: [deletedItem.userDocumentId]
        })
        showConfirmationPopup.open()
    }

    const onClickPrint = async () => {
        const data = getCurrentData()
        const checkedItems = data.filter((item) => item.isChecked)
        setDocumentsForPrint(checkedItems)
    }

    const onClickDownload = async () => {
        const data = getCurrentData()
        const checkedItems = data.filter((item) => item.isChecked)
        const userDocumentIds = checkedItems.map((item) => item.id)
        return download(userDocumentIds, {
            onSuccess: async (data) => {
                downloadFile(`downloads-${moment().format("YYYY-MM-DD")}.zip`, data)
                dispatch({isShowTableHeaderAction: false, isHideMenuActions: false})
            }
        })
    }

    const renderStatusHtml = ({status, statusUpdatedBy, statusUpdatedAt}) => {
        return (
            <div className={styles.statusWrap}>
                <div className={styles.status}>
                    {status === UserDocumentStatus.Approved ? (
                        <Icon icon="CHECKMARK_CIRCLE" color="#18A957" />
                    ) : status === UserDocumentStatus.Rejected ? (
                        <Icon icon="CROSS_CIRCLE_FILL" color="#DF1642" />
                    ) : (
                        <Icon icon="CHECKMARK_CIRCLE_1" color="#E6E6E6" />
                    )}
                </div>
                {!!status && status !== UserDocumentStatus.Pending && (
                    <div className={styles.statusInfo}>
                        <p className={styles.statusInfo__text}>{statusUpdatedBy}</p>
                        <p className={styles.statusInfo__text}>{moment(statusUpdatedAt).format(userDateTimeFormat)}</p>
                    </div>
                )}
            </div>
        )
    }

    const renderAdvisorsHtml = ({advisors}) => {
        return (
            <div className={styles.advisorsWrap}>
                {advisors?.map((advisor) => {
                    return <span>{advisor.fullName}</span>
                })}
            </div>
        )
    }

    const renderNameHtml = (data) => {
        const documentDetailsUrl = stringifyUrl(
            {
                url: `/documents/detail`,
                query: {
                    id: data.id,
                    backUrl,
                    userProfileId: data.ownerUserProfileId
                }
            },
            {skipNull: true}
        )

        return (
            <div
                className={styles.advisorsWrap}
                onClick={(e) => {
                    e.stopPropagation()
                    openLinkInNewTab(documentDetailsUrl)
                }}>
                <Tooltip title="Click to open document details in new tab">
                    <span className={styles.link}>{data.name}</span>
                </Tooltip>
            </div>
        )
    }

    const renderPreviewHtml = (data) => {
        return (
            <Tooltip title="Preview document">
                <div
                    className={styles.previewIcon}
                    onClick={(e) => {
                        e.stopPropagation()
                        onPreviewDocument(data)
                    }}>
                    <Icon icon="EYE" color="var(--primary-400-base)" />
                </div>
            </Tooltip>
        )
    }

    const onPreviewDocument = (data) => {
        setSelectedDocument(data)
        previewPopup.open()
    }

    const onClosePreview = () => {
        previewPopup.close()
        setSelectedDocument(null)
    }

    const confirmationTitle = useMemo(() => {
        if (!actionDocuments.action) return ""
        return `Are you sure you want to ${actionDocuments.action.toLowerCase()} ${
            actionDocuments.userDocumentIds.length
        } item(s)?`
    }, [actionDocuments])

    const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
        setDocumentsForPrint((prevState) =>
            update(prevState, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevState[dragIndex]]
                ]
            })
        )
    }, [])

    const handleExport = async (type: ExportFileType) => {
        try {
            onExporting()
            const reqPayload = {
                range: {
                    pageSize: total,
                    page: 1
                },
                ...filterParams,
                isExport: true
            }
            const {data} = await documentHubService.listDocuments(reqPayload)
            const reportColumns = getColumns().filter(
                (col) => fields.includes(col.title) && !["Preview"].includes(col.title)
            )
            const filename = ["documents", moment().format("DD_MM_YYYY-hhmm")].join("-")

            const payload = {
                filename,
                columns: reportColumns.map((col) => col.title),
                rows: data.map((item) =>
                    reportColumns.map((col) => {
                        const value = col.renderText
                            ? col.renderText(get(item, col.field), item)
                            : get(item, col.field)?.toString()
                        return (type === "csv" ? `"${value?.replaceAll(/[|,;"]/g, "")}"` : value) || ""
                    })
                )
            }
            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            }
        } catch (err) {
            handleError(err)
        } finally {
            onExported()
        }
    }

    return (
        <>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading || isSubmitting}
                menuActions={isHideMenuActions ? [] : menuActions}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                isShowCheckedColumn
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
            />
            <ConfirmPopup
                isVisible={showConfirmationPopup.isVisible}
                title={confirmationTitle}
                onClose={showConfirmationPopup.close}
                onConfirm={onHandleAction}
            />
            <PrintPreparePopup
                visible={!!documentsForPrint.length}
                onClose={() => setDocumentsForPrint([])}
                documents={documentsForPrint}
                moveItem={moveItem}
            />
            <PreviewDocument
                userDocumentId={selectedDocument?.userDocumentId}
                goToDetails={onClickRowItem}
                isVisible={previewPopup.isVisible}
                onClose={onClosePreview}
            />
        </>
    )
}

export const DocumentListView = KlassappTableHOC(DocumentList)
