/* eslint-disable react-hooks/exhaustive-deps */
import {getFullName} from "helpers"
import {CommunicationProvider} from "context/CommunicationContext"
import {ChatRoomProvider} from "context/ChatRoomContext"
import styles from "./ChatModalV2.module.css"
import {BasePopup} from "components"
import {StudentChat} from "../StudentChat"

type Props = {
    user: any
    onShow: boolean
    onClose: () => void
    userIdField: string
    departmentId: number
}

const ChatModal = (props: Props) => {
    const {departmentId, user, userIdField, onClose, onShow} = props

    return (
        <BasePopup
            isShow={onShow}
            onClose={onClose}
            leftIcon="MESSAGE"
            leftIconColor="#fff"
            width="700px"
            destroyOnClose>
            <div className={styles.titleWrap}>
                <span>{`Message to ${getFullName(user)}`}</span>
                <span className={styles.spanId}>ID: {user?.customUserId}</span>
            </div>
            <StudentChat user={user} userIdField={userIdField} departmentId={departmentId} />
        </BasePopup>
    )
}

export function ChatModalV2(props: Props) {
    return (
        <CommunicationProvider>
            <ChatRoomProvider>
                <ChatModal {...props} />
            </ChatRoomProvider>
        </CommunicationProvider>
    )
}
