/* eslint-disable react-hooks/exhaustive-deps */
import {BaseDepartmentId} from "types/departments"
import {useCallback, useContext, useMemo, useState} from "react"
import {CommunicationContext} from "context/CommunicationContext"
import {Communication} from "types/communication"
import {getFullName, getUnreadCountBadge, handleError, toastSuccess} from "helpers"
import {useQuery} from "@tanstack/react-query"
import {useModel} from "hooks"
import {intersection} from "lodash"
import {chatServiceV2} from "services"
import {Tabs} from "antd"
import {TabHeader} from "components/Tab"
import {DirectChat} from "sections/NewCommunication/parts/DirectChat"
import {BaseLoading} from "components"
import styles from "./StudentChat.module.css"
import cx from "classnames"
import {Compose} from "../Compose"
import {convertRoom} from "helpers/chat"

type Props = {
    user: any
    departmentId?: BaseDepartmentId
    userIdField: string
    isSidebarChat?: boolean
}

const STUDENT_CHAT_KEYS = {
    DIRECT: "direct",
    DEPARTMENT: "department"
}

export function StudentChat(props: Props) {
    const {user, departmentId, userIdField, isSidebarChat = false} = props
    const {changeRoom, activeRoom} = useContext(CommunicationContext)
    const model = useModel()
    const [activeKey, setActiveKey] = useState(STUDENT_CHAT_KEYS.DIRECT)
    const userId = user?.[userIdField]

    const predefinedDirectMessageRoom: Communication.DirectMessageRoom = useMemo(() => {
        return {
            roomId: Communication.PredefinedRoomId.DirectMessage,
            name: "Direct Message",
            type: Communication.RoomTypes.DirectChat, // Not DirectMessage type to show send options
            directMessageUserIds: [userId],
            isGroup: false
        }
    }, [userId])

    const {data: userRooms, isFetched} = useQuery<Communication.UserDirectAndDepartmentRooms>(
        ["user-department-room", userId, departmentId],
        async () => {
            const studentCampusIds: number[] = user?.campusIds || []
            const currentUser = await model.getOneCompleteUser(model.user.id)
            const userCampuses = currentUser.profiles.map(({campuses}) => campuses.map(({id}) => id))
            const userCampusIds = userCampuses.flatMap((id) => id)
            const sameCampusIds = intersection(userCampusIds, studentCampusIds)

            // use student campus if no overlapped campus between current vs student
            const campusId = sameCampusIds.length ? sameCampusIds[0] : undefined
            const _rooms = await chatServiceV2.getUserRooms(departmentId, campusId || 0, userId)

            let {directRoom, departmentRoom, allowCreateDepartmentChat} = _rooms
            if (directRoom) {
                const {unread} = directRoom
                directRoom = await chatServiceV2.getRoom({roomId: directRoom.roomId})
                directRoom = convertRoom(Communication.RoomTypes.DirectChat, directRoom)
                directRoom.unread = unread || 0
                changeRoom(directRoom)
            } else {
                changeRoom(predefinedDirectMessageRoom)
            }
            if (departmentRoom) {
                const {unread} = departmentRoom
                departmentRoom = await chatServiceV2.getRoom({roomId: departmentRoom.roomId})
                departmentRoom.unread = unread || 0
            }
            return {directRoom, departmentRoom, departmentId, campusId, userId, allowCreateDepartmentChat}
        },
        {
            initialData: {departmentId, userId, allowCreateDepartmentChat: false},
            enabled: !!user && !!userId && !!departmentId,
            onError: (error) => {
                handleError(error)
            },
            refetchOnMount: true
        }
    )

    const {directRoom, departmentRoom, campusId, allowCreateDepartmentChat} = userRooms

    const onChangeTab = useCallback(
        (_activeKey: string) => {
            if (isFetched) {
                setActiveKey(_activeKey)
                if (_activeKey === STUDENT_CHAT_KEYS.DIRECT) {
                    if (directRoom && activeRoom.roomId !== directRoom.roomId) changeRoom(directRoom)
                    else {
                        changeRoom(predefinedDirectMessageRoom)
                    }
                } else {
                    if (departmentRoom && activeRoom.roomId !== departmentRoom.roomId) changeRoom(departmentRoom)
                    else {
                        const newRoom: Communication.DepartmentRoom = {
                            roomId: Communication.PredefinedRoomId.WithoutDepartmentalRoom,
                            name: "Departmental Message",
                            type: Communication.RoomTypes.Department,
                            departmentId,
                            campusId,
                            user: {userId, fullName: getFullName(user)}
                        }
                        changeRoom(newRoom)
                    }
                }
            }
        },
        [isFetched, directRoom, departmentRoom, activeRoom]
    )

    const isOnlyMessageCreation = useMemo(() => {
        return (
            (activeKey === STUDENT_CHAT_KEYS.DIRECT && !directRoom) ||
            (activeKey === STUDENT_CHAT_KEYS.DEPARTMENT && !departmentRoom)
        )
    }, [directRoom, departmentRoom, activeKey])

    if (!user) return null

    return (
        <div>
            <Tabs
                className="fullwidth klassSubTab"
                activeKey={activeKey}
                onChange={onChangeTab}
                tabBarStyle={isSidebarChat ? {margin: "6px 6px 2px 6px"} : {}}>
                <Tabs.TabPane
                    className={cx(styles.tabContainer, {
                        [styles.reduceHeight]: isOnlyMessageCreation,
                        [styles.sidebarContainer]: isSidebarChat
                    })}
                    key={STUDENT_CHAT_KEYS.DIRECT}
                    tab={
                        <TabHeader
                            title="Direct"
                            indicator={
                                directRoom?.unread > 0 ? (
                                    <span className={styles.unreadCount}>{getUnreadCountBadge(directRoom.unread)}</span>
                                ) : undefined
                            }
                        />
                    }>
                    {activeRoom.roomId === directRoom?.roomId ? (
                        <DirectChat hideHeading />
                    ) : (
                        <div className={cx(styles.composeContainer, {[styles.sidebarComposeContainer]: isSidebarChat})}>
                            <span className={styles.noMessage}>No messages found. Let's start a chat!</span>
                            <Compose
                                onSuccess={() => {
                                    toastSuccess("Message sent")
                                }}
                                emojiPickerClassName={styles.emojiPicker}
                                userList={[{...user, userId}]}
                            />
                        </div>
                    )}
                </Tabs.TabPane>
                <Tabs.TabPane
                    disabled={!allowCreateDepartmentChat}
                    className={cx(styles.tabContainer, {
                        [styles.reduceHeight]: isOnlyMessageCreation,
                        [styles.sidebarContainer]: isSidebarChat
                    })}
                    key={STUDENT_CHAT_KEYS.DEPARTMENT}
                    tab={
                        <TabHeader
                            title="Department"
                            indicator={
                                departmentRoom?.unread > 0 ? (
                                    <span className={styles.unreadCount}>
                                        {getUnreadCountBadge(departmentRoom.unread)}
                                    </span>
                                ) : undefined
                            }
                        />
                    }>
                    {activeRoom.roomId === departmentRoom?.roomId ? (
                        <DirectChat hideHeading />
                    ) : (
                        <div className={cx(styles.composeContainer, {[styles.sidebarComposeContainer]: isSidebarChat})}>
                            <span className={styles.noMessage}>No messages found. Let's start a chat!</span>
                            <Compose
                                onSuccess={() => {
                                    toastSuccess("Message sent")
                                }}
                                emojiPickerClassName={styles.emojiPicker}
                                userList={[{...user, userId, isDepartmentChatUser: true}]}
                            />
                        </div>
                    )}
                </Tabs.TabPane>
            </Tabs>
            <BaseLoading isShow={!isFetched} />
        </div>
    )
}
