/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import {Permissions} from "@edular/permissions"
import {PermissionsRequired} from "components/PermissionsRequired"
import styles from "./AddCourseGrade.module.css"
import {BaseButton, BaseInput} from "components"
import {useTranslation} from "react-i18next"
import {BaseDatePicker} from "components/DateTimePicker"
import moment from "moment-timezone"
import cx from "classnames"
import {Col, Row} from "antd"
import {KlassDropdown} from "components/Select"
import {academicCourseGradesService, academicGradingSystem} from "services"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {handleError, toastError} from "helpers"
import {useQuery} from "@tanstack/react-query"

let updateDataValue = []

function AddCourseGrade(props) {
    const [date, setDate] = useState(moment())
    const [gradingElementId, setGradingElementId] = useState<{id: number; gradingItem: string}>()
    const [title, setTitle] = useState<string>()
    const [maxPointValue, setMaxPointValue] = useState<string>()
    const [updateData, setUpdateData] = useState([])
    const [errors, setErrors] = useState({})
    const [isSubmitting] = useState(false)
    const {t} = useTranslation(["common"])

    const {
        defaultGradingTemplate,
        setDefaultGradingTemplate,
        gradingTemplates,
        courseInfo,
        courseId,
        termId,
        students,
        cancelAddGrade,
        getCurrentData,
        dispatch,
        dispatchFunc,
        disabledSelectGradingTemplate,
        page,
        total,
        scheduleId,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction
    } = props

    const {data: dataGradingItems} = useQuery<
        Array<{
            id: number
            gradingItem: string
            colorLabel: string
        }>
    >(
        ["grading-items", defaultGradingTemplate],
        async () => {
            const {data} = await academicGradingSystem.getDetailGradingSystem(defaultGradingTemplate?.id)
            const {gradingElements = []} = data || {}
            const gradingItems = gradingElements.map((item) => ({
                id: item.id,
                gradingItem: item.gradingItem,
                colorLabel: item.colorLabel
            }))
            return gradingItems
        },
        {
            onError: (err) => {
                handleError(err)
            },
            enabled: !!defaultGradingTemplate,
            initialData: []
        }
    )

    const getFields = () => {
        return ["ID", "Name", "Grade", "Notes"]
    }

    const getColumns = () => {
        return [
            {
                title: "ID",
                field: "id",
                isFixed: true,
                width: 0.2
            },
            {
                title: "Name",
                field: "fullname",
                isFixed: true,
                isMainColumn: true,
                width: 0.3
            },
            {
                title: "Grade",
                titleHtml: "",
                field: "grade",
                width: 0.1
            },
            {
                title: "Notes",
                titleHtml: "",
                field: "notes",
                width: 0.1
            }
        ]
    }

    const onClickDoneBtn = useCallback(async () => {
        let totalErrors = {...errors}

        if (!title || title === "") {
            totalErrors = {...totalErrors, title: true}
        } else {
            totalErrors["title"] && delete totalErrors["title"]
        }
        if (!maxPointValue || maxPointValue === "") {
            totalErrors = {...totalErrors, maxPointValue: true}
        } else {
            totalErrors["maxPointValue"] && delete totalErrors["maxPointValue"]
        }
        if (!gradingElementId || !gradingElementId.id) {
            totalErrors = {...totalErrors, gradingElementId: true}
        } else {
            totalErrors["gradingElementId"] && delete totalErrors["gradingElementId"]
        }
        if (Object.keys(totalErrors).length === 0) {
            if (parseFloat(maxPointValue) < 0) {
                return toastError("Max point value must be a positive number. ")
            }
            const isExistNegativeGrade = updateData.find((item) => parseFloat(item.gradeValue) < 0)
            if (isExistNegativeGrade) {
                return toastError("Grade value must be a positive number. ")
            }
            dispatch({isLoading: true})
            try {
                const gradeData = updateData.filter(
                    (item) => item.gradeValue !== "" && item.gradeValue !== null && item.gradeValue !== undefined
                )
                const courseGradesStudents = gradeData.map((item) => ({
                    studentProfileId: item.studentProfileId,
                    grade: parseFloat(item.gradeValue),
                    notes: item.notesValue ?? "",
                    courseId,
                    termId,
                    scheduleId
                }))
                const params = {
                    title,
                    maxPointValue: parseFloat(maxPointValue),
                    gradingElementId: gradingElementId.id,

                    date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                    courseGradesStudents: courseGradesStudents
                }
                await academicCourseGradesService.addCourseGradeStudents(params)
            } catch (err) {
            } finally {
                dispatch({isLoading: false})
                cancelAddGrade()
            }
        } else {
            setErrors(totalErrors)
            toastError(t("common:validation.fieldRequired"))
        }
    }, [errors, title, maxPointValue, gradingElementId, date, updateData, courseId, termId, scheduleId])

    const onClickCancelBtn = useCallback(() => {
        cancelAddGrade()
    }, [])

    const onChangeInput = (id, field, value) => {
        const changeDataForm = updateDataValue.map((item) => {
            if (item.id !== id) {
                return item
            }
            let newValue: any = {
                ...item
            }
            if (field === "grade") {
                newValue = {
                    ...newValue,
                    grade: renderInput("grade", "Grade", item.id, value, true, "number"),
                    gradeValue: value
                }
            } else {
                newValue = {
                    ...newValue,
                    [field]: renderInput(field, field, item.id, value, true, "text"),
                    [`${field}Value`]: value
                }
            }
            return newValue
        })
        setUpdateData(changeDataForm)
        dispatch({data: changeDataForm, total: changeDataForm.length})
    }

    const renderInput = (
        field: string,
        nameField: string,
        id: string,
        value: string,
        isEditing: boolean,
        type: any = "text"
    ) => {
        if (!isEditing) {
            return (
                <div className={styles.titleWrap}>
                    <span className={styles.title}>{value}</span>
                </div>
            )
        }
        return (
            <BaseInput
                type={type}
                disabledArrow
                placeholder={nameField}
                value={value}
                onChange={(text) => onChangeInput(id, field, text)}
            />
        )
    }
    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const data = students
                .filter(
                    (s) =>
                        s.student &&
                        s.student.gradingTemplate?.id &&
                        s.student.gradingTemplate?.id === defaultGradingTemplate?.id
                )
                .map((item) => ({
                    ...item,
                    studentProfileId: item.profileId,
                    grade: renderInput("grade", "Grade", item.id, "", true, "number"),
                    notes: renderInput("notes", "Notes", item.id, "", true, "text")
                }))
            const listStudent = data.filter((item) => !item.isDropped)
            setUpdateData(listStudent)
            dispatch({data: listStudent, total: data.length})
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    useEffect(() => {
        if (defaultGradingTemplate) {
            getData()
        }
    }, [defaultGradingTemplate])

    useEffect(() => {
        updateDataValue = updateData
    }, [updateData])

    const getPageTitle = () => {
        return "Grade"
    }

    const onClickEdit = (editItem) => {
        const newData = data.map((item) => {
            if (editItem.id !== item.id) return item
            return {
                ...item
                // low: renderInput("low", "LOW", item.id, item.lowValue, true),
                // high: renderInput("high", "HIGH", item.id, item.highValue, true),
                // fourPointScale: renderInput(
                //     "fourPointScale",
                //     "4.0",
                //     item.id,
                //     item.fourPointScaleValue?.toFixed(2),
                //     true
                // ),
                // alphabetical: renderInput("alphabetical", "ALPHABETICAL", item.id, item.alphabeticalValue, true, "text")
            }
        })
        updateDataValue = [...newData]
        setUpdateData([...newData])
    }

    const onClickDelete = useCallback(
        async (deleteItem) => {
            const newData = updateData.filter((item) => deleteItem.id !== item.id)
            updateDataValue = newData
            setUpdateData(newData)
        },
        [updateData]
    )

    const onClickDeleteMulti = useCallback(async () => {
        const newData = getCurrentData().filter((item) => !item.isChecked)
        updateDataValue = [...newData]
        setUpdateData(newData)
        dispatch({data: newData, total: newData.length, isShowTableHeaderAction: false})
    }, [])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [data])

    const onClickRowItem = (row) => {}

    const onChangeElementData = (key, value) => {
        if (!value || value === "") {
            setErrors({...errors, gradingElementId: true})
        } else {
            errors["gradingElementId"] && delete errors["gradingElementId"]
        }
        setGradingElementId(value)
    }

    const onChangeTitle = useCallback(
        (text) => {
            if (!text || text === "") {
                setErrors({...errors, title: true})
            } else {
                errors["title"] && delete errors["title"]
            }
            setTitle(text)
        },
        [errors]
    )

    const onChangeMaxPointValue = useCallback(
        (text) => {
            if (!text || text === "") {
                setErrors({...errors, maxPointValue: true})
            } else {
                errors["maxPointValue"] && delete errors["maxPointValue"]
            }
            setMaxPointValue(text)
        },
        [errors]
    )

    const compareIsChanged = () => {
        return true
    }
    let isChanged = compareIsChanged()

    const dot = (color = "transparent") => ({
        alignItems: "center",
        display: "flex",

        ":before": {
            backgroundColor: color,
            borderRadius: 5,
            content: '" "',
            display: "block",
            marginRight: 8,
            height: 20,
            width: 20
        }
    })
    const colourStyles = {
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            return {...styles, ...dot(data.colorLabel)}
        },
        input: (styles, {data}) => ({...styles, ...dot()}),
        placeholder: (styles) => ({...styles, ...dot()}),
        singleValue: (styles, {data}) => ({...styles, ...dot(data.colorLabel)})
    }
    return (
        <div className={styles.container}>
            <div className={styles.titleView}>
                <span className={styles.titleAddGrade}>ADD GRADES</span>
                <span className={styles.subTitle}>Select date (Select date to add grades to)</span>
                <BaseDatePicker
                    onChange={(newValue) => {
                        setDate(moment(newValue))
                    }}
                    placeholder={t("user.endDate")}
                    value={date}
                    className={cx(styles.datePicker)}
                />
            </div>
            <hr className={styles.hr} />
            <Row gutter={[20, 20]}>
                <Col span={6}>
                    <span className={styles.titleTextField}>Title</span>
                    <BaseInput
                        onChange={onChangeTitle}
                        placeholder={t("academics:settings.description")}
                        value={title}
                        className={!!errors["title"] ? styles.error : ""}
                    />
                </Col>
                <Col span={6}>
                    <span className={styles.titleTextField}>Grading Template</span>
                    <div className={styles.gradingTemplateSelect}>
                        <KlassDropdown
                            placeholder="Grading Template"
                            closeMenuOnSelect
                            isDisabled={disabledSelectGradingTemplate}
                            value={defaultGradingTemplate}
                            onChange={(v) => {
                                setDefaultGradingTemplate(v)
                                setGradingElementId(null)
                            }}
                            options={gradingTemplates}
                            labelKey="title"
                            valueKey="id"
                        />
                    </div>
                </Col>
                <Col span={6}>
                    <span className={styles.titleTextField}>Grading Item</span>
                    <div className={styles.selectElement}>
                        <KlassDropdown
                            placeholder="Grading Item"
                            closeMenuOnSelect
                            error={!!errors["gradingElementId"]}
                            value={gradingElementId}
                            onChange={(newValue) => onChangeElementData("gradingElementId", newValue)}
                            options={dataGradingItems}
                            labelKey="gradingItem"
                            valueKey="id"
                            stylesCustom={colourStyles}
                        />
                    </div>
                </Col>
                <Col span={6}>
                    <span className={styles.titleTextField}>Max Point Value</span>
                    <BaseInput
                        onChange={onChangeMaxPointValue}
                        placeholder={"Point Value"}
                        disabledArrow
                        value={maxPointValue}
                        className={!!errors["maxPointValue"] ? styles.error : ""}
                    />
                </Col>
            </Row>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                menuActions={[]}
                data={data}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={false}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
            />
            <PermissionsRequired
                permissions={{
                    staff: [
                        Permissions.Staff.Academics.Instructional.CourseGrades.Add,
                        Permissions.Staff.Academics.Instructional.CourseGrades.Edit
                    ]
                }}>
                <div className={styles.actionSubmit}>
                    {isChanged && (
                        <BaseButton
                            title={t("common:action.cancel")}
                            variant="secondary"
                            className={styles.cancelBtn}
                            onClick={onClickCancelBtn}
                        />
                    )}
                    <div>
                        <BaseButton
                            title={isSubmitting ? t("common:action.saving") : t("common:action.save")}
                            onClick={onClickDoneBtn}
                            isActive={isChanged}
                            disabled={false}
                            // disabled={!!Object.keys(errors).length}
                            loading={isSubmitting}
                        />
                    </div>
                </div>
            </PermissionsRequired>
        </div>
    )
}

export default KlassappTableHOC(AddCourseGrade)
