import React, {useEffect, useState} from "react"
import {Checkbox, Divider, Tooltip} from "antd"
import {cloneDeep, isEmpty} from "lodash"
import {TFunction, withTranslation} from "react-i18next"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import {Search} from "components/inputs/Search"
import {BaseButton} from "components/buttons"
import {courseService, academicPlansService} from "services"
import {handleError, toastError} from "helpers"
import {Course} from "types/courses"
import styles from "./CoursesTable.module.css"
import {Icon} from "components"
import {useDebounce} from "react-use"
import {CoaType} from "types/coa"

type PageProps = {
    t: TFunction
    sectionId?: number
    unit?: Course.Unit
    selectedCourses?: Course.CourseTableItem[]
    onAddCourses: (
        addedCourses: Array<
            Course.CourseTableItem & {
                sectionCourseId?: number
                sectionId: number
                total: number
            }
        >
    ) => void
}

type CourseTable = Course.CourseTableItem & {isChecked: true}

type Props = KlassappTableProps<CourseTable> & PageProps

const CoursesTable = (props: Props) => {
    const [searchValue, setSearchValue] = useState("")
    const [searchDebounce, setSearchDebounce] = useState("")
    useDebounce(() => setSearchDebounce(searchValue), 400, [searchValue])

    const {
        t,
        page,
        total,
        pageSize,
        onDraggableColumn,
        columns,
        data,
        getCurrentData,
        onAddCourses,
        onUpdateRowData,
        onChangeFields,
        onChangePage,
        onChangeRowPerPage,
        onChangeAllFields,
        onClickSortColumn,
        allFields,
        onUpdateTableData,
        fields,
        orderField,
        isLoading,
        dispatch,
        dispatchFunc,
        sectionId,
        unit,
        selectedCourses
    } = props

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFields = () => {
        return [t("course.code"), t("course.name"), t("course.instructor"), "Class Assistance"]
    }

    const renderIsCA = (isCA: boolean) => {
        return (
            <Checkbox
                checked={isCA}
                onChange={(event) => {
                    const data = getCurrentData()
                    dispatch({
                        data: data.map((item) => ({
                            ...item,
                            isCA: renderIsCA(event.target.checked),
                            role: event.target.checked
                                ? Course.AcademicPlanCourseRole.ClassAssistance
                                : Course.AcademicPlanCourseRole.Student
                        }))
                    })
                }}
            />
        )
    }

    const getPageTitle = () => {
        return "Course"
    }

    const getColumns = () => {
        return [
            {
                title: t("course.code"),
                field: "code",
                style: {minWidth: "150px"}
            },
            {
                title: t("course.name"),
                field: "name",
                style: {minWidth: "250px"}
            },
            {
                title: t("course.instructor"),
                field: "instructor",
                style: {minWidth: "200px"}
            },
            {
                title: "Class Assistance",
                field: "isCA",
                style: {minWidth: "20px"},
                titleHtml: (
                    <div className={styles.classAssistance}>
                        <span>Class Assistance</span>
                        <Tooltip title={"Clock hour will not be calculated"} key={"Class Assistance"}>
                            <div className={styles.classAssistanceInfo}>
                                <Icon icon={"INFO"} />
                            </div>
                        </Tooltip>
                    </div>
                )
            }
        ]
    }

    const getData = async (searchValue = "") => {
        try {
            dispatch({isLoading: true})
            const selectedCourseIds = (selectedCourses || []).map((course) => course.id)
            const params: any = {
                filter: {
                    search: searchValue
                },
                range: {page, pageSize}
            }
            if (sectionId) {
                const courses = await academicPlansService.getAllSectionCourses({sectionId})
                const newData = courses.filter((course) => {
                    const notChoose = !selectedCourseIds.includes(course.id)
                    let hasMatchSearch = true
                    if (searchValue) {
                        hasMatchSearch =
                            course.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                            course.code.toLowerCase().includes(searchValue.toLowerCase())
                    }
                    course.isCA = renderIsCA(false)
                    return notChoose && hasMatchSearch
                })
                dispatch({data: newData, total: newData.length})
            } else {
                if (unit) {
                    params.filter.totalUnits = unit
                }
                params.filter.type = CoaType.Clock
                params.filter.ignoreCourseIds = selectedCourseIds
                if (!isEmpty(orderField)) {
                    params.sort = {
                        orderBy: orderField?.field,
                        orderDir: orderField?.order
                    }
                }
                const {data, total} = await courseService.getAll(params)
                const newData = data.map((course: any) => {
                    course.id = course.courseId
                    return course
                })
                dispatch({data: newData, total})
            }
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onChangeSearch = (newValue) => {
        setSearchValue(newValue)
    }
    useEffect(() => {
        getData(searchDebounce)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDebounce, page, pageSize, orderField])

    const onClickAdd = () => {
        const checkedData = data
            .filter((course) => course.isChecked)
            .map(({courseId, name, code, instructor, id, role, total}) => ({
                id,
                courseId,
                name,
                code,
                instructor,
                role,
                total
            }))
        if (checkedData.length === 0) {
            toastError("Please select the course")
            return
        }
        onAddCourses(cloneDeep(checkedData) as any)
        const restCourses = data.filter((course) => !course.isChecked)
        dispatch({data: restCourses})
    }

    return (
        <>
            <Divider />
            <div className={styles.selectCourseWrap}>
                <p className={styles.selectCourseTitle}>Select Courses to add</p>
                <Search value={searchValue} onChange={onChangeSearch} placeholder={t("course.searchForCourse")} />
                <div className={styles.selectCourseTable}>
                    <KlassappTableHeader
                        isShowAction={false}
                        actions={[]}
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={onChangePage}
                        onChangeRowPerPage={onChangeRowPerPage}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={onChangeFields}
                        onChangeAllFields={onChangeAllFields}
                        onDraggableColumn={onDraggableColumn}
                    />
                    <KlassappTable
                        columns={columns}
                        data={data}
                        menuActions={[]}
                        isLoading={isLoading}
                        fields={fields}
                        allFields={allFields}
                        orderField={orderField}
                        isShowCheckedColumn
                        onChangeFields={onChangeFields}
                        onUpdateRowData={onUpdateRowData}
                        onUpdateTableData={onUpdateTableData}
                        onClickSortColumn={onClickSortColumn}
                        onDraggableColumn={onDraggableColumn}
                        onChangeAllFields={onChangeAllFields}
                    />
                </div>
                <div className={styles.addAction}>
                    <BaseButton title={t("common:action.add")} onClick={onClickAdd} />
                </div>
            </div>
        </>
    )
}

export default KlassappTableHOC(withTranslation(["course", "common"])(CoursesTable))
