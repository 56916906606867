import {Auth} from "types/auth"
import {Timestamps} from "./common"
import {Modules} from "@edular/modules"

export type Department = {
    departmentId: number
    code: string
    name: string
    directorProfileIds: number[]
    applicationAcceptanceAutoStatusId: number
    useActivitiesFromDepartmentId: number | null
    isDepartmentalChatEnabled: boolean
    subunits?: DepartmentSubunit[]
    isLock: boolean
    chatActivity?: ChatActivity
} & Timestamps

export type ChatActivity = {
    activityId: number
    name: string
    departmentId: number
}

export type DepartmentWithLinkedEntities = Department & {
    directors: Auth.UserProfileShortDetails[]
}

export type DepartmentsCreateOnePayload = {
    code: string
    name: string
    directorProfileIds: number[]
    useActivitiesFromDepartmentId?: number | null
}

export type DepartmentEditPayload = {
    departmentId: number
} & Partial<{
    code: string
    name: string
    directorProfileIds: number[]
    applicationAcceptanceAutoStatusId: number
    useActivitiesFromDepartmentId: number | null
    isDepartmentalChatEnabled?: boolean
}>

export type DepartmentsDeletePayload = {
    departmentIds: number[]
}

export type EditableDepartmentDetails = {
    departmentId?: number
    code: string
    name: string
    directors: DepartmentWithLinkedEntities["directors"] | null
    useActivitiesFromDepartmentId?: number
    isDepartmentalChatEnabled?: boolean
    chatActivity?: ChatActivity
} & Partial<Timestamps>

export type UpdateApplicationAcceptanceAutoStatusPayload = {
    departmentId: number
    applicationAcceptanceAutoStatusId: number
}

export enum BaseDepartmentId {
    Admissions = 1,
    FinancialAid = 2,
    Academics = 3,
    StudentAccount = 4,
    CareerServices = 5,
    Alumni = 6,
    StudentServices = 7,
    AcademicAffair = 8
}

export const MAP_DEPARTMENT_ID_2_ABBREV: Record<BaseDepartmentId, string> = {
    [BaseDepartmentId.AcademicAffair]: "ACAD-AFRS",
    [BaseDepartmentId.Academics]: "ACAD",
    [BaseDepartmentId.Admissions]: "ADM",
    [BaseDepartmentId.Alumni]: "Alumni",
    [BaseDepartmentId.CareerServices]: "CS",
    [BaseDepartmentId.FinancialAid]: "FA",
    [BaseDepartmentId.StudentAccount]: "SA",
    [BaseDepartmentId.StudentServices]: "SS"
}

export const BASE_DEPARTMENT_NAME: Record<BaseDepartmentId, string> = {
    [BaseDepartmentId.AcademicAffair]: "Academic Affair",
    [BaseDepartmentId.Academics]: "Academics",
    [BaseDepartmentId.Admissions]: "Admissions",
    [BaseDepartmentId.Alumni]: "Alumni",
    [BaseDepartmentId.CareerServices]: "Career Services",
    [BaseDepartmentId.FinancialAid]: "Financial Aid",
    [BaseDepartmentId.StudentAccount]: "Student Account",
    [BaseDepartmentId.StudentServices]: "Student Services"
}

export const MAP_DEPARTMENT_ID_2_KEY: Record<BaseDepartmentId, string> = {
    [BaseDepartmentId.AcademicAffair]: "academic-affairs",
    [BaseDepartmentId.Academics]: "academics",
    [BaseDepartmentId.Admissions]: "admission",
    [BaseDepartmentId.Alumni]: "alumni",
    [BaseDepartmentId.CareerServices]: "career-services",
    [BaseDepartmentId.FinancialAid]: "financial-aid",
    [BaseDepartmentId.StudentAccount]: "student-account",
    [BaseDepartmentId.StudentServices]: "student-services"
}

export const DepartmentKeyNameByIdForPriority = {
    [BaseDepartmentId.Admissions]: "admission",
    [BaseDepartmentId.FinancialAid]: "finAid",
    [BaseDepartmentId.Academics]: "academic",
    [BaseDepartmentId.StudentAccount]: "studentAccount"
}

export const MODULE_DEPARTMENT_IDS = [
    BaseDepartmentId.Admissions,
    BaseDepartmentId.FinancialAid,
    BaseDepartmentId.Academics,
    BaseDepartmentId.StudentServices
]

export type DepartmentSubunit = {
    subunitId: number
    departmentId: Department["departmentId"]
    code: string
    name: string
    isLocked: boolean
    department?: DepartmentWithLinkedEntities
} & Timestamps

export type DepartmentSubunitCreatePayload = Partial<DepartmentSubunit>

export type DepartmentSubunitEditPayload = Partial<DepartmentSubunit> & {
    subunitId: DepartmentSubunit["subunitId"]
}

export type DepartmentSubunitDeletePayload = {
    ids: DepartmentSubunit["subunitId"][]
}

export const getSubunitName = (subunit?: Partial<DepartmentSubunit>, department?: Partial<Department>): string => {
    if (!subunit) return ""
    return `[${subunit.code}] ${subunit.name}`
}

export const SUBUNIT_ID_NEW = 0

export const DepartmentDialerModuleMapping = {
    [BaseDepartmentId.Admissions]: Modules.AdmissionsDialer,
    [BaseDepartmentId.FinancialAid]: Modules.FinancialAidDialer,
    [BaseDepartmentId.Academics]: Modules.AcademicDialer,
    [BaseDepartmentId.StudentAccount]: Modules.StudentAccountDialer,
    [BaseDepartmentId.CareerServices]: Modules.CareerServicesDialer,
    [BaseDepartmentId.StudentServices]: Modules.StudentServicesDialer,
    [BaseDepartmentId.AcademicAffair]: Modules.AcademicAffairDialer
}

export const DepartmentStudentDetailsURLMapping = {
    [BaseDepartmentId.Admissions]: "admissions",
    [BaseDepartmentId.FinancialAid]: "financial-aid",
    [BaseDepartmentId.Academics]: "academics/registrar",
    [BaseDepartmentId.StudentAccount]: "student-account",
    [BaseDepartmentId.StudentServices]: "student-services",
    [BaseDepartmentId.CareerServices]: "career-services"
}

export const DEPARTMENTS_WITH_ACTIVITIES = [
    BaseDepartmentId.Admissions,
    BaseDepartmentId.Academics,
    BaseDepartmentId.FinancialAid,
    BaseDepartmentId.StudentAccount,
    BaseDepartmentId.StudentServices,
    BaseDepartmentId.CareerServices
]
