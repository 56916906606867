import {get} from "lodash"
import BaseService from "./Base"
import {
    BppeAnnualAllGradsReportItem,
    BppeAnnualGradsByContractedItem,
    BppeAnnualStartDateReportItem,
    BppeAnnualTitleIVAuditItem,
    CourseChangesDetails,
    CourseRegistrationItem,
    D2LCourseOfferingItem,
    D2LSemesterItem,
    D2LUserItem,
    EditCourseChangesPayload,
    ExportAllListGradesGenesisPayload,
    ExportListGradesGenesisPayload,
    ListGradesGenesisExportPayload,
    ListGradesGenesisExportSummaryPayload,
    ListHistoryGradesGenesisExportPayload,
    ListUnofficialTranscriptPayload,
    MajorVersionDegreeRequirementItem,
    NaccasSummaryReportItem,
    PlacementRatesDetailsItem,
    PlacementRatesSummaryItem,
    PlacementVerificationItem,
    StudentGraduationItem,
    StudentLdaItem,
    UndergradMastersSAPItem,
    UnofficialTranscriptItem
} from "types/reports"

class ReportsService extends BaseService {
    getAll = async (payload): Promise<{total: number; data: CourseChangesDetails[]}> => {
        const response = await this.post("/v1/course-schedules/get-report", payload)
        return get(response, "data", [])
    }

    markAsChecked = async (schedules: any[]) => {
        const payload = schedules.map((schedule) => ({
            scheduleId: schedule.id,
            checked: schedule.isChecked,
            configured: schedule.configured,
            disabled: schedule.disabled
        }))

        const response = await this.post("/v1/course-schedules/report-update", payload)
        return get(response, "data", [])
    }

    edit = async (payload: EditCourseChangesPayload[]) => {
        const response = await this.post("/v1/course-schedules/report-update", payload)

        return get(response, "data", [])
    }

    get1098TReport = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/1098T-summary-reports/list`, params)
        return get(response, "data", {})
    }

    getEnrollmentAgreementReport = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/enrollment-agreement/list`, params)
        return get(response, "data", {})
    }

    getNaccasAnnualReport = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/naccas-annual/list`, params)
        return get(response, "data", {})
    }

    getNaccasAnnualExemptedStudentsListReport = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/naccas-annual-exempted-students/list`, params)
        return get(response, "data", {})
    }

    getNaccasAnnualEnrollmentCountReport = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/naccas-annual-enrollment-counts/list`, params)
        return get(response, "data", {})
    }

    getNaccasAnnualSummaryReport = async (params = {}): Promise<{total: number; data: NaccasSummaryReportItem[]}> => {
        const response = await this.post(`/v1/admin-reports/naccas-annual-summary/list`, params)
        return get(response, "data", {})
    }

    getBppeAnnualAllGradsReport = async (
        params = {}
    ): Promise<{total: number; data: BppeAnnualAllGradsReportItem[]}> => {
        const response = await this.post(`/v1/admin-reports/bppe-annual-all-grads/list`, params)
        return get(response, "data", {})
    }

    getBppeAnnualStartDateReport = async (
        params = {}
    ): Promise<{total: number; data: BppeAnnualStartDateReportItem[]}> => {
        const response = await this.post(`/v1/admin-reports/bppe-annual-start-date/list`, params)
        return get(response, "data", {})
    }

    getBppeAnnualGradsByContractedReport = async (
        params = {}
    ): Promise<{total: number; data: BppeAnnualGradsByContractedItem[]}> => {
        const response = await this.post(`/v1/admin-reports/bppe-annual-grads-contracted/list`, params)
        return get(response, "data", {})
    }

    getBppeAnnualTitleIVAuditReport = async (
        params = {}
    ): Promise<{total: number; data: BppeAnnualTitleIVAuditItem[]}> => {
        const response = await this.post(`/v1/admin-reports/bppe-annual-title-iv-audit/list`, params)
        return get(response, "data", {})
    }

    listGradeExport = async (params = {}) => {
        const response = await this.post(`/v3/reports/grades-export`, params)
        return get(response, "data.data", {})
    }
    listGradeExportSummary = async (params = {}) => {
        const response = await this.post(`/v3/reports/grades-export/summary`, params)
        return get(response, "data.data", {})
    }
    listHistoryExport = async (params = {}) => {
        const response = await this.post(`/v3/reports/history-export`, params)
        return get(response, "data.data", {})
    }
    exportGrades = async (params = {}) => {
        const response = await this.post(`/v3/reports/grades-export/export`, params)
        return get(response, "data.data", {})
    }
    exportAllGrades = async (params = {}) => {
        const response = await this.post(`/v3/reports/grades-export/export-all`, params)
        return get(response, "data.data", {})
    }

    listGradeGenesisExport = async (params: ListGradesGenesisExportPayload) => {
        const response = await this.post(`/v1/reports/grades-genesis-export/list`, params)
        return get(response, "data.data", {})
    }
    listGradeGenesisExportSummary = async (params: ListGradesGenesisExportSummaryPayload) => {
        const response = await this.post(`/v1/reports/grades-genesis-export/summary`, params)
        return get(response, "data.data", {})
    }
    listHistoryGradeGenesisExport = async (params: ListHistoryGradesGenesisExportPayload) => {
        const response = await this.post(`/v1/reports/grades-genesis-export/history/list`, params)
        return get(response, "data.data", {})
    }
    exportGradesGenesis = async (params: ExportListGradesGenesisPayload) => {
        const response = await this.post(`/v1/reports/grades-genesis-export/export`, params)
        return get(response, "data.data", {})
    }
    exportAllGradesGenesis = async (params: ExportAllListGradesGenesisPayload) => {
        const response = await this.post(`/v1/reports/grades-genesis-export/export-all`, params)
        return get(response, "data.data", {})
    }

    getIPEDSReport = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/ipeds-reports/list`, params)
        return get(response, "data", {})
    }

    getIPEDSCompletionsReport = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/ipeds-reports/completions`, params)
        return get(response, "data", {})
    }

    getIPEDSReport12MonthEnrollment4YearDegreeGranting = async (params = {}) => {
        const response = await this.post(
            `/v1/admin-reports/ipeds-reports/12-month-enrollment-report/four-year-degree-granting`,
            params
        )
        return get(response, "data", {})
    }

    getIPEDSReport12MonthEnrollment2YearDegreeGranting = async (params = {}) => {
        const response = await this.post(
            `/v1/admin-reports/ipeds-reports/12-month-enrollment-report/two-year-degree-granting`,
            params
        )
        return get(response, "data", {})
    }

    getIPEDSReport12MonthEnrollmentPublicNonDegreeGranting = async (params = {}) => {
        const response = await this.post(
            `/v1/admin-reports/ipeds-reports/12-month-enrollment-report/public-non-degree-granting`,
            params
        )
        return get(response, "data", {})
    }

    getIPEDSReport12MonthEnrollmentPrivateNonDegreeGranting = async (params = {}) => {
        const response = await this.post(
            `/v1/admin-reports/ipeds-reports/12-month-enrollment-report/private-non-degree-granting`,
            params
        )
        return get(response, "data", {})
    }

    getIPEDSReportLessThan2YearGraduation = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/ipeds-reports/graduation-reports-less-than-2-year`, params)
        return get(response, "data", {})
    }

    getIPEDSReport2YearGraduation = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/ipeds-reports/graduation-reports-2-year`, params)
        return get(response, "data", {})
    }

    getIPEDSReport4YearGraduation = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/ipeds-reports/graduation-reports-4-year`, params)
        return get(response, "data", {})
    }

    listOfCourseRegistration = async (params = {}): Promise<{total: number; data: CourseRegistrationItem[]}> => {
        const response = await this.post(`/v1/academic-reports/course-registration/list`, params)
        return get(response, "data", {})
    }

    listOfMajorVersionDegreeRequirement = async (
        params = {}
    ): Promise<{total: number; data: MajorVersionDegreeRequirementItem[]}> => {
        const response = await this.post(`/v1/academic-reports/major-version-degree-requirement/list`, params)
        return get(response, "data", {})
    }

    listOfStudentLda = async (params = {}): Promise<{total: number; data: StudentLdaItem[]}> => {
        const response = await this.post(`/v1/academic-reports/student-lda/list`, params)
        return get(response, "data", {})
    }

    listUnofficialTranscript = async (
        params: ListUnofficialTranscriptPayload = {}
    ): Promise<{total: number; data: UnofficialTranscriptItem[]}> => {
        const response = await this.post(`/v1/academic-reports/unofficial-transcript/list`, params)
        return get(response, "data", {})
    }

    getProgressReport = async (params: {studentProfileId: number; departmentId: number}) => {
        const response = await this.post(`/v1/academic-reports/progress-report/list`, params)
        return get(response, "data.data", {})
    }

    getD2LSemesterReport = async (params = {}): Promise<{total: number; data: D2LSemesterItem[]}> => {
        const response = await this.post(`/v1/academic-reports/d2l-semester/list`, params)
        return get(response, "data", {})
    }

    getD2LCourseOfferingReport = async (params = {}): Promise<{total: number; data: D2LCourseOfferingItem[]}> => {
        const response = await this.post(`/v1/academic-reports/d2l-course-offering/list`, params)
        return get(response, "data", {})
    }

    getD2LUsersReport = async (params = {}): Promise<{total: number; data: D2LUserItem[]}> => {
        const response = await this.post(`/v1/academic-reports/d2l-users/list`, params)
        return get(response, "data", {})
    }

    getUndergradMastersSAPReport = async (params = {}): Promise<{total: number; data: UndergradMastersSAPItem[]}> => {
        const response = await this.post(`/v1/academic-reports/undergrad-masters-sap/list`, params)
        return get(response, "data", {})
    }

    generateReport = async (params = {}): Promise<void> => {
        const response = await this.post(`/v1/reports/generate`, params)
        return get(response, "data", {})
    }

    downloadReport = async (params = {}): Promise<{downloadUrl: string}> => {
        const response = await this.post(`/v1/reports/download`, params)
        return get(response, "data.data", {})
    }

    getCareerServicePlacementRatesSummaryReport = async (
        params = {}
    ): Promise<{total: number; data: PlacementRatesSummaryItem[]}> => {
        const response = await this.post(`/v1/career-services-reports/placement-rates-summary/list`, params)
        return get(response, "data", {})
    }

    getCareerServicePlacementRatesDetailsReport = async (
        params = {}
    ): Promise<{total: number; data: PlacementRatesDetailsItem[]}> => {
        const response = await this.post(`/v1/career-services-reports/placement-rates-details/list`, params)
        return get(response, "data", {})
    }

    getCareerServiceStudentGraduationReport = async (
        params = {}
    ): Promise<{total: number; data: StudentGraduationItem[]}> => {
        const response = await this.post(`/v1/career-services-reports/student-graduation/list`, params)
        return get(response, "data", {})
    }

    getCareerServicePlacementVerificationReport = async (
        params = {}
    ): Promise<{total: number; data: PlacementVerificationItem[]}> => {
        const response = await this.post(`/v1/career-services-reports/placement-verification/list`, params)
        return get(response, "data", {})
    }

    getIPEDSReportGraduationRatesStudentLogs = async (params = {}) => {
        const response = await this.post(`/v1/admin-reports/ipeds-reports/graduation-reports/student-logs`, params)
        return get(response, "data", {})
    }
}

export default ReportsService
