import {useQuery, useQueryClient} from "@tanstack/react-query"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {useCallback} from "react"
import {mediaLibraryService} from "services"
import {Tag} from "types/tags"

const queryCacheKey = "all-media-library-tags"

const INITIAL_DATA: Tag[] = []

export type MediaLibraryTagsFilter = {
    search?: string
    userTypes?: number[]
    itemTypes?: number[]
    updatedAt?: Date | null
    campusIds?: number[]
    departmentSubunitIds?: number[]
    degreeLevelIds?: number[]
    programIds?: number[]
    userStates?: number[]
}

export const useAllMediaLibraryTags = (filters?: MediaLibraryTagsFilter) => {
    const queryClient = useQueryClient()

    const query = useQuery<Tag[]>(
        [queryCacheKey, filters],
        async () => {
            const {data: tags} = await mediaLibraryService.getMediaLibraryTagsList({
                filter: filters
            })
            return tags
        },
        {
            refetchOnMount: false
        }
    )

    return {
        tags: query.data ?? INITIAL_DATA,
        invalidateMediaLibraryTagsCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient]),
        isLoading: query.isLoading
    }
}
