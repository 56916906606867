import React from "react"
import {Checkbox} from "antd"
import {v4 as uuid} from "uuid"
import {isBoolean} from "lodash"
import moment from "moment"
import {withTranslation} from "react-i18next"
import {Model} from "Model"
import {BaseInput} from "components"
import {handleError, toastError, toastWarning} from "helpers"
import {shiftsService} from "services"
import {KlassappTableHOC} from "HOC"
import {KlassappTable, KlassappTableContext, KlassappTableHeader} from "uiKit"
import {BaseButton, SecondaryButton, AddItemCircleButton} from "components/buttons"
import {KlassappTableProps} from "types/common"
import styles from "./Shifts.module.css"
import {BaseTimePicker} from "components/DateTimePicker"
import CampusSelect from "components/CampusSelect"
import ProgramSelect from "components/ProgramSelect"

type PageProps = {
    t: Function
    model: Model
}

type Props = KlassappTableProps & PageProps

type State = {
    newItemData: any
    oldItemData: any
    isSubmitting: boolean
}

class ShiftTab extends React.Component<Props, State> {
    state = {
        newItemData: null,
        oldItemData: null,
        isSubmitting: false
    }

    componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getSettings},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getMenuActions", func: this.getMenuActions},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "onClickEdit", func: this.onClickEdit},
            {key: "onClickDelete", func: this.onClickDelete}
        ])
        this.getSettings()
    }

    getPageTitle = () => {
        return this.props.t("settings.shifts")
    }

    getFields = () => {
        const {t} = this.props
        return [
            t("settings.name"),
            t("settings.from"),
            t("settings.to"),
            t("settings.isActive"),
            t("settings.programs"),
            t("settings.campuses")
        ]
    }

    getTableHeaderActions = (isShowDuplicateBtn = false, checkedData = []) => {
        const hasLockedItem = (checkedData || []).some((item) => item.isLock)
        const {t, onClickShowConfirmModal} = this.props
        const actions = []
        if (!hasLockedItem) {
            actions.push({
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            })
        }
        return actions
    }

    getMenuActions = () => {
        const {t} = this.props
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: this.onClickEdit,
                canShow: () => {
                    return true
                }
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: this.onClickDelete,
                canShow: () => {
                    return true
                }
            }
        ]
    }

    getSettings = async () => {
        this.props.dispatch({isLoading: true})
        try {
            const {data, total} = await shiftsService.getAll()
            const timeFormat = this.props.model.getUserTimeFormat()
            const todayStr = moment().format("YYYY-MM-DD")
            const newData = data.map((item) => {
                item.id = item.shiftId
                item.isActiveHtml = this.renderIsActiveHtml(item)
                item.nameHtml = item.name
                item.startTimeHtml = item.startTime ? moment(todayStr + " " + item.startTime).format(timeFormat) : null
                item.endTimeHtml = item.endTime ? moment(todayStr + " " + item.endTime).format(timeFormat) : null
                item.programsHtml = this.renderProgramsInput(item.programs)
                item.campusesHtml = this.renderCampusesInput(item.campuses)
                return item
            })
            this.props.dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    renderIsActiveHtml = (item = null) => {
        if (item) {
            return (
                <div key={`show-${item.shiftId}`} className={styles.visibleCheckboxWrap}>
                    <Checkbox checked={item.isActive} />
                </div>
            )
        }
        const newItemData = this.state.newItemData || {}
        return (
            <div key={`edit-${newItemData.shiftId}`} className={styles.visibleCheckboxWrap}>
                <Checkbox
                    checked={newItemData.isActive}
                    onChange={(event) => this.onChangeNewItemData("isActive", event.target.checked)}
                />
            </div>
        )
    }

    getColumns = () => {
        const {t} = this.props

        return [
            {title: t("settings.name"), field: "nameHtml"},
            {title: t("settings.from"), field: "startTimeHtml", style: {minWidth: "100px"}},
            {title: t("settings.to"), field: "endTimeHtml", style: {minWidth: "100px"}},
            {title: t("settings.isActive"), field: "isActiveHtml"},
            {title: t("settings.programs"), field: "programsHtml", style: {minWidth: "250px"}},
            {title: t("settings.campuses"), field: "campusesHtml", style: {minWidth: "250px"}}
        ]
    }

    onClickDeleteMulti = async () => {
        const checkedItems = this.props.data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => item.shiftId)
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await shiftsService.delete({ids: payload})
            await this.getSettings()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickEdit = (editItem) => {
        const {newItemData} = this.state
        if (newItemData) {
            toastWarning(this.props.t("common:validation.saveBeforeEdit"))
            return
        }
        this.setState(
            {
                newItemData: {
                    ...editItem,
                    nameData: editItem.name,
                    startTime: editItem.startTime,
                    endTime: editItem.endTime,
                    isActive: !!editItem.isActive
                },
                oldItemData: {...editItem}
            },
            () => {
                const newData = this.props.data.map((item) => {
                    if (item.shiftId === editItem.shiftId) {
                        item.isActiveHtml = this.renderIsActiveHtml()
                        item.nameHtml = this.renderNameInput()
                        item.startTimeHtml = this.renderTimeInput("startTime")
                        item.endTimeHtml = this.renderTimeInput("endTime")
                        item.programsHtml = this.renderProgramsInput()
                        item.campusesHtml = this.renderCampusesInput()
                        return item
                    }
                    return item
                })
                this.props.dispatch({data: newData})
            }
        )
    }

    onClickDelete = async (deletedItem) => {
        if (deletedItem.isForm) {
            this.setState({newItemData: null, oldItemData: null})
            await this.getSettings()
            return
        }
        try {
            this.props.dispatch({isLoading: true})
            this.setState({newItemData: null, oldItemData: null})
            await shiftsService.delete({ids: [deletedItem.shiftId]})
            await this.getSettings()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onChangeNewItemData = (key: string, value: any) => {
        const {newItemData} = this.state
        newItemData[key] = value
        const newData = this.props.data.map((item) => {
            if (item.id === newItemData.id) {
                item.nameHtml = this.renderNameInput()
                item.startTimeHtml = this.renderTimeInput("startTime")
                item.endTimeHtml = this.renderTimeInput("endTime")
                item.isActiveHtml = this.renderIsActiveHtml()
                item.programsHtml = this.renderProgramsInput()
                item.campusesHtml = this.renderCampusesInput()
                return item
            }
            return item
        })
        this.setState({newItemData})
        this.props.dispatch({data: newData})
    }

    renderNameInput = () => {
        const newItemData = this.state.newItemData || {}
        return (
            <BaseInput
                value={newItemData.nameData}
                placeholder="Name"
                onChange={(value) => this.onChangeNewItemData("nameData", value)}
            />
        )
    }

    renderTimeInput = (key: "startTime" | "endTime") => {
        const newItemData = this.state.newItemData || {}
        const todayStr = moment().format("YYYY-MM-DD")
        const startTime = newItemData[key] ? moment(todayStr + " " + newItemData[key]) : undefined
        return (
            <BaseTimePicker
                value={startTime}
                onChange={(newValue) => {
                    const data = newValue ? moment(newValue).format("HH:mm:ss") : null
                    this.onChangeNewItemData(key, data)
                }}
                placeholder="From"
                format={this.props.model.getUserTimeFormat()}
                className={styles.timePicker}
                size="small"
                // error={hasError}
            />
        )
    }

    renderCampusesInput = (data?: any) => {
        const newItemData = this.state.newItemData || {}
        return (
            <KlassappTableContext.Consumer>
                {({setIsShowScrollTable}) => (
                    <div style={{width: "250px"}} onClick={() => setIsShowScrollTable(false)}>
                        <CampusSelect
                            isDisabled={!!data}
                            displayOnlyActiveCampuses
                            className={styles.select}
                            isClearable
                            isMulti
                            value={data ? data : newItemData.campuses}
                            onChange={(data) => {
                                this.onChangeNewItemData("campuses", data)
                            }}
                        />
                    </div>
                )}
            </KlassappTableContext.Consumer>
        )
    }

    renderProgramsInput = (data?: any) => {
        const newItemData = this.state.newItemData || {}
        return (
            <KlassappTableContext.Consumer>
                {({setIsShowScrollTable}) => (
                    <div style={{width: "250px"}} onClick={() => setIsShowScrollTable(false)}>
                        <ProgramSelect
                            isDisabled={!!data}
                            className={styles.select}
                            isClearable
                            isActive
                            isMulti
                            value={data ? data : newItemData.programs}
                            onChange={(data) => {
                                this.onChangeNewItemData("programs", data)
                            }}
                        />
                    </div>
                )}
            </KlassappTableContext.Consumer>
        )
    }

    onClickAddItem = () => {
        const newItem = {
            id: uuid(),
            nameHtml: this.renderNameInput(),
            startTimeHtml: this.renderTimeInput("startTime"),
            endTimeHtml: this.renderTimeInput("endTime"),
            isActive: false,
            isActiveHtml: this.renderIsActiveHtml(),
            campusesHtml: this.renderCampusesInput(),
            programsHtml: this.renderProgramsInput(),
            isForm: true
        }
        const {data} = this.props
        data.push(newItem)
        this.setState({newItemData: newItem, oldItemData: null})
        this.props.dispatch({data})
    }

    validateDataBeforeSubmit = () => {
        const {isActive, nameData, startTime, endTime} = this.state.newItemData
        const {t} = this.props
        if (!nameData) {
            toastError(
                t("common:validation.cantEmpty", {
                    field: t("settings.name")
                })
            )
            return false
        }
        if (!isBoolean(isActive)) {
            toastError(
                t("common:validation.cantEmpty", {
                    field: t("settings.isActive")
                })
            )
            return false
        }
        const todayStr = moment().format("YYYY-MM-DD")
        if (startTime && endTime && moment(todayStr + " " + startTime).isAfter(moment(todayStr + " " + endTime))) {
            toastError("End time must greater than start time")
            return false
        }
        return true
    }

    onClickSave = async () => {
        if (!this.validateDataBeforeSubmit()) {
            return
        }
        const {nameData, startTime, endTime, isActive, isForm, id, programs, campuses} = this.state.newItemData
        if (isForm) {
            const submitData = {
                name: nameData,
                startTime: startTime || undefined,
                endTime: endTime || undefined,
                isActive,
                programIds: programs?.map((p) => p.id) || [],
                campusIds: campuses?.map((c) => c.id) || []
            }
            await this.createSetting(submitData)
        } else {
            const submitData = {
                name: nameData,
                startTime: startTime || undefined,
                endTime: endTime || undefined,
                isActive,
                programIds: programs?.map((p) => p.id) || [],
                campusIds: campuses?.map((c) => c.id) || []
            }
            await this.updateSetting(id, submitData)
        }
        this.setState({newItemData: null, oldItemData: null}, () => {
            this.getSettings()
        })
    }

    createSetting = async (data) => {
        try {
            this.props.dispatch({isLoading: true})
            this.setState({isSubmitting: true})
            await shiftsService.create(data)
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
            this.setState({isSubmitting: false})
        }
    }

    updateSetting = async (shiftId, data) => {
        try {
            this.props.dispatch({isLoading: true})
            this.setState({isSubmitting: true})
            await shiftsService.update({...data, shiftId})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
            this.setState({isSubmitting: false})
        }
    }

    onClickCancel = () => {
        const {oldItemData} = this.state
        const newData = this.props.data
            .filter((item) => !item.isForm)
            .map((item) => {
                if (oldItemData && oldItemData.id === item.id) {
                    item.nameHtml = oldItemData.name
                    item.startTimeHtml = oldItemData.startTime
                    item.endTimeHtml = oldItemData.endTime
                    item.isActive = oldItemData.isActive
                    item.isActiveHtml = this.renderIsActiveHtml(item)
                    item.programsHtml = this.renderProgramsInput(item.programs)
                    item.campusesHtml = this.renderCampusesInput(item.campuses)
                    return item
                }
                return item
            })
        this.setState({newItemData: null, oldItemData: null})
        this.props.dispatch({data: newData})
    }

    render() {
        const {newItemData, isSubmitting} = this.state
        const {
            t,
            page,
            total,
            pageSize,
            columns,
            data,
            menuActions,
            allFields,
            fields,
            tableHeaderActions,
            isLoading,
            isHideMenuActions,
            isShowTableHeaderAction
        } = this.props

        return (
            <div className={styles.wrapper}>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    isShowCheckedColumn
                    onClickRowItem={() => {}}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
                {!newItemData ? (
                    !isLoading && <AddItemCircleButton onClick={this.onClickAddItem} />
                ) : (
                    <div className={styles.buttonWrap}>
                        <SecondaryButton
                            title={t("common:action.cancel")}
                            onClick={this.onClickCancel}
                            className={styles.cancelBtn}
                        />
                        <BaseButton
                            title={t("common:action.save").toUpperCase()}
                            onClick={this.onClickSave}
                            loading={isSubmitting}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export const Shift = KlassappTableHOC(withTranslation(["academics", "common"])(ShiftTab))
