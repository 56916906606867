import React, {useMemo, useRef, useState} from "react"
import {Button, Checkbox} from "antd"
import cx from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import {Icon, IconType} from "components/Icon"
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"
import {TableColumns, TableMenuActions, Order} from "types/common"
import {checkIsFirefox} from "helpers"
import {TableRowItem, EmptyRow, LoadingRow, ColumnPicker} from "./parts"
import styles from "./KlassappTable.module.css"
import {useClickAway} from "react-use"
import {KlassappTableContext} from "./KlassappTableContext"

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}

function BodyTable(props) {
    const {tableData, isLoading, columns, isDraggable, categoryClassNames, ...rest} = props

    if (isLoading) {
        return (
            <tbody className={styles.bodyTable}>
                <LoadingRow colSpan={columns.length + 2} />
            </tbody>
        )
    }
    if (!tableData.length) {
        return (
            <tbody className={styles.bodyTable}>
                <EmptyRow colSpan={columns.length + 2} />
            </tbody>
        )
    }

    if (isDraggable) {
        return (
            <Droppable droppableId="table">
                {(droppableProvided) => (
                    <tbody
                        ref={droppableProvided.innerRef}
                        className={styles.bodyTable}
                        {...droppableProvided.droppableProps}>
                        {tableData.map((item, index: number) => (
                            <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
                                {(provided, snapshot) => (
                                    <TableRowItem
                                        provided={provided}
                                        snapshot={snapshot}
                                        key={item.id}
                                        data={item}
                                        columns={columns}
                                        isDraggable={isDraggable}
                                        {...rest}
                                    />
                                )}
                            </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                    </tbody>
                )}
            </Droppable>
        )
    }

    return (
        <tbody className={styles.bodyTable}>
            {tableData.map((category) => (
                <React.Fragment key={category.id}>
                    <tr>
                        <td
                            className={cx(styles.subCategory, ...(categoryClassNames ?? []))}
                            colSpan={
                                category.numberOfColumns
                                    ? category.numberOfColumns
                                    : columns.filter((col) => props.fields?.includes(col.title)).length +
                                      (props.isShowColumnPicker ? 1 : 0)
                            }>
                            {category.category}
                        </td>
                        {category.isRightHTML && (
                            <td className={cx(styles.subCategory, styles.rightHTML)} colSpan={1}>
                                <span>{category.isRightHTML}</span>
                            </td>
                        )}
                    </tr>
                    {!!props.showCategoryEmptyRow && !category.records?.length && (
                        <EmptyRow colSpan={columns.length + 2} />
                    )}
                    {category.records?.map((item) => (
                        <TableRowItem key={item.id} data={item} columns={columns} isDraggable={isDraggable} {...rest} />
                    ))}
                </React.Fragment>
            ))}
        </tbody>
    )
}

export type KlassappTableSubCategoryProps = {
    tableClassName?: string
    className?: string
    parentColumns?: any[]
    categoryClassNames?
    rootClassName?: string
    columns: TableColumns[]
    fields: string[]
    data: any[]
    onClickRowItem?: Function
    onDoubleClickRowItem?: Function
    allFields?: string[]
    menuActions?: TableMenuActions[]
    onChangeFields?: (fields: string[]) => void
    onChangeAllFields?: (fields: string[]) => void
    onDraggableColumn?: (sourceIndex: number, destIndex: number) => void
    isShowCheckedColumn?: boolean
    isHideMenuActions?: boolean
    isLoading?: boolean
    isShowColumnPicker?: boolean
    isShowHeader?: boolean
    isShowScrollTable?: boolean
    isDraggable?: boolean
    showCategoryEmptyRow?: boolean
    onDraggableEnd?: Function
    onUpdateTableData?: Function
    onUpdateRowData?: Function
    orderField?: {
        field?: string
        order?: Order.Asc | Order.Desc
    }
    onClickSortColumn?: Function
    isHideSortIcon?: boolean
    onToggleCheckHeader?: (checked: boolean) => void
    icon?: IconType
    expandable?: {
        rowExpandable?: (record) => boolean
        expandedRowRender: (record, expanded) => React.ReactNode
    }
    onScrollDown?: (container: HTMLElement) => void
    onYReachEnd?: (container: HTMLElement) => void
}

export function KlassappTableSubCategory(props: KlassappTableSubCategoryProps) {
    const {
        className,
        parentColumns = [],
        columns,
        tableClassName,
        menuActions,
        data,
        isLoading,
        fields,
        allFields,
        isShowCheckedColumn,
        isShowColumnPicker,
        isShowHeader = true,
        showCategoryEmptyRow = false,
        isDraggable,
        orderField,
        onClickRowItem,
        onDoubleClickRowItem,
        onChangeFields,
        onChangeAllFields,
        onDraggableColumn,
        onUpdateTableData,
        onUpdateRowData,
        onToggleCheckHeader,
        onClickSortColumn,
        isHideSortIcon,
        onDraggableEnd,
        icon,
        expandable,
        categoryClassNames,
        rootClassName,
        onScrollDown,
        onYReachEnd
    } = props

    const colsPickerRef = useRef(null)
    const [isShowScrollTable, setIsShowScrollTable] = useState(true)

    useClickAway(colsPickerRef, () => {
        setIsShowChooseCols(false)
    })

    const onToggleCheck = (checked) => {
        if (onToggleCheckHeader) {
            onToggleCheckHeader(checked)
        } else {
            const newTableData = data.map((item) => {
                item.isChecked = checked
                return item
            })
            onUpdateTableData(newTableData)
        }
    }
    const [isShowChooseCols, setIsShowChooseCols] = useState(false)
    const isChecked = useMemo(() => {
        return data.length && data.every((row) => !!row?.isChecked)
    }, [data])

    const renderOrderIcon = (field, isHideSortIcon?: boolean) => {
        return orderField.field === field ? (
            orderField.order === "asc" ? (
                <Icon icon="SORT_DOWN_SOLID" className={styles.sortIcon} />
            ) : (
                <Icon icon="SORT_UP_SOLID" className={styles.sortIcon} />
            )
        ) : isHideSortIcon ? (
            ""
        ) : (
            <Icon icon="SORT_SOLID" className={styles.sortIcon} />
        )
    }

    const onDragEnd = (result) => {
        const {destination, source} = result || {}
        if (!destination || destination.index === source.index) {
            return
        }
        if (destination.index === source.index) {
            return
        }
        if (onDraggableEnd) {
            onDraggableEnd(result)
        } else {
            const newTableData = reorder(data, source.index, destination.index)
            onUpdateTableData(newTableData)
        }
    }

    const onShowChooseCols = () => {
        setIsShowChooseCols(true)
    }

    const renderTableContent = () => {
        return (
            <table className={cx(styles.table, tableClassName)}>
                {isShowHeader && (
                    <thead className={styles.tableHead}>
                        {parentColumns.length ? (
                            <tr>
                                {isShowCheckedColumn && (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`}></th>
                                )}
                                {!!expandable?.expandedRowRender && (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`}></th>
                                )}
                                {parentColumns?.map((col) => (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`} colSpan={col.span}>
                                        {col.title}
                                    </th>
                                ))}
                                {isShowColumnPicker ? (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`}></th>
                                ) : menuActions && menuActions.length ? (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`}></th>
                                ) : null}
                            </tr>
                        ) : null}
                        <tr className={styles.tableRowHeader}>
                            {isShowCheckedColumn && (
                                <th
                                    className={`${styles.tableCell} ${styles.tableCellHeader} ${styles.expandIconCell}`}>
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={(event) => onToggleCheck(event.target.checked)}
                                    />
                                </th>
                            )}
                            {!!expandable?.expandedRowRender && (
                                <th
                                    className={`${styles.tableCell} ${styles.tableCellHeader} ${styles.expandIconCell}`}></th>
                            )}
                            {columns.map((column, index) => {
                                const canSortable = column.sortable && onClickSortColumn
                                const orderField = column.orderField || column.field
                                const title = column.titleHtml || column.title
                                return (
                                    fields.includes(column.title) && (
                                        <th
                                            key={index}
                                            className={cx(
                                                styles.tableCell,
                                                styles.tableCellHeader,
                                                column.classNameHeader,
                                                canSortable ? styles.cursor : ""
                                            )}
                                            colSpan={column.subFields?.length ?? 1}
                                            style={column.headerStyle}
                                            onClick={canSortable ? () => onClickSortColumn(orderField) : () => {}}>
                                            {!column.isHideTitle && title}
                                            {canSortable && renderOrderIcon(orderField, isHideSortIcon)}
                                        </th>
                                    )
                                )
                            })}
                            {isShowColumnPicker ? (
                                <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>
                                    <div className={styles.flexEnd}>
                                        <Button
                                            className={styles.plusIconBtn}
                                            icon={<Icon icon={icon || "COLUMNS"} className={styles.iconPlus} />}
                                            onClick={onShowChooseCols}
                                        />
                                    </div>
                                </th>
                            ) : menuActions && menuActions.length ? (
                                <th className={`${styles.tableCell} ${styles.tableCellHeader}`}></th>
                            ) : null}
                        </tr>
                    </thead>
                )}
                <BodyTable
                    columns={columns}
                    tableData={data}
                    menuActions={menuActions}
                    fields={fields}
                    isLoading={isLoading}
                    isShowCheckedColumn={isShowCheckedColumn}
                    isShowColumnPicker={isShowColumnPicker}
                    showCategoryEmptyRow={showCategoryEmptyRow}
                    onClickRowItem={onClickRowItem}
                    onDoubleClickRowItem={onDoubleClickRowItem}
                    onUpdateRowData={onUpdateRowData}
                    isDraggable={isDraggable}
                    categoryClassNames={categoryClassNames}
                    expandable={expandable}
                />
            </table>
        )
    }

    const renderTable = () => {
        const isFirefox = checkIsFirefox()
        return (
            <KlassappTableContext.Provider value={{isShowScrollTable, setIsShowScrollTable}}>
                <div className={cx(styles.root, {"hidden-scroll": !isShowScrollTable}, rootClassName)}>
                    {isFirefox ? (
                        <div
                            className={cx(
                                "custom-scroll",
                                isShowScrollTable ? styles.tableScroll : styles.tableScrollHidden,
                                className
                            )}>
                            {renderTableContent()}
                        </div>
                    ) : (
                        <PerfectScrollbar
                            className={cx(
                                "custom-scroll",
                                isShowScrollTable ? styles.tableScroll : styles.tableScrollHidden,
                                className
                            )}
                            onScrollDown={onScrollDown}
                            onYReachEnd={onYReachEnd}>
                            {renderTableContent()}
                        </PerfectScrollbar>
                    )}
                    {isShowChooseCols && (
                        <div ref={colsPickerRef}>
                            <ColumnPicker
                                fields={fields}
                                allFields={allFields}
                                onChangeFields={onChangeFields}
                                onChangeAllFields={onChangeAllFields}
                                setIsShowChooseCols={setIsShowChooseCols}
                                onDraggableColumn={onDraggableColumn}
                            />
                        </div>
                    )}
                </div>
            </KlassappTableContext.Provider>
        )
    }

    if (isDraggable) {
        return (
            <DragDropContext onDragEnd={onDragEnd} style={{overflow: "auto"}}>
                {renderTable()}
            </DragDropContext>
        )
    }
    return renderTable()
}
