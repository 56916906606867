import {Timestamps} from "./common"
import {DepartmentSubunit} from "./departments"

export enum TaskType {
    DIGITAL_DOCUMENT = "dd",
    SCAN_UPLOAD = "scan",
    FROM_LIBRARY = "fromlibrary",
    FORM = "form",
    CUSTOM = "custom"
}

export enum TaskSubtype {
    ASSIGNED = "assigned",
    LIBRARY = "library"
}

export enum UserType {
    STUDENT = "student",
    STAFF = "staff",
    OTHERS = "others"
}

export enum SelectStaffFrom {
    NO_DEFAULT = "noDefault",
    ROLE = "fromContact",
    DIRECTORY = "fromDirectory"
}

export enum SelectOthersFrom {
    ROLE = "fromContact",
    MANUAL = "manually"
}

export enum DigitalDocumentFieldSetUp {
    SIGNATURE = "signature",
    INITIAL = "initial",
    TEXT = "text",
    CHECKBOX = "checkbox",
    DATE_FIELD = "dateField"
}

export enum TaskStatus {
    TODO = "todo",
    STARTED = "started",
    DONE = "done",
    AWAITING_APPROVAL = "awaiting_approval",
    REJECTED = "rejected",
    OVERDUE = "overdue",
    DRAFT = "draft"
}

export enum TaskAction {
    APPROVE = "APPROVE",
    REJECT = "REJECT",
    REMIND = "REMIND",
    REOPEN = "REOPEN"
}

export enum TaskActionFor {
    SUBTASK = "subtask",
    TASK_USER = "task_user"
}

export enum DataType {
    TEXT = "text",
    NUMERIC = "numeric",
    DATE = "date",
    SOCIAL_SECURITY_NUMBER = "social_security_number"
}

export enum DefaultDataType {
    NO_DEFAULT = "no_default",
    MANUAL_TEXT = "manual_text",
    STUDENT_DATA = "student_data"
}

export enum CheckboxType {
    SINGLE = "single",
    MULTIPLE = "multiple"
}

export enum InitialType {
    SINGLE = "single",
    MULTIPLE = "multiple"
}

export enum ParticipantType {
    PrimarySigner = "primarySigner",
    NonPrimarySigner = "nonPrimarySigner",
    UserDataOnly = "userDataOnly"
}

export enum SignatureMethod {
    HAND = "hand",
    KEYBOARD = "keyboard",
    BOTH = "both"
}

export interface Task {
    readonly id: number
    readonly taskId?: number
    readonly code: string
    name: string
    description: string
    departmentId: number
    department?: DepartmentSubunit
    type?: TaskType
    subtype?: TaskSubtype
    jsonData?: Record<string, any>
    signers?: any[]
    taskCode?: string
    parentTaskCode?: string
    reminders?: any[]
    dueDate?: string
    cc?: any[]
    visibleForUser?: boolean
    userDocumentInfo?: any
    scan?: any
    digitalDocument?: any
    form?: {
        code: string
        signers: Array<
            {
                id: number
                formId: number
                order: number
                label: string
                jsonData: Record<string, any>
                type: UserType
                participantType: ParticipantType
                // css: "student"
            } & Timestamps
        >
    }
    autoApprove?: boolean | 0 | 1
}

export type DigitalField = {
    id: number | string
    dDocId: number
    page: number
    placeInformation: FieldPlaceInformation
    fieldValues: FieldValue[]
    parentId: number | string
    label: string
    type: string
    signatureMethod: string
    values: string
    defaultDataType: string
    defaultData: string
    defaultParticipant: string
    signatureType: string
    dataType: string
    checkboxType: any
    required: boolean
    createdAt: string
    updatedAt: string
    deletedAt: string
    signerIds: number[]
    initialType: string
    fieldOrder: number
    syncToEdular: number
    signatureTypes: FieldSignatureType[]
}

export type FieldPlaceInformation = {
    width: number
    height: number
    top: number
    left: number
}

export type FieldValue = {
    id: string | number
    index: number
    page: number
    label: string
    position: FieldPlaceInformation
    type: string
    parentId: string | number
    parentSignatureTypeId: number
    placeInformation: FieldPlaceInformation
}

export type FieldSignatureType = {
    id: number
    type: string
    label: string
}
