/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useCallback, useMemo, useRef} from "react"
import {cloneDeep, pick, debounce, isEmpty} from "lodash"
import {useTranslation, withTranslation} from "react-i18next"
import moment from "moment"
import {Radio} from "antd"
import axios from "axios"
import cx from "classnames"
import ReactPlayer from "react-player/lazy"
import {Screen} from "components/ui"
import {BaseLoading} from "components/Loading"
import {BaseInput} from "components/inputs"
import {FormLabel} from "components/Form"
import {KlassDropAsyncPaginate, KlassDropdown, KlassDropdownAsync} from "components/Select"
import {BaseButton, SecondaryButton} from "components/buttons"
import DepartmentSubunitSelect from "components/DepartmentSubunitSelect"
import CampusSelect from "components/CampusSelect"
import DegreeLevelSelect from "components/DegreeLevelSelect"
import ProgramSelect from "components/ProgramSelect"
import TagSelect from "components/TagSelect"
import {PDFViewer} from "components/FileViewer"
import {Model} from "Model"
import {fileService, mediaLibraryService, termsService} from "services"
import {DepartmentSubunit} from "types/departments"
import {
    handleError,
    handleErrorMessage,
    getValuesChangeTwoObj,
    getFullName,
    formatDate,
    toastError,
    isValidUrl,
    matchYoutubeUrl,
    onErrorImageLoad,
    getPreviewUnavailableImage,
    getBlobFromBase64String
} from "helpers"
import styles from "./MediaLibrary.module.css"
import {useModel, useSearchParam} from "hooks"
import {STATES_OPTIONS as STUDENT_STATE_OPTIONS} from "types/students"
import {STAFF_STATE_OPTIONS} from "types/staffs"
import {OTHER_STATE_OPTIONS} from "types/others"
import * as pdfjsLib from "pdfjs-dist"
import "pdfjs-dist/build/pdf.worker.entry" // required to include pdf.js worker
import {v4 as uuid} from "uuid"
import {FileCardHandler} from "./FileCardHandler"
import {MediaLibraryItemSubType, MediaLibraryItemType} from "types/media-library"

const MEDIA_LIBRARY_TYPES = [
    {id: 1, name: MediaLibraryItemType.Image},
    {id: 2, name: MediaLibraryItemType.Video},
    {id: 3, name: MediaLibraryItemType.Document},
    {id: 4, name: MediaLibraryItemType.Link}
]

type Props = {
    model: Model
    history: any
    data?
}

enum ProfileTypeOptionId {
    Student = 2,
    Staff = 3,
    Others = 4
}

const PROFILE_TYPE_OPTIONS = [
    {id: ProfileTypeOptionId.Student, name: "student"},
    {id: ProfileTypeOptionId.Staff, name: "staff"},
    {id: ProfileTypeOptionId.Others, name: "others"}
]

const PROFILE_STATE_OPTIONS = PROFILE_TYPE_OPTIONS.map((profile) => {
    const PROFILE_OPTIONS =
        profile.id === ProfileTypeOptionId.Student
            ? STUDENT_STATE_OPTIONS
            : profile.id === ProfileTypeOptionId.Staff
            ? STAFF_STATE_OPTIONS
            : OTHER_STATE_OPTIONS

    return {
        userTypeId: profile.id,
        label: profile.name,
        options: PROFILE_OPTIONS
    }
})

const NEW_ITEM_DEFAULT_PROFILE_TYPE_OPTIONS = PROFILE_TYPE_OPTIONS.filter(({id}) =>
    [ProfileTypeOptionId.Student, ProfileTypeOptionId.Staff].includes(id)
)

const NEW_ITEM_DEFAULT_PROFILE_STATE_OPTIONS = PROFILE_STATE_OPTIONS.flatMap((state) => {
    return state.options.filter((option) => {
        return ["employee"].includes(option.id)
    })
})

function MediaLibrary(props: Props) {
    const model = useModel()

    const {value: mediaLibraryItemId} = useSearchParam("id", {parse: (it) => parseInt(it, 10)})

    const isCreatingNewItem = !mediaLibraryItemId

    const allUserCampusIds = useMemo(() => {
        const userCampuses = (model.user?.profiles || [])
            .map((profile) => (profile.campuses || []).map(({id}) => id))
            .filter(Boolean)

        return userCampuses.flat()
    }, [model.user])

    const initialUserTypes = isCreatingNewItem ? NEW_ITEM_DEFAULT_PROFILE_TYPE_OPTIONS : []
    const initialUserStates = isCreatingNewItem ? NEW_ITEM_DEFAULT_PROFILE_STATE_OPTIONS : []

    const initialData = (dataProps) => {
        const result = {
            type: "",
            title: "",
            description: "",
            campusIds: [],
            departmentSubunitIds: [],
            degreeLevelIds: [],
            programIds: [],
            tagIds: [],
            userTypes: initialUserTypes.map((item) => item.name),
            userStates: initialUserStates.map((item) => item.id),
            startingTermIds: [],
            details: {}
        }
        if (!dataProps) {
            return result
        }

        return {...result, ...dataProps}
    }

    const initData = initialData(props.data)
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [mediaLibraryData, setMediaLibraryData] = useState(cloneDeep(initData))
    const [oldMediaLibraryData, setOldMediaLibraryData] = useState(cloneDeep(initData))
    const [type, setType] = useState<MediaLibraryItemType>() // manage selected type
    const [startingTerm, setStartingTerm] = useState<any>([]) // manage selected startingTerm
    const [isShowSubType, setIsShowSubType] = useState<boolean>(false) // show / hide selected sub-type
    const [subType, setSubType] = useState<MediaLibraryItemSubType>() // manage selected sub-type
    const [userTypes] = useState(PROFILE_TYPE_OPTIONS) // manage userType list
    const [userType, setUserType] = useState(initialUserTypes) // manage selected userType
    const [states, setStates] = useState(PROFILE_STATE_OPTIONS) // manage state list
    const [state, setState] = useState(initialUserStates) // manage selected state
    const [campus, setCampus] = useState([]) // manage selected campus
    const [departmentSubunits, setDepartmentSubunits] = useState([]) // manage selected subunits
    const [degreeLevel, setDegreeLevel] = useState([]) // manage selected degreeLevel
    const [major, setMajor] = useState([]) // manage selected major
    const [tag, setTag] = useState([]) // manage selected tag
    const [title, setTitle] = useState("") // manage title
    const [link, setLink] = useState("") // manage link
    const [description, setDescription] = useState("") // manage description
    const [videoLinkThumbnail, setVideoLinkThumbnail] = useState<string | null>(null) // manage video link thumbnail
    const [errors, setErrors] = useState({})
    let [mediaFile, setMediaFile] = useState<any>()
    let [thumbnailFile, setThumbnailFile] = useState<any>()
    let [imageSource, setImageSource] = useState<string>()
    const [linkThumbnailChanged, setLinkThumbnailChanged] = useState(false)
    let forThumbnail = false

    let isChanged = true
    const {t} = useTranslation(["common", "settings"])
    const canvasRef = useRef(null) // Reference to the canvas element

    useEffect(() => {
        if (mediaLibraryItemId) {
            getMediaLibraryDetail(mediaLibraryItemId)
        }
    }, [mediaLibraryItemId])

    const onDropFiles = useCallback(
        async (acceptedFiles) => {
            const file = acceptedFiles[0]
            const reader = new FileReader()

            // extract 1st page to preview image
            reader.readAsDataURL(file)
            reader.onloadend = async () => {
                // file.imgSrc = [reader.result]
                isChanged = true
                if (type === MediaLibraryItemType.Link) {
                    setVideoLinkThumbnail(String(reader.result))
                }

                if (type === MediaLibraryItemType.Document) {
                    const pdf = await pdfjsLib.getDocument(reader.result).promise
                    const page = await pdf.getPage(1) // Extract the first page

                    const scale = 2
                    const viewport = page.getViewport({scale})

                    const canvas = canvasRef.current
                    const context = canvas.getContext("2d")

                    canvas.width = viewport.width
                    canvas.height = viewport.height

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    }

                    await page.render(renderContext).promise

                    // Convert canvas to a data URL and update the state
                    const imageBase64 = canvas.toDataURL("image/png")
                    const image = await getBlobFromBase64String(imageBase64)
                    setThumbnailFile(new File([image], `${uuid()}.png`, {type: "image/png"}))
                    setImageSource(imageBase64)
                } else {
                    setImageSource(reader.result as string)
                }
            }
            setMediaFile(file)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [type]
    )

    const onDropThumbnails = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0]
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = () => {
                // file.imgSrc = [reader.result]
                isChanged = true
                setVideoLinkThumbnail(String(reader.result))
            }
            setThumbnailFile(file)
            setLinkThumbnailChanged(true)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [type]
    )

    useEffect(() => {
        let statesSelected = []
        if (userType) {
            userType.every((userTypeItem) => {
                PROFILE_STATE_OPTIONS.forEach((item) => {
                    if (item.userTypeId === userTypeItem.id) {
                        statesSelected.push(item)
                    }
                })
                return true
            })
        }
        if (statesSelected.length < 1) {
            statesSelected = PROFILE_STATE_OPTIONS
        }
        setStates(statesSelected)
    }, [userType])

    useEffect(() => {
        const stateOptions = states.flatMap((stateOption) => stateOption.options.map((option) => option.id))
        const stateFiltered = state.filter((stateSelected) => {
            return stateOptions.includes(stateSelected.id)
        })
        setState(stateFiltered)
        onChangeData(
            "userStates",
            stateFiltered?.map((item) => {
                return item.id
            }) ?? []
        )
    }, [states])

    useEffect(() => {
        if (!mediaLibraryData?.id) {
            setMediaFile(undefined)
            setThumbnailFile(undefined)
            setImageSource(undefined)
            if ([MediaLibraryItemType.Image, MediaLibraryItemType.Video].includes(type)) {
                setIsShowSubType(true) // show sub-type selection
                setSubType(null) // set sub-type default selection on type change
            } else if ([MediaLibraryItemType.Document, MediaLibraryItemType.Link].includes(type)) {
                setIsShowSubType(false) // hide sub-type selection
                type === MediaLibraryItemType.Link ? setSubType("link") : setSubType("file") // set sub-type default selection on type change
            }
        }
    }, [type])

    useEffect(() => {
        setMediaFile(undefined)
        setThumbnailFile(undefined)
        setImageSource(undefined)
    }, [subType])

    useEffect(() => {
        isChanged = true
    }, [videoLinkThumbnail])

    const getParams = () => {
        const params = {
            filter: {},
            linkedEntities: true
        }
        return params
    }

    const getMediaLibraryDetail = async (id) => {
        try {
            setIsLoading(true)

            let params: any = getParams()
            params.filter.itemIds = [parseInt(id)]

            let {
                data: [item]
            } = await mediaLibraryService.getAll(params)
            item.id = item.itemId

            if (item.type === "image") {
                setVideoLinkThumbnail(item.details.thumbnailUrl)
                item.thumbnailCard = (
                    <img
                        className={styles.mediaImage}
                        src={
                            !!item.details.url ? item.details.url : getPreviewUnavailableImage(model.clientSetting.slug)
                        }
                        alt=""
                        onError={(currentTarget) => onErrorImageLoad(model.clientSetting.slug, currentTarget)}
                    />
                )
                item.previewThumbnailCard = (
                    <img
                        className={styles.mediaImage}
                        src={
                            !!item.details.thumbnailUrl
                                ? item.details.thumbnailUrl
                                : getPreviewUnavailableImage(model.clientSetting.slug)
                        }
                        alt=""
                        onError={(currentTarget) => onErrorImageLoad(model.clientSetting.slug, currentTarget)}
                    />
                )
                setSubType("file")
            } else if (item.type === "video") {
                item.thumbnailCard = <ReactPlayer url={item.details?.videoUrl} controls />
                item.previewThumbnailCard = (
                    <img
                        className={styles.mediaImage}
                        src={
                            !!item.details.thumbnailUrl
                                ? item.details.thumbnailUrl
                                : getPreviewUnavailableImage(model.clientSetting.slug)
                        }
                        alt=""
                        onError={(currentTarget) => onErrorImageLoad(model.clientSetting.slug, currentTarget)}
                    />
                )
                setSubType("file")
            } else if (item.type === "document" && item.details) {
                item.thumbnailCard = (
                    <PDFViewer
                        fileSrc={item.details.url}
                        options={{toolbar: false, zoom: "fitToWidth", view: "fitToWidth"}}
                    />
                )
                item.previewThumbnailCard = (
                    <img
                        className={styles.mediaImage}
                        src={
                            !!item.details.thumbnailUrl
                                ? item.details.thumbnailUrl
                                : getPreviewUnavailableImage(model.clientSetting.slug)
                        }
                        alt=""
                        onError={(currentTarget) => onErrorImageLoad(model.clientSetting.slug, currentTarget)}
                    />
                )
                setSubType("file")
            } else if (item.type === "link") {
                item.thumbnailCard = (
                    <img
                        className={styles.mediaImage}
                        src={
                            !!item.details.thumbnailUrl
                                ? item.details.thumbnailUrl
                                : getPreviewUnavailableImage(model.clientSetting.slug)
                        }
                        alt=""
                        onError={(currentTarget) => onErrorImageLoad(model.clientSetting.slug, currentTarget)}
                    />
                )
                setLink(item.details.url)
                setSubType("link")
                setVideoLinkThumbnail(item.details.thumbnailUrl)
            }

            setType(item.type)

            const fetchedUserTypes = userTypes.filter((x) => {
                return item.userTypes.indexOf(x.name) >= 0
            })
            setUserType(fetchedUserTypes)
            const fetchedStates = states.flatMap((state) => {
                return state.options.filter((x) => {
                    return item.userStates.indexOf(x.id) >= 0
                })
            })
            setState(fetchedStates)

            setTitle(item.title)
            setDescription(item.description)
            setCampus(item.campuses)
            setDepartmentSubunits(item.departmentSubunits)
            setDegreeLevel(item.degreeLevels)
            setMajor(item.programs)
            setTag(item.tags)
            setStartingTerm(item.startingTerms)

            setMediaLibraryData(initialData(item))
            setOldMediaLibraryData(initialData(item))
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onTermSearchChange = async (search = "", loadedOptions) => {
        try {
            if (!mediaLibraryData.campusIds.length) {
                return {
                    options: [],
                    hasMore: false
                }
            }
            const params = {
                limit: 20,
                offset: loadedOptions.length,
                text: search,
                is_active: true,
                ...(!!mediaLibraryData.campusIds.length && {campusId: mediaLibraryData.campusIds.join(",")})
            }
            const {data: terms, total} = await termsService.getAllTerms(params)
            return {
                options: terms,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onChangeData = (key, value) => {
        if (key === "campusIds") {
            onTermSearchChange("", [])
        }
        setMediaLibraryData((prev) => ({
            ...prev,
            [key]: value
        }))
    }

    const validateBeforeSubmit = () => {
        const attrs = {
            type: {type: "array", errorMessage: t("common:validation.hasToBeSelect", {field: "Type"})},
            title: {type: "string", errorMessage: t("common:validation.cantEmpty", {field: "Title"})},
            userTypes: {type: "array", errorMessage: t("common:validation.hasToBeSelect", {field: "User Types"})},
            userStates: {type: "array", errorMessage: t("common:validation.hasToBeSelect", {field: "User States"})},
            campusIds: {type: "array", errorMessage: t("common:validation.hasToBeSelect", {field: "Campus"})},
            tags: {type: "array", errorMessage: t("common:validation.hasToBeSelect", {field: "Tag"})}
        }
        for (let [key, value] of Object.entries(attrs)) {
            delete errors[key]
            if (!mediaLibraryData[key]) {
                setErrors({[key]: value.errorMessage})
                toastError(value.errorMessage)
                return true
            } else if (
                (value.type === "string" && mediaLibraryData[key].trim() === "") ||
                (value.type === "array" && mediaLibraryData[key].length < 1) ||
                (value.type === "object" && Object.keys(mediaLibraryData[key]).length < 1)
            ) {
                setErrors({[key]: value.errorMessage})
                toastError(value.errorMessage)
                return true
            }
        }
        if (!mediaLibraryData.id && link.trim() === "" && mediaFile === undefined && thumbnailFile === undefined) {
            toastError("Media file required")
            return true
        }
        if (subType === "link" && !isValidUrl((link ?? "").trim())) {
            toastError("Please enter a valid URL")
            return true
        }
        return false
    }

    const getDataSubmit = () => {
        const attrs = [
            "type",
            "title",
            "description",
            "campusIds",
            "departmentSubunitIds",
            "degreeLevelIds",
            "programIds",
            "tags",
            "userTypes",
            "userStates",
            "details",
            "startingTermIds"
        ]
        const oldData = pick(oldMediaLibraryData, attrs)
        const newData = pick(mediaLibraryData, attrs)
        const changes = getValuesChangeTwoObj(oldData, newData)

        const oldDetails = oldMediaLibraryData.details || {}
        const newDetails = mediaLibraryData.details || {}
        const detailsChanges = getValuesChangeTwoObj(oldDetails, newDetails)

        const previewChanges =
            videoLinkThumbnail === oldDetails?.thumbnailUrl
                ? {}
                : {
                      thumbnailUrl: videoLinkThumbnail
                  }

        const combinedChanges = {...changes, ...detailsChanges, ...previewChanges}

        if (!mediaLibraryData.id) {
            return newData
        }
        return isEmpty(combinedChanges) ? null : combinedChanges
    }

    const onClickSave = async () => {
        const isInvalid = validateBeforeSubmit()
        if (isInvalid) {
            return
        }
        if (!mediaLibraryData.id) {
            createMediaLibrary()
        } else {
            updateMediaLibrary()
        }
    }

    const createMediaLibrary = async () => {
        setIsSubmitting(true)
        try {
            // set default media details using link
            mediaLibraryData.details = {
                url: link
            }

            let url, thumbnailUrl
            if (subType === "file") {
                forThumbnail = false
                const fileUploadData = await getPresignedUrl()
                if (fileUploadData === false) {
                    setIsSubmitting(false)
                    return
                } else {
                    const {url: fileUrl, urlForUploading} = fileUploadData
                    if (!(await uploadUsingPresignedUrl(urlForUploading))) {
                        setIsSubmitting(false)
                        return
                    }

                    url = fileUrl
                }
            }
            if (thumbnailFile) {
                forThumbnail = true
                const thumbnailUploadData = await getPresignedUrl()
                if (thumbnailUploadData === false) {
                    setIsSubmitting(false)
                    return
                } else {
                    const {url: createdThumbnailUrl, urlForUploading} = thumbnailUploadData
                    if (!(await uploadUsingPresignedUrl(urlForUploading))) {
                        setIsSubmitting(false)
                        return
                    }

                    thumbnailUrl = createdThumbnailUrl
                }
            }

            if (type === MediaLibraryItemType.Video) {
                if (subType === "file") {
                    const resultVideoDetails = await extractVideoDetails(url)
                    if (resultVideoDetails !== false) {
                        mediaLibraryData.details = {
                            videoUrl: url,
                            durationInSeconds: resultVideoDetails.data.durationInSeconds,
                            thumbnailUrl: thumbnailFile ? thumbnailUrl ?? "" : resultVideoDetails.data.thumbnailUrl
                        }
                    }
                } else if (subType === "link") {
                    mediaLibraryData.details = {
                        videoUrl: link,
                        thumbnailUrl: thumbnailFile ? thumbnailUrl ?? "" : videoLinkThumbnail
                    }
                }

                if (mediaLibraryData.details.videoUrl === undefined) {
                    handleErrorMessage("Error fetching video details")
                }
            }

            if (type === MediaLibraryItemType.Link) {
                mediaLibraryData.details = {
                    ...mediaLibraryData.details,
                    thumbnailUrl: thumbnailFile ? thumbnailUrl ?? "" : videoLinkThumbnail
                }
            }

            if (type === MediaLibraryItemType.Document) {
                mediaLibraryData.details = {
                    ...mediaLibraryData.details,
                    url: url,
                    thumbnailUrl: thumbnailUrl ?? ""
                }
            }

            if (type === MediaLibraryItemType.Image) {
                mediaLibraryData.details = {
                    ...mediaLibraryData.details,
                    url: subType === "file" ? url : link,
                    thumbnailUrl: thumbnailUrl ?? ""
                }
            }
            setMediaLibraryData(mediaLibraryData)
            const data = getDataSubmit()
            await mediaLibraryService.create(data)
            onClickBack()
        } catch (e) {
            setIsSubmitting(false)
            handleError(e)
        }
    }

    const updateMediaLibrary = async () => {
        setIsSubmitting(true)
        try {
            let data: any = getDataSubmit()
            let thumbnailUrl
            if (thumbnailFile) {
                forThumbnail = true
            } else if (mediaFile) {
                forThumbnail = false
            }

            if (
                (!videoLinkThumbnail || videoLinkThumbnail.startsWith("data")) &&
                ((forThumbnail && thumbnailFile !== undefined) || mediaFile !== undefined)
            ) {
                const thumbnailUploadData = await getPresignedUrl()
                if (thumbnailUploadData === false) {
                    setIsSubmitting(false)
                    return
                } else {
                    const {url: newThumbnailUrl, urlForUploading} = thumbnailUploadData
                    if (!(await uploadUsingPresignedUrl(urlForUploading))) {
                        setIsSubmitting(false)
                        return
                    }

                    thumbnailUrl = newThumbnailUrl
                }
            }

            if (type === MediaLibraryItemType.Link) {
                if (linkThumbnailChanged) {
                    data = {
                        ...data,
                        type: type,
                        details: {
                            url: mediaLibraryData.details.url,
                            thumbnailUrl: thumbnailUrl ?? videoLinkThumbnail
                        }
                    }
                } else {
                    data = {
                        ...data,
                        type: type
                    }
                }
            }

            delete data.url
            delete data.thumbnailUrl

            if (data !== null) {
                await mediaLibraryService.update(mediaLibraryData.itemId, data)
                onClickBack()
                return
            }
            onClickBack()
        } catch (e) {
            setIsSubmitting(false)
            handleError(e)
        }
    }

    const getLinkPreview = useCallback(
        debounce(async (url: string): Promise<boolean> => {
            try {
                if (!url) return false

                if (!isValidUrl(url)) return false
                delete errors["link"]
                const result = await mediaLibraryService.getLinkPreview({
                    url
                })
                if (result.success === false) {
                    return false
                }

                const title: string = result.data.title ? result.data.title : ""
                const description: string = result.data.description ? result.data.description : ""
                const images: string = result.data.images
                    ? result.data.images.length
                        ? result.data.images[0]
                        : ""
                    : ""

                setTitle(title)
                onChangeData("title", title)

                setDescription(description)
                onChangeData("description", description)

                setVideoLinkThumbnail(images)

                return true
            } catch (e) {
                errors["link"] = e
                handleError(e)
                return false
            } finally {
                setErrors({...errors})
            }
        }, 500),
        []
    )

    const extractVideoDetails = async (url) => {
        try {
            const params = {
                url
            }
            const result = await mediaLibraryService.extractVideoDetails(params)
            if (result.success === false) {
                return false
            }
            return result
        } catch (e) {
            handleError(e)
            return false
        }
    }

    const uploadUsingPresignedUrl = async (urlForUploading) => {
        try {
            if (forThumbnail) {
                if (thumbnailFile) {
                    const headers = {
                        "Content-Type": thumbnailFile.type
                    }

                    const result = await axios.put(urlForUploading, thumbnailFile, {
                        headers
                    })
                    return result
                } else {
                    handleErrorMessage("Error uploading file")
                    return false
                }
            } else {
                if (mediaFile) {
                    const headers = {
                        "Content-Type": mediaFile.type
                    }

                    const result = await axios.put(urlForUploading, mediaFile, {
                        headers
                    })
                    return result
                } else {
                    handleErrorMessage("Error uploading file")
                    return false
                }
            }
        } catch (e) {
            handleError(e)
            return false
        }
    }

    const getPresignedUrl = async () => {
        try {
            let fileExt: string
            if (forThumbnail) {
                if (thumbnailFile) {
                    const fileType = thumbnailFile.type
                    const fileExtension = fileType.split("/")
                    fileExt = fileExtension[1]
                }
            } else {
                if (mediaFile) {
                    const fileType = mediaFile.type
                    const fileExtension = fileType.split("/")
                    fileExt = fileExtension[1]
                }
            }

            if (!fileExt) {
                handleErrorMessage("No file uploaded")
                return false
            }

            // get presigned url
            const result = await fileService.createSignedUrlForUploadingFile({fileExtension: fileExt})
            return result
        } catch (e) {
            handleError(e)
            return false
        }
    }

    const onClickCancelBtn = () => {
        if (!mediaLibraryData.id) {
            onClickBack()
        } else {
            setMediaLibraryData(cloneDeep(oldMediaLibraryData))
        }
    }

    const onClickBack = () => {
        const {history} = props
        history.push("/media-library")
    }

    if (mediaLibraryData.id) {
        isChanged = !!getDataSubmit()
    }

    const onRemoveFile = () => {
        setMediaFile(undefined)
        setImageSource(undefined)
    }

    const onRemoveImageThumbnail = () => {
        setVideoLinkThumbnail(null)
        setThumbnailFile(undefined)
    }

    const onChangeLink = (value: string) => {
        if (matchYoutubeUrl(value)) {
            value = value.split("&")[0]
        }
        setLink(value)
        onChangeData("details", {
            url: value
        })
        if (mediaLibraryData.id) {
            onChangeData("type", mediaLibraryData.type)
            setLinkThumbnailChanged(true)
        }
        getLinkPreview(value)
    }

    return (
        <Screen
            htmlTitle={t("settings:mediaLibrary.title")}
            header={{
                title: mediaLibraryData.id ? mediaLibraryData.title : t("settings:mediaLibrary.create"),
                backUrl: "/media-library"
            }}>
            <BaseLoading isShow={isLoading} />
            <div className={cx(styles.formGroup, styles.formGroupHalf)}>
                <FormLabel label="Type" isRequired />
                <KlassDropdown
                    options={MEDIA_LIBRARY_TYPES}
                    value={MEDIA_LIBRARY_TYPES.find((x) => x.name === type)}
                    onChange={(selectedValues) => {
                        setType(selectedValues?.name)
                        onChangeData("type", selectedValues.name)
                    }}
                    placeholder="Type"
                    readOnly={mediaLibraryData.id ? true : false}
                    error={!!errors["type"]}
                />
            </div>
            {isShowSubType && !mediaLibraryData?.id && (
                <div className={cx(styles.formGroup, styles.formGroupHalf)}>
                    <Radio.Group
                        name="radio-sub-type"
                        className={styles.radioGroup}
                        onChange={(event) => setSubType(event.target.value)}
                        value={subType}>
                        <Radio className={cx(styles.radioItem, styles.radioItemBorderRight)} value={"file"}>
                            {t("settings:mediaLibrary.uploadFile")}
                        </Radio>
                        <Radio className={styles.radioItem} value={"link"}>
                            {t("settings:mediaLibrary.pasteLink")}
                        </Radio>
                    </Radio.Group>
                </div>
            )}
            <FileCardHandler
                mediaLibraryData={mediaLibraryData}
                itemType={type}
                itemSubType={subType}
                link={link}
                videoLinkThumbnail={videoLinkThumbnail}
                imageSource={imageSource}
                mediaFile={mediaFile}
                errors={errors}
                onChangeLink={onChangeLink}
                onRemoveFile={onRemoveFile}
                onRemoveImageThumbnail={onRemoveImageThumbnail}
                onDropFiles={onDropFiles}
                onDropThumbnails={onDropThumbnails}
            />
            <div className={cx(styles.formGroup, styles.borderTop)}>
                <FormLabel label="Title" isRequired />
                <BaseInput
                    value={title}
                    onChange={(newValue) => {
                        setTitle(newValue)
                        onChangeData("title", newValue)
                    }}
                    placeholder="Title"
                    error={!!errors["title"]}
                />
            </div>
            <div className={styles.formGroup}>
                <FormLabel label="Description" />
                <BaseInput
                    value={description}
                    onChange={(newValue) => {
                        setDescription(newValue)
                        onChangeData("description", newValue)
                    }}
                    placeholder="Description"
                />
            </div>
            <div className={cx(styles.formGroup, styles.formGroup2)}>
                <div className={styles.formGroup2Div}>
                    <FormLabel label="User Type" isRequired />
                    <KlassDropdownAsync
                        value={userType}
                        valueKey="id"
                        onChange={(selectedValues) => {
                            setUserType(selectedValues ?? [])
                            onChangeData(
                                "userTypes",
                                selectedValues?.map((item) => {
                                    return item.name
                                }) ?? []
                            )
                        }}
                        defaultOptions={userTypes}
                        isMulti
                        placeholder="Select"
                        error={!!errors["userTypes"]}
                    />
                </div>
                <div className={styles.formGroup2Div}>
                    <FormLabel label="State" isRequired />
                    <KlassDropdownAsync
                        value={state}
                        valueKey="id"
                        onChange={(selectedValues) => {
                            setState(selectedValues ?? [])
                            onChangeData(
                                "userStates",
                                selectedValues?.map((item) => {
                                    return item.id
                                }) ?? []
                            )
                        }}
                        defaultOptions={states}
                        isMulti
                        placeholder="Select"
                        error={!!errors["userStates"]}
                    />
                </div>
            </div>
            <div className={styles.formGroup}>
                <FormLabel label="Campus" isRequired />
                <CampusSelect
                    isClearable
                    isMulti
                    displayOnlyCampusIds={allUserCampusIds}
                    displayOnlyActiveCampuses
                    value={campus}
                    onChange={(options) => {
                        setCampus(options ?? [])
                        onChangeData(
                            "campusIds",
                            options?.map((item) => {
                                return parseInt(item.id)
                            }) ?? []
                        )
                        onChangeData("startingTermIds", [])
                        setStartingTerm([])
                    }}
                    error={!!errors["campusIds"]}
                />
            </div>
            <div className={styles.formGroup}>
                <FormLabel label={"Starting Term"} />
                <KlassDropAsyncPaginate
                    isMulti
                    onChange={(selectedValues) => {
                        setStartingTerm(selectedValues ?? [])
                        onChangeData(
                            "startingTermIds",
                            selectedValues?.map((item) => {
                                return parseInt(item.id)
                            }) ?? []
                        )
                    }}
                    getOptionLabel={(option: any) => (option.code ? ` ${option.name} (${option.code})` : option.name)}
                    loadOptions={onTermSearchChange}
                    value={startingTerm}
                    isClearable
                    cacheUniqs={[campus]}
                />
            </div>
            <div className={styles.formGroup}>
                <FormLabel label="Department" />
                <DepartmentSubunitSelect
                    isMulti
                    isClearable
                    placeholder="Select"
                    defaultOptions={departmentSubunits}
                    value={departmentSubunits}
                    onChange={(subunits?: DepartmentSubunit[]) => {
                        setDepartmentSubunits(subunits ?? [])
                        onChangeData("departmentSubunitIds", subunits?.map((subunit) => subunit.subunitId) ?? [])
                    }}
                />
            </div>
            <div className={styles.formGroup}>
                <FormLabel label="Degree Level" />
                <DegreeLevelSelect
                    isClearable
                    isMulti
                    value={degreeLevel}
                    onChange={(options) => {
                        setDegreeLevel(options ?? [])
                        onChangeData(
                            "degreeLevelIds",
                            options?.map((item) => {
                                return parseInt(item.degreeLevelId)
                            }) ?? []
                        )
                    }}
                />
            </div>
            <div className={styles.formGroup}>
                <FormLabel label="Program" />
                <ProgramSelect
                    isClearable
                    isMulti
                    value={major}
                    onChange={(options) => {
                        setMajor(options ?? [])
                        onChangeData(
                            "programIds",
                            options?.map((item) => {
                                return parseInt(item.id)
                            }) ?? []
                        )
                    }}
                />
            </div>
            <div className={styles.formGroup}>
                <FormLabel label="Tag" isRequired />
                <TagSelect
                    isClearable
                    isMulti
                    value={tag}
                    onChange={(options = []) => {
                        setTag(options ?? [])
                        onChangeData(
                            "tags",
                            options.map(({name, tagId, isNew}) => (isNew ? {name} : {name, tagId}))
                        )
                    }}
                    error={!!errors["tags"]}
                    creatable
                />
            </div>
            <div className={styles.footerWrap}>
                {mediaLibraryData.id && (
                    <div className={styles.footerInfoWrap}>
                        <span className={styles.footerInfo}>
                            Created on <span>{formatDate(mediaLibraryData.createdAt)}.</span>
                            {mediaLibraryData.updatedByProfile ? (
                                <>
                                    Last modified on{" "}
                                    <span>{moment(mediaLibraryData.updatedAt).format("MM/DD/YYYY hh:mm A")}</span> by{" "}
                                    <span> {getFullName(mediaLibraryData.updatedByProfile)}</span>
                                </>
                            ) : (
                                ""
                            )}
                        </span>
                    </div>
                )}
                <div className={styles.actionDetailWrap}>
                    {isChanged && (
                        <SecondaryButton
                            title={t("common:action.cancel")}
                            className={styles.cancelBtn}
                            onClick={onClickCancelBtn}
                        />
                    )}
                    <BaseButton
                        title={t("common:action.save")}
                        uppercase
                        isActive={isChanged}
                        loading={isSubmitting}
                        onClick={onClickSave}
                    />
                </div>
            </div>
            <canvas ref={canvasRef} style={{display: "none"}} />
            <BaseLoading isShow={isSubmitting} />
        </Screen>
    )
}

export default withTranslation(["settings", "common"])(MediaLibrary)
