import {Timestamps} from "types/common"
import {Department} from "types/departments"
import {Campus} from "types/campus"
import {FundSource} from "types/fin-aid/fund-source"

export enum BankAccountType {
    Checking = "checking",
    Saving = "saving",
    MoneyMarketAccount = "money_market_account",
    CertificateOfDeposit = "certificate_of_deposit"
}

export type BankAccount = Timestamps & {
    accountId: number
    position: number
    name: string
    accountType: BankAccountType
    accountNumber: string
    routingNumber?: string | null
    address?: string | null
    phoneNumber?: string | null
}
