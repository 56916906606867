/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {Icon, IconType} from "components/Icon"
import styles from "./Card.module.css"

type Props = {
    info: Array<{
        icon: IconType
        title: string
        color?: string
        value: string
    }>
}

export function CardInfo(props: Props) {
    return (
        <div className={styles.wrap}>
            {props.info.map((info, index) => (
                <div className={styles.card} key={index}>
                    <Icon color={info.color} icon={info.icon} className={styles.icon} />
                    <p className={styles.title}>{info.title}</p>
                    <p className={styles.value}>{info.value}</p>
                </div>
            ))}
        </div>
    )
}
