import {v4 as uuid} from "uuid"
import {get, head} from "lodash"
import {DigitalDocumentFieldSetUp, DigitalField} from "types/tasks"

export const duplicateField = (
    fields: DigitalField[],
    fieldToDuplicate: DigitalField
): {newFields: DigitalField[]; newField: DigitalField} => {
    switch (fieldToDuplicate.type) {
        case DigitalDocumentFieldSetUp.TEXT:
            return duplicateTextField(fields, fieldToDuplicate)
        case DigitalDocumentFieldSetUp.SIGNATURE:
            return duplicateSignatureField(fields, fieldToDuplicate)
        case DigitalDocumentFieldSetUp.CHECKBOX:
        case DigitalDocumentFieldSetUp.INITIAL:
            return duplicateCheckboxOrInitialField(fields, fieldToDuplicate)
        default:
            throw new Error("Unsupported field type")
    }
}

const duplicateTextField = (
    fields: DigitalField[],
    field: DigitalField
): {newFields: DigitalField[]; newField: DigitalField} => {
    const newField: DigitalField = {
        ...field,
        id: uuid(),
        placeInformation: {
            ...field.placeInformation,
            top: field.placeInformation.top + field.placeInformation.height * 1.5
        }
    }
    return {newFields: [...fields, newField], newField}
}

const duplicateSignatureField = (
    fields: DigitalField[],
    field: DigitalField
): {newFields: DigitalField[]; newField: DigitalField} => {
    const newFields = [...fields]
    const dateField = fields.find(
        ({parentId, type}) => parentId === field.id && type === DigitalDocumentFieldSetUp.DATE_FIELD
    )
    const newField = {
        ...field,
        id: uuid(),
        placeInformation: {
            ...field.placeInformation,
            top: field.placeInformation.top + field.placeInformation.height * 1.5
        }
    }
    newFields.push(newField)
    if (dateField) {
        const newDateField = {
            ...dateField,
            id: uuid(),
            parentId: newField.id,
            parentSignatureTypeId: get(newField, "signatureTypes[0].id", null),
            placeInformation: {
                ...dateField.placeInformation,
                top: dateField.placeInformation.top + dateField.placeInformation.height * 1.5
            }
        }
        newFields.push(newDateField)
    }
    return {newFields: fields, newField}
}

const duplicateCheckboxOrInitialField = (
    fields: DigitalField[],
    field: DigitalField
): {newFields: DigitalField[]; newField: DigitalField} => {
    const newField = {
        ...field,
        id: uuid(),
        placeInformation: {
            ...field.placeInformation,
            top: field.placeInformation.top + field.placeInformation.height * 1.5
        }
    }
    const newChildFields = fields
        .filter((childField) => childField.parentId && childField.parentId === field.id)
        .map((childField) => {
            const oldChildFieldValue = field.fieldValues.find(({id}) => id === childField.id)
            return {
                ...childField,
                id: uuid(),
                label: get(oldChildFieldValue, "label", ""),
                parentId: newField.id,
                parentSignatureTypeId: get(newField, "signatureTypes[0].id", null),
                placeInformation: {
                    ...childField.placeInformation,
                    top: childField.placeInformation.top + childField.placeInformation.height * 1.5
                }
            }
        })
    const firstChildField = {
        ...(head(field.fieldValues) as any),
        id: uuid(),
        parentId: newField.id,
        parentSignatureTypeId: get(newField, "signatureTypes[0].id", null),
        placeInformation: {
            ...newField.placeInformation,
            top: newField.placeInformation.top + newField.placeInformation.height * 1.5
        }
    }
    newField.fieldValues = [firstChildField, ...newChildFields]
    const newFields = [...fields, newField, ...newChildFields]
    return {newFields, newField}
}
