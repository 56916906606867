import React, {useMemo, useState} from "react"
import {Droppable} from "react-beautiful-dnd"
import {Menu, Dropdown, Button, Popover} from "antd"
import {keyBy, get, isEmpty} from "lodash"
import cx from "classnames"
import moment from "moment"
import {AcademicPlans} from "types/academicPlans"
import {handleError} from "helpers"
import {academicPlansService} from "services"
import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import {useVisible} from "hooks"
import {Auth} from "types/auth"
import {Search} from "components/inputs/Search"
import {TermChooseCoursesPopup} from "../Popup"
import {SectionItem} from "./SectionItem"
import styles from "./TermItem.module.css"
import {Course} from "types/courses"

const getBackground = (isDraggingOver, canRegister) => {
    if (canRegister) {
        return isDraggingOver ? "var(--warning-300)" : "var(--warning-100)"
    }
    return isDraggingOver ? "#1f7fda" : "#a5d3ff"
}

const getListStyle = (isDraggingOver, canRegister) => ({
    background: getBackground(isDraggingOver, canRegister),
    padding: 8,
    minHeight: 100
})

type Props = {
    index: number
    isStaff: boolean
    studentId: number
    student: Auth.DepartmentStudent
    termItem: AcademicPlans.Term
    registeringTerm: AcademicPlans.Term
    availableTerms: AcademicPlans.AvailableTerm[]
    selectedAvailableTermIds: number[]
    conflictEvents: any[]
    onUpdateTermItem: (index: number, newTerm: AcademicPlans.Term) => void
    onSelectAvailableTerm: (termId: number, availableTerm: AcademicPlans.AvailableTerm) => void
    reloadTerm: () => void
    updateEvents: (data: AcademicPlans.UpdateEvents[]) => void
    onRegisterCourseSuccess: () => void
    changeActiveRegistrationTerm: (term: AcademicPlans.Term) => void
}

export function TermItem(props: Props) {
    const {
        index,
        isStaff,
        studentId,
        student,
        termItem,
        availableTerms,
        registeringTerm,
        selectedAvailableTermIds,
        conflictEvents,
        onSelectAvailableTerm,
        reloadTerm,
        updateEvents,
        onRegisterCourseSuccess,
        changeActiveRegistrationTerm
    } = props
    const [search, setSearch] = useState("")
    const addCoursesPopup = useVisible(false)
    const {sections} = termItem
    const canTermRegister =
        (termItem.isCurrentSelect || termItem.termId) &&
        moment().startOf("date").isSameOrAfter(moment(termItem.registration_start_date).startOf("date")) &&
        moment().endOf("date").isSameOrBefore(moment(termItem.registration_end_date).endOf("date"))
    const isCurrentActiveTerm =
        registeringTerm && registeringTerm.termId === termItem.termId && registeringTerm.id === termItem.id
    const isCurrentRegistrationTerm = canTermRegister && isCurrentActiveTerm
    const newAvailableTerms = availableTerms.filter((term) => !selectedAvailableTermIds.includes(term.id))
    const searchAvailableTerms = search
        ? newAvailableTerms.filter(
              (term) =>
                  term.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
                  term.code.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          )
        : newAvailableTerms

    const hasCourseRegistered = useMemo(
        () =>
            termItem.sections.some((section) =>
                section.courses.some(
                    (course) => course.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Registered
                )
            ),
        [termItem]
    )

    let totalCreditHour = 0
    let totalEach = 0
    termItem.sections.forEach((section) => {
        section.courses.forEach((course) => {
            if (course.courseTotalUnits === Course.Unit.Hour) {
                totalCreditHour += course.credit || 0
            } else {
                totalEach += course.credit || 0
            }
        })
    })

    const removeTerm = async () => {
        try {
            await academicPlansService.removeTerm({academicPlanTermId: termItem.id})
            reloadTerm()
        } catch (error) {
            handleError(error)
        }
    }

    const onClickAddCourses = () => {
        addCoursesPopup.open()
    }

    const renderTermChooseMenuOptions = () => {
        return (
            <div>
                <div className={styles.searchWrap}>
                    <Search value={search} onChange={setSearch} placeholder="Search" />
                </div>
                <div className={styles.menuActionBody}>
                    {isEmpty(searchAvailableTerms) ? (
                        <div>Not result found</div>
                    ) : (
                        searchAvailableTerms.map((term, index) => (
                            <div
                                key={index}
                                className={styles.menuActionItem}
                                onClick={() => {
                                    onSelectAvailableTerm(termItem.id, term)
                                }}>
                                {term.name} ({term.code})
                            </div>
                        ))
                    )}
                </div>
            </div>
        )
    }

    const renderMenuOptions = () => {
        if (termItem.termId) {
            return (
                <Menu className={styles.menuActionWrap}>
                    {!hasCourseRegistered && (
                        <Menu.Item
                            key="removeSelection"
                            className={styles.menuActionItem}
                            onClick={(event) => {
                                event.domEvent.stopPropagation()
                                onSelectAvailableTerm(termItem.id, null)
                            }}>
                            <Icon className={styles.menuActionIcon} icon="CROSS_CIRCLE" />
                            <span className={styles.menuActionTitle}>Unselect Term</span>
                        </Menu.Item>
                    )}
                    <Menu.Item
                        key="delete"
                        className={styles.menuActionItem}
                        onClick={(event) => {
                            event.domEvent.stopPropagation()
                            removeTerm()
                        }}>
                        <Icon className={styles.menuActionIcon} icon="DELETE" />
                        <span className={styles.menuActionTitle}>Delete</span>
                    </Menu.Item>
                </Menu>
            )
        }
        return (
            <Menu className={styles.menuActionWrap}>
                <Menu.Item key="delete" className={styles.menuActionItem} onClick={removeTerm}>
                    <Icon className={styles.menuActionIcon} icon="DELETE" />
                    <span className={styles.menuActionTitle}>Delete</span>
                </Menu.Item>
            </Menu>
        )
    }

    const renderTermTitle = () => {
        return (
            <div
                className={cx(styles.termTitle, {
                    [styles.termTitle__register]: isCurrentActiveTerm
                })}
                onClick={(event) => {
                    event.stopPropagation()
                }}>
                {termItem.termId ? termItem.title : `Term ${index + 1}`}
            </div>
        )
    }

    const onClickTermHeader = () => {
        if (termItem.termId) {
            changeActiveRegistrationTerm(termItem)
        }
    }

    const renderHeaderSelection = () => {
        if (isCurrentActiveTerm) {
            return <div className={styles.termSelectedLabel}>Term selected</div>
        }
        if (termItem.termId) {
            return (
                <div className={styles.termNotSelectedLabel} onClick={onClickTermHeader}>
                    Click to select a term
                </div>
            )
        }
        return <div style={{height: 19}}></div>
    }

    const renderHeader = () => {
        return (
            <div
                className={cx(styles.termHeader, {
                    [styles.termHeader__register]: canTermRegister,
                    [styles.termHeader__activeTerm]: isCurrentActiveTerm && !canTermRegister,
                    [styles.termHeader__activeRegistration]: isCurrentRegistrationTerm
                })}
                onClick={onClickTermHeader}>
                <div>
                    {newAvailableTerms.length > 0 ? (
                        <Popover content={renderTermChooseMenuOptions} trigger="click">
                            <div className={styles.chooseTitleWrap}>
                                {renderTermTitle()}
                                <span className={styles.chooseTitle__icon}>
                                    <Icon icon="CHEVRON_DOWN" color="#fff" />
                                </span>
                            </div>
                        </Popover>
                    ) : (
                        renderTermTitle()
                    )}
                    <div>
                        {isCurrentRegistrationTerm && <span className={styles.registerOpen}>Registration open</span>}
                        {renderHeaderSelection()}
                    </div>
                </div>
                <div className={styles.termHeaderRight}>
                    <div className={styles.hourInfo}>
                        <div className={cx(styles.hourInfoItem)}>
                            <span className={styles.hourInfoItemTitle}>Credit</span>
                            <span className={styles.hourInfoItemValue}>{totalCreditHour}</span>
                        </div>
                        <div
                            className={cx(styles.hourInfoItem, {
                                [styles.hourInfoItem__register]: isCurrentActiveTerm
                            })}>
                            <span className={styles.hourInfoItemTitle}>Each</span>
                            <span className={styles.hourInfoItemValue}>{totalEach}</span>
                        </div>
                    </div>
                    <Dropdown overlay={renderMenuOptions} trigger={["click"]}>
                        <Button
                            size="small"
                            className={styles.iconBtn}
                            icon={<Icon icon="MORE_VERTICAL" color="#fff" className={styles.moreIcon} />}
                            onClick={(event) => {
                                event.stopPropagation()
                            }}
                        />
                    </Dropdown>
                </div>
            </div>
        )
    }

    const renderBody = () => {
        const availableTermsKeyById = keyBy(availableTerms, "id")
        const availableCourses = termItem.termId ? get(availableTermsKeyById, [termItem.termId, "courses"], []) : []
        return (
            <div className={styles.termItemBodyWrap}>
                {sections.map((termSection, sectionIndex) => (
                    <SectionItem
                        key={sectionIndex}
                        studentId={studentId}
                        student={student}
                        index={sectionIndex}
                        termItem={termItem}
                        termSection={termSection}
                        canTermRegister={isCurrentRegistrationTerm}
                        conflictEvents={conflictEvents}
                        availableCourses={availableCourses}
                        registeringTerm={registeringTerm}
                        reloadView={reloadTerm}
                        updateEvents={updateEvents}
                        onRegisterCourseSuccess={onRegisterCourseSuccess}
                    />
                ))}
                <Droppable droppableId={`termSectionEmpty_${termItem.id || index}`} dropDisabled>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver, isCurrentRegistrationTerm)}>
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }

    const renderFooter = () => {
        if (!isStaff) {
            return null
        }
        return (
            <div className={styles.termItemFooter}>
                <BaseButton title="Add Courses" variant="secondary" onClick={onClickAddCourses} />
            </div>
        )
    }

    return (
        <div
            className={cx(styles.termItem, {
                [styles.termItem__register]: isCurrentRegistrationTerm
            })}>
            <div className={styles.termBody}>
                {renderHeader()}
                {renderBody()}
                {renderFooter()}
            </div>
            {addCoursesPopup.isVisible && (
                <TermChooseCoursesPopup
                    isShow={addCoursesPopup.isVisible}
                    onClose={addCoursesPopup.close}
                    academicPlanTermId={termItem.id}
                    studentId={studentId}
                    onSaveSuccess={reloadTerm}
                />
            )}
        </div>
    )
}
