/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import {Row, Col, Tooltip, Menu, Dropdown} from "antd"
import {uniq, head} from "lodash"
import {useHistory} from "react-router-dom"
import {Icon} from "components/Icon"
import {getFieldLabel, getFullNameOrPreferredName} from "helpers"
import {useModel} from "hooks"
import {Auth} from "types/auth"
import styles from "./StudentInfo.module.css"
import {get} from "lodash"
import {Settings} from "types/settings"
import {UserType} from "types/tasks"
import {getFirstProfile} from "helpers/task"

type Props = {
    user: Auth.UserProfile
    renderExtraPhoneEmail?: (props) => JSX.Element
    renderLeftInfo?: (props) => JSX.Element
    renderRightInfo?: (props) => JSX.Element
}

export function OtherInfo(props: Props) {
    const {user, renderExtraPhoneEmail, renderLeftInfo, renderRightInfo} = props
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [activeProfileId, setActiveProfileId] = useState(null)
    const [activeProfile, setActiveProfile] = useState(null)
    const {t} = useTranslation(["common", "chat", "financialAid"])
    const history = useHistory()
    const model = useModel()
    const otherProfiles = (user?.profiles || []).filter((profile) => profile.type === Auth.UserProfileType.Others)

    useEffect(() => {
        const otherProfile = getFirstProfile(user, Auth.UserProfileType.Others)
        setActiveProfileId(otherProfile?.id)
        setActiveProfile(otherProfile)
    }, [user])

    if (!activeProfileId) {
        return <div>Not found</div>
    }

    const userId = user?.id
    const studentCustomUserId = get(user, "customUserId")

    const toggleShowDetail = () => {
        setIsShowDetail(!isShowDetail)
    }

    const goToUserDetail = () => {
        if (userId) {
            history.push({
                pathname: `/users/${userId}`,
                state: {edit: true, defaultRole: UserType.OTHERS}
            })
        }
    }

    const goCalendar = () => {
        history.push("/calendar")
    }

    if (!user) {
        return <div>Not found</div>
    }

    let campuses = activeProfile.campuses?.map((campus) => campus.name).filter((item) => item) ?? []
    campuses = uniq(campuses)
    const phones = user.phones?.map(({phone}) => phone) ?? []
    const emails = user.emails?.map(({email}) => email) ?? []

    const renderAvatar = () => {
        let photo = "/image/DefaultAvatar.png"
        if (user.photo) {
            photo = user.photo["128"]
        }
        return (
            <div>
                <div className={styles.imgPadding}>
                    <img src={photo} className={styles.staffAvatar} alt="" />
                </div>
            </div>
        )
    }

    const onChangeActiveProfileId = (profileId) => {
        setActiveProfileId(profileId)
    }

    const renderListProfileIds = () => {
        return (
            <Menu className={styles.menuActionWrap}>
                {otherProfiles.map((profile, index) => (
                    <Menu.Item
                        key={index}
                        className={styles.menuActionItem}
                        onClick={() => onChangeActiveProfileId(profile.id)}>
                        <span>{profile.customProfileId}</span>
                    </Menu.Item>
                ))}
            </Menu>
        )
    }

    const renderHeader = () => {
        return (
            <div className={styles.header}>
                <div className={styles.leftHeader}>
                    <p className={styles.studentName}>{getFullNameOrPreferredName(user)}</p>
                    <div className={styles.studentInfoHeader}>
                        <p className={styles.studentIdWrap}>
                            <span className={styles.studentId__title}>{t("studentInfo.userID")}:</span>
                            <span className={styles.studentId__value}>{studentCustomUserId}</span>
                        </p>
                        <div className={styles.studentIdWrap}>
                            <span className={styles.studentId__title}>{t("studentInfo.profileId")}:</span>
                            <Dropdown overlay={renderListProfileIds} trigger={["click"]}>
                                <div className={styles.enrollmentWrap}>
                                    <Icon icon="ARROW_DOWN" className={styles.arrowDownIcon} />
                                    <span className={cx(styles.studentId__value, styles.enrollmentId)}>
                                        {activeProfile.customProfileId}
                                    </span>
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                <div className={styles.rightHeader}>
                    <Tooltip title="Detail">
                        <div className={styles.rightHeaderIconWrap} onClick={goToUserDetail}>
                            <Icon icon="PROFILE_FILL_CIRCLE" color="#fff" className={styles.rightHeaderIcon} />
                        </div>
                    </Tooltip>
                    <Tooltip title="Appointment">
                        <div className={styles.rightHeaderIconWrap} onClick={goCalendar}>
                            <Icon icon="APPOINTMENT_FILL_CIRCLE" color="#fff" className={styles.rightHeaderIcon} />
                        </div>
                    </Tooltip>
                </div>
            </div>
        )
    }

    const renderPhoneEmailStudent = () => {
        return (
            <>
                <div className={styles.studentPhoneEmail}>
                    <div className={styles.studentPhoneWrap}>
                        <div className={styles.studentPhoneIconWrap}>
                            <Icon icon="PHONE" className={styles.studentPhoneIcon} />
                        </div>
                        <div className={styles.studentPhoneInfo}>
                            {phones.map((phone, index) => (
                                <p key={index} className={styles.studentPhone__value}>
                                    {phone}
                                </p>
                            ))}
                        </div>
                    </div>
                    <div className={styles.studentEmailWrap}>
                        <div className={styles.studentPhoneIconWrap}>
                            <Icon icon="EMAIL" className={styles.studentPhoneIcon} />
                        </div>
                        <div className={styles.studentPhoneInfo}>
                            {emails.map((email, index) => (
                                <p key={index} className={styles.studentPhone__value}>
                                    {email}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.studentAddress}>
                    <div className={styles.studentPhoneIconWrap}>
                        <Icon icon="LOCATION_FILL" className={styles.studentPhoneIcon} />
                    </div>
                    <div className={styles.studentPhoneInfo}>
                        <p className={styles.studentPhone__value}>{user.address}</p>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={styles.userWrap}>
            <Row gutter={[24, 24]}>
                <Col span={3}>{renderAvatar()}</Col>
                <Col span={21}>
                    <div className={styles.body}>
                        {renderHeader()}
                        <div className={styles.content}>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>{t("studentInfo.title")}</span>
                                <span className={styles.content__value}>{activeProfile.title}</span>
                            </div>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>
                                    {getFieldLabel(model, Settings.GeneralLabel.Campus, t("studentInfo.campus"))}
                                </span>
                                <div className={cx(styles.content__value, styles.campusWrap)}>
                                    {campuses.map((campus, index) => (
                                        <span key={index} className={styles.campusName}>
                                            {campus}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>{t("studentInfo.role")}</span>
                                <span className={styles.content__value}>
                                    {(activeProfile.roles || []).map((role) => role.name).join(", ")}
                                </span>
                            </div>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>{t("studentInfo.department")}</span>
                                <span className={styles.content__value}>
                                    {activeProfile.departmentSubunits
                                        .map((departmentSubunit) => departmentSubunit.name)
                                        .join(", ")}
                                </span>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={cx(styles.hideDetailWrap, styles.hideStaffDetailWrap)}>
                                <div className={styles.hideDetailBtn} onClick={toggleShowDetail}>
                                    <span className={styles.hideDetail__text}>
                                        {isShowDetail ? t("studentInfo.hideDetails") : t("studentInfo.showDetails")}
                                    </span>
                                    <div
                                        className={cx(styles.hideDetailIconWrap, {
                                            [styles.hideDetailIcon__collapse]: isShowDetail
                                        })}>
                                        <Icon
                                            icon="CHEVRON_DOWN"
                                            color="var(--primary-400-base)"
                                            className={styles.hideDetailIcon}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isShowDetail && (
                            <div className={styles.detailBody}>
                                {renderLeftInfo ? (
                                    renderLeftInfo({user})
                                ) : (
                                    <div className={styles.detailBodyLeft}>
                                        <div>{renderPhoneEmailStudent()}</div>
                                        {renderExtraPhoneEmail && renderExtraPhoneEmail({user})}
                                    </div>
                                )}
                                {renderRightInfo ? (
                                    renderRightInfo({user})
                                ) : (
                                    <div className={cx(styles.detailBodyRight, styles.detailBodyRight__empty)} />
                                )}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    )
}
