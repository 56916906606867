/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {cloneDeep, get, head, isEmpty, keyBy, pick} from "lodash"
import {useHistory} from "react-router-dom"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {Icon} from "components/Icon"
import {PDFViewer} from "components/FileViewer"
import {KlassappTableHOC} from "HOC"
import {taskService} from "services"
import {checkDepartmentPermission, getFullName, handleError} from "helpers"
import {useIsMounted, useModel, useVisible} from "hooks"
import {TaskAction, TaskActionFor, TaskStatus, TaskSubtype, TaskType} from "types/tasks"
import {Status} from "sections/Tasks/Common"
import {ConfirmPopup, RemindPopup} from "../"
import styles from "./ResponsesTable.module.css"
import {Auth} from "types/auth"
import {DepartmentPermissionTarget, PermissionType} from "@edular/permissions"
import ReassignPopup from "../ReassignPopup"

function ResponsesTable(props) {
    const [checkedData, setCheckedData] = useState([])
    const [subTasks, setSubTasks] = useState([])
    const [fileUrls, setFileUrls] = useState([])
    const [confirmPopupType, setConfirmPopupType] = useState("")
    const [confirmPopupActionFor, setConfirmPopupActionFor] = useState("")
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        isLoadedTableFuncs,
        getCurrentData,
        taskData,
        filter,
        userId,
        isReget,
        type,
        openedSubTaskId,
        onChangeOpenedSubTaskId,
        canAccessTaskOther,
        getCurrentPage,
        getCurrentPageSize
    } = props
    const {t} = useTranslation(["tasks", "common"])
    const isMounted = useIsMounted()
    const confirmPopup = useVisible(false)
    const remindPopup = useVisible(false)
    const reassignPopup = useVisible(false)
    const model = useModel()
    const history = useHistory()
    const taskType = taskData.type || type
    const isDigitalDocument = taskType === TaskType.DIGITAL_DOCUMENT || taskType === TaskType.FORM
    const isDraftDigitalTask = isDigitalDocument && (taskData.status === TaskStatus.DRAFT || taskData.isDraft)

    const getFields = () => {
        if (isDraftDigitalTask) {
            return ["SubTask code", "Responses", "User", "Enrollment ID", "Role", "Email"]
        }
        return ["SubTask code", "Responses", "User", "Enrollment ID", "Role", "Email", "Status", "Submit Date"]
    }

    const getPageTitle = () => {
        return "Task"
    }

    const canReassignTask = React.useMemo(() => {
        return (
            checkDepartmentPermission(
                model,
                taskData.department.departmentId,
                taskData.department.subunitId,
                DepartmentPermissionTarget.TasksReassign,
                PermissionType.Edit
            ) &&
            checkDepartmentPermission(
                model,
                taskData.department.departmentId,
                taskData.department.subunitId,
                DepartmentPermissionTarget.TasksAccessOthers,
                PermissionType.View
            )
        )
    }, [model, taskData])

    const canReopenCompleted = React.useMemo(() => {
        return checkDepartmentPermission(
            model,
            taskData.department.departmentId,
            taskData.department.subunitId,
            DepartmentPermissionTarget.TasksReopenCompleted,
            PermissionType.Edit
        )
    }, [model, taskData])

    const getTableHeaderActions = () => {
        if (isDraftDigitalTask) {
            return []
        }
        const isCreator = userId && get(taskData, "createdByUser.userId", "") === userId
        const approvalUserIds = (taskData.cc || []).map((approval) => approval.userId)
        const isApprovalUser = approvalUserIds.includes(userId)
        const isAdmin = model.isAdmin()
        const canShowWithUser = isCreator || isApprovalUser || isAdmin || canAccessTaskOther
        const data = getCurrentData()
        const checkedData = data
            .filter((task) => task.isChecked)
            .map((task) => pick(task, ["status", "id", "parentId", "type"]))
        const isSubtaskCompleted =
            data.filter((d) => d.parentId === checkedData?.[0]?.parentId).every((d) => d.status === TaskStatus.DONE) ||
            data.filter((d) => d.parentId === checkedData?.[0]?.parentId).every((d) => d.status === TaskStatus.REJECTED)
        const isShowApproveReject =
            canShowWithUser &&
            checkedData.every((item) => item.status === TaskStatus.AWAITING_APPROVAL && !item.parentId) &&
            !taskData.autoApprove
        const isExistedParent = checkedData.some((item) => !item.parentId)
        const isExistedChild = checkedData.some((item) => !!item.parentId)
        if (isExistedParent && isExistedChild) {
            return []
        }
        const isShowReject = checkedData.every((item) => {
            if (!item.parentId) {
                return (
                    canShowWithUser &&
                    (item.status === TaskStatus.AWAITING_APPROVAL ||
                        item.status === TaskStatus.STARTED ||
                        item.status === TaskStatus.TODO ||
                        item.status === TaskStatus.OVERDUE)
                )
            }
            return userId === item.userId && item.status === TaskStatus.TODO
        })
        const canReopenInProgress =
            canShowWithUser &&
            !isExistedParent &&
            checkedData.every(
                (item) =>
                    item.parentId &&
                    (item.status === TaskStatus.DONE ||
                        item.status === TaskStatus.REJECTED ||
                        item.status === TaskStatus.AWAITING_APPROVAL)
            )
        const isShowReopen = (isSubtaskCompleted ? canReopenCompleted : canReopenInProgress) && !isExistedParent
        const isShowRemind =
            canShowWithUser &&
            !isExistedParent &&
            checkedData.every((item) => [TaskStatus.OVERDUE, TaskStatus.TODO].includes(item.status) && item.parentId)
        const isShowReassign =
            (isCreator || canReassignTask || isApprovalUser) &&
            !isExistedParent &&
            checkedData.every(
                (item) =>
                    [TaskStatus.OVERDUE, TaskStatus.TODO, TaskStatus.STARTED].includes(item.status) &&
                    item.parentId &&
                    item.type === Auth.UserProfileType.Staff
            )

        const tableHeaderActions = []
        if (isShowApproveReject) {
            tableHeaderActions.push({
                title: t("common:action.approve"),
                icon: "CHECKMARK_CIRCLE",
                iconClassName: styles.approveIcon,
                action: () => onClickApprove()
            })
        }
        if (isShowReject) {
            tableHeaderActions.push({
                title: t("common:action.notApproved"),
                icon: "CROSS_CIRCLE_FILL",
                iconClassName: styles.rejectIcon,
                action: () => onClickReject()
            })
        }
        if (isShowReopen) {
            tableHeaderActions.push({
                title: t("common:action.reopen"),
                icon: "PERSON_DONE",
                action: () => onClickReopen()
            })
        }
        if (isShowRemind) {
            tableHeaderActions.push({
                title: t("common:action.remind"),
                icon: "BULB",
                action: () => onClickRemind()
            })
        }
        if (isShowReassign) {
            tableHeaderActions.push({
                title: t("common:action.reassign"),
                icon: "DUPLICATE",
                action: () => onClickReassign()
            })
        }
        return tableHeaderActions
    }

    const onClickRemind = async () => {
        const data = getCurrentData()
        const checkedData: any[] = data.filter((item) => item.isChecked)
        setCheckedData(checkedData)
        remindPopup.open()
        try {
            const {taskUserId} = head(checkedData)
            await taskService.remindTaskUser({taskUserIds: [taskUserId]})
        } catch (error) {
            handleError(error)
        }
    }

    const onClickReassign = async () => {
        const data = getCurrentData()
        const checkedData = data.filter((item) => item.isChecked)
        setCheckedData(checkedData)
        reassignPopup.open()
    }

    const reassignTask = async (userId: number) => {
        if (!checkedData[0]) return
        try {
            const taskUserIds = checkedData.map((item) => item.taskUserId)
            const promises = []
            taskUserIds.forEach((taskUserId) => promises.push(taskService.reassignTaskUser({taskUserId, userId})))
            await Promise.all(promises)
            reassignPopup.close()
            await getData()
        } catch (error) {
            handleError(error)
        }
    }

    const onClickApprove = () => {
        const data = getCurrentData()
        const checkedData = data.filter((task) => task.isChecked)
        const isSubTaskExisted = checkedData.some((item) => !item.parentId)
        if (isSubTaskExisted) {
            setCheckedData(checkedData)
            setConfirmPopupActionFor(TaskActionFor.SUBTASK)
        } else {
            const listTasks = data.filter(
                (item) =>
                    item.id === checkedData[0].parentSubTaskId ||
                    item.parentSubTaskId === checkedData[0].parentSubTaskId
            )
            setCheckedData(listTasks)
            setConfirmPopupActionFor(TaskActionFor.TASK_USER)
        }
        setConfirmPopupType(TaskAction.APPROVE)
        confirmPopup.open()
    }

    const onClickReject = () => {
        const data = getCurrentData()
        const checkedData = data.filter((task) => task.isChecked)
        const isSubTaskExisted = checkedData.some((item) => !item.parentId)
        if (isSubTaskExisted) {
            setCheckedData(checkedData)
            setConfirmPopupActionFor(TaskActionFor.SUBTASK)
        } else {
            const listTasks = data.filter(
                (item) =>
                    item.id === checkedData[0].parentSubTaskId ||
                    item.parentSubTaskId === checkedData[0].parentSubTaskId
            )
            setCheckedData(listTasks)
            setConfirmPopupActionFor(TaskActionFor.TASK_USER)
        }
        setConfirmPopupType(TaskAction.REJECT)
        confirmPopup.open()
    }

    const onClickReopen = () => {
        const data = getCurrentData()
        const checkedData = data.filter((task) => task.isChecked)
        const isDigitalDocument = taskData.type === TaskType.DIGITAL_DOCUMENT || taskData.type === TaskType.FORM
        if (isDigitalDocument) {
            const taskUser: any = head(checkedData)
            history.push(
                `/tasks/remarks?taskId=${taskUser.taskId}&subtaskId=${taskUser.subTaskId}&taskUserId=${taskUser.taskUserId}&type=dd`
            )
        } else {
            const listTasks = data.filter(
                (item: any) =>
                    item.id === checkedData[0].parentSubTaskId ||
                    item.parentSubTaskId === checkedData[0].parentSubTaskId
            )
            setCheckedData(listTasks)
            setConfirmPopupActionFor(TaskActionFor.TASK_USER)
            setConfirmPopupType(TaskAction.REOPEN)
            confirmPopup.open()
        }
    }

    const getColumns = () => {
        return [
            {
                title: "SubTask code",
                field: "taskHtml",
                style: {minWidth: "200px"}
            },
            {
                title: "Responses",
                field: "responses",
                style: {minWidth: "100px"}
            },
            {
                title: "User",
                field: "userHtml",
                style: {minWidth: "150px"}
            },
            {
                title: "Enrollment ID",
                field: "enrollmentIdHtml",
                style: {minWidth: "130px"}
            },
            {
                title: "Role",
                field: "roleHtml",
                style: {minWidth: "100px"}
            },
            {
                title: "Email",
                field: "emailHtml",
                style: {minWidth: "200px", whiteSpace: "pre"}
            },
            {
                title: "Status",
                field: "statusHtml",
                style: {minWidth: "125px"}
            },
            {
                title: "Submit Date",
                field: "submittedAt",
                fieldType: "dateTime",
                style: {minWidth: "140px"}
            }
        ]
    }

    const onToggleRow = (event, rowItem) => {
        const data = getCurrentData()
        event.stopPropagation()
        if (rowItem.isOpenChild) {
            if (onChangeOpenedSubTaskId) {
                onChangeOpenedSubTaskId("", true)
            }
            const newData = data
                .filter((item) => item.parentSubTaskId !== rowItem.id)
                .map((item) => {
                    if (item.id === rowItem.id) {
                        item.isOpenChild = false
                    }
                    item.taskHtml = renderTaskHtml(item)
                    item.responses = renderResponsesHtml(item)
                    item.statusHtml = renderStatusHtml(item)
                    return item
                })
            props.dispatch({data: newData})
        } else {
            const canAnswer = rowItem.users.some((taskUser, index) =>
                checkSubTaskCanAnswer({subTask: rowItem, taskUser, index, taskUserOverdueIndex: -1})
            )
            if (onChangeOpenedSubTaskId) {
                onChangeOpenedSubTaskId(rowItem.subTaskId, canAnswer)
            }
            let findIndex = -1
            let childs = data.find((item, index) => {
                if (item.id === rowItem.id) {
                    findIndex = index
                    return true
                }
                return false
            }).users
            if (childs && childs.length) {
                const newData = cloneDeep(data)
                childs = childs.map((childItem) => {
                    childItem.cellTableStyle = styles.cellChildTableStyle
                    childItem.parentId = rowItem.subTaskId
                    return childItem
                })
                newData.splice(findIndex + 1, 0, ...childs)
                const subTasks = newData
                    .filter((item) => {
                        if (item.parentId && item.parentId !== rowItem.subTaskId) {
                            return false
                        }
                        return true
                    })
                    .map((item: any) => {
                        if (item.id === rowItem.id) {
                            item.subTaskId = rowItem.subTaskId
                            item.isOpenChild = true
                            item.userHtml = getFullName(rowItem.primaryUser)
                            item.enrollmentIdHtml = getEnrollmentIds(rowItem.primaryUser)
                            item.applicantIdHtml = getApplicantIds(rowItem.primaryUser)
                            item.emailHtml = [rowItem.primaryUser.email, rowItem.primaryUser.emailTwo]
                                .filter(Boolean)
                                .join("\n")
                            item.roleHtml = rowItem.roleHtml
                        } else if (item.parentId === rowItem.subTaskId) {
                            item.taskUserId = item.taskUserId || item.id
                            if (!item.parentSubTaskId || item.parentSubTaskId === rowItem.id) {
                                item.id = `${rowItem.id}_${item.id}`
                                item.parentSubTaskId = rowItem.id
                            }
                            item.userHtml = getFullName(item)
                            item.enrollmentIdHtml = getEnrollmentIds(item)
                            item.applicantIdHtml = getApplicantIds(item)
                            item.emailHtml = [item.email, item.emailTwo].filter(Boolean).join("\n")
                            item.roleHtml = item.type
                        } else if (item.parentId) {
                            item.userHtml = getFullName(item)
                            item.enrollmentIdHtml = getEnrollmentIds(item)
                            item.applicantIdHtml = getApplicantIds(item)
                            item.emailHtml = [item.email, item.emailTwo].filter(Boolean).join("\n")
                            item.roleHtml = item.type
                        } else {
                            item.isOpenChild = false
                            item.userHtml = getFullName(item.primaryUser)
                            item.enrollmentIdHtml = getEnrollmentIds(item.primaryUser)
                            item.applicantIdHtml = getApplicantIds(item.primaryUser)
                            item.emailHtml = [item.primaryUser.email, item.primaryUser.emailTwo]
                                .filter(Boolean)
                                .join("\n")
                        }
                        item.taskHtml = renderTaskHtml(item)
                        item.responses = renderResponsesHtml(item)
                        item.statusHtml = renderStatusHtml(item)
                        return item
                    })
                props.dispatch({data: subTasks, isLoading: false})
            }
        }
    }

    const renderTaskHtml = (subTask) => {
        return (
            <div className={styles.taskWrap}>
                {!subTask.parentId && (subTask.users || []).length ? (
                    <>
                        <div className={styles.arrowWrap} onClick={(e) => onToggleRow(e, subTask)}>
                            <Icon
                                icon={subTask.isOpenChild ? "ARROW_DOWN" : "ARROW_RIGHT"}
                                className={styles.arrowIcon}
                            />
                        </div>
                        <p className={styles.task__id}>{subTask.code || subTask.subTaskId}</p>
                    </>
                ) : (
                    <div className={styles.arrowWrap}></div>
                )}
            </div>
        )
    }

    const renderResponsesHtml = (subTask) => {
        return !subTask.parentId && (subTask.users || []).length ? <div>{subTask.responsesLabel}</div> : null
    }

    const getEnrollmentIds = ({customProfileId}) => {
        return (
            <div className={styles.enrollmentIdsContainer}>
                <span>{customProfileId}</span>
            </div>
        )
    }

    const getApplicantIds = ({customProfileId}) => {
        return (
            <div className={styles.enrollmentIdsContainer}>
                <span>{customProfileId}</span>
            </div>
        )
    }

    const renderStatusHtml = (subTask) => {
        return <Status status={subTask.status} doneWithRejected={subTask.rejected} />
    }

    const getParams = () => {
        const page = getCurrentPage()
        const pageSize = getCurrentPageSize()
        const {status} = filter || {}
        const filterData: any = {}
        if (status && status.id !== "all") {
            filterData.status = [status.status]
        }
        const params = {
            filter: filterData,
            range: {page, pageSize}
        }
        return params
    }

    const checkSubTaskCanAnswer = ({subTask, taskUser, index, taskUserOverdueIndex}) => {
        if (taskUser.status === TaskStatus.OVERDUE && taskUserOverdueIndex === -1) {
            taskUserOverdueIndex = index
        }
        const isTaskCurrentUserOverdue =
            subTask.status === TaskStatus.OVERDUE &&
            taskUser.status === TaskStatus.OVERDUE &&
            taskUserOverdueIndex === index &&
            (taskUser.userId === userId || canAccessTaskOther)
        const isTodoTaskUser = taskUser.status === TaskStatus.TODO && (taskUser.userId === userId || canAccessTaskOther)
        return isTodoTaskUser || isTaskCurrentUserOverdue
    }

    const getData = async () => {
        const {dispatch, onChangeSignerUsers} = props
        if (isMounted) {
            dispatch({isLoading: true})
        }
        try {
            const params = getParams()
            const {data, total} = await taskService.getSubTasks(+taskData.id, params)
            const subTasks = cloneDeep(data)
            setSubTasks(subTasks)
            const newData = data.map((item: any, index) => {
                item.subTaskId = item.id
                item.id = `${item.id}_${index}`
                const usersKeyByUserId = keyBy(item.users, "userId")
                item.isOpenChild = false
                item.taskHtml = renderTaskHtml(item)
                item.responses = renderResponsesHtml(item)
                item.userHtml = getFullName(item.primaryUser)
                item.enrollmentIdHtml = getEnrollmentIds(item.primaryUser)
                item.applicantIdHtml = getApplicantIds(item.primaryUser)
                item.emailHtml = [item.primaryUser.email, item.primaryUser.emailTwo].filter(Boolean).join("\n")
                item.roleHtml = get(usersKeyByUserId[item.primaryUser.userId], "type", "")
                item.statusHtml = renderStatusHtml(item)
                return item
            })
            let signerUsers = []
            data.forEach((subTask) => {
                let taskUserOverdueIndex = -1
                const signerUsersItem = subTask.users
                    .filter((taskUser, index) =>
                        checkSubTaskCanAnswer({taskUser, subTask, index, taskUserOverdueIndex})
                    )
                    .map((item) =>
                        pick(item, ["id", "userId", "profileId", "subTaskId", "taskId", "encodedSecurityLevelWeb"])
                    )
                signerUsers = [...signerUsers, ...signerUsersItem]
            })
            const dataDigitalDocument = get(data, "[0]files", "") || ""
            setFileUrls(dataDigitalDocument)
            if (onChangeSignerUsers) {
                onChangeSignerUsers(signerUsers)
            }
            if (isMounted) {
                dispatch({data: newData, total})
            }
        } catch (error) {
            handleError(error)
        } finally {
            if (isMounted) {
                dispatch({isLoading: false})
            }
        }
    }

    const onClickRowItem = () => {}

    const onClickDeleteMulti = async () => {}

    const onClickDuplicateMulti = async () => {}

    const onClickEdit = (editedItem) => {}

    const onClickDelete = async (deletedItem) => {}

    const getFileUrlsPreview = () => {
        switch (type) {
            case TaskType.FORM: {
                const defaultPdfUrls = get(data, "[0].files", "")
                const webFormsUrl = process.env.REACT_APP_WEBFORMS_BASE_URL
                const subTasksKeyById = keyBy(subTasks, "id")
                const webFormUrl = `${webFormsUrl}/?form_id=${taskData.form.code}&token=${model.authToken}&profileId=${model.profileId}&pdf`
                if (openedSubTaskId) {
                    return get(subTasksKeyById, `[${openedSubTaskId}].files`, "") || defaultPdfUrls || [webFormUrl]
                }
                return defaultPdfUrls || [webFormUrl]
            }
            case TaskType.DIGITAL_DOCUMENT: {
                const subTasksKeyById = keyBy(subTasks, "id")
                const defaultPdfUrl = get(taskData, "digitalDocument.file", "")
                if (openedSubTaskId) {
                    return get(subTasksKeyById, `[${openedSubTaskId}].files`, "") || [defaultPdfUrl]
                }
                return [defaultPdfUrl]
            }
            case TaskType.SCAN_UPLOAD: {
                const subTasksKeyById = keyBy(subTasks, "id")
                if (openedSubTaskId) {
                    return get(subTasksKeyById, `[${openedSubTaskId}].files`, "")
                }
                return get(data, "[0].files", [])
            }
            case TaskType.CUSTOM: {
                return (get(data, "[0].files", []) || []).filter((item) => item)
            }
            default:
                return []
        }
    }

    useEffect(() => {
        const fileUrls = getFileUrlsPreview()
        setFileUrls(fileUrls)
    }, [openedSubTaskId, data])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, filter, isReget])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const onCloseConfirmPopup = () => {
        confirmPopup.close()
        getData()
    }

    const isShowPdfPreview =
        [TaskType.FORM, TaskType.SCAN_UPLOAD, TaskType.DIGITAL_DOCUMENT, TaskType.CUSTOM].includes(type) ||
        taskData.subtype !== TaskSubtype.LIBRARY

    return (
        <div className={styles.wrap}>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            {confirmPopupType && (
                <ConfirmPopup
                    isShow={confirmPopup.isVisible}
                    onClose={onCloseConfirmPopup}
                    type={confirmPopupType}
                    taskData={taskData}
                    checkedData={checkedData}
                    confirmPopupActionFor={confirmPopupActionFor}
                />
            )}
            <RemindPopup
                isShow={remindPopup.isVisible}
                onClose={() => remindPopup.close()}
                userInfo={checkedData[0]}
                taskData={taskData}
            />
            <ReassignPopup
                isShow={reassignPopup.isVisible}
                onClose={() => reassignPopup.close()}
                onConfirm={reassignTask}
            />
            {isShowPdfPreview &&
                !isEmpty(fileUrls) &&
                fileUrls.map((fileUrl, index) => (
                    <div key={index} className={styles.filePreview}>
                        <PDFViewer fileSrc={fileUrl} />
                    </div>
                ))}
        </div>
    )
}

export default KlassappTableHOC(ResponsesTable)
