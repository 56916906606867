import {useCallback, useEffect, useState} from "react"
import {Row, Col} from "antd"
import moment from "moment"
import debounce from "debounce-promise"
import cx from "classnames"

import {KlassDropdown, KlassDropAsyncPaginate} from "components/Select"
import {BaseDatePicker} from "components/DateTimePicker"
import {BaseButton} from "components/buttons"
import {BaseNewFilter} from "uiKit"
import {formatCodeName} from "helpers"
import {useModel} from "hooks"
import {campusesService, degreeLevelService, majorService, tagService} from "services"
import DepartmentSubunitSelect from "components/DepartmentSubunitSelect"
import {ViewType} from "../../MediaLibrary"
import {ReactComponent as IconViewBlocks} from "../../icons/view-blocks.svg"
import {ReactComponent as IconViewList} from "../../icons/view-list.svg"
import styles from "./Filter.module.css"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import {OTHER_STATE_OPTIONS} from "types/others"
import {STAFF_STATE_OPTIONS} from "types/staffs"
import {STATES_OPTIONS} from "types/students"
import {Auth} from "types/auth"
import {Tag} from "types/tags"

const USER_STATE_OPTIONS = [
    ...STATES_OPTIONS.map((item) => ({
        ...item,
        userTypeId: "student"
    })),
    ...STAFF_STATE_OPTIONS.map((item) => ({
        ...item,
        userTypeId: "staff"
    })),
    ...OTHER_STATE_OPTIONS.map((item) => ({
        ...item,
        userTypeId: "others"
    }))
]

export const MEDIAL_LIBRARY_ITEM_TYPE_OPTIONS = [
    {id: "image", name: "Image"},
    {id: "video", name: "Video"},
    {id: "document", name: "Document"},
    {id: "link", name: "Link"}
]
const USER_TYPE_OPTIONS = [
    {id: "student", name: "Student"},
    {id: "staff", name: "Staff"},
    {id: "others", name: "Others"}
]

type Props = {
    filterObject?: any
    filterFormSearch: string
    updateFilter: (field: string, value: any) => void
    onClickFilter: (tags: Tag[]) => void
    onClearFilter: () => void
    onChangeSearch: (value: string) => void
    viewType: ViewType
    setViewType: (value: ViewType) => void
    isPageFavorite: boolean
}

export function Filter(props: Props) {
    const {
        filterObject,
        filterFormSearch,
        updateFilter,
        onClickFilter,
        onClearFilter,
        onChangeSearch,
        viewType,
        setViewType,
        isPageFavorite
    } = props
    const model = useModel()
    const user = model.getCurrentUserProfile()
    const isStudent = model.isStudent()
    const {t} = useTranslation(["common", "settings"])
    const history = useHistory()
    const [selectedTags, setSelectedTags] = useState<Tag[]>([])
    const filteredUserStates = isStudent
        ? USER_STATE_OPTIONS.filter((state) => state.id === user.state)
        : USER_STATE_OPTIONS
    const [states, setStates] = useState(filteredUserStates) // manage state list

    useEffect(() => {
        let statesSelected = []
        if (filterObject.userTypes?.length) {
            filterObject.userTypes.every((userTypeItem) => {
                if (userTypeItem.id === 1) {
                    statesSelected = filteredUserStates
                    return false
                }
                filteredUserStates.forEach((item) => {
                    if (item.userTypeId === userTypeItem.id) {
                        statesSelected.push(item)
                    }
                })
                return true
            })
        }
        if (statesSelected.length < 1) {
            statesSelected = filteredUserStates
        }
        setStates(statesSelected)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObject.userTypes])

    useEffect(() => {
        setSelectedTags(filterObject.tags)
    }, [filterObject.tags])

    const onCampusSearch = useCallback(
        async (campus: string, loadedOptions) => {
            try {
                const filter = {search: campus}
                if (isStudent && user?.campuses.length) {
                    const ids = user.campuses.map((campus) => campus.id)
                    filter["id"] = ids
                }
                const {data: campuses, total} = await campusesService.getAll({
                    fields: ["id", "name", "code"],
                    filter,
                    range: {limit: 20, offset: loadedOptions.length}
                })
                return {
                    options: campuses.map(({id, name, code}) => ({id, name, code})),
                    hasMore: loadedOptions.length < total
                }
            } catch (error) {
                return {
                    options: [],
                    hasMore: false
                }
            }
        },
        [isStudent, user.campuses]
    )

    const onProgramSearch = useCallback(
        async (program: string, loadedOptions) => {
            try {
                const pageSize = 20
                const page = Math.ceil(loadedOptions.length / pageSize) + 1
                let filter = {search: program}
                if (isStudent && user.currentMajor) {
                    filter["programIds"] = [(user.currentMajor as Auth.CurrentMajor).id]
                }
                const {data: programs, total} = await majorService.getAll({
                    filter,
                    range: {page, pageSize}
                })
                return {
                    options: programs.map(({id, name, code}) => ({id, name, code})),
                    hasMore: loadedOptions.length < total
                }
            } catch (error) {
                return {
                    options: [],
                    hasMore: false
                }
            }
        },
        [isStudent, user.currentMajor]
    )

    const onDegreeLevelSearch = useCallback(
        async (search: string, loadedOptions) => {
            try {
                const pageSize = 20
                const page = Math.ceil(loadedOptions.length / pageSize) + 1
                const filter = {search}

                if (isStudent && user.currentMajor && (user.currentMajor as Auth.CurrentMajor).degreeLevelId) {
                    filter["degreeLevelIds"] = [(user.currentMajor as Auth.CurrentMajor).degreeLevelId]
                }
                const {data: degreeLevels, total} = await degreeLevelService.getAll({
                    filter,
                    range: {page, pageSize}
                })
                return {
                    options: degreeLevels.map(({degreeLevelId, name, code}) => ({degreeLevelId, name, code})),
                    hasMore: loadedOptions.length < total
                }
            } catch (error) {
                return {
                    options: [],
                    hasMore: false
                }
            }
        },
        [isStudent, user.currentMajor]
    )

    const onTagSearch = useCallback(async (search: string, loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data: tags, total} = await tagService.getAllTags({
                filter: {search},
                range: {page, pageSize}
            })
            return {
                options: tags.map(({tagId, name, code}) => ({tagId, name, code})),
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }, [])

    const onClickAdd = () => {
        history.push("/media-library/detail")
    }

    const delayTime = 500
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceCampuses = useCallback(debounce(onCampusSearch, delayTime), [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncePrograms = useCallback(debounce(onProgramSearch, delayTime), [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceDegreeLevels = useCallback(debounce(onDegreeLevelSearch, delayTime), [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceTags = useCallback(debounce(onTagSearch, delayTime), [])

    const handleFiltersApply = useCallback(() => {
        updateFilter("tags", selectedTags)
        onClickFilter(selectedTags)
    }, [onClickFilter, selectedTags, updateFilter])

    return (
        <BaseNewFilter
            className={styles.filterContainer}
            searchValue={filterFormSearch}
            onSearchInput={onChangeSearch}
            onClick={handleFiltersApply}
            onClickClear={onClearFilter}
            filter={filterObject}
            renderRightFilter={() => (
                <div className={styles.filterRightContainer}>
                    <div className={styles.listingTypeContainer}>
                        <IconViewBlocks
                            width="32"
                            height="32"
                            onClick={() => setViewType(ViewType.Blocks)}
                            className={cx(
                                {[styles.listingIconSelected]: viewType === ViewType.Blocks},
                                styles.listingIcon
                            )}
                        />
                        <IconViewList
                            width="32"
                            height="32"
                            onClick={() => setViewType(ViewType.List)}
                            className={cx(
                                {[styles.listingIconSelected]: viewType === ViewType.List},
                                styles.listingIcon
                            )}
                        />
                    </div>
                    {!isPageFavorite && model.isStaffOrAdmin() && (
                        <div className={styles.actionWrap}>
                            <BaseButton
                                title={t("common:action.add")}
                                onClick={onClickAdd}
                                className={styles.addButton}
                            />
                        </div>
                    )}
                </div>
            )}>
            <Row gutter={[40, 32]}>
                <Col span={12}>
                    <KlassDropdown
                        options={MEDIAL_LIBRARY_ITEM_TYPE_OPTIONS}
                        value={filterObject.itemType}
                        onChange={(value) => updateFilter("itemType", value)}
                        placeholder="Type"
                    />
                </Col>
                <Col span={12}>
                    <BaseDatePicker
                        placeholder="Date Updated"
                        format="YYYY-MM-DD"
                        value={filterObject.updatedAt ? moment(filterObject.updatedAt) : null}
                        onChange={(value) => updateFilter("updatedAt", value)}
                    />
                </Col>
                <Col span={12}>
                    <KlassDropAsyncPaginate
                        loadOptions={debounceCampuses}
                        value={filterObject.campuses}
                        valueKey="id"
                        onChange={(value) => updateFilter("campuses", value)}
                        isMulti
                        placeholder="Campus"
                        getOptionLabel={formatCodeName}
                    />
                </Col>
                <Col span={12}>
                    <DepartmentSubunitSelect
                        isMulti
                        isClearable
                        placeholder="Department"
                        defaultOptions={filterObject.departmentSubunits}
                        value={filterObject.departmentSubunits}
                        onChange={(value) => updateFilter("departmentSubunits", value)}
                        shortly
                    />
                </Col>
                <Col span={12}>
                    <KlassDropAsyncPaginate
                        loadOptions={debounceDegreeLevels}
                        value={filterObject.degreeLevels}
                        valueKey="degreeLevelId"
                        onChange={(value) => updateFilter("degreeLevels", value)}
                        getOptionLabel={formatCodeName}
                        isMulti
                        placeholder="Degree Level"
                    />
                </Col>
                <Col span={12}>
                    <KlassDropAsyncPaginate
                        loadOptions={debouncePrograms}
                        value={filterObject.programs}
                        valueKey="id"
                        onChange={(value) => updateFilter("programs", value)}
                        getOptionLabel={formatCodeName}
                        isMulti
                        placeholder="Program"
                    />
                </Col>
                <Col span={12}>
                    <KlassDropdown
                        options={
                            isStudent
                                ? USER_TYPE_OPTIONS.filter((type) => type.id === Auth.UserProfileType.Student)
                                : USER_TYPE_OPTIONS
                        }
                        value={filterObject.userTypes}
                        valueKey="id"
                        onChange={(value) => updateFilter("userTypes", value)}
                        isMulti
                        placeholder="User Type"
                    />
                </Col>
                <Col span={12}>
                    <KlassDropdown
                        options={states}
                        value={filterObject.userStates}
                        valueKey="id"
                        onChange={(value) => updateFilter("userStates", value)}
                        isMulti
                        placeholder="State"
                    />
                </Col>
                <Col span={24}>
                    <KlassDropAsyncPaginate
                        loadOptions={debounceTags}
                        value={selectedTags}
                        valueKey="tagId"
                        onChange={(value) => setSelectedTags(value ?? [])}
                        getOptionLabel={formatCodeName}
                        isMulti
                        placeholder="Tags"
                    />
                </Col>
            </Row>
        </BaseNewFilter>
    )
}
