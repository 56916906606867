import {get} from "lodash"
import BaseService from "./Base"

class DocumentHubService extends BaseService {
    async listDocuments(params) {
        const response = await this.post("/v1/document-hub/documents/list", params)
        return get(response, "data", [])
    }

    async getStatistics(params = {}) {
        const response = await this.post("/v1/document-hub/stats/list", params)
        return get(response, "data.data", {})
    }

    async createDocumentsBulk(data = {}) {
        const response = await this.post("/v1/document-hub/documents/create-bulk", data)
        return get(response, "data.data", {})
    }

    async downloadDocuments(payload: {userDocumentIds: Array<number>}) {
        const response = await this.post(`/v1/document-hub/documents/download`, payload, {
            responseType: "blob"
        })
        return get(response, "data", null)
    }
}

export default DocumentHubService
