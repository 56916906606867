/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer, useEffect, useMemo, useState} from "react"
import {Menu, Dropdown, Button, Checkbox, Tooltip} from "antd"
import cx from "classnames"
import moment from "moment-timezone"
import {head, isEmpty, keyBy, intersection} from "lodash"
import {useTranslation} from "react-i18next"
import {Icon} from "components/Icon"
import {BaseButton} from "components/buttons"
import {BaseDatePicker} from "components/DateTimePicker"
import {useVisible, useModel} from "hooks"
import {formatDateOnly, getFullName, handleError, toastSuccess} from "helpers"
import {academicPlansService} from "services"
import {AcademicPlans} from "types/academicPlans"
import {Auth} from "types/auth"
import {ConfirmPopup} from "uiKit"
import {PrerequisitesRequired} from "../Popup"
import styles from "./CourseItem.module.css"
import {Course} from "types/courses"
import {FormLabel} from "components/Form"
import {BaseLoading} from "components"
import {SelectSchedulePopup} from "uiKit/popup/SelectSchedulePopup"

function reducer(state, action) {
    return {...state, ...action}
}

type Props = {
    termItem: AcademicPlans.Term
    studentId: number
    student: Auth.DepartmentStudent
    termSection: AcademicPlans.TermSection
    termCourse: AcademicPlans.TermCourse
    canTermRegister: boolean
    conflictEvents: any[]
    availableCourses: any[]
    unavailableScheduleIds?: number[]
    registeringTerm: AcademicPlans.Term
    reloadView: () => void
    updateEvents: (data: AcademicPlans.UpdateEvents[]) => void
    onRegisterCourseSuccess: () => void
}

enum ConfirmType {
    DropCourse = "dropCourse",
    CancelDropCourse = "cancelDropCourse",
    RemoveCourse = "removeCourse",
    RegisterConflictCourse = "registerConflictCourse"
}

export function CourseItem(props: Props) {
    const initialState = {
        courseSchedules: [],
        isRegistering: false,
        courseScheduleIdSelected: null
    }
    const [isSelectingSchedule, setSelectingSchedule] = useState(false)
    const [{courseSchedules, isRegistering, courseScheduleIdSelected}, dispatch] = useReducer(reducer, initialState)
    const [isShowAllCampuses, setShowAllCampuses] = useState(false)
    const {
        termItem,
        studentId,
        student,
        termCourse,
        canTermRegister,
        conflictEvents,
        availableCourses,
        registeringTerm,
        reloadView,
        updateEvents,
        onRegisterCourseSuccess
    } = props
    const {reviewStatus, courseId, prerequisiteCourses, courseTotalUnits, academicPlanCourseId} = termCourse
    const prerequisitesPopup = useVisible(false)
    const confirmPopup = useVisible(false)
    const transferSchedulePopup = useVisible(false)
    const {t} = useTranslation(["common"])
    const [confirmType, setConfirmType] = useState<ConfirmType>(null)
    const [dropDate, setDropDate] = useState(null)
    const model = useModel()
    const dateFormat = model.getUserDateFormat()
    const availableCoursesById = keyBy(availableCourses, "id")
    const hasEnrolled = [
        Auth.StudentState.Student,
        Auth.StudentState.Enrollment,
        Auth.StudentState.TemporaryOut
    ].includes(student.profileState)
    const hasAvailable = !isEmpty(availableCoursesById[courseId])
    const canRegister =
        canTermRegister &&
        hasAvailable &&
        (reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Rejected ||
            reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Approved)
    const canDrop = reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Registered
    const isShowPrerequisiteRequire = !isEmpty(prerequisiteCourses)
    const isHourUnit = courseTotalUnits === Course.Unit.Hour
    const canRemoveCourse = reviewStatus !== AcademicPlans.AcademicPlanCourseReviewStatus.Registered
    const canCancelDrop =
        model.isStaffOrAdmin() && reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Dropped

    useEffect(() => {
        if (canRegister) {
            getSchedulesByCourse()
        }
    }, [registeringTerm])

    useEffect(() => {
        if (termCourse?.selectedScheduleId) {
            dispatch({courseScheduleIdSelected: termCourse.selectedScheduleId})
        }
    }, [termCourse])

    const filteredSchedules = useMemo(() => {
        if (isShowAllCampuses) {
            return termCourse.schedules
        }
        return termCourse.schedules.filter((schedule) => intersection(schedule.campusIds, student.campusIds).length > 0)
    }, [isShowAllCampuses, termCourse.schedules])

    const activeSchedule = useMemo(() => {
        return termCourse.schedules.find(
            (schedule) => schedule.id === courseScheduleIdSelected || schedule.id === termCourse.registeredScheduleId
        )
    }, [courseScheduleIdSelected, termCourse.registeredScheduleId])

    const confirmPopupInfo = useMemo(() => {
        const courseInfo = `${termCourse.courseName} (${termCourse.courseCode} )`
        switch (confirmType) {
            case ConfirmType.CancelDropCourse:
                return {
                    title: `Cancel Drop course - ${courseInfo}`,
                    description: "By approving, you are canceling this dropped course. Would you like to proceed?"
                }
            case ConfirmType.DropCourse:
                return {
                    title: `Drop course - ${courseInfo}`,
                    description:
                        "By approving, you are withdrawing the student from this course. Would you like to proceed?"
                }
            case ConfirmType.RemoveCourse:
                return {
                    title: `Remove course - ${courseInfo}`,
                    description: "By approving, you are removing this course. Would you like to proceed?"
                }
            case ConfirmType.RegisterConflictCourse:
                return {
                    title: "You are registering a course with schedule conflict, do you wish to continue?",
                    description: null
                }
            default:
                return {title: "", description: ""}
        }
    }, [confirmType])

    const registeredScheduleName = useMemo(() => {
        if (
            termCourse.registeredScheduleId &&
            (termCourse.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Registered ||
                termCourse.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.PendingReview)
        ) {
            const registeredSchedule = termCourse.schedules.find(
                (schedule) => schedule.id === termCourse.registeredScheduleId
            )
            return registeredSchedule ? registeredSchedule.schedule_suffix : null
        }
        return null
    }, [termCourse])

    const getOptions = () => {
        const options = []
        if (isShowPrerequisiteRequire) {
            options.push({
                label: "Show Prerequisites",
                icon: "UNORDER_LIST",
                action: () => onShowPrerequisiteCourse()
            })
        }
        if (canRemoveCourse) {
            options.push({
                label: "Remove Course",
                icon: "DELETE",
                action: () => showConfirmPopup(ConfirmType.RemoveCourse)
            })
        }
        if (canCancelDrop) {
            options.push({
                label: "Cancel Drop",
                icon: "CLOSE",
                action: () => showConfirmPopup(ConfirmType.CancelDropCourse)
            })
        }
        if (termCourse.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Registered && canDrop) {
            options.push({
                label: "Drop",
                icon: "DELETE",
                action: () => showConfirmPopup(ConfirmType.DropCourse)
            })
            if (model.isStaffOrAdmin()) {
                options.push({
                    label: "Change Schedule",
                    icon: "TIMER",
                    action: () => showTransferSchedulePopup()
                })
            }
        }
        return options
    }

    const removeCourse = async () => {
        try {
            await academicPlansService.removeCourse({
                studentProfileId: studentId,
                academicPlanCourseIds: [academicPlanCourseId],
                academicPlanTermId: termItem.id
            })
            reloadView()
        } catch (error) {
            handleError(error)
        }
    }

    const showConfirmPopup = (confirmType: ConfirmType) => {
        setConfirmType(confirmType)
        confirmPopup.open()
    }

    const showTransferSchedulePopup = () => {
        transferSchedulePopup.open()
    }

    const hideTransferSchedulePopup = () => {
        transferSchedulePopup.close()
    }

    const closeConfirmPopup = () => {
        setConfirmType(null)
        confirmPopup.close()
    }

    const dropCourse = async () => {
        try {
            await academicPlansService.dropCourse({
                studentProfileId: studentId,
                academicPlanCourseId,
                dropDate: dropDate || undefined
            })
            setDropDate(null)
            closeConfirmPopup()
            toastSuccess("Drop course successfully")
            reloadView()
        } catch (error) {
            handleError(error)
        }
    }

    const cancelDropCourse = async () => {
        try {
            await academicPlansService.updateAcademicPlanCourse({
                academicPlanId: termCourse.academicPlanCourseId,
                reviewStatus: AcademicPlans.AcademicPlanCourseReviewStatus.Registered,
                droppedDate: null,
                status: AcademicPlans.AcademicPlanCourseReviewStatus.Registered
            })
            setDropDate(null)
            closeConfirmPopup()
            toastSuccess("Cancel Drop course successfully")
            reloadView()
        } catch (error) {
            handleError(error)
        }
    }

    const reviewCourse = async (reviewStatus) => {
        try {
            await academicPlansService.reviewCourse({
                studentProfileId: studentId,
                academicPlanCourseId,
                staffProfileId: model.profileId,
                reviewStatus
            })
            reloadView()
        } catch (error) {
            handleError(error)
        }
    }

    const onShowPrerequisiteCourse = () => {
        prerequisitesPopup.open()
    }

    const getSchedulesByCourse = async () => {
        try {
            const courseSchedules = await academicPlansService.getSchedulesByCourse({
                filter: {
                    course_id: termCourse.courseId,
                    term_id: termItem.termId
                }
            })
            const newCourseSchedules = courseSchedules.map((courseSchedule) => {
                courseSchedule.schedule_events = (courseSchedule.schedule_events || []).map((scheduleEvent) => {
                    scheduleEvent.courseId = termCourse.courseId
                    scheduleEvent.courseCode = termCourse.courseCode
                    scheduleEvent.courseName = termCourse.courseName
                    return scheduleEvent
                })
                return courseSchedule
            })
            dispatch({courseSchedules: newCourseSchedules})
        } catch (error) {
            handleError(error)
        }
    }

    const onClickRegisterCourse = async () => {
        try {
            dispatch({isRegistering: true})
            await academicPlansService.registerCourse({
                academicPlanCourseId,
                scheduleId: courseScheduleIdSelected,
                studentProfileId: studentId
            })
            onRegisterCourseSuccess()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isRegistering: false, courseScheduleIdSelected: null})
        }
    }

    const onConfirmPopup = () => {
        switch (confirmType) {
            case ConfirmType.CancelDropCourse:
                cancelDropCourse()
                break
            case ConfirmType.DropCourse:
                dropCourse()
                break
            case ConfirmType.RemoveCourse:
                removeCourse()
                break
            case ConfirmType.RegisterConflictCourse:
                onClickRegisterCourse()
                break
            default:
                break
        }
    }

    const onChangeCourseSchedule = async (courseSchedule) => {
        try {
            setSelectingSchedule(true)
            await academicPlansService.updateAcademicPlanCourse({
                selectedScheduleId: courseSchedule.id === courseScheduleIdSelected ? null : courseSchedule.id,
                academicPlanId: termCourse.academicPlanCourseId
            })
            const scheduleData = courseSchedules.find((item) => item.id === courseSchedule.id)
            if (courseSchedule.id === courseScheduleIdSelected) {
                dispatch({courseScheduleIdSelected: null})
                const scheduleSelected = courseSchedules.find((item) => item.id === courseScheduleIdSelected)
                updateEvents([
                    {
                        type: AcademicPlans.UpdateEventType.Delete,
                        changedEvents: scheduleSelected.schedule_events,
                        schedule: scheduleData
                    }
                ])
            } else {
                const scheduleSelected = courseSchedules.find((item) => item.id === courseScheduleIdSelected)
                dispatch({courseScheduleIdSelected: courseSchedule.id})
                const data: AcademicPlans.UpdateEvents[] = []
                if (scheduleSelected) {
                    data.push({
                        type: AcademicPlans.UpdateEventType.Delete,
                        changedEvents: scheduleSelected.schedule_events || [],
                        schedule: scheduleData
                    })
                }
                data.push({
                    type: AcademicPlans.UpdateEventType.Add,
                    schedule: scheduleData,
                    changedEvents: scheduleData?.schedule_events || []
                })
                updateEvents(data)
            }
        } catch (err) {
        } finally {
            setSelectingSchedule(false)
        }
    }

    const toggleShowAllCampuses = (checked) => {
        setShowAllCampuses(checked)
        if (!checked) {
            const newSchedules = termCourse.schedules.filter(
                (schedule) => intersection(schedule.campusIds, student.campusIds).length > 0
            )
            const stillExistedSchedule = newSchedules.some((schedule) => schedule.id === activeSchedule.id)
            if (!stillExistedSchedule) {
                onChangeCourseSchedule(activeSchedule)
            }
        }
    }

    const renderReviewStatus = () => {
        if (termCourse.status === AcademicPlans.AcademicPlanCourseStatus.Loa) {
            return (
                <div className={styles.courseStatus}>
                    <Icon icon="CROSS_CIRCLE_FILL" className={styles.courseStatus__icon} />
                    <span className={styles.courseStatus_loa}>Loa</span>
                </div>
            )
        }
        if (
            (!hasAvailable || !hasEnrolled || !termItem.termId) &&
            termCourse.status !== AcademicPlans.AcademicPlanCourseStatus.Dropped
        ) {
            let message = ""
            if (!hasEnrolled) {
                message = "Not Enroll"
            } else if (!termItem.termId) {
                message = "Please select term to see course schedule"
            } else {
                message = "Course is not available"
            }
            return (
                <div className={styles.courseStatus}>
                    <Icon icon="WARNING_TRIANGLE_FILL" color="#ff8a38" className={styles.courseStatus__icon} />
                    <span className={styles.courseStatus__removed}>{message}</span>
                </div>
            )
        }

        if (
            termCourse.status === AcademicPlans.AcademicPlanCourseStatus.Completed ||
            termCourse.status === AcademicPlans.AcademicPlanCourseStatus.Finished
        ) {
            return (
                <div className={styles.courseStatus}>
                    <Icon icon="CHECKMARK_CIRCLE_1" color="#18a957" className={styles.courseStatus__icon} />
                    <span className={styles.courseStatus__registered}>Completed ({registeredScheduleName})</span>
                </div>
            )
        }
        if (termCourse.status === AcademicPlans.AcademicPlanCourseStatus.Incomplete) {
            return (
                <div className={styles.courseStatus}>
                    <Icon icon="CHECKMARK_CIRCLE_1" color="#18a957" className={styles.courseStatus__icon} />
                    <span className={styles.courseStatus__registered}>Incomplete ({registeredScheduleName})</span>
                </div>
            )
        }
        if (termCourse.status === AcademicPlans.AcademicPlanCourseStatus.LoaIncomplete) {
            return (
                <div className={styles.courseStatus}>
                    <Icon icon="CHECKMARK_CIRCLE_1" color="#18a957" className={styles.courseStatus__icon} />
                    <span className={styles.courseStatus__registered}>Loa-Incomplete ({registeredScheduleName})</span>
                </div>
            )
        }
        if (termCourse.status === AcademicPlans.AcademicPlanCourseStatus.Inprogress) {
            return (
                <div className={styles.courseStatus}>
                    <Icon icon="CHECKMARK_CIRCLE_1" color="#18a957" className={styles.courseStatus__icon} />
                    <span className={styles.courseStatus__registered}>In Progress ({registeredScheduleName})</span>
                </div>
            )
        }
        if (termCourse.status === AcademicPlans.AcademicPlanCourseStatus.Failed) {
            return (
                <div className={styles.courseStatus}>
                    <Icon icon="CROSS_CIRCLE_FILL" color="#df1642" className={styles.courseStatus__icon} />
                    <span className={styles.courseStatus__removed}>Failed</span>
                </div>
            )
        }
        let pendingReviewType = ""
        if (termCourse.changeRequests && termCourse.changeRequests.length > 0) {
            const changeRequest = head(termCourse.changeRequests)
            pendingReviewType = changeRequest.type
            if (changeRequest.type === AcademicPlans.ChangeRequestType.REMOVE_COURSE) {
                return (
                    <div className={styles.courseStatus}>
                        <Icon icon="CROSS_CIRCLE_FILL" color="#df1642" className={styles.courseStatus__icon} />
                        <span className={styles.courseStatus__removed}>Removed</span>
                    </div>
                )
            }
        }
        switch (reviewStatus) {
            case AcademicPlans.AcademicPlanCourseReviewStatus.PendingReview: {
                const message =
                    pendingReviewType === AcademicPlans.ChangeRequestType.DROP_COURSE
                        ? "(Drop)"
                        : `(Register ${registeredScheduleName}) `
                return (
                    <div className={styles.courseStatus}>
                        <Icon icon="WARNING_TRIANGLE_FILL" color="#ff8a38" className={styles.courseStatus__icon} />
                        <span className={styles.courseStatus__pending}>Pending Review {message}</span>
                    </div>
                )
            }
            case AcademicPlans.AcademicPlanCourseReviewStatus.Registered:
                return (
                    <div className={styles.courseStatus}>
                        <Icon icon="CHECKMARK_CIRCLE_1" color="#18a957" className={styles.courseStatus__icon} />
                        <span className={styles.courseStatus__registered}>Registered ({registeredScheduleName})</span>
                    </div>
                )
            case AcademicPlans.AcademicPlanCourseReviewStatus.Dropped:
                return (
                    <div className={styles.courseStatus}>
                        <Icon icon="CHECKMARK_CIRCLE_1" color="#df1642" className={styles.courseStatus__icon} />
                        <span className={styles.courseStatus__removed}>
                            Dropped ({formatDateOnly(termCourse.droppedDate || termCourse.updatedAt, dateFormat)})
                        </span>
                    </div>
                )
            default:
                return null
        }
    }

    const renderRegisterButton = () => {
        if (!!courseScheduleIdSelected) {
            const hasConflict = conflictEvents.some((conflictEvent) =>
                (conflictEvent.courseIds || []).includes(termCourse.courseId)
            )
            return (
                <div className={styles.registerCourseBtnWrap}>
                    <BaseButton
                        title={hasConflict ? "Schedule conflict" : "Click here to register course"}
                        uppercase={false}
                        className={styles.registerCourseBtn}
                        onClick={onClickRegisterCourse}
                        loading={isRegistering}
                        disabled={hasConflict}
                    />
                    {model.isStaffOrAdmin() && hasConflict && (
                        <BaseButton
                            title="Register anyways"
                            uppercase={false}
                            className={styles.registerCourseBtn}
                            onClick={() => showConfirmPopup(ConfirmType.RegisterConflictCourse)}
                            loading={isRegistering}
                        />
                    )}
                </div>
            )
        }
        return null
    }

    const renderSelectSchedule = () => {
        return (
            <div className={styles.selectScheduleWrap}>
                <div className={styles.selectScheduleTitle}>
                    <span>Select schedule</span>
                    <div>
                        <Checkbox
                            checked={isShowAllCampuses}
                            onChange={(event) => toggleShowAllCampuses(event.target.checked)}>
                            All Campuses
                        </Checkbox>
                    </div>
                </div>
                <div className={styles.listSchedule}>
                    {isEmpty(filteredSchedules) ? (
                        <span>No Schedule</span>
                    ) : (
                        <div>
                            {isSelectingSchedule ? <BaseLoading isShow /> : null}

                            {filteredSchedules.map((courseSchedule) => (
                                <span
                                    key={courseSchedule.id}
                                    className={cx(styles.scheduleItem, {
                                        [styles.scheduleItem__selected]: courseSchedule.id === courseScheduleIdSelected,
                                        [styles.scheduleDropped]:
                                            props.unavailableScheduleIds?.length &&
                                            props.unavailableScheduleIds.includes(courseSchedule.id)
                                    })}
                                    onClick={() => onChangeCourseSchedule(courseSchedule)}>
                                    {courseSchedule.schedule_suffix}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const renderPendingMenus = () => {
        if (model.isStaffOrAdmin()) {
            return (
                <Menu className={styles.menuActionWrap}>
                    <Menu.Item
                        key="approve"
                        className={styles.menuActionItem}
                        onClick={() => reviewCourse(AcademicPlans.AcademicPlanCourseReviewStatus.Approved)}>
                        <Icon
                            className={styles.menuActionIcon}
                            icon="CHECKMARK_CIRCLE"
                            color="var(--success-400-base)"
                        />
                        <span className={styles.menuActionTitle}>Approve</span>
                    </Menu.Item>
                    <Menu.Item
                        key="reject"
                        className={styles.menuActionItem}
                        onClick={() => reviewCourse(AcademicPlans.AcademicPlanCourseReviewStatus.Rejected)}>
                        <Icon className={styles.menuActionIcon} icon="CROSS_CIRCLE" color="var(--error-400-base)" />
                        <span className={styles.menuActionTitle}>Reject</span>
                    </Menu.Item>
                </Menu>
            )
        }
        return null
    }

    const renderMenuOptions = () => {
        const options = getOptions()
        if (!isEmpty(options)) {
            return (
                <div className={styles.courseItemDetail__action}>
                    <Dropdown
                        overlay={
                            <Menu className={styles.menuActionWrap}>
                                {options.map((option, index) => (
                                    <Menu.Item key={index} className={styles.menuActionItem} onClick={option.action}>
                                        <Icon className={styles.menuActionIcon} icon={option.icon} />
                                        <span className={styles.menuActionTitle}>{option.label}</span>
                                    </Menu.Item>
                                ))}
                            </Menu>
                        }
                        trigger={["click"]}>
                        <Button
                            className={styles.iconBtn}
                            icon={<Icon className={styles.threeDotsIcon} icon="MORE_VERTICAL" color="#939393" />}
                        />
                    </Dropdown>
                </div>
            )
        }
        return null
    }

    const renderPendingReview = () => {
        if (
            termCourse.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.PendingReview &&
            model.isStaffOrAdmin()
        ) {
            return (
                <div className={styles.pendingReview}>
                    <div className={styles.pendingReview__left}>
                        <Icon icon="WARNING_TRIANGLE_FILL" color="#fff" className={styles.pendingReviewIcon} />
                        <span className={styles.pendingReviewTitle}>Pending Review</span>
                    </div>
                    <div className={styles.pendingReview__action}>
                        <Dropdown overlay={renderPendingMenus} trigger={["click"]}>
                            <Button
                                className={styles.iconBtn}
                                icon={<Icon className={styles.threeDotsIcon} icon="MORE_VERTICAL" color="#fff" />}
                            />
                        </Dropdown>
                    </div>
                </div>
            )
        }
        return null
    }

    const renderCourseDayInfo = () => {
        if (isEmpty(activeSchedule)) {
            return null
        }
        return (
            <div className={styles.courseItemDetail}>
                <div className={styles.courseItemBodyItem}>
                    <span className={styles.courseItemBodyItem__title}>First day</span>
                    <span className={styles.courseItemBodyItem__value}>
                        {activeSchedule.firstDayOfCourse
                            ? moment(activeSchedule.firstDayOfCourse).utc().format(dateFormat)
                            : ""}
                    </span>
                </div>
                <div className={styles.courseItemBodyItem}>
                    <span className={styles.courseItemBodyItem__title}>Last Day</span>
                    <span className={styles.courseItemBodyItem__value}>
                        {activeSchedule.course_last_date
                            ? moment(activeSchedule.course_last_date).utc().format(dateFormat)
                            : ""}
                    </span>
                </div>
            </div>
        )
    }

    const renderAvailable = () => {
        let available = ""
        if (!isEmpty(activeSchedule)) {
            const {total, current} = activeSchedule.available
            available = `${total - current} / ${total}`
        }
        return (
            <div className={styles.courseItemBodyItem}>
                <span className={styles.courseItemBodyItem__title}>Available</span>
                <span className={styles.courseItemBodyItem__value}>{available}</span>
            </div>
        )
    }

    const renderInstructors = () => {
        const instructor = isEmpty(activeSchedule)
            ? termCourse.courseInstructor
            : activeSchedule.instructors.map((instructor) => getFullName(instructor)).join(", ")
        return (
            <div className={styles.courseItemBodyItem}>
                <span className={styles.courseItemBodyItem__title}>Instructor</span>
                <span className={styles.courseItemBodyItem__value}>{instructor}</span>
            </div>
        )
    }

    return (
        <>
            <div className={styles.courseItem}>
                {renderPendingReview()}
                <div className={styles.bodyWrap}>
                    {termCourse.role === Course.AcademicPlanCourseRole.ClassAssistance ? (
                        <div className={styles.classAssistance}>
                            <span className={styles.classAssistanceLabel}>Class Assistance</span>
                            <Tooltip title={"Clock hour will not be calculated"} key={"Class Assistance"}>
                                <div className={styles.classAssistanceInfo}>
                                    <Icon icon={"INFO"} />
                                </div>
                            </Tooltip>
                        </div>
                    ) : null}
                    <div className={styles.courseItemDetail}>
                        <div className={styles.courseItemDetail__left}>
                            <span className={styles.courseItemDetail__left__courseCode}>{termCourse.courseCode}</span>
                            <span className={styles.courseItemDetail__left__courseName}>{termCourse.courseName}</span>
                        </div>
                        {renderMenuOptions()}
                    </div>
                    <div className={styles.courseItemDetail}>
                        <div className={styles.courseItemBodyItem}>
                            <span className={styles.courseItemBodyItem__title}>{isHourUnit ? "Credit" : "Each"}</span>
                            <div className={styles.courseItemBodyItem__creditWrap}>
                                <span className={styles.courseItemBodyItem__credit}>{termCourse.credit || 0}</span>
                            </div>
                        </div>
                        {renderAvailable()}
                    </div>
                    <div className={styles.courseItemDetail}>
                        <div className={styles.courseItemBodyItem}>
                            <span className={styles.courseItemBodyItem__title}>Track</span>
                            <span className={styles.courseItemBodyItem__value}>{termCourse.courseTrack}</span>
                        </div>
                        {renderInstructors()}
                    </div>
                    {renderCourseDayInfo()}
                    {termCourse.reviewStatus && renderReviewStatus()}
                    {canRegister && renderRegisterButton()}
                    {isShowPrerequisiteRequire && (
                        <div className={styles.prerequisiteRequireWrap} onClick={onShowPrerequisiteCourse}>
                            <div className={styles.prerequisiteRequireBody}>
                                <Icon icon="ALERT" />
                                <span className={styles.prerequisiteRequireText}>Prerequisites Required</span>
                            </div>
                        </div>
                    )}
                    {prerequisitesPopup.isVisible && (
                        <PrerequisitesRequired
                            isShow={prerequisitesPopup.isVisible}
                            onClose={prerequisitesPopup.close}
                            courseIds={(termCourse.prerequisiteCourses || []).map((item) => item.prerequisiteCourseId)}
                        />
                    )}
                </div>
            </div>
            {canRegister && renderSelectSchedule()}
            {confirmPopup.isVisible && (
                <ConfirmPopup
                    isVisible={confirmPopup.isVisible}
                    onClose={closeConfirmPopup}
                    onConfirm={onConfirmPopup}
                    title={confirmPopupInfo.title}
                    description={confirmPopupInfo.description}
                    cancelLabel={t("common:action.cancel")}
                    confirmLabel={t("common:action.yes")}>
                    {confirmType === ConfirmType.DropCourse && (
                        <div className={styles.dropDateWrap}>
                            <FormLabel label="Drop Date" />
                            <BaseDatePicker value={dropDate} onChange={(newValue) => setDropDate(newValue)} />
                        </div>
                    )}
                </ConfirmPopup>
            )}
            {transferSchedulePopup.isVisible && (
                <SelectSchedulePopup
                    isVisible={transferSchedulePopup.isVisible}
                    onClose={hideTransferSchedulePopup}
                    schedules={filteredSchedules}
                    termCourse={termCourse}
                    registeredScheduleId={termCourse?.registeredScheduleId}
                    studentProfileId={studentId}
                    onRefresh={onRegisterCourseSuccess}
                    title={confirmPopupInfo.title}
                />
            )}
        </>
    )
}
