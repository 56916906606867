/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useState} from "react"
import styles from "./DocumentForm.module.css"
import {StudentActivity} from "types/activity"
import {Auth} from "types/auth"
import {v4 as uuid} from "uuid"
import {Checkbox, Tooltip} from "antd"
import {FileSelect} from "../FileSelect"
import {BaseButton, BaseInput, BaseLoading, Icon} from "components"
import DepartmentSubunitSelect from "components/DepartmentSubunitSelect"
import StudentActivitySelect from "components/StudentActivitySelect"
import StudentChecklistSelect from "components/StudentChecklistSelect"
import {Modules, useEdularModulesContext} from "@edular/modules"
import {getEnableSubunitsByDepartmentPermission, toastError} from "helpers"
import {useModel} from "hooks"
import {useDocumentActions} from "hooks/"
import {PermissionType} from "types/permission"
import UserSelectV3 from "components/UserSelectV3"

type Document = {
    id: string
    file: File
    student?: any
    documentName?: string
    description?: string
    departmentSubunit?: any
    isVisibleForUser: boolean
    linkedActivities: StudentActivity[]
    linkedChecklists: any[]
}

const INITIAL_DOCUMENT_INFO: Document = {
    id: null,
    file: null,
    documentName: "",
    description: "",
    isVisibleForUser: false,
    linkedActivities: [],
    linkedChecklists: []
}

type Props = {
    profileId?: number
    onCancel: () => void
    onDocumentsCreated: () => void
}

export function DocumentForm(props: Props) {
    const {profileId, onCancel, onDocumentsCreated} = props
    const [documents, setDocuments] = useState<Document[]>([])
    const {isModuleEnable} = useEdularModulesContext()
    const [errors, setErrors] = useState<Record<string, string[]>>({})
    const model = useModel()
    const {bulkCreate, isBulkCreating} = useDocumentActions()
    const accessibleSubunitIds = useMemo(() => {
        return getEnableSubunitsByDepartmentPermission(PermissionType.Add, model, "Documents")
    }, [])

    const handleFieldChange = (id: string, key: string, value: any) => {
        setDocuments((prev) =>
            prev.map((x) => {
                if (x.id === id) {
                    if (key === "student") {
                        return {...x, [key]: value, linkedActivities: [], linkedChecklists: []}
                    }
                    return {...x, [key]: value}
                }
                return x
            })
        )
    }

    const onSelectFiles = (attachedFiles) => {
        setDocuments((prev) => {
            const _newItems: Document[] = attachedFiles.map((x) => {
                return {
                    ...INITIAL_DOCUMENT_INFO,
                    isVisibleForUser: !model.isStaffOrAdmin(),
                    file: x,
                    id: uuid()
                }
            })
            return [...prev, ..._newItems]
        })
    }

    const removeDocument = (id: string) => {
        setDocuments((prev) => prev.filter((x) => x.id !== id))
    }

    const validateBeforeSubmit = () => {
        let _errors: Record<string, string[]> = {}
        documents.forEach((x) => {
            let error: string[] = []
            if (x.documentName.trim() === "") {
                error.push("documentName")
            }
            if (!profileId && !x.student) {
                error.push("student")
            }
            if (!x.departmentSubunit) {
                error.push("departmentSubunit")
            }
            if (error.length > 0) {
                _errors[x.id] = error
            }
        })
        setErrors(_errors)

        return Object.keys(_errors).length > 0
    }

    const onSubmit = async () => {
        if (validateBeforeSubmit()) {
            toastError("Please input required information")
            return
        }
        const formData = new FormData()
        documents.forEach((x) => {
            const {
                file,
                student,
                departmentSubunit,
                documentName,
                description,
                isVisibleForUser,
                linkedActivities,
                linkedChecklists,
                id
            } = x
            const fileExtension = file.name.split(".").pop()
            const payload = {
                ownerUserProfileId: profileId ?? student?.profileId,
                ownerUserId: student?.id,
                departmentSubunitId: departmentSubunit.subunitId,
                isVisibleForUser,
                name: documentName,
                description,
                id,
                departmentId: departmentSubunit.departmentId,
                studentChecklistItemIds: (linkedChecklists || []).map((item) => item.studentChecklistItemId),
                studentActivityIds: (linkedActivities || []).map((item) => item.studentActivityId)
            }
            formData.append(`document`, file, `${id}.${fileExtension}`)
            formData.append(`data`, JSON.stringify(payload))
        })
        bulkCreate(formData, {
            onSuccess: onDocumentsCreated
        })
    }

    const isEnableActivity = isModuleEnable(Modules.ActivitiesAndFollowups)
    const gridTemplateColumns = useMemo(() => {
        let frs: number[] = []
        if (profileId) {
            if (model.isStaffOrAdmin()) {
                if (isEnableActivity) {
                    // cols: file, name, description, department, visible, activities, checklists, action
                    frs = [5, 5, 8, 4.5, 2, 2, 2, 0.5]
                } else {
                    // cols: file, name, description,  department, visible, checklists, action
                    frs = [6, 5, 8, 4, 2, 2, 0.5]
                }
            } else {
                if (isEnableActivity) {
                    // cols: file, name, description, department, activities, checklists, action
                    frs = [5, 5, 8, 4.5, 2, 2, 0.5]
                } else {
                    // cols: file, name, description, department, checklists, action
                    frs = [6, 5, 8, 4, 2, 0.5]
                }
            }
        } else {
            if (isEnableActivity) {
                // cols: file, student, name, description, department, visible, activities, checklists, action
                frs = [5, 4, 4, 7, 3, 2, 2, 2, 0.5]
            } else {
                // cols: file, student, name, description, department, visible, checklists, action
                frs = [5, 4, 4, 7, 4, 2, 2, 0.5]
            }
        }

        return frs.map((x) => `minmax(0, ${x}fr)`).join(" ")
    }, [profileId, isEnableActivity])

    return (
        <div className={styles.container}>
            <div className={styles.formTitle}>Upload documents</div>
            <FileSelect onSelectFiles={onSelectFiles} />
            <div className={styles.tableContainer}>
                <div className={styles.rowHeader} style={{gridTemplateColumns}}>
                    <span className={styles.headerTitle}>File</span>
                    {!profileId && (
                        <span className={styles.headerTitle}>
                            Student<span className={styles.star}>*</span>
                        </span>
                    )}
                    <span className={styles.headerTitle}>
                        Document Name<span className={styles.star}>*</span>
                    </span>
                    <span className={styles.headerTitle}>Description</span>
                    <span className={styles.headerTitle}>
                        Department<span className={styles.star}>*</span>
                    </span>
                    {model.isStaffOrAdmin() && <span className={styles.headerTitle}>Visible to user</span>}
                    {isEnableActivity && <span className={styles.headerTitle}>Activities</span>}
                    <span className={styles.headerTitle}>Checklists</span>
                </div>
                {documents.map((document, index) => {
                    const profileIdSelected = profileId ?? document.student?.profileId
                    return (
                        <div key={index} className={styles.row} style={{gridTemplateColumns}}>
                            <span className={styles.docName}>{document.file?.name}</span>
                            {!profileId && (
                                <div className={styles.col}>
                                    <UserSelectV3
                                        type={[Auth.UserProfileType.Student]}
                                        value={document.student}
                                        onChange={(v) => handleFieldChange(document.id, "student", v)}
                                        placeholder="student"
                                        error={(errors[document.id] || []).includes("student")}
                                    />
                                </div>
                            )}
                            <div className={styles.col}>
                                <BaseInput
                                    placeholder="name"
                                    value={document.documentName}
                                    onChange={(v) => handleFieldChange(document.id, "documentName", v)}
                                    error={(errors[document.id] || []).includes("documentName")}
                                />
                            </div>
                            <div className={styles.col}>
                                <BaseInput
                                    placeholder="description"
                                    value={document.description}
                                    onChange={(v) => handleFieldChange(document.id, "description", v)}
                                    error={(errors[document.id] || []).includes("description")}
                                />
                            </div>
                            <div className={styles.col}>
                                <DepartmentSubunitSelect
                                    subunitIds={accessibleSubunitIds}
                                    placeholder="department"
                                    value={document.departmentSubunit}
                                    onChange={(v) => handleFieldChange(document.id, "departmentSubunit", v)}
                                    error={(errors[document.id] || []).includes("departmentSubunit")}
                                />
                            </div>
                            {model.isStaffOrAdmin() && (
                                <div className={styles.checkbox}>
                                    <Checkbox
                                        checked={document.isVisibleForUser}
                                        onChange={(v) =>
                                            handleFieldChange(document.id, "isVisibleForUser", v.target.checked)
                                        }
                                    />
                                </div>
                            )}
                            {isModuleEnable(Modules.ActivitiesAndFollowups) && (
                                <div className={styles.col}>
                                    {profileIdSelected && (
                                        <StudentActivitySelect
                                            key={profileIdSelected}
                                            profileId={profileIdSelected}
                                            value={document.linkedActivities}
                                            onChange={(v) => handleFieldChange(document.id, "linkedActivities", v)}
                                            isMulti
                                        />
                                    )}
                                </div>
                            )}
                            <div className={styles.col}>
                                {profileIdSelected && (
                                    <StudentChecklistSelect
                                        key={profileIdSelected}
                                        profileIds={[profileIdSelected]}
                                        value={document.linkedChecklists}
                                        onChange={(v) => handleFieldChange(document.id, "linkedChecklists", v)}
                                        isMulti
                                    />
                                )}
                            </div>
                            <div className={styles.action}>
                                <Tooltip title="Remove document">
                                    <div className={styles.removeWrap} onClick={() => removeDocument(document.id)}>
                                        <Icon icon="DELETE" className={styles.removeIcon} />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={styles.doneBtn}>
                <BaseButton title="Cancel" onClick={onCancel} variant="secondary" />
                <BaseButton
                    title="Submit"
                    onClick={onSubmit}
                    loading={isBulkCreating}
                    disabled={documents.length <= 0}
                />
            </div>
            <BaseLoading isShow={isBulkCreating} />
        </div>
    )
}
