import {Col, Modal, Row} from "antd"
import {BaseButton} from "components"
import styles from "./PaymentsTable.module.css"
import React, {useCallback} from "react"
import BankAccountSelect from "components/BankAccountSelect"
import {BankAccount} from "types/student-account/bank-account"
import {useTranslation} from "react-i18next"

type PostModalProps = {
    visible: boolean
    onClose: () => void
    onSubmit: (payload: {bankAccountId?: number}) => void
}
const PostModal = ({visible, onClose, onSubmit}: PostModalProps) => {
    const {t} = useTranslation(["studentAccount", "common"])
    const [selectedBankAccount, setSelectedBankAccount] = React.useState<BankAccount | undefined>()

    const handleSubmit = useCallback(() => {
        onSubmit({bankAccountId: selectedBankAccount?.accountId})
    }, [onSubmit, selectedBankAccount])

    return (
        <Modal keyboard={false} closable={false} centered open={visible} onCancel={onClose} footer={null}>
            <h2 className={styles.modalTitle}>Select Bank Account</h2>

            <BankAccountSelect isClearable value={selectedBankAccount} onChange={setSelectedBankAccount} />

            <Row gutter={16} justify="space-between" className="mt-24">
                <Col>
                    <BaseButton title={t("common:action.cancel")} variant="secondary" onClick={onClose} />
                </Col>
                <Col>
                    <BaseButton title={t("common:action.post")} onClick={handleSubmit} />
                </Col>
            </Row>
        </Modal>
    )
}

export default PostModal
