import React, {ReactNode} from "react"
import {BasePopup} from "../BasePopup"
import {BaseButton, SecondaryButton} from "components/buttons/Button"
import styles from "./ConfirmPopup.module.css"
import {useTranslation} from "react-i18next"

type Props = {
    title: string
    isVisible: boolean
    description?: string
    loading?: boolean
    cancelLabel?: string
    confirmLabel?: string
    icon?: string
    children?: ReactNode
    onClose: () => void
    onConfirm: () => void
}

export const ConfirmPopup = (props: Props) => {
    const {
        title,
        isVisible = true,
        description = undefined,
        onClose,
        onConfirm,
        loading = false,
        cancelLabel = "",
        confirmLabel = "",
        icon = "WARNING_SOLID",
        children
    } = props
    const {t} = useTranslation(["common"])
    return (
        <BasePopup isShow={isVisible} onClose={onClose} leftIcon={icon} leftIconColor="#fff" width="400px">
            <div className={styles.root}>
                <div className={styles.title}>{title}</div>
                {description && <div className={styles.body}>{description}</div>}
                {children}
                <div className={styles.footer}>
                    <div className={styles.buttons}>
                        <div className={styles.button}>
                            <SecondaryButton
                                onClick={onClose}
                                title={cancelLabel || t("common:action.cancelModal")}
                                disabled={loading}
                            />
                        </div>
                        <div className={styles.button}>
                            <BaseButton
                                onClick={onConfirm}
                                title={confirmLabel || t("common:action.confirm")}
                                loading={loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </BasePopup>
    )
}
