import {ActiveStudentContext} from "context/ActiveStudentContext"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {useModel, useMutation} from "hooks"
import {get, isEmpty, orderBy, sumBy, uniq} from "lodash"
import React, {useContext, useEffect, useState} from "react"
import {finAidService, fundSourcesService, studentService} from "services"
import {Auth} from "types/auth"
import {Order, PaginationPerPage} from "types/common"
import {BaseDepartmentId} from "types/departments"
import {FilterKey} from "types/filter"
import {FundSource} from "types/fin-aid/fund-source"
import {StatusCards} from "../Common"
import {StudentsTable} from "./parts"
import StudentFilter from "./parts/StudentFilter"
import {Column, ColumnData} from "./parts/StudentsTable/types"
import {FAStatusCards} from "sections/FinancialAid/staffParts/Student/parts/StatusCards"
import {FinancialAidStatusSelect} from "components/FinancialAidStatusSelect"
import NumberFormat from "react-number-format"
import {preventMinusCharacter} from "helpers/inputHelper"
import styles from "./StudentsTab.module.css"
import {useAllFinancialAidStatuses} from "hooks/student-statuses/useAllFinancialAidStatuses"
import {TermEnrollStatusSelect} from "components/TermEnrollStatusSelect"
import {TERM_BILLING_METHOD_OPTIONS, TERM_ENROLL_STATUS_OPTIONS} from "types/students"
import {BillingMethodSelect} from "components/BillingMethodSelect"
import {formatCurrency, formatDateOnly, getFullName, handleError} from "helpers"
import cx from "classnames"
import moment from "moment/moment"

import {FinAidStudentTerm} from "types/fin-aid/fin-aid"
import {ExportFileType} from "components/ui"
import {exportCsv, exportExcel} from "helpers/export-table"
import {v4 as uuid} from "uuid"

type Props = {
    departmentId: BaseDepartmentId
    isSAReport?: boolean
    isSAStudents?: boolean
    isFAStudents?: boolean
    updateState?: (data: any) => void
    exportFileType?: ExportFileType
}

export function StudentsTab({
    departmentId,
    isSAReport = false,
    isSAStudents = false,
    isFAStudents = false,
    updateState,
    exportFileType
}: Props) {
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const filterKey = isFAStudents ? FilterKey.FinAidPage_Students_Priority : FilterKey.StudentAccount_Students
    const storageFilter = model.getStorageFilter(filterKey)
    const [search, setSearch] = useState(isSAReport ? "" : !isEmpty(storageFilter?.search) ? storageFilter.search : "")
    const [filters, setFilters] = useState(
        isSAReport
            ? {}
            : !isEmpty(storageFilter?.filter)
            ? storageFilter.filter
            : {states: [Auth.UserProfileState.Student]}
    )
    const {studentDetailVisible, setStudent, setDepartmentId} = useContext(ActiveStudentContext)
    const {statuses: faStatuses} = useAllFinancialAidStatuses()
    const [fundSources, setFundSources] = React.useState<FundSource[]>([])
    const [isLoading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(PaginationPerPage.item20)
    const [total, setTotal] = useState(0)
    const [records, setRecords] = useState<Auth.DepartmentStudent[]>([])

    // region get data

    const getData = React.useCallback(async () => {
        const getFilter = () => {
            const {
                campus,
                program,
                acadAdvisors,
                faAdvisors,
                admAdvisors,
                term,
                startingTerm,
                checklist,
                status,
                state,
                profileStatus,
                applicationCompletedDate,
                startDateRange,
                createdAtRange,
                inquiryDateRange,
                lastActivityDateRange,
                includeArchive = false,
                showDueOnly = false,
                fullyEnrolled,
                onlySEV,
                sapCheckpoints,
                retentionLevels,
                studentStatusIds,
                admissionsLeadSourceIds,
                leadTypeIds,
                activityIds,
                activityDescription
            } = filters
            const filter: any = {
                departmentId,
                includeArchive,
                showDueOnly,
                onlySEV,
                activityDescription
            }
            if (fullyEnrolled) {
                filter.fullyEnrolled = true
            }
            if (campus) {
                filter.campusIds = campus.map(({id}) => id)
            }
            if (program) {
                filter.programIds = program.map(({id}) => id)
            }
            if (studentStatusIds) {
                filter.studentStatusIds = studentStatusIds.map(({statusId}) => statusId)
            }
            if (admissionsLeadSourceIds) {
                filter.admissionsLeadSourceIds = admissionsLeadSourceIds.map(({id}) => id)
            }
            if (leadTypeIds) {
                filter.leadTypeIds = leadTypeIds
            }

            if (admAdvisors?.length) {
                filter.admAdvisorProfileIds = admAdvisors.map((profile) => profile.profileId)
            }
            if (faAdvisors?.length) {
                filter.faAdvisorProfileIds = faAdvisors.map((profile) => profile.profileId)
            }
            if (acadAdvisors?.length) {
                filter.acadAdvisorProfileIds = acadAdvisors.map((profile) => profile.profileId)
            }

            filter.checklistCompleted = checklist === "completed"
            if (status?.length) {
                filter.statusIds = status.map(({statusId}) => statusId)
            }
            if (state) {
                filter.states = state.map(({id}) => id)
            }
            if (profileStatus) {
                filter.profileStatus = profileStatus.map(({id}) => id === "active")
            }
            if (term) {
                filter.termsIds = term.map(({id}) => id)
            }
            if (startingTerm) {
                filter.startingTermIds = startingTerm.map(({id}) => id)
            }
            if (activityIds) {
                filter.activityIds = activityIds.map(({activityId}) => activityId)
            }
            if (applicationCompletedDate) {
                filter.applicationCompletedDate = moment(applicationCompletedDate).format("YYYY-MM-DD")
            }
            if (startDateRange && startDateRange.length) {
                filter.startDateRange = [
                    moment(startDateRange[0]).format("YYYY-MM-DD"),
                    moment(startDateRange[1]).format("YYYY-MM-DD")
                ]
            }
            if (createdAtRange && createdAtRange.length) {
                filter.createdAtRange = [
                    moment(createdAtRange[0]).startOf("date").toISOString(),
                    moment(createdAtRange[1]).endOf("date").toISOString()
                ]
            }
            if (inquiryDateRange && inquiryDateRange.length) {
                filter.inquiryDateRange = [
                    moment(inquiryDateRange[0]).format("YYYY-MM-DD"),
                    moment(inquiryDateRange[1]).format("YYYY-MM-DD")
                ]
            }
            if (lastActivityDateRange && lastActivityDateRange.length) {
                filter.lastActivityDateRange = [
                    moment(lastActivityDateRange[0]).startOf("date").toISOString(),
                    moment(lastActivityDateRange[1]).endOf("date").toISOString()
                ]
            }
            if (sapCheckpoints) {
                filter.sapCheckpoints = sapCheckpoints.map(({id}) => id)
            }
            if (retentionLevels) {
                filter.retentionLevels = retentionLevels.map(({id}) => id)
            }

            return filter
        }

        try {
            setLoading(true)
            const {data, total} = await studentService.getDepartmentStudents({
                filter: {
                    ...getFilter(),
                    search,
                    states: filters.states,
                    studentTransactionDates: filters.tranxDates,
                    campusIds: filters.campuses?.length ? filters.campuses.map((campus) => campus.id) : undefined,
                    programIds: filters.programs?.length ? filters.programs.map((program) => program.id) : undefined,
                    statusIds: filters.statuses?.length ? filters.statuses.map((status) => status.statusId) : undefined,
                    studentStatusIds: filters.studentStatuses?.length
                        ? filters.studentStatuses.map((status) => status.statusId)
                        : undefined,
                    enrollmentStatus: filters.enrollmentStatus ? filters.enrollmentStatus.id : undefined,
                    enrollmentType: filters.enrollmentType ? filters.enrollmentType.id : undefined
                },
                sort: {orderBy: "firstName", orderDir: Order.Asc},
                range: {page, pageSize},
                isSAReport: isSAReport || isSAStudents,
                isFAStudents
            })
            setRecords(
                data.map((record) => ({
                    ...record,
                    id: `${record.profileId}_${record.faTermId || record.termId || uuid()}`
                }))
            )
            setTotal(total)
        } catch (error) {
            handleError(error)
        } finally {
            setLoading(false)
        }
    }, [departmentId, search, filters, page, pageSize, isSAReport, isSAStudents, isFAStudents])

    useEffect(() => {
        getData()
    }, [getData])

    // endregion get data

    const updateFAStudentTerm = useMutation(async (data: Partial<FinAidStudentTerm>) => {
        await finAidService.updateStudentTerm(data)
    }, {})

    // region columns

    const columns = React.useMemo(() => {
        const cols: Column[] = [
            {field: "customProfileId", title: "Enrollment ID"},
            {field: "profileState", title: "User State"},
            {field: "firstName", title: "First Name"},
            {field: "lastName", title: "Last Name"}
        ]

        if (isFAStudents) {
            cols.push(
                ...[
                    {
                        field: "faTermName",
                        title: "Term",
                        render: (column, record) => record.faTermName || record.termName
                    },
                    {field: "termCredits", title: "Term Credits"},
                    {
                        field: "faStatusId",
                        title: "FA Status",
                        editable: {
                            render: (column: Column, record: typeof records[0]) => (
                                <div className="w-full" onClick={(e) => e.stopPropagation()}>
                                    <FinancialAidStatusSelect
                                        value={faStatuses.find(
                                            (status) => status.statusId === get(record, column.field)
                                        )}
                                        onChange={(option) => {
                                            const profileId = record.profileId
                                            const termId = record.faTermId || record.termId
                                            const faStatusId = option?.statusId ?? null
                                            setRecords((oldRecords) =>
                                                oldRecords.map((oldRecord) =>
                                                    oldRecord.id !== record.id ? oldRecord : {...oldRecord, faStatusId}
                                                )
                                            )
                                            updateFAStudentTerm.mutate({
                                                profileId,
                                                termId,
                                                faStatusId,
                                                faStatusUpdatedAt: new Date().toISOString(),
                                                faStatusUpdatedByProfileId: model.profileId
                                            })
                                        }}
                                    />
                                </div>
                            )
                        }
                    },
                    {
                        field: "termEnrollStatus",
                        title: "Term Enroll Status",
                        editable: {
                            render: (column: Column, record: typeof records[0]) => (
                                <div className="w-full" onClick={(e) => e.stopPropagation()}>
                                    <TermEnrollStatusSelect
                                        value={TERM_ENROLL_STATUS_OPTIONS.find(
                                            (option) => option.id === get(record, column.field)
                                        )}
                                        onChange={(option) => {
                                            const profileId = record.profileId
                                            const termId = record.faTermId || record.termId
                                            const termEnrollStatus = option?.id ?? null
                                            setRecords((oldRecords) =>
                                                oldRecords.map((oldRecord) =>
                                                    oldRecord.id !== record.id
                                                        ? oldRecord
                                                        : {
                                                              ...oldRecord,
                                                              termEnrollStatus
                                                          }
                                                )
                                            )
                                            updateFAStudentTerm.mutate({
                                                profileId,
                                                termId,
                                                termEnrollStatus
                                            })
                                        }}
                                    />
                                </div>
                            )
                        }
                    },
                    {
                        field: "termBillingMethod",
                        title: "Billing Method",
                        editable: {
                            render: (column: Column, record: typeof records[0]) => (
                                <div className="w-full" onClick={(e) => e.stopPropagation()}>
                                    <BillingMethodSelect
                                        value={TERM_BILLING_METHOD_OPTIONS.find(
                                            (option) => option.id === get(record, column.field)
                                        )}
                                        onChange={(option) => {
                                            const profileId = record.profileId
                                            const termId = record.faTermId || record.termId
                                            const termBillingMethod = option?.id ?? null
                                            setRecords((oldRecords) =>
                                                oldRecords.map((oldRecord) =>
                                                    oldRecord.id !== record.id
                                                        ? oldRecord
                                                        : {
                                                              ...oldRecord,
                                                              termBillingMethod
                                                          }
                                                )
                                            )
                                            updateFAStudentTerm.mutate({
                                                profileId,
                                                termId,
                                                termBillingMethod
                                            })
                                        }}
                                    />
                                </div>
                            )
                        }
                    }
                ]
            )
        }

        cols.push(
            {field: "lastDayOfAttendance", title: "LDA"},
            {field: "faReport.totalCharges", title: "Total Charges"},
            {field: "faReport.arBalance", title: "AR Balance"},
            {field: "faReport.institutionalCharges", title: "Institutional Charges"}
        )

        cols.push(
            ...fundSources.map((fundSource) => ({
                field: `faReport.fs${fundSource.fundSourceId}`,
                title: `${fundSource.code} - ${fundSource.title}`,
                titleHtml: (
                    <span>
                        {fundSource.code}
                        <br />
                        {fundSource.title}
                    </span>
                ),
                backgroundColor: "#edffd4"
            }))
        )

        if (isFAStudents) {
            cols.push(
                ...[
                    {
                        field: "vaBenefit",
                        title: "VA Benefit",
                        backgroundColor: "#e9f4ff",
                        editable: {
                            render: (column: Column, record: typeof records[0]) => (
                                <div className="w-full" onClick={(e) => e.stopPropagation()}>
                                    <NumberFormat
                                        className={styles.inputNumber}
                                        prefix="$"
                                        thousandSeparator
                                        decimalScale={2}
                                        fixedDecimalScale={false}
                                        onKeyDown={preventMinusCharacter}
                                        min={0}
                                        value={get(record, column.field)}
                                        onValueChange={({floatValue}) => {}}
                                        onBlur={(e) => {
                                            const profileId = record.profileId
                                            const termId = record.faTermId || record.termId
                                            const vaBenefit = Number(e.target.value.replace(/[^0-9.-]+/g, ""))
                                            setRecords((oldRecords) =>
                                                oldRecords.map((oldRecord) =>
                                                    oldRecord.id !== record.id ? oldRecord : {...oldRecord, vaBenefit}
                                                )
                                            )
                                            updateFAStudentTerm.mutate({
                                                profileId,
                                                termId,
                                                vaBenefit
                                            })
                                        }}
                                    />
                                </div>
                            )
                        }
                    },
                    {
                        field: "estimatedAid",
                        title: "Estimated Aid",
                        backgroundColor: "#e9f4ff",
                        editable: {
                            render: (column: Column, record: typeof records[0]) => (
                                <div className="w-full" onClick={(e) => e.stopPropagation()}>
                                    <NumberFormat
                                        className={styles.inputNumber}
                                        prefix="$"
                                        thousandSeparator
                                        decimalScale={2}
                                        fixedDecimalScale={false}
                                        onKeyDown={preventMinusCharacter}
                                        min={0}
                                        value={get(record, column.field)}
                                        onValueChange={({floatValue}) => {}}
                                        onBlur={(e) => {
                                            const profileId = record.profileId
                                            const termId = record.faTermId || record.termId
                                            const estimatedAid = Number(e.target.value.replace(/[^0-9.-]+/g, ""))
                                            setRecords((oldRecords) =>
                                                oldRecords.map((oldRecord) =>
                                                    oldRecord.id !== record.id
                                                        ? oldRecord
                                                        : {
                                                              ...oldRecord,
                                                              estimatedAid
                                                          }
                                                )
                                            )
                                            updateFAStudentTerm.mutate({
                                                profileId,
                                                termId,
                                                estimatedAid
                                            })
                                        }}
                                    />
                                </div>
                            )
                        }
                    },
                    {
                        field: "cashNeeded",
                        title: "Cash Needed",
                        backgroundColor: "#ffecc7",
                        render: (column: Column, record: typeof records[0]) =>
                            formatCurrency(record.paymentPlan?.originalAmount),
                        renderText: (_, record: typeof records[0]) =>
                            (record.paymentPlan?.originalAmount ?? 0).toFixed(2)
                    },
                    {
                        field: "cashReceived",
                        title: "Cash Received",
                        backgroundColor: "#ffecc7",
                        render: (column: Column, record: typeof records[0]) =>
                            formatCurrency(sumBy(record.paymentPlan?.items ?? [], "paidAmount")),
                        renderText: (_, record: typeof records[0]) =>
                            sumBy(record.paymentPlan?.items ?? [], "paidAmount").toFixed(2)
                    },
                    {
                        field: "cashDeferred",
                        title: "Cash Deferred",
                        backgroundColor: "#ffecc7",
                        render: (column: Column, record: typeof records[0]) => formatCurrency(0),
                        renderText: (_, record: typeof records[0]) => (0).toFixed(2)
                    },
                    {
                        field: "termFinanceReview",
                        title: "TFR",
                        editable: {
                            render: (column: Column, record: typeof records[0]) => {
                                return record.tfrActivity?.activity?.name
                            }
                        }
                    },
                    {
                        field: "tfrDueDate",
                        title: "TFR Due Date",
                        render: (column: Column, record: typeof records[0]) =>
                            formatDateOnly(record.tfrActivity?.dueDate, userDateFormat)
                    },
                    {
                        field: "tfrUpdatedAt",
                        title: "TFR Last Mod",
                        render: (column: Column, record: typeof records[0]) =>
                            formatDateOnly(record.tfrActivity?.updatedAt, userDateFormat)
                    },
                    {
                        field: "paymentPlan",
                        title: "Payment Plan",
                        render: (column: Column, record: typeof records[0]) => record.paymentPlan?.title
                    },
                    {
                        field: "paymentPlanDueDate",
                        title: "Payment Plan Due Date",
                        render: (column: Column, record: typeof records[0]) => {
                            const upcomingItem = record.paymentPlan?.items?.find((item) =>
                                moment().isBefore(item.dueDate)
                            )
                            return formatDateOnly(upcomingItem?.dueDate, userDateFormat)
                        }
                    },
                    {
                        field: "admissionAdvisors",
                        title: "Admission Advisor",
                        render: (column: Column, record: typeof records[0]) =>
                            uniq(record.admissionAdvisors?.map((advisor) => getFullName(advisor))).join(", ")
                    },
                    {
                        field: "academicsAdvisors",
                        title: "Academic Advisor",
                        render: (column: Column, record: typeof records[0]) =>
                            uniq(record.academicsAdvisors?.map((advisor) => getFullName(advisor))).join(", ")
                    },
                    {
                        field: "finAidAdvisors",
                        title: "Financial Aid Advisor",
                        render: (column: Column, record: typeof records[0]) =>
                            uniq(record.finAidAdvisors?.map((advisor) => getFullName(advisor))).join(", ")
                    },
                    {
                        field: "email",
                        title: "Email"
                    }
                ]
            )
        } else {
            cols.push(
                ...[
                    {
                        field: "faReport.totalTitleIVReceipts",
                        title: "Total Title IV Receipts",
                        backgroundColor: "#e9f4ff"
                    },
                    {field: "totalR2T4", title: "Total Title IV Returns (R2T4)", backgroundColor: "#e9f4ff"},
                    {
                        field: "totalTitleIVNet",
                        title: "Total Title IV Net (inc T4 Credit Balance)",
                        backgroundColor: "#e9f4ff"
                    },
                    {
                        field: "instChargesLessFundsApplied",
                        title: "Inst. Charges less funds applied first less T4",
                        backgroundColor: "#e9f4ff"
                    },
                    {
                        field: "titleIVRevenueAdjustment",
                        title: "Title IV Revenue Adjustment",
                        backgroundColor: "#e9f4ff"
                    },
                    {field: "faReport.remainingBalance", title: "Remaining Balance", backgroundColor: "#e9f4ff"},
                    {field: "faReport.totalPayments", title: "Student Payments included", backgroundColor: "#ffecc7"},
                    {field: "numerator", title: "Numerator", backgroundColor: "#e9f4ff"},
                    {field: "titleRevenueAdjustment", title: "Title Revenue Adjustment", backgroundColor: "#e9f4ff"},
                    {field: "faReport.totalTitleIV", title: "Total Title IV", backgroundColor: "#e9f4ff"},
                    {field: "denominator", title: "Denominator", backgroundColor: "#e9f4ff"},
                    {field: "ninetyTenPercentage", title: "90/10 %", backgroundColor: "#e9f4ff"},
                    // {field: "amountsReduced", title: "Amounts Reduced (WIA etc)", backgroundColor: "#e9f4ff"},
                    {
                        field: "amountsReducedOther",
                        title: "Amounts Reduced Other Non-Title IV",
                        backgroundColor: "#e9f4ff"
                    },
                    {field: "totalNinetyTenTrans", title: "Total of 90/10 Trans", backgroundColor: "#e9f4ff"}
                ]
            )
        }

        return cols.filter(Boolean)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDateFormat, fundSources, faStatuses, isFAStudents])

    const fixedColumns = React.useMemo(() => {
        const columns = ["customProfileId", "profileState", "firstName", "lastName"]
        if (isFAStudents) {
            columns.push("term")
        } else {
            columns.push("lastDayOfAttendance")
        }
        return columns.filter(Boolean)
    }, [isFAStudents])

    const [visibleColumns, setVisibleColumns] = useState(columns.map((column) => column.field))

    const onChangeVisibleColumns = (fields: string[]) => {
        setVisibleColumns(fields)
    }

    const initialColumnData: Record<string, ColumnData> = React.useMemo(
        () => ({
            customProfileId: {min: 50, max: 150, width: 135},
            profileState: {min: 100, max: 200, width: 110},
            firstName: {min: 100, max: 300, width: 120},
            lastName: {min: 100, max: 300, width: 120},
            lastDayOfAttendance: {min: 80, max: 150, width: 100},
            "faReport.totalCharges": {min: 80, max: 150, width: 120},
            "faReport.arBalance": {min: 80, max: 150, width: 120},
            "faReport.institutionalCharges": {min: 80, max: 150, width: 120},
            termName: {min: 50, max: 150, width: 100}
        }),
        []
    )

    // endregion columns

    React.useEffect(() => {
        ;(async function getFundSources() {
            const {data: fundSources} = await fundSourcesService.getFundSources({
                filter: {isActive: true},
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                sort: {
                    orderBy: "firstAppliedOrder",
                    orderDir: Order.Asc
                }
            })
            setFundSources(
                orderBy(
                    fundSources.filter((fundSource: FundSource) => fundSource.isActive && fundSource.visibleInFATable),
                    ["firstAppliedOrder", "position"],
                    ["asc", "asc"]
                )
            )
            setVisibleColumns((prevData) => [
                ...prevData,
                ...fundSources.map(({fundSourceId}) => `faReport.fs${fundSourceId}`)
            ])
        })()
    }, [])

    useEffect(() => {
        if (!isSAReport) {
            model.updateStorageFilter(filterKey, {filter: filters})
        }
        // eslint-disable-next-line
    }, [filterKey, filters, isSAReport])

    useEffect(() => {
        if (!isSAReport) {
            model.updateStorageFilter(filterKey, {search})
        }
        // eslint-disable-next-line
    }, [filterKey, search, isSAReport])

    useEffect(() => {
        if (exportFileType) {
            ;(async function handleExportFileTypeSelect() {
                try {
                    updateState({isExporting: true})
                    const reportDate = moment().format(userDateTimeFormat)
                    const payload = {
                        filename: "Financial Aid - Students",
                        columns: [...columns.map((col) => col.title), "Report Date"],
                        rows: records.map((item) => [
                            ...columns.map((col) => {
                                const value = col.renderText
                                    ? col.renderText(get(item, col.field), item)
                                    : col.render
                                    ? col.render(get(item, col.field), item)?.toString?.()
                                    : get(item, col.field)?.toString?.()
                                return (
                                    (exportFileType === "csv" ? value?.replaceAll(/[^a-zA-Z0-9 -]/g, "") : value) || ""
                                )
                            }),
                            reportDate
                        ])
                    }
                    if (exportFileType === "csv") {
                        exportCsv(payload)
                    } else if (exportFileType === "excel") {
                        exportExcel(payload)
                    }
                } catch (error) {
                    handleError(error)
                } finally {
                    updateState({isExporting: false, exportFileType: null})
                }
            })()
        }
    }, [columns, records, updateState, exportFileType, userDateTimeFormat])

    // region cell render

    const renderCell = React.useCallback(
        (column: Column, record: typeof records[0]) => {
            if (column.editable) {
                return column.editable.render(column, record)
            }

            if (column.render) {
                return <div className={styles.item}>{column.render(column, record)}</div>
            }

            const field = column.field
            const value = get(record, field)
            if (field.startsWith("faReport.fs")) {
                return <div className={cx(styles.item, styles.cellMoney)}>{formatCurrency(value as number)}</div>
            }
            switch (field) {
                case "lastDayOfAttendance":
                    return <div className={styles.item}>{value ? moment(value).format(userDateFormat) : ""}</div>
                case "statusCashIn":
                case "statusCashOut":
                    return (
                        <p
                            className={cx(styles.item, {
                                [styles.status]: !!value,
                                [styles.status__complete]: value === "complete"
                            })}>
                            {value}
                        </p>
                    )
                case "faReport.totalCharges":
                case "faReport.arBalance":
                case "faReport.institutionalCharges":
                case "faReport.totalTitleIVReceipts":
                case "faReport.totalTitleIV":
                case "faReport.remainingBalance":
                case "faReport.totalPayments":
                    return <div className={cx(styles.item, styles.cellMoney)}>{formatCurrency(value as number)}</div>
                default:
                    return <div className={styles.item}>{value}</div>
            }
        },
        [userDateFormat]
    )

    const renderTotalCell = React.useCallback(
        (field: string) => {
            if (
                [
                    "faReport.totalCharges",
                    "faReport.arBalance",
                    "faReport.institutionalCharges",
                    "faReport.totalTitleIVReceipts",
                    "faReport.remainingBalance",
                    "faReport.totalPayments",
                    "faReport.totalTitleIV"
                ].includes(field) ||
                field.startsWith("faReport.fs")
            ) {
                return (
                    <div className={cx(styles.item, styles.itemTotal, styles.cellMoney)}>
                        {formatCurrency(sumBy(records, field))}
                    </div>
                )
            }
            return null
        },
        [records]
    )

    const handleRowClick = React.useCallback(
        (record: typeof records[0]) => {
            if (isSAReport) return
            setStudent(record as unknown as Auth.DepartmentStudent)
            setDepartmentId(departmentId)
            studentDetailVisible.open()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [departmentId, isSAReport]
    )

    // endregion cell render

    return (
        <div>
            {!isSAReport && departmentId === BaseDepartmentId.StudentAccount && <StatusCards />}

            {departmentId === BaseDepartmentId.FinancialAid && <FAStatusCards />}

            {departmentId === BaseDepartmentId.StudentAccount && (
                <StudentFilter
                    {...{
                        columns,
                        visibleColumns,
                        onChangeVisibleColumns,
                        search,
                        setSearch,
                        initialFilter: filters,
                        applyFilter: setFilters
                    }}
                />
            )}

            <StudentsTable<typeof records[0]>
                {...{
                    columns,
                    visibleColumns,
                    fixedColumns,
                    initialColumnData,
                    totalRows: ["TOTAL"],
                    onRowClick: handleRowClick,
                    renderCell,
                    renderTotalCell,
                    page,
                    pageSize,
                    setPage,
                    setPageSize,
                    isLoading,
                    total,
                    records
                }}
            />
        </div>
    )
}
