/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from "react"
import {Modules, useEdularModulesContext} from "@edular/modules"
import {useTranslation} from "react-i18next"
import moment from "moment"
import cx from "classnames"
import {Button, Col, Dropdown, Menu, Row, Spin, Tooltip} from "antd"
import {get, isEmpty, uniq} from "lodash"
import {useHistory} from "react-router-dom"
import {Icon} from "components/Icon"
import {
    checkPermission,
    formatDateOnly,
    getFieldLabel,
    getFullNameOrPreferredName,
    getShortName,
    handleError
} from "helpers"
import {studentActivitiesService, studentService, userServiceV3} from "services"
import {useAllEnrollmentTypes, useModel, useMutation} from "hooks"
import {BaseDepartmentId} from "types/departments"
import {Auth} from "types/auth"
import {stringifyUrl} from "query-string"
import {StudentStatus} from "types/students"
import {Permissions} from "types/permission"
import {AdvisorTitle} from "./parts"
import styles from "./StudentInfo.module.css"
import orderBy from "lodash/orderBy"
import {AcademicsForm, AdmissionsForm, FinancialAidForm, StudentServicesForm} from "./parts/StudentDetailsEdit"
import {Settings} from "types/settings"
import {BaseLoading} from "components"
import {genderPronounOptionsKeyById} from "sections/users/Detail/parts/Personal/parts/GeneralDetail"
import {StudentServicesStaffTab} from "sections/StudentServices/StudentServicesStaff/StudentServicesStaff"
import {RegistrarStaffTab} from "sections/academics/registrar/RegistrarStaff/RegistrarStaff"
import {ActiveStudentContext, ActiveStudentProvider} from "context/ActiveStudentContext"
import {NewStudentStatusSelect} from "components/NewStudentStatusSelect"
import {UserType} from "types/tasks"
import {RegistrarStudentTab} from "sections/academics/student/AcademicsStudent"

import {useAllDepartmentSubunits, useAllStudentDepartmentStatuses} from "hooks"
import {getFirstProfile} from "helpers/task"

type Props = {
    student: Auth.DepartmentStudent | Auth.UserProfile
    departmentId?: number
    isDetailButtonVisible: boolean
    detailUrl?: string
    fromPage?: string
    backUrl?: string
    backCalendarUrl?: string
    advisorTitle?: AdvisorTitle
    isShowHeaderEditIcon?: boolean
    onChangeStage?: (stageId: number) => void
    onChangeStatus?: (status: Auth.StudentDepartmentStatusDetails) => void
    onChangeStudentStatus?: (data: {profileId: number; statusId: number}) => void
    onChangeFinancialAidStatus?: (data: {profileId: number; statusId: number}) => void
    reLoadStudent?: (studentProfileId: number) => void
    renderExtraPhoneEmail?: (props) => JSX.Element
    renderLeftInfo?: (props) => JSX.Element
    renderRightInfo?: (props) => JSX.Element
    onLoadStudentStats?: (studentStats: Auth.StudentStatistics) => void
    fromUserSection?: boolean
}

const studentDetailUrls = {
    academics: {
        goStudentDetailUrl: "/academics/registrar/users/",
        backUrl: `/academics/registrar/staff?tab=${RegistrarStaffTab.Students}`
    },
    admissions: {
        goStudentDetailUrl: "/users/",
        backUrl: "/admissions/staff?tab=2"
    },
    "financial-aid": {
        goStudentDetailUrl: "/users/",
        backUrl: "/financial-aid/staff?tab=students"
    },
    "student-services": {
        goStudentDetailUrl: "/users/",
        backUrl: `/student-services/staff?tab=${StudentServicesStaffTab.Students}`
    },
    "academic-affairs": {
        goStudentDetailUrl: "/users/",
        backUrl: `/academic-affairs/staff?tab=${StudentServicesStaffTab.Students}`
    },
    practical: {
        goStudentDetailUrl: "/users/",
        backUrl: `/academics/practical/`
    }
}

export type DefaultStatus = {
    statusId: number
    name: string
}

export function StudentInfoView(props: Props) {
    const {
        student,
        departmentId,
        detailUrl,
        isDetailButtonVisible,
        isShowHeaderEditIcon,
        onChangeStage,
        onChangeStatus,
        onChangeStudentStatus,
        onChangeFinancialAidStatus,
        onLoadStudentStats,
        renderExtraPhoneEmail,
        renderLeftInfo,
        renderRightInfo,
        fromUserSection = false
    } = props
    const {isModuleEnable} = useEdularModulesContext()
    const {setStudent, setDepartmentId, studentAdvisors, advisorsPopup} = useContext(ActiveStudentContext)
    const model = useModel()
    const {statuses} = useAllStudentDepartmentStatuses()
    const [holdActivities, setHoldActivities] = useState([])
    const {subunits} = useAllDepartmentSubunits()
    const [isLoading, setIsLoading] = useState(false)
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [studentProfileId, setStudentProfileId] = useState(null)
    const [systemStatus, setSystemStatus] = useState("")
    const [newStudentStatus, setNewStudentStatus] = useState<Partial<StudentStatus>>()
    const [academicStatus, setAcademicStatus] = useState<DefaultStatus>()
    const [admissionsStatus, setAdmissionsStatus] = useState<DefaultStatus>()
    const [financialAidStatus, setFinancialAidStatus] = useState<DefaultStatus>()
    const [studentAccountStatus, setStudentAccountStatus] = useState<DefaultStatus>()
    const [lastDayOfAttendance, setLastDayOfAttendance] = useState("")
    const [studentStats, setStudentStats] = useState(null)
    const [isShowForm, setIsShowForm] = useState<boolean>(false)
    const [userData, setUserData] = useState<Auth.UserProfile>(null)
    const [isLoadingUser, setIsLoadingUser] = useState(false)

    const {t} = useTranslation(["common", "chat", "financialAid", "user"])
    const history = useHistory()
    const isStaff = model.isStaffOrAdmin()
    const defaultFormat = model.getUserDateFormat() || "MM/DD/YYYY"
    const customUserId = get(student, "customUserId")
    const studentProfiles = (userData?.profiles || []).filter(
        (profile) => !!profile.active && profile.state && profile.type === Auth.UserProfileType.Student
    )
    const activeProfile = studentProfiles?.find((profile) => profile.id === studentProfileId)
    const userId = userData?.id
    const customProfileId = activeProfile?.customProfileId
    const {enrollmentTypes} = useAllEnrollmentTypes()

    useEffect(() => {
        if (studentProfileId) {
            getUserData(studentProfileId)
            getStudentStatuses()
        }
    }, [studentProfileId])

    useEffect(() => {
        if (student) {
            const studentProfileId = !isEmpty(student.profiles)
                ? getFirstProfile(student as Auth.UserProfile, Auth.UserProfileType.Student).id
                : +get(student, "profileId")
            setStudentProfileId(studentProfileId)
            setDepartmentId(departmentId)
            if (studentProfileId && departmentId) {
                loadWarnings(studentProfileId)
            }
        }
    }, [student, departmentId])

    useEffect(() => {
        if (studentProfileId && userData) {
            getStudentStatistics(studentProfileId)
        }
    }, [departmentId, studentProfileId, userData])

    const getUserData = async (profileId: number) => {
        try {
            setIsLoadingUser(true)
            const {
                data: [user]
            } = await userServiceV3.getAll({
                filter: {profileId: [profileId]},
                linkedObjects: true
            })
            setUserData(user)

            const {
                data: [student]
            } = await studentService.getDepartmentStudents({
                filter: {profileIds: [profileId], departmentId}
            })
            setStudent(student)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoadingUser(false)
        }
    }

    const getStudentStatistics = async (studentProfileId) => {
        try {
            const data = await studentService.getOverallStats({studentProfileId})
            setStudentStats(data)
            const activeProfile = userData?.profiles?.find((profile) => profile.id === studentProfileId)

            data.sapStatus = activeProfile?.sapStatus
            data.phaseId = activeProfile?.phaseId
            data.shiftId = activeProfile?.shiftId
            const studentLda = data.lastDayOfAttendance
            if (onLoadStudentStats) {
                onLoadStudentStats(data)
            }
            setLastDayOfAttendance(formatDateOnly(studentLda, defaultFormat))
        } catch (error) {
            handleError(error)
        }
    }

    const getStudentStatuses = async () => {
        try {
            if (studentProfileId) {
                setIsLoading(true)
                const {data: statuses} = await studentService.getStudentStatuses(studentProfileId)
                let systemStatusProfile = ""
                const academicStatus =
                    statuses.find((status) => status && status.departmentId === BaseDepartmentId.Academics) || {}
                const admissionsStatus =
                    statuses.find((status) => status && status.departmentId === BaseDepartmentId.Admissions) || {}
                const financialAidStatus =
                    statuses.find((status) => status && status.departmentId === BaseDepartmentId.FinancialAid) || {}
                const studentAccountStatus =
                    statuses.find((status) => status && status.departmentId === BaseDepartmentId.StudentAccount) || {}

                if (student?.profileState) {
                    systemStatusProfile = [Auth.StudentState.Applicant, Auth.StudentState.Prospect].includes(
                        student.profileState
                    )
                        ? student.profileState || "active"
                        : !!student?.profileStatus
                        ? "active"
                        : "inactive"
                }

                if (student?.profiles?.length) {
                    const currentProfile = student.profiles.find((profile) => profile.id === studentProfileId)
                    systemStatusProfile = [Auth.UserProfileState.Applicant, Auth.UserProfileState.Prospect].includes(
                        currentProfile?.state
                    )
                        ? currentProfile?.applicationState || "active"
                        : currentProfile?.active
                        ? "active"
                        : "inactive"
                    setNewStudentStatus({
                        statusId: currentProfile.status?.statusId,
                        name: currentProfile.status?.name,
                        hexColor: currentProfile.status?.hexColor,
                        state: currentProfile.state
                    })
                }
                setSystemStatus(systemStatusProfile)
                setAcademicStatus({
                    statusId: academicStatus?.status?.statusId || 0,
                    name: academicStatus?.status?.name || "Not Defined"
                })
                setAdmissionsStatus({
                    statusId: admissionsStatus?.status?.statusId || 0,
                    name: admissionsStatus?.status?.name || "Not Defined"
                })
                setFinancialAidStatus({
                    statusId: financialAidStatus?.status?.statusId || 0,
                    name: financialAidStatus?.status?.name || "Not Defined"
                })
                setStudentAccountStatus({
                    statusId: studentAccountStatus?.status?.statusId || 0,
                    name: studentAccountStatus?.status?.name || "Not Defined"
                })
                if (onChangeStage) {
                    let stageId = null
                    switch (departmentId) {
                        case BaseDepartmentId.Academics:
                            stageId = academicStatus?.status?.stageId
                            break
                        case BaseDepartmentId.Admissions:
                            stageId = admissionsStatus?.status?.stageId
                            break
                        case BaseDepartmentId.FinancialAid:
                            stageId = financialAidStatus?.status?.stageId
                            break
                        case BaseDepartmentId.StudentAccount:
                            stageId = studentAccountStatus?.status?.stageId
                            break
                        default:
                            break
                    }
                    if (stageId) {
                        onChangeStage(stageId)
                    }
                }
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const loadWarnings = async (studentProfileId) => {
        const departmentSubunitIds = subunits
            .filter((subunit) => subunit.departmentId === departmentId)
            .map((subunit) => subunit.subunitId)
        const body = {
            filter: {
                ...(departmentSubunitIds.length && {departmentSubunitIds: departmentSubunitIds}),
                profileId: studentProfileId
            }
        }
        const {data: holdActivities} = await studentActivitiesService.getHoldActivities(body)
        setHoldActivities(holdActivities)
    }

    const checkHasPermissionToUpdateAdvisor = () => {
        if (!departmentId) {
            return isStaff
        }
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return checkPermission({staff: [Permissions.Staff.Admissions.Students.UpdateAdvisor.Edit]}, model)
            case BaseDepartmentId.Academics:
                return checkPermission(
                    {staff: [Permissions.Staff.Academics.Registrar.Students.UpdateAdvisor.Edit]},
                    model
                )
            case BaseDepartmentId.FinancialAid:
                return checkPermission({staff: [Permissions.Staff.FinancialAid.Students.UpdateAdvisor.Edit]}, model)
            case BaseDepartmentId.StudentAccount:
                return checkPermission({staff: [Permissions.Staff.StudentAccount.Students.UpdateAdvisor.Edit]}, model)
            case BaseDepartmentId.StudentServices:
                return checkPermission(
                    {
                        staff: [
                            Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students.UpdateAdvisor
                                .Edit
                        ]
                    },
                    model
                )
            default:
                return false
        }
    }

    const toggleShowDetail = () => {
        setIsShowDetail(!isShowDetail)
    }

    const goDetailView = () => {
        let pushOptions: any = {pathname: `/${detailUrl}/student/${studentProfileId}`, state: null}
        if (props.backUrl) {
            pushOptions = stringifyUrl(
                {
                    url: `/${detailUrl}/student/${studentProfileId}`,
                    query: {
                        tab: RegistrarStudentTab.Workspace,
                        backUrl: props.backUrl,
                        department: departmentId
                    }
                },
                {skipNull: true}
            )
        }
        history.push(pushOptions)
    }

    const goStudentDetail = () => {
        if (userId) {
            let pushOptions = {pathname: `/users/${userId}`, state: null}
            if (props.fromPage) {
                pushOptions = {
                    pathname: `${studentDetailUrls[props.fromPage].goStudentDetailUrl}${userId}`,
                    state: {backUrlFromPage: props.backUrl || studentDetailUrls[props.fromPage].backUrl}
                }
            }
            pushOptions.state = {...pushOptions.state, edit: true, defaultRole: UserType.STUDENT}
            history.push(pushOptions)
        }
    }

    const goCalendar = () => {
        let pushOptions = {pathname: `/calendar`, state: null}
        if (props.backCalendarUrl) {
            pushOptions = {
                pathname: `/academics/registrar/calendar`,
                state: {backUrl: props.backCalendarUrl}
            }
        }
        history.push(pushOptions)
    }

    const goFerpa = () => {
        if (userId) {
            let pushOptions = {pathname: `/users/${userId}`, search: `?tab=${Auth.UserDetailTab.FERPA}`, state: null}
            if (props.fromPage) {
                pushOptions = {
                    pathname: `${studentDetailUrls[props.fromPage].goStudentDetailUrl}${userId}`,
                    search: `?tab=${Auth.UserDetailTab.FERPA}`,
                    state: {backUrlFromPage: props.backUrl || studentDetailUrls[props.fromPage].backUrl}
                }
            }
            pushOptions.state = {...pushOptions.state, edit: true}
            history.push(pushOptions)
        }
    }

    const goDocuments = () => {
        if (userId) {
            let pushOptions = {
                pathname: `/users/${userId}`,
                search: `?tab=${Auth.UserDetailTab.Documents}`,
                state: null
            }
            if (props.fromPage) {
                pushOptions = {
                    pathname: `${studentDetailUrls[props.fromPage].goStudentDetailUrl}${userId}`,
                    search: `?tab=${Auth.UserDetailTab.Documents}`,
                    state: {backUrlFromPage: props.backUrl || studentDetailUrls[props.fromPage].backUrl}
                }
            }
            pushOptions.state = {...pushOptions.state, edit: true}
            history.push(pushOptions)
        }
    }

    const handleChangeStatus = async (newStatus, departmentId) => {
        await updateCurrentStatus(studentProfileId, newStatus, departmentId)
        if (props.onChangeStage) {
            props.onChangeStage(newStatus.stageId)
        }
        onChangeStatus?.({profileId: studentProfileId, statusId: newStatus.statusId, departmentId})
    }

    const updateNewStudentStatus = async (newStatus: StudentStatus) => {
        await studentService.updateNewStudentStatus({studentProfileId, statusId: newStatus.statusId})
        onChangeStudentStatus?.({profileId: studentProfileId, statusId: newStatus.statusId})
    }

    const handleNewStudentStatus = (newStatus: StudentStatus) => {
        updateNewStudentStatusMutate.mutate(newStatus)
    }

    const updateNewStudentStatusMutate = useMutation(updateNewStudentStatus, {
        onMutate: (newStatus: StudentStatus) => {
            setNewStudentStatus({
                statusId: newStatus.statusId,
                name: newStatus.name,
                hexColor: newStatus.hexColor,
                state: newStatus.state
            })
            const oldStudentStatus = newStudentStatus
            return {oldStudentStatus}
        },
        onError: (error, newStatus, context) => {
            if (!context) return
            handleError(error)
            setNewStudentStatus({
                statusId: context.oldStudentStatus.statusId,
                name: context.oldStudentStatus.name,
                hexColor: context.oldStudentStatus.hexColor,
                state: context.oldStudentStatus.state
            })
        }
    })

    const updateCurrentStatus = async (studentProfileId, newStatus, departmentId) => {
        try {
            await studentService.updateStudentStatus(studentProfileId, {statusId: newStatus.statusId})
            switch (departmentId) {
                case BaseDepartmentId.Academics:
                    setAcademicStatus({statusId: newStatus.statusId, name: newStatus.name})
                    break
                case BaseDepartmentId.Admissions:
                    setAdmissionsStatus({statusId: newStatus.statusId, name: newStatus.name})
                    break
                case BaseDepartmentId.FinancialAid:
                    setFinancialAidStatus({statusId: newStatus.statusId, name: newStatus.name})
                    break
                case BaseDepartmentId.StudentAccount:
                    setStudentAccountStatus({statusId: newStatus.statusId, name: newStatus.name})
                    break
                default:
                    break
            }
        } catch (error) {
            handleError(error)
        }
    }

    const getDepartmentStatusInfo = (departmentId) => {
        switch (departmentId) {
            case BaseDepartmentId.Academics:
                return {
                    statusId: academicStatus.statusId,
                    title: t("common:message.academic"),
                    status: academicStatus.name,
                    className: styles.statusBar__academic
                }
            case BaseDepartmentId.Admissions:
                return {
                    statusId: admissionsStatus.statusId,
                    title: t("common:message.admissions"),
                    status: admissionsStatus.name,
                    className: styles.statusBar__admissions
                }
            case BaseDepartmentId.FinancialAid:
                return {
                    statusId: financialAidStatus.statusId,
                    title: t("common:message.financial"),
                    status: financialAidStatus.name,
                    className: styles.statusBar__finAid
                }
            case BaseDepartmentId.StudentAccount:
                return {
                    statusId: studentAccountStatus.statusId,
                    title: t("common:message.studentAccount"),
                    status: studentAccountStatus.name,
                    className: styles.statusBar__studentAccount
                }
            default:
                return {
                    statusId: 0,
                    title: "",
                    status: ""
                }
        }
    }

    const renderListDepartmentStatus = (departmentStatuses, departmentId) => {
        const selectedStatus = departmentStatuses
            .filter((status) => status.statusSelected)
            .map(({statusId}) => `${statusId}`)
        return (
            <Menu className={styles.menuActionWrap} selectedKeys={selectedStatus}>
                {departmentStatuses.map((status) => (
                    <Menu.Item
                        key={status.statusId}
                        className={styles.menuActionItem}
                        onClick={() => handleChangeStatus(status, departmentId)}>
                        <span className={styles.menuActionItem__status}>{status.name}</span>
                    </Menu.Item>
                ))}
            </Menu>
        )
    }

    const checkCanEditDepartmentStatus = (departmentId: number) => {
        switch (departmentId) {
            case BaseDepartmentId.Academics:
                return checkPermission(
                    {
                        staff: [
                            Permissions.Staff.Academics.Registrar.Students.WorkspaceStudent.Edit,
                            Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students.StudentDetail
                                .Edit,
                            Permissions.Staff.AcademicAffairs.StudentServices.Students.StudentDetail.Edit
                        ]
                    },
                    model
                )
            case BaseDepartmentId.Admissions:
                return checkPermission(
                    {
                        staff: [Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.Edit]
                    },
                    model
                )
            case BaseDepartmentId.FinancialAid:
                return checkPermission(
                    {
                        staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.Edit]
                    },
                    model
                )
            case BaseDepartmentId.StudentAccount:
                return checkPermission(
                    {
                        staff: [Permissions.Staff.StudentAccount.Workspace.Edit]
                    },
                    model
                )
            default:
                return false
        }
    }

    const renderDepartmentStatus = (departmentId) => {
        if (model.clientSetting.isDepartmentalStatusesHidden) {
            return null
        }
        const userProfileType = model.getUserProfileType()
        const canEdit =
            userProfileType === Auth.UserProfileType.Admin ||
            (userProfileType === Auth.UserProfileType.Staff && checkCanEditDepartmentStatus(departmentId))
        const {statusId, title, status, className} = getDepartmentStatusInfo(departmentId)
        const departmentStatuses = statuses
            .filter((status) => status.departmentId === departmentId)
            .map((rest) => ({...rest, statusSelected: rest.statusId === statusId}))
        if (departmentStatuses.length > 0 && canEdit) {
            return (
                <Col span={4}>
                    <div className={styles.statusItem}>
                        <p className={styles.title}>{title}</p>
                        <Dropdown
                            overlay={() => renderListDepartmentStatus(departmentStatuses, departmentId)}
                            trigger={["click"]}>
                            <div className={cx(styles.statusBar, className, styles.statusItem__cursor)}>
                                <span className={styles.statusText}>{status}</span>
                                <Icon icon="ARROW_DOWN" className={styles.statusArrowDownIcon} color="#fff" />
                            </div>
                        </Dropdown>
                    </div>
                </Col>
            )
        }
        return (
            <Col span={4}>
                <div className={styles.statusItem}>
                    <p className={styles.title}>{title}</p>
                    <span className={cx(styles.statusBar, className)}>
                        <span className={styles.statusText}>{status}</span>
                    </span>
                </div>
            </Col>
        )
    }

    const renderStudentStatus = () => {
        if (isLoading) {
            return (
                <div className={styles.loadingWrap}>
                    <Spin />
                </div>
            )
        }
        return (
            <>
                <Col span={4}>
                    <div className={styles.statusItem}>
                        <p className={styles.title}>{t("common:message.profileStatus")}</p>
                        <span
                            className={cx(styles.statusBar, {
                                [styles.statusBar__inactive]: ["cancelled", "inactive"].includes(systemStatus)
                            })}>
                            {systemStatus}
                        </span>
                    </div>
                </Col>
                {activeProfile?.state && model.clientSetting.isNewStudentStatusesVisible && renderNewStudentStatus()}
                {isModuleEnable(Modules.Academic) && renderDepartmentStatus(BaseDepartmentId.Academics)}
                {isModuleEnable(Modules.Admissions) && renderDepartmentStatus(BaseDepartmentId.Admissions)}
                {isModuleEnable(Modules.FinancialAid) && renderDepartmentStatus(BaseDepartmentId.FinancialAid)}
                {isModuleEnable(Modules.StudentAccount) && renderDepartmentStatus(BaseDepartmentId.StudentAccount)}
            </>
        )
    }

    const renderNewStudentStatus = () => {
        return (
            <NewStudentStatusSelect
                variant="card"
                value={newStudentStatus}
                onChange={handleNewStudentStatus}
                studentState={activeProfile.state as unknown as Auth.StudentState}
            />
        )
    }

    const renderStudentAvatar = () => {
        let studentPhoto = "/image/DefaultAvatar.png"
        if (student?.photo) {
            studentPhoto = student.photo["128"]
        }
        return (
            <div>
                <img src={studentPhoto} className={styles.avatar} alt="" />
            </div>
        )
    }

    const renderHeader = () => {
        const genderPronounValue = genderPronounOptionsKeyById[student?.genderPronoun] || null

        return (
            <>
                <div>
                    {holdActivities.length > 0 &&
                        holdActivities.map(({name}, index) => (
                            <div className={styles.actionNeededWrap} key={index}>
                                <Icon icon="ALERT" className={styles.actionNeededIcon} />
                                <p className={styles.actionNeededText}>{name}</p>
                            </div>
                        ))}
                </div>
                <div className={styles.header}>
                    <div className={styles.leftHeader}>
                        {renderStudentAvatar()}
                        <p className={styles.studentName}>
                            {getFullNameOrPreferredName(student)}
                            {genderPronounValue && (
                                <span className={styles.studentGenderPronoun}>({genderPronounValue?.name})</span>
                            )}
                        </p>
                    </div>
                    <div className={styles.rightHeader}>
                        {isDetailButtonVisible && (
                            <Tooltip title="Detail View">
                                <div className={styles.rightHeaderIconWrap} onClick={goDetailView}>
                                    <Icon icon="DETAIL_FILL_CIRCLE" color="#fff" className={styles.rightHeaderIcon} />
                                </div>
                            </Tooltip>
                        )}
                        <Tooltip title="Student Detail">
                            <div className={styles.rightHeaderIconWrap} onClick={goStudentDetail}>
                                <Icon icon="PROFILE_FILL_CIRCLE" color="#fff" className={styles.rightHeaderIcon} />
                            </div>
                        </Tooltip>
                        <Tooltip title="Appointment">
                            <div className={styles.rightHeaderIconWrap} onClick={goCalendar}>
                                <Icon icon="APPOINTMENT_FILL_CIRCLE" color="#fff" className={styles.rightHeaderIcon} />
                            </div>
                        </Tooltip>
                        <Tooltip title="Documents">
                            <div className={styles.rightHeaderIconWrap} onClick={goDocuments}>
                                <Icon icon="DOCUMENT_FILL_CIRCLE" color="#fff" className={styles.rightHeaderIcon} />
                            </div>
                        </Tooltip>
                        {canShowFerpa && (
                            <Tooltip title="FERPA">
                                <div className={cx(styles.rightHeaderIconWrap, styles.paddingBottom)} onClick={goFerpa}>
                                    <span className={styles.ferpaButton}>FERPA</span>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </>
        )
    }

    const renderPhoneEmailStudent = () => {
        return (
            <>
                <div className={styles.studentPhoneEmail}>
                    <div className={styles.studentPhoneWrap}>
                        <div className={styles.studentPhoneIconWrap}>
                            <Icon icon="PHONE" className={styles.studentPhoneIcon} />
                        </div>
                        <div className={styles.studentPhoneInfo}>
                            {phones.length ? (
                                phones.map((phone, index) => (
                                    <p key={index} className={styles.studentPhone__value}>
                                        {phone}
                                    </p>
                                ))
                            ) : (
                                <p className={styles.studentPhone__value}>N/A</p>
                            )}
                        </div>
                    </div>
                    <div className={styles.studentEmailWrap}>
                        <div className={styles.studentPhoneIconWrap}>
                            <Icon icon="EMAIL" className={styles.studentPhoneIcon} />
                        </div>
                        <div className={styles.studentPhoneInfo}>
                            {emails.length ? (
                                emails.map((email, index) => (
                                    <p key={index} className={styles.studentPhone__value}>
                                        {email}
                                    </p>
                                ))
                            ) : (
                                <p className={styles.studentPhone__value}>N/A</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.studentAddress}>
                    <div className={styles.studentPhoneIconWrap}>
                        <Icon icon="LOCATION_FILL" className={styles.studentPhoneIcon} />
                    </div>
                    <div className={styles.studentPhoneInfo}>
                        <p className={styles.studentPhone__value}>
                            {[student.address, student.city, student.state, student.country, student.postalCode]
                                .map((it) => it?.trim())
                                .filter(Boolean)
                                .join(",") || "N/A"}
                        </p>
                    </div>
                </div>
            </>
        )
    }

    const renderListAdvisors = () => {
        const studentAdvisorsOrderByDefault = orderBy(studentAdvisors, ["isDefault"], ["desc"])
        return (
            <div className={cx(styles.content__value, styles.advisorsWrap)}>
                {(studentAdvisorsOrderByDefault || []).map((advisor, index) => (
                    <div key={index} className={styles.advisorWrap}>
                        <span className={styles.advisorName}>{getShortName(advisor)}</span>
                        {!!advisor.isDefault && <Icon className={styles.defaultIcon} icon="CHECKMARK_CIRCLE" />}
                        {index !== studentAdvisorsOrderByDefault.length - 1 && ", "}
                    </div>
                ))}
            </div>
        )
    }

    const getDepartmentAdvisorTitle = () => {
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return t("studentInfo.advisor", {departmentName: "Admissions"})
            case BaseDepartmentId.FinancialAid:
                return t("studentInfo.advisor", {departmentName: "Financial Aid"})
            case BaseDepartmentId.StudentAccount:
                return t("studentInfo.advisor", {departmentName: "Student Account"})
            case BaseDepartmentId.StudentServices:
                return t("studentInfo.advisor", {departmentName: "Student Service"})
            case BaseDepartmentId.Academics:
                return t("studentInfo.advisor", {departmentName: "Academic"})
            case BaseDepartmentId.CareerServices:
                return t("studentInfo.advisor", {departmentName: "Career Services"})
            default:
                return t("studentInfo.advisor", {departmentName: ""})
        }
    }

    const checkEditStudentPermission = () => {
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return checkPermission(
                    {staff: [Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.Edit]},
                    model
                )
            case BaseDepartmentId.FinancialAid:
                return checkPermission(
                    {staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.Edit]},
                    model
                )
            case BaseDepartmentId.Academics:
                return checkPermission(
                    {staff: [Permissions.Staff.AcademicAffairs.StudentServices.Students.StudentServices.Edit]},
                    model
                )
            case BaseDepartmentId.StudentServices:
                return checkPermission(
                    {staff: [Permissions.Staff.AcademicAffairs.StudentServices.Students.AcademicPlanning.Edit]},
                    model
                )
            default:
                return false
        }
    }

    const onSaveSuccess = () => {
        setIsShowForm(false)
        getUserData(studentProfileId)
    }

    const renderFormBody = () => {
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return <AdmissionsForm onClickCancel={() => setIsShowForm(false)} onSaveSuccess={onSaveSuccess} />
            case BaseDepartmentId.FinancialAid:
                return <FinancialAidForm onClickCancel={() => setIsShowForm(false)} onSaveSuccess={onSaveSuccess} />
            case BaseDepartmentId.Academics:
                return <AcademicsForm onClickCancel={() => setIsShowForm(false)} onSaveSuccess={onSaveSuccess} />
            case BaseDepartmentId.StudentServices:
                return <StudentServicesForm onClickCancel={() => setIsShowForm(false)} onSaveSuccess={onSaveSuccess} />
            default:
                return null
        }
    }

    if (isLoadingUser || !userData) {
        return (
            <div className={styles.loadingWrap}>
                <BaseLoading isShow />
            </div>
        )
    }

    if (!student) {
        return <div>Not found</div>
    }

    const startDate = activeProfile?.startDate ? moment(activeProfile.startDate).utc().format("MM/DD/YYYY") : null
    const academicTrack = activeProfile?.academicTrack || "-"
    const enrollmentType = (enrollmentTypes || []).find(({code}) => code === activeProfile?.enrollmentType)
    const enrollmentStatus = enrollmentType ? enrollmentType.name : ""
    let campuses = uniq(activeProfile?.campuses.map((campus) => campus.name).filter((item) => item))
    const termName = get(activeProfile, "currentTerm.name", "")
    const programName = get(activeProfile, "currentMajor.name", "")
    const contractEndDate = activeProfile?.contractEndDate
        ? moment(activeProfile.contractEndDate).utc().format("MM/DD/YYYY")
        : ""
    const firstDateOfClass = activeProfile?.firstDateOfClass
        ? moment(activeProfile.firstDateOfClass).utc().format("MM/DD/YYYY")
        : ""
    const projectedEndDate = activeProfile?.projectedEndDate
        ? moment(activeProfile.projectedEndDate).utc().format("MM/DD/YYYY")
        : ""
    const actualEndDate = activeProfile?.actualEndDate
        ? moment(activeProfile.actualEndDate).utc().format("MM/DD/YYYY")
        : ""
    const startingTermName = get(activeProfile, "startingTerm.name", "")
    const versionName = get(activeProfile, "currentMajorVersionId.versionName", "")
    const versionCode = get(activeProfile, "currentMajorVersionId.versionCode", "")
    const programVersionName = `${versionName}${versionCode ? ` (${versionCode})` : ""}`
    const phones = userData.phones.map(({phone}) => phone)
    const emails = userData.emails.map(({email}) => email)
    const hasPermissionToUpdateAdvisor = checkHasPermissionToUpdateAdvisor()
    const canShowFerpa = checkPermission(
        {
            staff: [Permissions.Staff.Users.Students.Ferpa.View]
        },
        model
    )
    if (campuses.length > 6) {
        const lastString = `+${campuses.slice(5).length}`
        campuses = campuses.slice(0, 5)
        campuses[campuses.length - 1] = `${
            campuses[campuses.length - 1]
        } <span style="color: var(--primary-400-base)"> (${lastString})</span>`
    }

    const renderListCustomProfileIds = () => {
        return (
            <Menu className={styles.menuActionWrap}>
                {studentProfiles.map((profile, index) => (
                    <Menu.Item
                        key={index}
                        className={styles.menuActionItem}
                        onClick={() => setStudentProfileId(profile.id)}>
                        <span>
                            {profile.customProfileId} (
                            {[Auth.UserProfileState.Applicant, Auth.UserProfileState.Prospect].includes(profile.state)
                                ? t("studentInfo.applicantID")
                                : [Auth.UserProfileState.Student, Auth.UserProfileState.Alumni].includes(profile?.state)
                                ? t("studentInfo.enrollmentID")
                                : t("studentInfo.profileId")}
                            )
                        </span>
                    </Menu.Item>
                ))}
            </Menu>
        )
    }

    const isProspectOrApplicant = [Auth.UserProfileState.Applicant, Auth.UserProfileState.Prospect].includes(
        activeProfile?.state
    )

    const isStudentOrAlumni = [Auth.UserProfileState.Student, Auth.UserProfileState.Alumni].includes(
        activeProfile?.state
    )

    const customProfileIdLabel = isProspectOrApplicant
        ? t("studentInfo.applicantID")
        : isStudentOrAlumni
        ? t("studentInfo.enrollmentID")
        : t("studentInfo.profileId")

    if (isShowForm) return renderFormBody()

    return (
        <div className={styles.userWrap}>
            {isShowHeaderEditIcon && checkEditStudentPermission() ? (
                <Button
                    type="primary"
                    shape="circle"
                    icon={<Icon icon="EDIT_LINE" color="#FFF" />}
                    className={styles.headerEditIconWrap}
                    onClick={() => setIsShowForm(true)}
                />
            ) : (
                ""
            )}
            <div className={styles.statusWrap}>
                <div className={styles.statusContainer}>{renderStudentStatus()}</div>
                <div className={styles.hideDetailBtn} onClick={toggleShowDetail}>
                    <span className={styles.hideDetail__text}>
                        {isShowDetail ? t("studentInfo.hideDetails") : t("studentInfo.moreDetails")}
                    </span>
                    <div
                        className={cx(styles.hideDetailIconWrap, {
                            [styles.hideDetailIcon__collapse]: isShowDetail
                        })}>
                        <Icon icon="CHEVRON_DOWN" color="var(--primary-400-base)" className={styles.hideDetailIcon} />
                    </div>
                </div>
            </div>
            <Row gutter={[24, 10]}>
                <Col span={24}>{renderHeader()}</Col>
                <Col span={24}>
                    <div className={styles.body}>
                        <div className={styles.content}>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>{customProfileIdLabel}</span>
                                {studentProfiles?.length > 1 ? (
                                    <Dropdown overlay={renderListCustomProfileIds} trigger={["click"]}>
                                        <div className={styles.enrollmentWrap}>
                                            <span className={cx(styles.content__value, styles.enrollmentId)}>
                                                {activeProfile?.customProfileId}
                                            </span>
                                            <Icon icon="ARROW_DOWN" className={styles.arrowDownIcon} />
                                        </div>
                                    </Dropdown>
                                ) : (
                                    <span className={styles.content__value}>{customProfileId}</span>
                                )}
                            </div>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>{getDepartmentAdvisorTitle()}</span>
                                {hasPermissionToUpdateAdvisor ? (
                                    <div
                                        className={cx(styles.content__value, styles.selectAdvisor)}
                                        onClick={advisorsPopup.open}>
                                        {studentAdvisors && studentAdvisors.length ? (
                                            renderListAdvisors()
                                        ) : (
                                            <p className={styles.selectAdvisor__text}>
                                                {departmentId
                                                    ? t("studentInfo.selectAdvisor")
                                                    : t("studentInfo.showAdvisor")}
                                            </p>
                                        )}
                                    </div>
                                ) : (
                                    renderListAdvisors()
                                )}
                            </div>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>
                                    {getFieldLabel(model, Settings.GeneralLabel.Program, t("studentInfo.program"))}
                                </span>
                                <span className={styles.content__value}>{programName}</span>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>{t("studentInfo.userID")}</span>
                                <span className={styles.content__value}>{customUserId}</span>
                            </div>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>{t("studentInfo.enrollmentType")}</span>
                                <span className={styles.content__value}>{enrollmentStatus}</span>
                            </div>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>
                                    {getFieldLabel(
                                        model,
                                        Settings.GeneralLabel.ProgramVersion,
                                        t("studentInfo.programVersion")
                                    )}
                                </span>
                                <span className={styles.content__value}>{programVersionName}</span>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>{t("user:user.startTerm")}</span>
                                <span className={styles.content__value}>{startingTermName}</span>
                            </div>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>
                                    {getFieldLabel(model, Settings.GeneralLabel.Campus, t("studentInfo.campus"))}
                                </span>
                                <div className={cx(styles.content__value, styles.campusWrap)}>
                                    {campuses.map((campus, index) => (
                                        <span key={index} className={styles.campusName}>
                                            <div dangerouslySetInnerHTML={{__html: campus}} />
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>
                                    {getFieldLabel(
                                        model,
                                        Settings.GeneralLabel.AcademicTrack,
                                        t("studentInfo.academicTrack")
                                    )}
                                </span>
                                <span className={styles.content__value}>{academicTrack}</span>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>
                                    {getFieldLabel(model, Settings.GeneralLabel.StartDate, t("studentInfo.startDate"))}
                                </span>
                                <span className={styles.content__value}>{startDate}</span>
                            </div>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>
                                    {getFieldLabel(
                                        model,
                                        Settings.GeneralLabel.ContractEndDate,
                                        t("studentInfo.contractEndDate")
                                    )}
                                </span>
                                <span className={styles.content__value}>{contractEndDate}</span>
                            </div>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>
                                    {getFieldLabel(
                                        model,
                                        Settings.GeneralLabel.CurrentTerm,
                                        t("studentInfo.currentTerm")
                                    )}
                                </span>
                                <span className={styles.content__value}>{termName}</span>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>
                                    {getFieldLabel(
                                        model,
                                        Settings.GeneralLabel.FirstDateOfClass,
                                        t("studentInfo.firstDateOfClass")
                                    )}
                                </span>
                                <span className={styles.content__value}>{firstDateOfClass}</span>
                            </div>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>
                                    {getFieldLabel(
                                        model,
                                        Settings.GeneralLabel.ProjectedEndDate,
                                        t("studentInfo.projectedEndDate")
                                    )}
                                </span>
                                <span className={styles.content__value}>{projectedEndDate}</span>
                            </div>
                            <div className={cx(styles.contentItem, styles.contentItemNoBorder)}></div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>
                                    {getFieldLabel(
                                        model,
                                        Settings.GeneralLabel.ActualEndDate,
                                        t("studentInfo.actualEndDate")
                                    )}
                                </span>
                                <span className={styles.content__value}>{actualEndDate}</span>
                            </div>
                            <div className={styles.contentItem}>
                                <span className={styles.content__title}>
                                    {getFieldLabel(model, Settings.GeneralLabel.Lda, t("user.lastDayAttendance"))}
                                </span>
                                <span className={styles.content__value}>{lastDayOfAttendance}</span>
                            </div>
                            <div className={cx(styles.contentItem, styles.contentItemNoBorder)}></div>
                        </div>
                        <div className={styles.content}></div>
                        {isShowDetail && (
                            <div className={styles.detailBody}>
                                {renderLeftInfo ? (
                                    renderLeftInfo({
                                        student,
                                        departmentId,
                                        statuses,
                                        studentStats,
                                        activeProfileId: studentProfileId
                                    })
                                ) : (
                                    <div className={styles.detailBodyLeft}>
                                        <div style={{display: "flex", flexDirection: "column", gap: 8, padding: 10}}>
                                            {renderPhoneEmailStudent()}
                                        </div>
                                        {renderExtraPhoneEmail &&
                                            renderExtraPhoneEmail({
                                                student,
                                                departmentId,
                                                statuses,
                                                studentStats,
                                                activeProfileId: studentProfileId
                                            })}
                                    </div>
                                )}
                                {renderRightInfo ? (
                                    renderRightInfo({
                                        student,
                                        departmentId,
                                        statuses,
                                        studentStats,
                                        activeProfileId: studentProfileId,
                                        lastDayOfAttendance
                                    })
                                ) : (
                                    <div className={cx(styles.detailBodyRight, styles.detailBodyRight__empty)} />
                                )}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export function StudentInfo(props: Props) {
    return (
        <ActiveStudentProvider departmentId={props.departmentId}>
            <StudentInfoView {...props} />
        </ActiveStudentProvider>
    )
}
