import React, {useEffect, useState} from "react"
import moment from "moment"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {Icon} from "components/Icon"
import {KlassappTableProps} from "types/common"

import {GradeTabHeader} from "./parts"
import styles from "./GradeTab.module.css"
import {KlassappTableHOC} from "HOC"
import {TFunction, useTranslation, withTranslation} from "react-i18next"
import {academicCourseGradesService, academicGradingSystem, courseService} from "services"
import {get, head} from "lodash"
import {formatDateOnly, getFullName, handleError} from "helpers"
import {Course} from "types/courses"
import {Model} from "Model"
import AddCourseGrade from "sections/academics/instructional/InstructionalCourse/parts/CourseGradesTab/AddCourseGrade"
import {GradingSystemItem} from "types/gradingSystem"
import {useModel} from "hooks"

type GradeTabProps = KlassappTableProps & {
    studentProfileIds: number[]
    courseIds: number[]
    scheduleId?: number
    gradingTemplates: GradingSystemItem[]
    termIds: number[]
    studentGradingTemplateId: number
    courseInfo: Course.InstructionalCourse
    history?: any
    model: Model
    t?: TFunction
}

const GradeTab = (props: GradeTabProps) => {
    const [defaultGradingTemplate, setDefaultGradingTemplate] = useState<GradingSystemItem>()
    const [dataGradingItems, setDataGradingItems] = useState([])
    const [isAddGrade, setIsAddGrade] = useState(false)
    const [students, setStudents] = useState([])
    const [gradingElementId, setGradingElementId] = useState<{id: number; gradingItem: string; colorLabel: string}>()
    const {t} = useTranslation(["common"])
    const model = useModel()
    const {
        gradingTemplates,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        onChangePage,
        onUpdateRowData,
        onUpdateTableData,
        onClickSortColumn,
        onChangeRowPerPage,
        onChangeFields,
        studentGradingTemplateId,
        onDraggableColumn,
        onChangeAllFields,
        fields,
        isLoading,
        orderField,
        dispatchFunc,
        courseInfo,
        courseIds,
        termIds,
        studentProfileIds,
        scheduleId
    } = props

    const cancelAddGrade = () => {
        getListGrades()
        setIsAddGrade(false)
    }

    const onAddGrade = () => {
        setIsAddGrade(true)
    }

    const getListStudent = async () => {
        try {
            const params: any = {
                filter: {courseIds, studentProfileIds},
                range: {page: 1, pageSize: 1}
            }
            if (termIds?.length) {
                params.filter.termIds = termIds
            }
            if (scheduleId) {
                params.filter.scheduleId = scheduleId
            }
            const {data: studentStats} = await courseService.listInstructionalStudents(params)

            const data = studentStats.map((item: any) => {
                item.id = item.student.profileId
                item.profileId = item.student.profileId
                item.fullname = getFullName(item.student)
                item.isMakeup = !!item.student.isMakeup
                item.isDropped = !!item.student.isDropped
                item.droppedDate = item.student.droppedDate
                item.addDropDate = item.student.addDropDate
                item.addDropHours = item.student.addDropHours
                item.addDropVariant = item.student.addDropVariant
                item.student.gradingTemplate = item.student.gradingTemplateTitle
                    ? {
                          title: item.student.gradingTemplateTitle,
                          id: item.student.gradingTemplateId
                      }
                    : gradingTemplates[0]
                return item
            })
            setStudents(data)
        } catch (e) {
            console.log("e")
        }
    }

    const getPageTitle = () => {
        return t("user.users")
    }

    const getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    const getFields = () => {
        return ["Title", "Date", "Time", "Type", "Grade", "Max Point Value", "Notes"]
    }

    const getGradingItems = async () => {
        try {
            if (defaultGradingTemplate) {
                const {data} = await academicGradingSystem.getDetailGradingSystem(defaultGradingTemplate.id)
                const {gradingElements = []} = data || {}
                let gradingItems = gradingElements.map((item) => ({
                    id: item.id,
                    gradingItem: item.gradingItem,
                    colorLabel: item.colorLabel
                }))
                gradingItems = [{id: -1, gradingItem: "All", colorLabel: "transparent"}, ...gradingItems]
                setDataGradingItems(gradingItems)
            }
        } catch (e) {
            handleError(e)
        }
    }
    useEffect(() => {
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getListGrades},
            {key: "getFields", func: getFields},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "getColumns", func: getTableColumn}
        ])
        getListGrades()
        getListStudent()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getGradingItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultGradingTemplate])

    useEffect(() => {
        if (gradingTemplates?.length) {
            const defaultGradingTemplate = gradingTemplates.find((item) => item.id === studentGradingTemplateId)
            setDefaultGradingTemplate(defaultGradingTemplate ?? gradingTemplates[0])
        }
    }, [studentGradingTemplateId, gradingTemplates])

    const getTableColumn = () => {
        return [
            {
                title: "Title",
                field: "title",
                style: {minWidth: "200px"}
            },
            {
                title: "Date",
                titleHtml: renderDateHeader(),
                field: "dateHtml",
                style: {minWidth: "200px"}
            },
            {
                title: "Time",
                titleHtml: renderTimeHeader(),
                field: "timeHtml",
                style: {minWidth: "150px"}
            },
            {
                title: "Type",
                field: "typeHtml",
                style: {minWidth: "200px"}
            },
            {
                title: "Grade",
                field: "grade",
                style: {minWidth: "150px"}
            },
            {
                title: "Max Point Value",
                field: "maxPointValue",
                style: {minWidth: "150px"}
            },
            {
                title: "Notes",
                field: "notes",
                style: {minWidth: "300px"}
            }
        ]
    }

    const renderDateHeader = () => {
        return (
            <div className={styles.tableHeaderTitleWrap} style={{paddingLeft: 8}}>
                <Icon className={styles.dateIcon} icon="CALENDAR" />
                <p className={styles.tableHeaderTitle}>Date</p>
            </div>
        )
    }

    const renderTimeHeader = () => {
        return (
            <div className={styles.tableHeaderTitleWrap}>
                <Icon className={styles.timeIcon} icon="CLOCK" />
                <p className={styles.tableHeaderTitle}>Time</p>
            </div>
        )
    }

    const getListGrades = async (gradingElementId?: number) => {
        const {studentProfileIds = [], courseIds = [], termIds, dispatch} = props
        dispatch({isLoading: true})
        try {
            const courseId = head(courseIds)
            let filter: any = {
                studentProfileIds,
                courseIds: courseId ? [courseId] : []
            }
            if (termIds?.length) {
                filter.termIds = termIds
            }
            if (gradingElementId !== -1) {
                filter.gradingElementId = gradingElementId
            }
            let response: any = {}
            if (studentProfileIds.length) {
                try {
                    response = await academicCourseGradesService.getCourseGradeStudents({
                        filter: filter,
                        range: {
                            page: 1,
                            pageSize: 5000
                        }
                    })
                } catch (err) {}
            }

            const courseGrades = get(response, ["courseGrades", 0, "courseGrade"], [])
            const listData = []
            for (let i = 0; i < courseGrades.length; i++) {
                if (courseGrades[i].date) {
                    listData.push({
                        ...courseGrades[i],
                        id: courseGrades[i].id + i,
                        dateHtml: renderDate(courseGrades[i]),
                        timeHtml: renderTime(courseGrades[i]),
                        typeHtml: renderType(courseGrades[i])
                    })
                }
            }
            dispatch({data: listData, total: listData.length})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const renderDate = (data) => {
        return <div style={{paddingLeft: 10}}>{formatDateOnly(data.date, model.getUserDateFormat())}</div>
    }

    const renderTime = (data) => {
        return (
            <div>
                {data.startTime && data.endTime
                    ? `${data.startTime} - ${data.endTime}`
                    : moment(data.date).format(model.getUserTimeFormat())}
            </div>
        )
    }

    const renderType = (data) => {
        return (
            <div className={styles.gradeTypeWrap}>
                <span className={styles.gradeTypeColor} style={{background: data?.colorLabel}}></span>
                <p className={styles.gradeType}>{data?.gradingItem ?? ""}</p>
            </div>
        )
    }

    const onChangeElementData = (key, value) => {
        setGradingElementId(value)
        getListGrades(value.id)
    }

    if (isAddGrade) {
        return (
            <AddCourseGrade
                courseInfo={courseInfo}
                defaultGradingTemplate={defaultGradingTemplate}
                students={students}
                setDefaultGradingTemplate={setDefaultGradingTemplate}
                gradingTemplates={gradingTemplates}
                courseId={head(courseIds)}
                scheduleId={scheduleId}
                termId={head(termIds)}
                disabledSelectGradingTemplate
                cancelAddGrade={cancelAddGrade}
            />
        )
    }

    return (
        <div>
            <GradeTabHeader
                onChangeElementData={onChangeElementData}
                gradingElementId={gradingElementId}
                onAddGrade={onAddGrade}
                dataGradingItems={dataGradingItems}
            />
            <div className={styles.tableWrap}>
                <KlassappTableHeader
                    page={page}
                    total={total}
                    isShowAction={!!data.find((item) => item.isChecked)}
                    actions={[]}
                    defaultPageSize={pageSize}
                    onChangePage={onChangePage}
                    onChangeRowPerPage={onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={onChangeFields}
                    onChangeAllFields={onChangeAllFields}
                    onDraggableColumn={onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={[]}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumn={false}
                    onUpdateRowData={onUpdateRowData}
                    isShowCheckedColumnHeader={false}
                    onChangeFields={onChangeFields}
                    onChangeAllFields={onChangeAllFields}
                    onDraggableColumn={onDraggableColumn}
                    onUpdateTableData={onUpdateTableData}
                    onClickSortColumn={onClickSortColumn}
                />
            </div>
        </div>
    )
}

export default KlassappTableHOC(withTranslation(["user"])(GradeTab))
