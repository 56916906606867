/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import {uniq, head, get} from "lodash"
import {Row, Col, Dropdown, Menu, Tooltip} from "antd"
import {useHistory} from "react-router-dom"
import {Icon} from "components/Icon"
import {getFieldLabel, getFullNameOrPreferredName} from "helpers"
import {Auth} from "types/auth"
import {useModel} from "hooks"
import {Settings} from "types/settings"
import styles from "./StudentInfo.module.css"
import {UserType} from "types/tasks"
import {getFirstProfile} from "helpers/task"

type CardField =
    | "photo"
    | "state"
    | "userId"
    | "profileId"
    | "title"
    | "role"
    | "campus"
    | "department"
    | "phone"
    | "email"
    | "address"

export type StaffInfoFieldsVisibilityConfig = Partial<Record<CardField, boolean>>

const DEFAULT_FIELDS_VISIBILITY_CONFIG: StaffInfoFieldsVisibilityConfig = {
    photo: true,
    state: true,
    userId: true,
    profileId: true,
    title: true,
    role: true,
    campus: true,
    department: true,
    phone: true,
    email: true,
    address: true
}

type StaffInfoProps = {
    user: Auth.UserProfile
    isAddButtonVisible?: boolean
    fieldsVisibility?: StaffInfoFieldsVisibilityConfig
    renderExtraPhoneEmail?: (props) => JSX.Element
    renderLeftInfo?: (props) => JSX.Element
    renderRightInfo?: (props) => JSX.Element
    onClickAdd?: () => void
}

const STATE_OPTIONS = [
    {id: "applicant", name: "Applicant"},
    {id: "employee", name: "Employee"},
    {id: "ex-employee", name: "Ex-employee"},
    {id: "supervisor", name: "Supervisor"}
]

const useFieldsVisibilityConfig = (partial: StaffInfoFieldsVisibilityConfig = {}) => {
    const config = useMemo(() => ({...DEFAULT_FIELDS_VISIBILITY_CONFIG, ...partial}), [partial])

    return {
        isFieldVisible: (field: CardField) => config[field] ?? false
    }
}

export function StaffInfo(props: StaffInfoProps) {
    const {user, renderExtraPhoneEmail, renderLeftInfo, renderRightInfo, isAddButtonVisible, onClickAdd} = props
    const [isShowDetail, setIsShowDetail] = useState(false)
    const [activeProfileId, setActiveProfileId] = useState(null)
    const model = useModel()
    const {isFieldVisible} = useFieldsVisibilityConfig(props.fieldsVisibility)
    const {t} = useTranslation(["common", "chat", "financialAid"])
    const history = useHistory()
    const staffProfiles = (user?.profiles || []).filter((profile) => profile.type === Auth.UserProfileType.Staff)
    const userId = user?.id
    const staffCustomUserId = get(user, "customUserId")

    const activeProfile = useMemo(
        () => staffProfiles.find((profile) => profile.id === activeProfileId),
        [activeProfileId]
    )

    useEffect(() => {
        const staffProfile = getFirstProfile(user, Auth.UserProfileType.Staff)
        setActiveProfileId(staffProfile.id)
    }, [user])

    const toggleShowDetail = () => {
        setIsShowDetail(!isShowDetail)
    }

    const goToUserDetail = () => {
        if (userId) {
            history.push({
                pathname: `/users/${userId}`,
                state: {edit: true, defaultRole: UserType.STAFF}
            })
        }
    }

    const goToCalendar = () => {
        history.push("/calendar")
    }

    if (!user || !activeProfile) {
        return <div>Not found</div>
    }

    let campuses = activeProfile.campuses?.map((campus) => campus.name).filter((item) => item) ?? []
    campuses = uniq(campuses)
    if (campuses.length > 6) {
        const lastString = `+${campuses.slice(5).length}`
        campuses = campuses.slice(0, 5)
        campuses[campuses.length - 1] = `${
            campuses[campuses.length - 1]
        } <span style="color: var(--primary-400-base)"> (${lastString})</span>`
    }

    let roles = activeProfile.roles.map((role) => role.name).filter((item) => item)
    roles = uniq(roles)
    if (roles.length > 6) {
        const lastString = `+${roles.slice(5).length}`
        roles = roles.slice(0, 5)
        roles[roles.length - 1] = `${
            roles[roles.length - 1]
        } <span style="color: var(--primary-400-base)"> (${lastString})</span>`
    }

    let departmentSubunits = activeProfile.departmentSubunits.map((departmentSubunit) => departmentSubunit.name)
    departmentSubunits = uniq(departmentSubunits)
    if (departmentSubunits.length > 6) {
        const lastString = `+${departmentSubunits.slice(5).length}`
        departmentSubunits = departmentSubunits.slice(0, 5)
        departmentSubunits[departmentSubunits.length - 1] = `${
            departmentSubunits[departmentSubunits.length - 1]
        } <span style="color: var(--primary-400-base)"> (${lastString})</span>`
    }

    const phones = user.phones.map(({phone}) => phone)
    const emails = user.emails.map(({email}) => email)

    const renderStaffState = () => {
        const state = STATE_OPTIONS.find((state) => state.id === activeProfile.state)
        return (
            <span className={cx(styles.statusBar, {[styles.statusBarNotDefined]: !state})}>
                {state?.name || "Not Defined"}
            </span>
        )
    }

    const renderAvatar = () => {
        let photo = "/image/DefaultAvatar.png"

        if (isFieldVisible("photo") && user.photo) {
            photo = user.photo["128"]
        }
        return (
            <div>
                <div className={styles.imgPadding}>
                    <img src={photo} className={styles.staffAvatar} alt="" />
                </div>
                {isFieldVisible("state") ? renderStaffState() : null}
            </div>
        )
    }

    const onChangeActiveProfileId = (profileId) => {
        setActiveProfileId(profileId)
    }

    const renderListProfileIds = () => (
        <Menu className={styles.menuActionWrap}>
            {staffProfiles.map((profile, index) => (
                <Menu.Item
                    key={index}
                    className={styles.menuActionItem}
                    onClick={() => onChangeActiveProfileId(profile.id)}>
                    <span>{profile.customProfileId}</span>
                </Menu.Item>
            ))}
        </Menu>
    )

    const renderHeader = () => {
        return (
            <div style={{marginBottom: 10}}>
                <p className={styles.studentName}>{getFullNameOrPreferredName(user)}</p>
                <div className={styles.header}>
                    <div className={styles.leftHeader}>
                        {(isFieldVisible("userId") || isFieldVisible("profileId")) && (
                            <div className={styles.studentInfoHeader}>
                                {isFieldVisible("userId") && (
                                    <p className={styles.studentIdWrap}>
                                        <span className={styles.studentId__title}>{t("studentInfo.userID")}:</span>
                                        <span className={styles.studentId__value}>{staffCustomUserId}</span>
                                    </p>
                                )}
                                {isFieldVisible("profileId") && (
                                    <div className={styles.studentIdWrap}>
                                        <span className={styles.studentId__title}>{t("studentInfo.profileId")}:</span>
                                        <Dropdown overlay={renderListProfileIds} trigger={["click"]}>
                                            <div className={styles.enrollmentWrap}>
                                                <Icon icon="ARROW_DOWN" className={styles.arrowDownIcon} />
                                                <span className={cx(styles.studentId__value, styles.enrollmentId)}>
                                                    {activeProfile.customProfileId}
                                                </span>
                                            </div>
                                        </Dropdown>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={styles.rightHeader}>
                        <Tooltip title="Detail">
                            <div className={styles.rightHeaderIconWrap} onClick={goToUserDetail}>
                                <Icon icon="PROFILE_FILL_CIRCLE" color="#fff" className={styles.rightHeaderIcon} />
                            </div>
                        </Tooltip>
                        <Tooltip title="Appointment">
                            <div className={styles.rightHeaderIconWrap} onClick={goToCalendar}>
                                <Icon icon="APPOINTMENT_FILL_CIRCLE" color="#fff" className={styles.rightHeaderIcon} />
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        )
    }

    const renderPhoneEmailSection = () => {
        return (
            <>
                <div className={styles.studentPhoneEmail}>
                    {isFieldVisible("phone") && (
                        <div className={styles.studentPhoneWrap}>
                            <div className={styles.studentPhoneIconWrap}>
                                <Icon icon="PHONE" className={styles.studentPhoneIcon} />
                            </div>
                            <div className={styles.studentPhoneInfo}>
                                {phones.length ? (
                                    phones.map((phone, index) => (
                                        <p key={index} className={styles.studentPhone__value}>
                                            {phone}
                                        </p>
                                    ))
                                ) : (
                                    <p className={styles.studentPhone__value}>N/A</p>
                                )}
                            </div>
                        </div>
                    )}
                    {isFieldVisible("email") && (
                        <div className={styles.studentEmailWrap}>
                            <div className={styles.studentPhoneIconWrap}>
                                <Icon icon="EMAIL" className={styles.studentPhoneIcon} />
                            </div>
                            <div className={styles.studentPhoneInfo}>
                                {emails.map((email, index) => (
                                    <p key={index} className={styles.studentPhone__value}>
                                        {email}
                                    </p>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                {isFieldVisible("address") && (
                    <div className={styles.studentAddress}>
                        <div className={styles.studentPhoneIconWrap}>
                            <Icon icon="LOCATION_FILL" className={styles.studentPhoneIcon} />
                        </div>
                        <div className={styles.studentPhoneInfo}>
                            <p className={styles.studentPhone__value}>
                                {[user.address, user.city, user.state, user.country, user.postalCode]
                                    .map((it) => it?.trim())
                                    .filter(Boolean)
                                    .join(",") || "N/A"}
                            </p>
                        </div>
                    </div>
                )}
            </>
        )
    }

    const isMoreDetailsAvailable = [
        renderLeftInfo,
        isFieldVisible("phone"),
        isFieldVisible("email"),
        isFieldVisible("address"),
        renderExtraPhoneEmail,
        renderRightInfo
    ].some(Boolean)

    return (
        <div className={styles.userWrap}>
            <Row gutter={[24, 24]}>
                <Col span={3}>{renderAvatar()}</Col>
                <Col span={21}>
                    <div className={styles.body}>
                        {renderHeader()}
                        <div className={styles.content}>
                            {isFieldVisible("title") && (
                                <div className={styles.contentItem}>
                                    <span className={styles.content__title}>{t("studentInfo.title")}</span>
                                    <span className={styles.content__value}>{activeProfile.title || "N/A"}</span>
                                </div>
                            )}
                            {isFieldVisible("campus") && (
                                <div className={styles.contentItem}>
                                    <span className={styles.content__title}>
                                        {getFieldLabel(model, Settings.GeneralLabel.Campus, t("studentInfo.campus"))}
                                    </span>
                                    <div className={cx(styles.content__value, styles.campusWrap)}>
                                        {campuses.map((campus, index) => (
                                            <span key={index} className={styles.campusName}>
                                                <div dangerouslySetInnerHTML={{__html: campus || "N/A"}} />
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={styles.content}>
                            {isFieldVisible("role") && (
                                <div className={styles.contentItem}>
                                    <span className={styles.content__title}>{t("studentInfo.role")}</span>
                                    <span className={styles.content__value}>
                                        {roles.length
                                            ? roles.map((role, index) => (
                                                  <span key={index} className={styles.campusName}>
                                                      <div dangerouslySetInnerHTML={{__html: role}} />
                                                  </span>
                                              ))
                                            : "N/A"}
                                    </span>
                                </div>
                            )}
                            {isFieldVisible("department") && (
                                <div className={styles.contentItem}>
                                    <span className={styles.content__title}>{t("studentInfo.department")}</span>
                                    <span className={cx(styles.content__value, styles.campusWrap)}>
                                        {departmentSubunits.length
                                            ? departmentSubunits.map((departmentSubunit, index) => (
                                                  <span key={index} className={styles.campusName}>
                                                      <div dangerouslySetInnerHTML={{__html: departmentSubunit}} />
                                                  </span>
                                              ))
                                            : "N/A"}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className={styles.content}>
                            {isMoreDetailsAvailable && (
                                <div className={cx(styles.hideDetailWrap, styles.hideStaffDetailWrap)}>
                                    <div className={styles.hideDetailBtn} onClick={toggleShowDetail}>
                                        <span className={styles.hideDetail__text}>
                                            {isShowDetail ? t("studentInfo.hideDetails") : t("studentInfo.showDetails")}
                                        </span>
                                        <div
                                            className={cx(styles.hideDetailIconWrap, {
                                                [styles.hideDetailIcon__collapse]: isShowDetail
                                            })}>
                                            <Icon
                                                icon="CHEVRON_DOWN"
                                                color="var(--primary-400-base)"
                                                className={styles.hideDetailIcon}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isAddButtonVisible && (
                                <div className={styles.messageBtnWrap}>
                                    <p className={styles.profileBtn} onClick={() => onClickAdd()}>
                                        {t("common:action.addContact")}
                                    </p>
                                </div>
                            )}
                        </div>
                        {isShowDetail && (
                            <div className={styles.detailBody}>
                                {renderLeftInfo ? (
                                    renderLeftInfo({user})
                                ) : (
                                    <div className={styles.detailBodyLeft}>
                                        <div style={{gap: 8, display: "flex", flexDirection: "column"}}>
                                            {renderPhoneEmailSection()}
                                        </div>
                                        {renderExtraPhoneEmail && renderExtraPhoneEmail({user})}
                                    </div>
                                )}
                                {renderRightInfo ? (
                                    renderRightInfo({user})
                                ) : (
                                    <div className={cx(styles.detailBodyRight, styles.detailBodyRight__empty)} />
                                )}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    )
}
