import {Timestamps} from "types/common"
import {Auth} from "types/auth"
import {TermDetails} from "types/terms"
import {LedgerAccount} from "types/student-account/ledger-accounts"
import {StudentCharge} from "types/student-account/student-ledger"
import {StudentPayment, StudentPaymentPlanItem} from "types/student-account/student-payment"

export enum PaymentPlanType {
    MinimumAmount = "min_amount",
    NumberPayments = "num_payments"
}

export enum PaymentPlanFrequency {
    Weekly = "weekly",
    BiWeekly = "bi-weekly",
    Monthly = "monthly"
}

export const FREQUENCY_OPTIONS = [
    {id: PaymentPlanFrequency.Weekly, name: "Weekly"},
    {id: PaymentPlanFrequency.BiWeekly, name: "Bi-weekly"},
    {id: PaymentPlanFrequency.Monthly, name: "Monthly"}
]

export const WEEKDAY_OPTIONS = [
    {id: 1, name: "Monday"},
    {id: 2, name: "Tuesday"},
    {id: 3, name: "Wednesday"},
    {id: 4, name: "Thursday"},
    {id: 5, name: "Friday"},
    {id: 6, name: "Saturday"},
    {id: 7, name: "Sunday"}
]

export enum PaymentPlanStatus {
    Active = "active",
    Canceled = "canceled"
}

export type PaymentPlan = Timestamps & {
    paymentPlanId: number
    studentProfileId: number
    studentProfile?: Auth.LinkedProfileDetails // populate
    status: PaymentPlanStatus
    code: string
    title: string
    academicYear: number
    accountId: number
    account?: LedgerAccount // populate
    termId?: number | null
    term?: TermDetails // populate
    description?: string | null
    type: PaymentPlanType
    originalAmount: number
    currentBalance: number
    minPaymentAmount: number
    numberOfPayments: number
    firstPaymentDue: string // date
    frequency: PaymentPlanFrequency
    reminderIds?: number[] | null
    reminders?: PaymentReminder[] // populate

    updatedByProfileId?: number
    updatedBy?: Auth.LinkedProfileDetails // populate

    chargeIds?: number[] // populate
    charges?: StudentCharge[] // populate
    items?: PaymentPlanItem[] // populate

    paymentItems?: StudentPaymentPlanItem[] // populate
    payments?: StudentPayment[] // populate
}

export type PaymentPlanItem = Timestamps & {
    paymentPlanItemId: number
    paymentPlanId: number
    dueDate: string
    amount: number
    paidAmount: number
    paidAt?: string | null

    // internal
    paymentAmount?: number
    paymentAccountId?: number
}

export enum PaymentReminderType {
    Before = "before",
    After = "after",
    On = "on"
}

export type PaymentReminder = Timestamps & {
    paymentReminderId: number
    position: number
    numDays: number
    type: PaymentReminderType
    templateId?: number | null
}

export enum PaymentPlanLinkedEntity {
    Profile = "profile",
    Account = "account",
    Term = "term",
    Charges = "charges",
    Payments = "payments",
    Items = "items"
}
