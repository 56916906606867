import React, {useContext} from "react"
import {Tabs} from "antd"
import {useActiveTab} from "hooks"
import {Screen} from "components/ui"
import {TabHeader} from "components/Tab"
import {StudentsTab, WorkspaceTab} from "./parts"
import {useTranslation} from "react-i18next"
import Disbursements from "../../FinancialAid/FinancialAidStudent/parts/FinAidAward/Disbursement/Disbursements"
import NinetyTenTab from "./parts/NinetyTenTab"
import BillingsTab from "./parts/BillingsTab"
import PaymentsTab from "./parts/PaymentsTab"
import {ActiveStudentContext, ActiveStudentProvider} from "context/ActiveStudentContext"
import {BaseDepartmentId} from "types/departments"
import styles from "sections/Admission/Admission.module.css"
import cx from "classnames"
import {AdvisorTitle, StudentInfoSidebar} from "uiKit"
import StudentAccountPanelInfo from "sections/shared/student/StudentAccountPanelInfo/StudentAccountPanelInfo"

const {TabPane} = Tabs

export enum StudentAccountStaffTab {
    Workspace = "workspace",
    Students = "students",
    Billings = "billings",
    Orders = "orders",
    Payments = "payments",
    Disbursement = "disbursement",
    Refund = "refund",
    NinetyTen = "9010"
}

const StudentAccountStaffView = (props) => {
    const [activeTabKey, onChangeTab] = useActiveTab(StudentAccountStaffTab.Workspace)
    const {t} = useTranslation(["student"])
    const departmentId = BaseDepartmentId.StudentAccount
    const {
        student,
        setStudent,
        setStatusChange,
        setStudentStatusChange,
        setFinancialAidStatusChange,
        studentDetailVisible
    } = useContext(ActiveStudentContext)

    React.useEffect(() => {
        setStudent(undefined)
        studentDetailVisible.close()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabKey])

    return (
        <div className={styles.screenWrap}>
            <Screen
                htmlTitle={t("student.title")}
                header={{
                    title: t("student.title")
                }}
                isReducingPaddingRight={studentDetailVisible.isVisible}>
                <Tabs className="fullwidth klassTab" activeKey={activeTabKey} onChange={onChangeTab}>
                    <TabPane
                        tab={<TabHeader title={t("student.workspace.title")} />}
                        key={StudentAccountStaffTab.Workspace}>
                        {activeTabKey === StudentAccountStaffTab.Workspace && <WorkspaceTab />}
                    </TabPane>
                    <TabPane
                        tab={<TabHeader title={t("student.students.title")} />}
                        key={StudentAccountStaffTab.Students}>
                        {activeTabKey === StudentAccountStaffTab.Students && (
                            <StudentsTab isSAStudents departmentId={BaseDepartmentId.StudentAccount} />
                        )}
                    </TabPane>
                    <TabPane tab={<TabHeader title={t("student.billings")} />} key={StudentAccountStaffTab.Billings}>
                        {activeTabKey === StudentAccountStaffTab.Billings && <BillingsTab />}
                    </TabPane>
                    <TabPane tab={<TabHeader title="Disbursement" />} key={StudentAccountStaffTab.Disbursement}>
                        {activeTabKey === StudentAccountStaffTab.Disbursement && <Disbursements />}
                    </TabPane>
                    <TabPane tab={<TabHeader title="Payments" />} key={StudentAccountStaffTab.Payments}>
                        {activeTabKey === StudentAccountStaffTab.Payments && <PaymentsTab />}
                    </TabPane>
                    <TabPane tab={<TabHeader title="90/10" />} key={StudentAccountStaffTab.NinetyTen}>
                        {activeTabKey === StudentAccountStaffTab.NinetyTen && <NinetyTenTab />}
                    </TabPane>
                </Tabs>
            </Screen>
            <div className={cx(styles.expandedSidebar, {[styles.hidden]: !studentDetailVisible.isVisible})}>
                {!!student?.profileId && (
                    <StudentInfoSidebar
                        onClose={studentDetailVisible.close}
                        data={{
                            departmentId,
                            student,
                            isDetailButtonVisible: true,
                            detailUrl: "student-account",
                            fromPage: "student-account",
                            renderDepartmentInfo: (props) => <StudentAccountPanelInfo student={student} {...props} />,
                            onChangeStatus: setStatusChange,
                            onChangeStudentStatus: setStudentStatusChange,
                            onChangeFinancialAidStatus: setFinancialAidStatusChange,
                            advisorTitle: AdvisorTitle.advisors
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default function StudentAccountStaff() {
    return (
        <ActiveStudentProvider departmentId={BaseDepartmentId.StudentAccount}>
            <StudentAccountStaffView />
        </ActiveStudentProvider>
    )
}
